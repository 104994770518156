import { getError, AuthActions } from '@dive/react-redux-networking';

import { CoachingActions } from '../actions/coaching';

const DEFAULT = {
    isLoading: false,
    filters: {}
};

const coaching = (state = Object.assign({}, DEFAULT), action) => {
    switch (action.type) {
        case CoachingActions.SET_FILTERS:
            return Object.assign({}, DEFAULT, {
                filters: action.filters
            });

        case CoachingActions.GET_COACHINGS:
            return Object.assign({}, state, { isLoading: true, error: null });
        case CoachingActions.GET_COACHINGS_SUCCESS:
            // combine pages if necessary
            const combinePages =
                action.meta.previousAction.payload.request.combinePages;
            return Object.assign({}, state, {
                data:
                    action.payload.data.page > 1 && combinePages
                        ? Object.assign({}, action.payload.data, {
                              coaching: [
                                  ...state.data.coaching,
                                  ...action.payload.data.coaching
                              ]
                          })
                        : action.payload.data,
                isLoading: false,
                timestamp: new Date().getTime()
            });
        case CoachingActions.GET_COACHINGS_FAIL:
            return Object.assign({}, state, {
                isLoading: false,
                error: getError(action).getMessage()
            });
        case CoachingActions.CLEAR_COACHINGS:
            return Object.assign({}, DEFAULT);

        /* LOGOUT */
        case AuthActions.CLEAR_AUTH:
            // remove list
            return Object.assign({}, DEFAULT);

        default:
            return state;
    }
};

export default coaching;
