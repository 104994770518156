// @flow
import * as React from 'react';
import { connect } from 'react-redux';
import { t } from '@dive/localization-js';
import {
    isImpersonating,
    stopImpersonation
} from '@dive/react-redux-networking';
import { getFullName } from '../../../core/models/client';
import { ROUTE_APP } from '../../platform/routing';

const mapStateToProps = state => {
    return {
        user: state.user.data,
        isImpersonating: state.user.data && isImpersonating(state.user.data)
    };
};

const mapDispatchToProps = {
    stopImpersonation
};

class ImpersonationBanner extends React.Component {
    stopImpersonation = () => {
        this.props.stopImpersonation().then(() => {
            window.location = ROUTE_APP;
        });
    };

    render() {
        return this.props.isImpersonating ? (
            <div className="impersonation alert alert-warning">
                <div className="ml-3 mr-3">
                    {t('general.impersonation.warning', {
                        user: getFullName(this.props.user)
                    })}
                </div>
                <button className="btn" onClick={this.stopImpersonation}>
                    {t('general.impersonation.stop')}
                </button>
            </div>
        ) : null;
    }
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(ImpersonationBanner);
