import { isVoid, debounce } from '@dive/utils-js';
import React, { Component } from 'react';

import IconButton from '../buttons/IconButton';

class SearchField extends Component {
    btnStyle = {
        top: '15%',
        right: '5%'
    };

    constructor(props) {
        super(props);

        this.state = {
            value: props.initialValue || ''
        };

        this.handleSearch = debounce(this.handleChange);
        this.handleValueChange = this.handleValueChange.bind(this);
        this.handleClick = this.handleClick.bind(this);
    }

    handleChange() {
        const { name, onChange } = this.props;
        const { value } = this.state;

        onChange(value, name);
    }

    handleClick(e) {
        e.preventDefault();

        this.handleChange();
    }

    handleValueChange(e) {
        this.setState({ value: e.currentTarget.value }, this.handleSearch);
    }

    render() {
        const { className, name, placeholder } = this.props;
        const { value } = this.state;

        let classes = 'position-relative';

        if (!isVoid(className)) {
            classes += ` ${className}`;
        }

        return (
            <div className={classes}>
                <input
                    placeholder={placeholder}
                    onChange={this.handleValueChange}
                    value={value}
                    className="form-control pr-5"
                    type="text"
                    name={name}
                />

                <IconButton
                    onClick={this.handleClick}
                    style={this.btnStyle}
                    className="position-absolute"
                    icon="search"
                />
            </div>
        );
    }
}

export default SearchField;
