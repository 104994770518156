import React, { Fragment } from 'react';
import { connect } from 'react-redux';
import { t } from '@dive/localization-js';
import Card from '../../shared/card/Card';
import BackButton from '../../shared/buttons/BackButton';
import { ROUTE_LOGIN } from '../../../platform/routing';
import DynamicForm from '../../shared/forms/DynamicForm';
import Logo from '../../shared/logo/Logo';
import { register } from '@dive/react-redux-networking';

const mapDispatchToProps = dispatch => {
    return {
        register: data => {
            return dispatch(register(data));
        }
    };
};

class RegisterContainer extends React.Component {
    constructor(props) {
        super(props);

        this.setFields();

        this.state = {
            isLoading: false,
            showSuccess: false,
            errors: {}
        };
    }

    setFields() {
        this.fields = [
            {
                name: 'email',
                label: t('register.form.email'),
                type: 'email',
                required: true,
                size: '12'
            },
            {
                name: 'first_name',
                label: t('register.form.first_name'),
                type: 'string',
                required: true,
                size: '6'
            },
            {
                name: 'name',
                label: t('register.form.name'),
                type: 'string',
                required: true,
                size: '6'
            },
            {
                name: 'address_country',
                label: t('register.form.country'),
                type: 'select',
                required: true,
                size: '12',
                options: [
                    {
                        name: t('clients.detail.general.country.be'),
                        value: 'BE'
                    },
                    {
                        name: t('clients.detail.general.country.nl'),
                        value: 'NL'
                    }
                ]
            },
            {
                name: 'password',
                label: t('register.form.password'),
                type: 'password',
                required: true,
                size: '12',
                min_length: 8
            },
            {
                name: 'password_confirmation',
                label: t('register.form.password_confirm'),
                type: 'password',
                equals: 'password',
                required: true,
                size: '12',
                min_length: 8
            },
            {
                name: 'privacy_policy',
                label: (
                    <span>
                        {t('register.form.terms')}
                        <a
                            target="_blank"
                            rel="noopener noreferrer"
                            href="https://myfoodcoachandme.com/wp-content/uploads/My-Food-Coach-and-Me-PRIVACY-POLICY.pdf">
                            {t(
                                'register.form.terms.privacy_policy'
                            ).toLowerCase()}
                        </a>
                    </span>
                ),
                type: 'checkbox',
                required: true,
                size: '12'
            },
            {
                name: 'licenses',
                label: (
                    <span>
                        {t('register.form.terms')}
                        <a
                            target="_blank"
                            rel="noopener noreferrer"
                            href="https://myfoodcoachandme.com/wp-content/uploads/My-Food-Coach-and-Me-LICENTIEVOORWAARDEN.pdf">
                            {t('register.form.terms.licenses').toLowerCase()}
                        </a>
                    </span>
                ),
                type: 'checkbox',
                required: true,
                size: '12'
            },
            {
                name: 'data_protection',
                label: (
                    <span>
                        {t('register.form.terms')}
                        <a
                            target="_blank"
                            rel="noopener noreferrer"
                            href="https://myfoodcoachandme.com/wp-content/uploads/My-Food-Coach-and-Me-VERWERKINGSVOORWAARDEN.pdf">
                            {t(
                                'register.form.terms.data_protection'
                            ).toLowerCase()}
                        </a>
                    </span>
                ),
                type: 'checkbox',
                required: true,
                size: '12'
            }
        ];
    }

    onInputChanged(event) {
        this.setState({
            [event.target.name]: event.target.value
        });
    }

    onSubmit = data => {
        return this.props.register(data);
    };

    onSuccess = () => {
        // nothing
        this.setState({
            showSuccess: true
        });
    };

    render() {
        return (
            <div className="container">
                <Card>
                    <BackButton
                        to={ROUTE_LOGIN}
                        label={t('reset.password.back')}
                    />
                    <Logo />
                    {this.state.showSuccess ? (
                        <Fragment>
                            <h1>{t('register.title.success')}</h1>
                            <p>{t('register.description.success')}</p>
                        </Fragment>
                    ) : (
                        <Fragment>
                            <h1>{t('register.title')}</h1>
                            <p>{t('register.description')}</p>
                            <DynamicForm
                                model={{ country: 'BE' }}
                                fields={this.fields}
                                onFinished={this.onSuccess}
                                onSaveForm={this.onSubmit}
                            />
                        </Fragment>
                    )}
                </Card>
            </div>
        );
    }
}

export default connect(
    undefined,
    mapDispatchToProps
)(RegisterContainer);
