import { t } from '@dive/localization-js';
import { request } from '@dive/react-redux-networking';
import { isVoid } from '@dive/utils-js';
import ContentBody from 'app/components/shared/content/ContentBody';
import ContentHeader from 'app/components/shared/content/ContentHeader';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import {
    createLibraryEntry,
    deleteLibraryEntry,
    fetchLibrary,
    getLibrary,
    resetFilters,
    setFilters,
    updateLibraryEntry
} from '../../../../core/actions/library';
import {
    selectPage,
    selectPagination,
    selectSort
} from '../../../../core/models/app';
import {
    FileType,
    hasSetFilters,
    selectLibraryData,
    selectLibraryEntries,
    selectLibraryError,
    selectLibraryFilters
} from '../../../../core/models/library';
import { isLoadingData } from '../../../../core/utils/data';
import { selectCategoriesAsOptions } from '../../../platform/model/category';
import { history, ROUTE_LIBRARY_DETAIL } from '../../../platform/routing';
import RoundButton from '../../shared/buttons/RoundButton';
import DateRangePicker from '../../shared/datepicker/DateRangePicker';
import PageFilter from '../../shared/filter/PageFilter';
import SearchField from '../../shared/forms/SearchField';
import Select from '../../shared/forms/Select';
import PageHeader from '../../shared/header/PageHeader';
import DeleteAlertModal from '../../shared/modal/DeleteAlertModal';
import ErrorView from '../../shared/view/ErrorView';
import LibraryUploadForm from '../form/LibraryUploadForm';
import LibraryTable from '../table/LibraryTable';

class LibraryOverviewContainer extends Component {
    state = {
        searchKey: 0
    };
    types;

    constructor(props) {
        super(props);

        this.types = Object.keys(FileType).map(type => ({
            display: t('library.type.' + FileType[type]),
            value: FileType[type]
        }));

        this.actions = [
            {
                onClick: this.showForm,
                icon: 'edit'
            },
            {
                className: 'btn-alert',
                onClick: this.showAlert,
                icon: 'delete'
            }
        ];
    }

    // ///////////////////////////////////////////////////////////////////////
    // LIFECYCLE METHODS
    // ///////////////////////////////////////////////////////////////////////

    componentDidMount() {
        this.props.getLibrary();
    }

    componentDidUpdate() {
        this.props.getLibrary();
    }

    // ///////////////////////////////////////////////////////////////////////
    // EVENT HANDLERS
    // ///////////////////////////////////////////////////////////////////////

    handleClear = () => {
        this.props.resetFilters();

        this.setState(prevState => ({ searchKey: prevState.searchKey + 1 }));
    };

    handleCreate = data => {
        return this.props.createLibraryEntry(data);
    };

    handleDelete = () => {
        return this.props.deleteLibraryEntry(this.state.delete);
    };

    handleFinished(entry) {
        history.push(ROUTE_LIBRARY_DETAIL.replace(':id', entry.id));
    }

    handleUpdate = (id, data) => {
        return this.props.updateLibraryEntry(id, data);
    };

    handleFilterChange = (value, name) => {
        const filters = { ...this.props.filters, [name]: value };

        this.props.setFilters(filters);
    };

    handleSort = (column, order) => {
        const sort = isVoid(column) ? null : { column, order };

        this.props.fetchLibrary(this.props.page, sort);
    };

    handlePageChanged = page => {
        this.props.fetchLibrary(page, this.props.sort);
    };

    // ///////////////////////////////////////////////////////////////////////
    // OTHERS
    // ///////////////////////////////////////////////////////////////////////

    hideAlert = () => {
        this.setState({ delete: null });
    };

    hideForm = () => {
        this.setState({ entry: null });
    };

    showAlert = entry => {
        this.setState({ delete: entry.id });
    };

    showForm = entry => {
        const model = isVoid(entry) ? {} : { ...entry };

        this.setState({ entry: model });
    };

    render() {
        const {
            categoryOptions,
            error,
            isLoading,
            filters,
            hasFilters,
            library,
            page,
            pagination,
            sort
        } = this.props;
        const { delete: del, entry, searchKey } = this.state;

        const select = { maxWidth: '13rem' };

        return (
            <>
                <ContentHeader>
                    <PageHeader
                        className="d-flex justify-content-between"
                        title={t('library')}>
                        <RoundButton
                            className="mb-auto"
                            icon="add"
                            label={t('library.add')}
                            onClick={this.showForm}
                        />
                    </PageHeader>

                    <PageFilter
                        onClearClick={this.handleClear}
                        showClear={hasFilters}>
                        <Select
                            style={select}
                            className="mr-2"
                            name="type"
                            options={this.types}
                            placeholder={t('library.filter.file_type')}
                            onChange={this.handleFilterChange}
                            value={filters.type}
                        />

                        {this.props.tags && this.props.tags.length > 0 && (
                            <select
                                className="form-control"
                                value={this.props.filters.tag || ''}
                                onChange={e =>
                                    this.handleFilterChange(
                                        e.target.value,
                                        'tag'
                                    )
                                }>
                                <option>
                                    {t('digitalcoaching.filter.tag')}
                                </option>
                                {this.props.tags.map(tag => (
                                    <option key={tag.id} value={tag.id}>
                                        {tag.name}
                                    </option>
                                ))}
                            </select>
                        )}

                        <p className="d-none d-md-block mb-0 mx-2">
                            {t('library.filter.publication')}:
                        </p>

                        <Select
                            style={select}
                            className="mr-2 mt-2 mt-md-0"
                            name="category"
                            options={categoryOptions}
                            placeholder={t('library.filter.category')}
                            onChange={this.handleFilterChange}
                            value={filters.category}
                        />

                        <DateRangePicker
                            onDateChange={this.handleFilterChange}
                            className="flex-column flex-sm-row mt-2 mt-md-0 "
                            startDate={filters.interval.startDate}
                            endDate={filters.interval.endDate}
                            name="interval"
                        />

                        <SearchField
                            key={searchKey}
                            name="search"
                            placeholder={t('library.filter.search') + '...'}
                            onChange={this.handleFilterChange}
                            initialValue={filters.search}
                            className="mt-2 mt-md-0 ml-xl-auto"
                        />
                    </PageFilter>
                </ContentHeader>

                <ContentBody>
                    {isVoid(error) ? (
                        <LibraryTable
                            actions={this.actions}
                            category={filters.category}
                            tag={filters.tag}
                            library={library}
                            isLoading={isLoading}
                            onDelete={this.showAlert}
                            onEdit={this.showForm}
                            page={page}
                            pagination={pagination}
                            sort={sort}
                            onSort={this.handleSort}
                            onPageChanged={this.handlePageChanged}
                        />
                    ) : (
                        <ErrorView error={error} />
                    )}
                </ContentBody>

                {!isVoid(entry) && (
                    <LibraryUploadForm
                        entry={entry}
                        onCreate={this.handleCreate}
                        onUpdate={this.handleUpdate}
                        onDismissClicked={this.hideForm}
                        onFinished={this.handleFinished}
                    />
                )}

                {!isVoid(del) && (
                    <DeleteAlertModal
                        title={t('library.delete.title')}
                        description={t('library.delete.description')}
                        onDelete={this.handleDelete}
                        onDismissClicked={this.hideAlert}
                        onFinished={this.hideAlert}
                    />
                )}
            </>
        );
    }
}

const mapStateToProps = state => ({
    categoryOptions: selectCategoriesAsOptions(state),
    tags: state.tags.data,
    filters: selectLibraryFilters(state),
    hasFilters: hasSetFilters(state),
    error: selectLibraryError(state),
    isLoading: isLoadingData(state.library),
    library: selectLibraryEntries(state),
    page: selectPage(selectLibraryData(state)),
    pagination: selectPagination(selectLibraryData(state)),
    sort: selectSort(selectLibraryData(state))
});

const mapDispatchToProps = dispatch => ({
    resetFilters: () => dispatch(resetFilters()),
    setFilters: filters => dispatch(setFilters(filters)),
    createLibraryEntry: data => request(dispatch(createLibraryEntry(data))),
    deleteLibraryEntry: id => request(dispatch(deleteLibraryEntry(id))),
    updateLibraryEntry: (id, data) =>
        request(dispatch(updateLibraryEntry(id, data))),
    fetchLibrary: (page, sort) => dispatch(fetchLibrary(page, sort)),
    getLibrary: () => dispatch(getLibrary())
});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(LibraryOverviewContainer);
