import React, { Component } from 'react';
import {
    fetchSettings,
    updateSettings
} from '../../../../../core/actions/settings';
import {
    getClientOverviewColumns,
    getConsultationOverviewColumns
} from '../../../../../core/models/settings';

import { DASHBOARD_FILTERS } from 'app/platform/constants';
import DynamicForm from '../../../shared/forms/DynamicForm';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { isEmptyText } from '@dive/utils-js';
import { request } from '@dive/react-redux-networking';
import { t } from '@dive/localization-js';

const CLIENT_COLUMNS = [
    'weight',
    'bmi',
    'ideal_weight',
    'birth_date',
    'length',
    'coaching',
    'categories'
];

const CONSULTATION_COLUMNS = [
    'note',
    'weight',
    'bmi',
    'fat',
    'lean',
    'muscle',
    'visceral',
    'bmr',
    'clothing_size',
    'blood_pressure'
];

class GeneralSettingsForm extends Component {
    fields = [
        {
            name: 'max_coaching_interval',
            label: t('settings.general.max_coaching_interval'),
            type: 'number',
            size: '6'
        },
        {
            name: 'client',
            label: t('settings.general.client_overview_columns'),
            type: 'checkbox',
            required: false,
            isArray: true,
            size: '12',
            options: CLIENT_COLUMNS.map(col => ({
                value: col,
                name: t('clients.list.' + col)
            }))
        },
        {
            name: 'consultation',
            label: t('settings.general.consultation_overview_columns'),
            type: 'checkbox',
            required: false,
            isArray: true,
            size: '12'
        },
        {
            name: 'dashboard',
            label: t('settings.general.dashboard'),
            type: 'checkbox',
            required: false,
            isArray: true,
            options: DASHBOARD_FILTERS.map(col => ({
                value: col,
                name: t('dashboard.filters.' + col)
            }))
        }
    ];

    constructor(props) {
        super(props);

        // DEFAULT DASHBOARD ITEMS FILTERS
        const filters =
            props.settings.dashboard_filters &&
            props.settings.dashboard_filters.split(',');

        // ADD CUSTOM FIELDS TO CONSULTATIONS FILTERS
        this.addCustomOptionsToFilter(
            CONSULTATION_COLUMNS,
            'consultation',
            'consultations.list'
        );

        // DASHBOARD CHECKED FILTERS
        const dashboard = filters
            ? DASHBOARD_FILTERS.filter(item => !filters.includes(item))
            : DASHBOARD_FILTERS;

        // CLIENT CHECKED FILTERS
        const client = getClientOverviewColumns(props.settings).split(',');

        // CONSULTATION CHECKED FILTERS
        const consultation = getConsultationOverviewColumns(
            props.settings
        ).split(',');

        this.state = {
            isLoading: false,
            errors: {},
            settings: {
                ...props.settings,
                dashboard,
                client,
                consultation
            }
        };
    }

    addCustomOptionsToFilter = (
        defaultOptions,
        fieldName,
        translationPrefix
    ) => {
        // DEFAULT OPTIONS
        const options = defaultOptions.map(col => ({
            value: col,
            name: t(`${translationPrefix}.${col}`)
        }));

        // ALL CUSTOM FIELDS
        const custom = this.props.fields.map(field => ({
            value: String(field.id),
            name: field.name
        }));

        // CONCAT CUSTOM FIELDS TO DEFAULT CLIENT FIELDS
        this.fields.find(
            field => field.name === fieldName
        ).options = options.concat(custom);
    };

    handleSubmit = model => {
        const { client, consultation, dashboard, ...data } = model;

        // client columns back to comma separated strings
        data.client_overview_columns = client
            .sort(
                (a, b) => CLIENT_COLUMNS.indexOf(a) - CLIENT_COLUMNS.indexOf(b)
            )
            .join(',');

        // dashboard columns back to comma separated strings
        data.dashboard_filters = DASHBOARD_FILTERS.filter(
            item => !dashboard.includes(item)
        ).join(',');

        data.consultation_overview_columns = consultation
            .filter(c => !isEmptyText(c))
            .sort((a, b) => {
                if (CONSULTATION_COLUMNS.indexOf(a) === -1) {
                    return 1;
                }
                if (CONSULTATION_COLUMNS.indexOf(b) === -1) {
                    return -1;
                }

                return (
                    CONSULTATION_COLUMNS.indexOf(a) -
                    CONSULTATION_COLUMNS.indexOf(b)
                );
            })
            .join(',');

        return this.props.saveSettings(data);
    };

    render() {
        const { settings } = this.state;
        const { fetchSettings } = this.props;

        if (!settings) {
            return null;
        }

        return (
            <DynamicForm
                title={t('treatment.plan.edit')}
                model={settings}
                fields={this.fields}
                onFinished={fetchSettings}
                onSaveForm={this.handleSubmit}
                submitLabel={t('general.form.update')}
            />
        );
    }
}

GeneralSettingsForm.propTypes = {
    settings: PropTypes.object.isRequired
};

const mapDispatchToProps = dispatch => ({
    saveSettings: settings => request(dispatch(updateSettings(settings))),
    fetchSettings: () => dispatch(fetchSettings())
});

export default connect(
    undefined,
    mapDispatchToProps
)(GeneralSettingsForm);
