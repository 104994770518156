import DashboardCard from './cards/DashboardCard';
import React from 'react';

export default function DashboardCardOverview({ cards }) {
    return (
        <div className="row">
            {cards.map(card => {
                return <DashboardCard key={card} cardName={card} />;
            })}
        </div>
    );
}
