import { isVoid } from '@dive/utils-js';
import { t } from '@dive/localization-js';
import { request } from '@dive/react-redux-networking';
import React, { Component } from 'react';
import { connect } from 'react-redux';

import {
    deleteEntryReceivers,
    resetClientLibraryFilters,
    setClientLibraryFilters,
    updateEntryReceivers
} from '../../../../../core/actions/library';
import {
    hasSetClientLibraryFilters,
    selectClientLibrary,
    selectClientLibraryData,
    selectClientLibraryEntries,
    selectClientLibraryError,
    selectClientLibraryFilters
} from '../../../../platform/model/client';
import { isLoadingData } from '../../../../../core/utils/data';
import {
    selectPage,
    selectPagination,
    selectSort
} from '../../../../../core/models/app';
import RoundButton from '../../../shared/buttons/RoundButton';
import LibraryTable from '../../../library/table/LibraryTable';
import LibraryLinkForm from '../../../library/form/LibraryLinkForm';
import DeleteAlertModal from '../../../shared/modal/DeleteAlertModal';
import ErrorView from '../../../shared/view/ErrorView';
import PageFilter from '../../../shared/filter/PageFilter';
import SearchField from '../../../shared/forms/SearchField';
import {
    getClientLibrary,
    fetchClientLibrary
} from 'app/platform/actions/library';

class ClientLibraryTab extends Component {
    id;
    state = {
        delete: null,
        displayForm: false,
        searchKey: 0
    };

    constructor(props) {
        super(props);

        this.client = this.props.client;
        this.id = this.props.client.id;

        this.actions = [
            { icon: 'edit', onClick: this.handleEdit },
            { className: 'btn-alert', icon: 'unlink', onClick: this.showAlert }
        ];
    }

    // ///////////////////////////////////////////////////////////////////////
    // LIFECYCLE METHODS
    // ///////////////////////////////////////////////////////////////////////

    componentDidMount() {
        this.props.getClientLibrary(this.id);
    }

    componentDidUpdate() {
        this.props.getClientLibrary(this.id);
    }

    // ///////////////////////////////////////////////////////////////////////
    // EVENT HANDLERS
    // ///////////////////////////////////////////////////////////////////////

    handleClear = () => {
        this.props.resetFilters();

        this.setState(prevState => ({ searchKey: prevState.searchKey + 1 }));
    };

    handleCreate = model => {
        const { id, ...data } = model;
        return this.props.updateEntryReceivers(id, data);
    };

    handleDelete = () => {
        const data = { clients: [{ id: this.id }] };

        return this.props.deleteEntryReceivers(this.state.delete, data);
    };

    handleDismiss = () => {
        this.setState({ displayForm: false, receiver: null });
    };

    handleEdit = entry => {
        this.setState({
            displayForm: true,
            receiver: {
                entry: entry.id,
                published_at: entry.receivers.clients.find(
                    c => c.id === this.id
                ).published_at,
                type: 'clients'
            }
        });
    };

    handleFinished = () => {
        this.setState({ displayForm: false, receiver: null });
    };

    handleFilterChange = (value, name) => {
        const filters = { ...this.props.filters, [name]: value };

        this.props.setFilters(filters);
    };

    handleSort = (column, order) => {
        const sort = isVoid(column) ? null : { column, order };

        this.props.fetchClientLibrary(this.id, this.props.page, sort);
    };

    handlePageChanged = page => {
        this.props.fetchClientLibrary(this.id, page, this.props.sort);
    };

    // ///////////////////////////////////////////////////////////////////////
    // OTHERS
    // ///////////////////////////////////////////////////////////////////////

    hideAlert = () => {
        this.setState({ delete: null });
    };

    showAlert = receiver => {
        this.setState({ delete: receiver.id });
    };

    toggleLink = () => {
        this.setState(prevState => ({ displayForm: !prevState.displayForm }));
    };

    render() {
        const {
            error,
            isLoading,
            library,
            pagination,
            readOnly,
            filters,
            hasFilters,
            sort
        } = this.props;
        const { delete: del, displayForm, receiver, searchKey } = this.state;

        return (
            <>
                {!readOnly && (
                    <div className="d-flex align-items-center mb-3">
                        <PageFilter
                            onClearClick={this.handleClear}
                            showClear={hasFilters}>
                            <SearchField
                                key={searchKey}
                                name="search"
                                placeholder={t('library.filter.search') + '...'}
                                onChange={this.handleFilterChange}
                                initialValue={filters.search}
                                className="mt-2 mt-md-0 ml-xl-auto"
                            />
                        </PageFilter>

                        {this.props.tags && this.props.tags.length > 0 && (
                            <form className="form-filter ml-md-2 mt-2">
                                <select
                                    className="form-control"
                                    value={filters.tag || ''}
                                    onChange={e =>
                                        this.handleFilterChange(
                                            e.target.value,
                                            'tag'
                                        )
                                    }>
                                    <option>
                                        {t('digitalcoaching.filter.tag')}
                                    </option>
                                    {this.props.tags.map(tag => (
                                        <option key={tag.id} value={tag.id}>
                                            {tag.name}
                                        </option>
                                    ))}
                                </select>
                            </form>
                        )}

                        <RoundButton
                            className="ml-auto"
                            icon="link"
                            label={t('library.client.link')}
                            onClick={this.toggleLink}
                        />
                    </div>
                )}

                {isVoid(error) ? (
                    <LibraryTable
                        noNavigate
                        actions={this.actions}
                        client={this.id}
                        error={error}
                        isLoading={isLoading}
                        library={library}
                        pagination={pagination}
                        sort={sort}
                        onSort={this.handleSort}
                        onPageChanged={this.handlePageChanged}
                    />
                ) : (
                    <ErrorView error={error} />
                )}

                {displayForm && (
                    <LibraryLinkForm
                        client={this.client}
                        onCreate={this.handleCreate}
                        onDismiss={this.handleDismiss}
                        onFinished={this.handleFinished}
                        receiver={receiver}
                    />
                )}

                {!isVoid(del) && (
                    <DeleteAlertModal
                        title={t('library.link.delete.title')}
                        description={t('library.link.delete.description')}
                        onDelete={this.handleDelete}
                        onDismissClicked={this.hideAlert}
                        onFinished={this.hideAlert}
                    />
                )}
            </>
        );
    }
}

const mapStateToProps = state => ({
    filters: selectClientLibraryFilters(state),
    error: selectClientLibraryError(state),
    isLoading: isLoadingData(selectClientLibrary(state)),
    library: selectClientLibraryEntries(state),
    hasFilters: hasSetClientLibraryFilters(state),
    page: selectPage(selectClientLibraryData(state)),
    pagination: selectPagination(selectClientLibraryData(state)),
    sort: selectSort(selectClientLibraryData(state)),
    tags: state.tags.data
});

const mapDispatchToProps = dispatch => ({
    resetFilters: () => dispatch(resetClientLibraryFilters()),
    setFilters: filters => dispatch(setClientLibraryFilters(filters)),
    deleteEntryReceivers: (id, data) =>
        request(dispatch(deleteEntryReceivers(id, data))),
    fetchClientLibrary: (id, page, sort) =>
        dispatch(fetchClientLibrary(id, page, sort)),
    getClientLibrary: id => dispatch(getClientLibrary(id)),
    updateEntryReceivers: (id, data) =>
        request(dispatch(updateEntryReceivers(id, data)))
});

export default connect(mapStateToProps, mapDispatchToProps)(ClientLibraryTab);
