import { isVoid } from '@dive/utils-js';
import React from 'react';

import CoachingItem from './CoachingItem';
import { t } from '@dive/localization-js';

const DigitalCoachingItems = ({ className, coaching, onImageClick }) => {
    if (
        isVoid(coaching.image_food) &&
        isVoid(coaching.image_movement) &&
        isVoid(coaching.image_motivation) &&
        isVoid(coaching.message_food) &&
        isVoid(coaching.message_movement) &&
        isVoid(coaching.message_motivation)
    ) {
        return null;
    }

    return (
        <dl className={className}>
            <CoachingItem
                onImageClick={onImageClick}
                message={coaching.message_food}
                image={coaching.image_food}
                type="food"
            />

            <CoachingItem
                onImageClick={onImageClick}
                message={coaching.message_movement}
                image={coaching.image_movement}
                type="movement"
            />

            <CoachingItem
                onImageClick={onImageClick}
                message={coaching.message_motivation}
                image={coaching.image_motivation}
                type="motivation"
            />

            {coaching.tags.length > 0 && (
                <>
                    <dt>{t('digitalcoaching.list.tags')}</dt>
                    <dd>{coaching.tags.map(tag => tag.name).join(', ')}</dd>
                </>
            )}
        </dl>
    );
};

export default DigitalCoachingItems;
