import React, { Fragment } from 'react';
import { connect } from 'react-redux';
import { t } from '@dive/localization-js';
import { request } from '@dive/react-redux-networking';

import DynamicForm from '../../shared/forms/DynamicForm';
import {
    acceptInvitation,
    savePassword
} from '../../../../core/actions/password';
import Card from '../../shared/card/Card';
import { history, ROUTE_LOGIN } from 'app/platform/routing';
import Logo from '../../shared/logo/Logo';
import { ROUTE_MOBILE_APP } from '../../../platform/routing';

const mapStateToProps = (state, props) => {
    const encodedEmail = props.match.params.email;
    const decodedEmail = encodedEmail ? decodeURIComponent(encodedEmail) : null;
    const isMobile = props.match.params.type === 'm';

    // when the url has 'invite' it is user activation instead of password reset
    return {
        token: props.match.params.token,
        email: decodedEmail,
        isActivation: props.match.path.indexOf('invite') >= 0,
        isMobile: isMobile
    };
};

const mapDispatchToProps = dispatch => {
    return {
        savePassword: (data, isActivation) => {
            if (isActivation) {
                return request(dispatch(acceptInvitation(data)));
            } else {
                return request(dispatch(savePassword(data)));
            }
        }
    };
};

class NewPasswordContainer extends React.Component {
    constructor(props) {
        super(props);

        this.setFields();

        this.state = {
            isFinished: false
        };
    }

    setFields() {
        this.fields = [
            {
                name: 'password_new',
                label: t('reset.new_password.label'),
                type: 'password',
                required: true,
                size: '12',
                min_length: 8
            },
            {
                name: 'password_confirmation',
                label: t('reset.new_password.confirmation_label'),
                type: 'password',
                required: true,
                equals: 'password_new',
                size: '12',
                min_length: 8
            }
        ];
    }

    onSubmit = data => {
        // validate password and confirmation
        // write the object that will be posted to the API
        data.password = data.password_new;
        data.token = this.props.token;
        data.email = this.props.email;
        // remove the new_password field
        delete data['password_new'];

        return this.props.savePassword(data, this.props.isActivation);
    };

    onSuccess = () => {
        if (this.props.isActivation) {
            // Go to mobile app page
            history.push(ROUTE_MOBILE_APP);
        } else if (this.props.isMobile) {
            this.setState({
                isFinished: true
            });
        } else {
            // Go to login
            history.push(ROUTE_LOGIN);
        }
    };

    render() {
        return (
            <div className="container">
                <Card>
                    <div className="mb-5">
                        <Logo />
                    </div>
                    <Fragment>
                        <h1>
                            {!this.state.isFinished
                                ? t(
                                      `reset.new_password.${
                                          this.props.isActivation
                                              ? 'invitation.'
                                              : ''
                                      }title`
                                  )
                                : t('reset.new_password.mobile_success.title')}
                        </h1>
                        <p>
                            {!this.state.isFinished
                                ? t(
                                      `reset.new_password.${
                                          this.props.isActivation
                                              ? 'invitation.'
                                              : ''
                                      }description`
                                  )
                                : t(
                                      'reset.new_password.mobile_success.description'
                                  )}
                        </p>
                        {!this.state.isFinished && (
                            <DynamicForm
                                model={{}}
                                fields={this.fields}
                                submitLabel={t('general.form.confirm')}
                                onSaveForm={this.onSubmit}
                                onFinished={this.onSuccess}
                            />
                        )}
                    </Fragment>
                </Card>
            </div>
        );
    }
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(NewPasswordContainer);
