import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { t } from '@dive/localization-js';
import {
    deleteConsultation,
    fetchClientConsultations
} from '../../../../../core/actions/consultations';
import LoadingIcon from '../../../shared/loading/LoadingIcon';
import Card from '../../../shared/card/Card';
import SortableTable from '../../../shared/table/SortableTable';
import Pagination from '../../../shared/pagination/Pagination';
import moment from 'moment/moment';
import RoundButton from '../../../shared/buttons/RoundButton';
import {
    history,
    ROUTE_CLIENTS_DETAIL_TAB_CONSULTATIONS_DETAIL
} from '../../../../platform/routing';
import {
    getFormattedNumber,
    isLoadingData
} from '../../../../../core/utils/data';
import ErrorView from '../../../shared/view/ErrorView';
import EmptyView from '../../../shared/view/EmptyView';
import ConsultationForm from './form/ConsultationForm';
import { getConsultationOverviewColumns } from '../../../../../core/models/settings';
import MultilineText from '../../../shared/text/MultilineText';
import { selectFieldData } from '../../../../platform/model/fields';

const mapStateToProps = state => {
    const { consultations } = state.clients.detail;
    const data = consultations ? consultations.data : null;
    return {
        // check if the current page id matches the user object we have
        shouldLoadData:
            !consultations ||
            (!consultations.isLoading &&
                !consultations.data &&
                !consultations.error),
        isLoading: isLoadingData(consultations),
        consultations: data ? data.consultations : null,
        error: consultations ? consultations.error : null,
        pagination: {
            currentPage: data ? data.page : null,
            pageSize: data ? data.page_size : null,
            count: data ? data.count : null
        },
        sort: {
            column: data && data.sort ? data.sort.column : null,
            order: data && data.sort ? data.sort.order : null
        },
        visibleColumns: getConsultationOverviewColumns(
            state.settings.data
        ).split(','),
        fields: selectFieldData(state, 'consultation')
    };
};

const mapDispatchToProps = dispatch => {
    return {
        fetchClientConsultations: (id, page, column, sort) => {
            dispatch(fetchClientConsultations(id, page, column, sort));
        },
        deleteConsultation: id => {
            return dispatch(deleteConsultation(id));
        }
    };
};

class ClientConsultationsTab extends Component {
    constructor(props) {
        super(props);
        this.state = {
            actions: {},
            errors: {}
        };
    }

    componentDidMount() {
        if (this.props.shouldLoadData) {
            this.props.fetchClientConsultations(this.props.client.id);
        }
    }

    componentDidUpdate(prevProps) {
        // check if we need to reload clients
        if (!prevProps.shouldLoadData && this.props.shouldLoadData) {
            this.props.fetchClientConsultations(this.props.client.id);
        }
    }

    getColumns() {
        return [
            {
                title: t('consultations.list.date'),
                key: 'scheduled',
                sortable: true
            },
            ...this.props.visibleColumns.map(column => {
                const c = parseInt(column);

                if (isNaN(c)) {
                    return {
                        title: t(`consultations.list.${column}`),
                        key: column
                    };
                }

                return {
                    title: this.props.fields.find(field => field.id === c).name,
                    key: column
                };
            })
        ];
    }

    getColumn = (consultation, measurements, column) => {
        switch (column) {
            case 'weight':
                return (
                    <td key={column}>
                        {measurements[column]
                            ? `${getFormattedNumber(
                                  measurements[column],
                                  'nl'
                              )}${t('general.units.kg')}`
                            : ''}
                    </td>
                );

            case 'lean':
            case 'fat':
            case 'muscle':
            case 'visceral':
                return (
                    <td key={column}>
                        {measurements[column]
                            ? `${getFormattedNumber(
                                  measurements[column],
                                  'nl'
                              )}${t('general.units.kg')}`
                            : ''}
                        {measurements[column] &&
                        measurements[`${column}_percentage`]
                            ? ' / '
                            : ''}
                        {measurements[`${column}_percentage`]
                            ? `${getFormattedNumber(
                                  measurements[`${column}_percentage`],
                                  'nl'
                              )}%`
                            : ''}
                    </td>
                );

            case 'notes':
                return (
                    <td key={column}>
                        <MultilineText>
                            {(consultation.note_food
                                ? `• ${consultation.note_food}\n`
                                : '') +
                                (consultation.note_movement
                                    ? `• ${consultation.note_movement}\n`
                                    : '') +
                                (consultation.extra
                                    ? `• ${consultation.extra}\n`
                                    : '')}
                        </MultilineText>
                    </td>
                );

            case 'bmi':
            case 'bmr':
                return (
                    <td key={column}>
                        {getFormattedNumber(measurements[column], 'nl')}
                    </td>
                );

            case 'note':
                return (
                    <td key={column}>
                        <MultilineText>{consultation.note}</MultilineText>
                    </td>
                );

            case 'clothing_size':
            case 'blood_pressure':
                return <td key={column}>{measurements[column]}</td>;

            default:
                return (
                    <td key={column}>
                        {consultation.data.find(d => d.id === +column).value}
                    </td>
                );
        }
    };

    fetchSort = (column, sort) => {
        this.props.fetchClientConsultations(
            this.props.client.id,
            this.props.pagination.currentPage,
            column,
            sort
        );
    };

    fetchPage = page => {
        this.props.fetchClientConsultations(
            this.props.client.id,
            page,
            this.props.sort.column,
            this.props.sort.order
        );
    };

    addConsultation = () => {
        this.editConsultation({ client_id: this.props.client.id });
    };

    editConsultation = consultation => {
        this.setState({
            actions: {
                updateConsultation: consultation
            }
        });
    };

    onFinishAction() {
        // remove dialog
        this.setState({ actions: {} });
        // refresh consultations
        this.refreshConsultations();
    }

    showConsultationDetail(id) {
        history.push(
            ROUTE_CLIENTS_DETAIL_TAB_CONSULTATIONS_DETAIL.replace(
                ':id',
                this.props.client.id
            ).replace(':consultation_id', id)
        );
    }

    refreshConsultations() {
        // refresh consultations
        this.props.fetchClientConsultations(
            this.props.client.id,
            this.props.pagination.currentPage,
            this.props.sort.column,
            this.props.sort.order
        );
    }

    render() {
        return this.props.isLoading ? (
            <LoadingIcon />
        ) : (
            <Fragment>
                <div className="d-flex align-items-center mb-3">
                    {!this.props.readOnly && (
                        <RoundButton
                            icon="add"
                            className="btn-round ml-auto"
                            onClick={this.addConsultation}
                            label={t('consultations.add')}
                        />
                    )}
                </div>
                <Card padding={false}>
                    {this.props.consultations &&
                    this.props.consultations.length > 0 ? (
                        <Fragment>
                            <SortableTable
                                columns={this.getColumns()}
                                sortColumn={this.props.sort.column}
                                order={this.props.sort.order}
                                isLoading={this.props.isLoading}
                                onFetch={this.fetchSort}>
                                {this.props.consultations.map(consultation => {
                                    const measurements = {};
                                    consultation.measurements.map(
                                        measurement => {
                                            measurements[measurement.key] =
                                                measurement.value;
                                            return measurement;
                                        }
                                    );
                                    return (
                                        <tr
                                            className="tr-selectable"
                                            onClick={() =>
                                                this.showConsultationDetail(
                                                    consultation.id
                                                )
                                            }
                                            key={consultation.id}>
                                            <td>
                                                <span className="font-weight-semi-bold">
                                                    {moment(
                                                        consultation.scheduled
                                                    ).format(`DD/MM/YYYY`)}
                                                </span>{' '}
                                                -{' '}
                                                {moment(
                                                    consultation.scheduled
                                                ).format(
                                                    `HH[${t(
                                                        'general.units.hour'
                                                    )}]mm`
                                                )}
                                            </td>
                                            {this.props.visibleColumns.map(
                                                column =>
                                                    this.getColumn(
                                                        consultation,
                                                        measurements,
                                                        column
                                                    )
                                            )}
                                        </tr>
                                    );
                                })}
                            </SortableTable>
                            <Pagination
                                currentPage={this.props.pagination.currentPage}
                                count={this.props.pagination.count}
                                pageSize={this.props.pagination.pageSize}
                                onFetch={this.fetchPage}
                            />
                        </Fragment>
                    ) : this.props.error ? (
                        <ErrorView error={this.props.error} />
                    ) : (
                        <EmptyView label={t('consultations.list.empty')} />
                    )}
                </Card>
                {/* Create/edit consultation modal */}
                {this.state.actions.updateConsultation && (
                    <ConsultationForm
                        consultation={this.state.actions.updateConsultation}
                        onDismissClicked={() => this.editConsultation(null)}
                        onFinished={() => this.onFinishAction()}
                    />
                )}
            </Fragment>
        );
    }
}

ClientConsultationsTab.propTypes = {
    client: PropTypes.object.isRequired
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(ClientConsultationsTab);
