import { getError, AuthActions } from '@dive/react-redux-networking';

import { SubscriptionsActions } from '../actions/subscriptions';

const DEFAULT = { isLoading: false };

const subscriptions = (state = Object.assign({}, DEFAULT), action) => {
    switch (action.type) {
        case SubscriptionsActions.GET_SUBSCRIPTIONS:
            return Object.assign({}, state, { isLoading: true, error: null });
        case SubscriptionsActions.GET_SUBSCRIPTIONS_SUCCESS:
            return Object.assign({}, state, {
                data: action.payload.data,
                isLoading: false,
                timestamp: new Date().getTime()
            });
        case SubscriptionsActions.GET_SUBSCRIPTIONS_FAIL:
            return Object.assign({}, state, {
                isLoading: false,
                error: getError(action).getMessage()
            });

        case SubscriptionsActions.UPDATE_SUBSCRIPTION_SUCCESS:
        case SubscriptionsActions.CLEAR_SUBSCRIPTIONS:
            return Object.assign({}, DEFAULT);

        /* LOGOUT */
        case AuthActions.CLEAR_AUTH:
            // remove list
            return Object.assign({}, DEFAULT);

        default:
            return state;
    }
};

export default subscriptions;
