import { getError, AuthActions } from '@dive/react-redux-networking';
import { UserFieldsActions } from '../actions/fields';

const DEFAULT = {
    client: { isLoading: false },
    consultation: { isLoading: false },
    diary_simple: { isLoading: false }
};

const assignValue = action => {
    return {
        [action.meta.previousAction.model]: {
            isLoading: false,
            data: action.payload.data,
            timestamp: Date.now()
        }
    };
};

const userFields = (state = Object.assign({}, DEFAULT), action) => {
    switch (action.type) {
        case UserFieldsActions.GET_USER_FIELDS:
            return Object.assign({}, state, {
                [action.model]: Object.assign({}, state[action.model], {
                    isLoading: true,
                    error: null
                })
            });
        case UserFieldsActions.GET_USER_FIELDS_SUCCESS:
            const data = assignValue(action);
            return Object.assign({}, state, {
                ...data,
                timestamp: new Date().getTime()
            });
        case UserFieldsActions.GET_USER_FIELDS_FAIL:
            return Object.assign({}, state, {
                isLoading: false,
                error: getError(action).getMessage()
            });
        case UserFieldsActions.CLEAR_USER_FIELDS:
            return Object.assign({}, DEFAULT);

        /* LOGOUT */
        case AuthActions.CLEAR_AUTH:
            // remove list
            return Object.assign({}, DEFAULT);

        default:
            return state;
    }
};

export default userFields;
