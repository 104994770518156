import React from 'react';
import LOGO from '../../../assets/logo.png';

const Logo = () => {
    return (
        <img
            src={LOGO}
            className="img-responsive"
            style={{ maxWidth: 220 }}
            alt="My Food Coach and Me"
        />
    );
};

export default Logo;
