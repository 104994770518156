import { isVoid } from '@dive/utils-js';
import React from 'react';

import PropTypes from 'prop-types';
import Icon from 'app/components/shared/icon/Icon';

const IconButton = ({ className, icon, label, onClick, style, title }) => {
    let classes = 'btn btn-icon';

    if (!isVoid(className)) {
        classes += ` ${className}`;
    }

    return (
        <button
            style={style}
            title={title}
            className={classes}
            onClick={onClick}>
            <Icon name={icon} />

            {label}
        </button>
    );
};

IconButton.propTypes = {
    icon: PropTypes.string.isRequired,
    label: PropTypes.string,
    onClick: PropTypes.func.isRequired
};

export default IconButton;
