import { AuthActions, getError } from '@dive/react-redux-networking';

import { ConsultationsActions } from '../actions/consultations';
import { MeasurementsActions } from '../actions/measurements';

const DEFAULT = {
    latest: {},
    months: '2'
};

const measurements = (state = Object.assign({}, DEFAULT), action) => {
    let typeKey;
    switch (action.type) {
        case MeasurementsActions.GET_LATEST_MEASUREMENTS:
            return Object.assign({}, state, {
                latest: Object.assign({}, state.latest, {
                    isLoading: true,
                    error: null
                })
            });

        case MeasurementsActions.GET_LATEST_MEASUREMENTS_SUCCESS:
            return Object.assign({}, state, {
                latest: Object.assign({}, state.latest, {
                    isLoading: false,
                    data: action.payload.data,
                    timestamp: new Date().getTime()
                })
            });

        case MeasurementsActions.GET_LATEST_MEASUREMENTS_FAIL:
            return Object.assign({}, state, {
                latest: Object.assign({}, state.latest, {
                    isLoading: false,
                    error: getError(action).getMessage()
                })
            });

        case MeasurementsActions.GET_GRAPH:
        case MeasurementsActions.GET_CLIENT_GRAPH:
            typeKey = action.payload.request.typeOfData;
            return Object.assign({}, state, {
                [typeKey]: { isLoading: true, error: null }
            });
        case MeasurementsActions.GET_GRAPH_SUCCESS:
        case MeasurementsActions.GET_CLIENT_GRAPH_SUCCESS:
            typeKey = action.payload.config.typeOfData
                ? action.payload.config.typeOfData
                : action.meta.previousAction.payload.request.typeOfData;
            return Object.assign({}, state, {
                [typeKey]: {
                    isLoading: false,
                    data: action.payload.data,
                    timestamp: new Date().getTime()
                }
            });
        case MeasurementsActions.GET_GRAPH_FAIL:
        case MeasurementsActions.GET_CLIENT_GRAPH_FAIL:
            if (action.error.config) {
                typeKey = action.error.config.typeOfData;
            } else {
                typeKey = action.meta.previousAction.payload.request.typeOfData;
            }
            return Object.assign({}, state, {
                [typeKey]: {
                    isLoading: false,
                    error: getError(action).getMessage()
                }
            });

        case MeasurementsActions.SET_MONTHS_FILTER:
            return Object.assign({}, DEFAULT, {
                months: action.months
            });

        case ConsultationsActions.CREATE_CONSULTATION_SUCCESS:
        case ConsultationsActions.UPDATE_CONSULTATION_SUCCESS:
        case MeasurementsActions.CLEAR_CLIENT_GRAPH:
            return Object.assign({}, DEFAULT);

        /* LOGOUT */
        case AuthActions.CLEAR_AUTH:
            // remove list
            return Object.assign({}, DEFAULT);

        default:
            return state;
    }
};

export default measurements;
