import React, { Component } from 'react';
import {
    selectSettings,
    selectSettingsError
} from '../../../../core/models/settings';

import ContentBody from '../../shared/content/ContentBody';
import ContentHeader from 'app/components/shared/content/ContentHeader';
import { DASHBOARD_FILTERS } from 'app/platform/constants';
import DashboardCardOverview from './DashboardCardOverview';
import LoadingIcon from 'app/components/shared/loading/LoadingIcon';
import { connect } from 'react-redux';
import { getSettings } from 'core/actions/settings';
import { isLoadingData } from '../../../../core/utils/data';
import { t } from '@dive/localization-js';

const mapStateToProps = state => {
    const filters = state.settings.data.dashboard_filters
        ? state.settings.data.dashboard_filters.split(',')
        : [];

    return {
        error: selectSettingsError(state),
        isLoading: isLoadingData(selectSettings(state)),
        cards: DASHBOARD_FILTERS.filter(filter => !filters.includes(filter))
    };
};

const mapDispatchToProps = {
    getSettings
};

class DashboardOverviewContainer extends Component {
    constructor(props) {
        super(props);
        this.state = {};
    }

    componentDidMount() {
        this.props.getSettings();
    }

    componentDidUpdate(prevProps) {
        if (prevProps.cards !== this.props.cards) {
            this.props.getSettings();
        }
    }

    render() {
        return (
            <>
                <ContentHeader title={t('dashboard.title')} />
                <ContentBody>
                    {!this.props.isLoading && !this.props.error ? (
                        <DashboardCardOverview cards={this.props.cards} />
                    ) : (
                        <LoadingIcon />
                    )}
                </ContentBody>
            </>
        );
    }
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(DashboardOverviewContainer);
