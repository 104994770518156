import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { t } from '@dive/localization-js';
import { request } from '@dive/react-redux-networking';

import { DiagnosisTypes } from '../ClientGeneralTab';
import { updateClient } from 'app/platform/actions/clients';
import DynamicFormDialog from '../../../../shared/forms/DynamicFormDialog';
import setFields from '../../../../../../core/models/fields';

const mapDispatchToProps = dispatch => {
    return {
        saveClient: client => {
            return request(dispatch(updateClient(client)));
        }
    };
};

class ClientDiagnosisForm extends Component {
    constructor(props) {
        super(props);
        this.state = {};
    }

    componentDidMount() {
        // get form fields for data, set id because this is always an update
        const model = { id: this.props.client.id };

        this.fields = setFields(
            model,
            this.props.client.data,
            this.props.type,
            'clients.detail.general.diagnose.form'
        );

        this.setState({
            model: model
        });
    }

    onSubmit = data => {
        // convert fields back to client model
        const client = Object.assign({}, this.props.client);
        client.data = client.data.map(field => ({
            id: field.id,
            value:
                field.group === this.props.type
                    ? data[field.internal_name]
                    : field.value
        }));
        return this.props.saveClient(client);
    };

    render() {
        const { model } = this.state;
        return model ? (
            <DynamicFormDialog
                title={t(
                    this.props.type === DiagnosisTypes.GENERAL
                        ? 'clients.edit.general'
                        : 'clients.edit.food'
                )}
                model={model}
                fields={this.fields}
                onDismissClicked={this.props.onDismissClicked}
                onFinished={this.props.onFinished}
                onSaveForm={this.onSubmit}
            />
        ) : null;
    }
}

ClientDiagnosisForm.propTypes = {
    client: PropTypes.object.isRequired,
    type: PropTypes.string.isRequired,
    onDismissClicked: PropTypes.func.isRequired,
    onFinished: PropTypes.func.isRequired
};

export default connect(
    undefined,
    mapDispatchToProps
)(ClientDiagnosisForm);
