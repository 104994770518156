import { Redirect, Route } from 'react-router-dom';

import React from 'react';
import { createBrowserHistory } from 'history';
import { isValid } from './model/subscription';

export const ROUTE_ONBOARDING = '/';
export const ROUTE_LOGIN = '/login';
export const ROUTE_REGISTER = '/register';
export const ROUTE_PASSWORD_RESET = '/password/reset';
export const ROUTE_NEW_PASSWORD =
    ROUTE_PASSWORD_RESET + '/:token/:email/:type?';
export const ROUTE_EMAIL_VERIFICATION = '/user/email/verify';
export const ROUTE_ACTIVATE_MOBILE_ACCOUNT = '/invite/:token/:email';
export const ROUTE_MOBILE_APP = '/download/app';

export const ROUTE_APP = '/';

export const ROUTE_DASHBOARD = '/dashboard';

export const ROUTE_CLIENTS = '/clients';
export const ROUTE_CLIENTS_INACTIVE = ROUTE_CLIENTS + '/inactive';
export const ROUTE_CLIENTS_DETAIL = ROUTE_CLIENTS + '/:id';
export const ROUTE_CLIENTS_DETAIL_TAB_GENERAL = ROUTE_CLIENTS_DETAIL;
export const ROUTE_CLIENTS_DETAIL_TAB_TREATMENT =
    ROUTE_CLIENTS_DETAIL + '/treatment';
export const ROUTE_CLIENTS_DETAIL_TAB_CONSULTATIONS =
    ROUTE_CLIENTS_DETAIL + '/consultations';
export const ROUTE_CLIENTS_DETAIL_TAB_CONSULTATIONS_DETAIL =
    ROUTE_CLIENTS_DETAIL_TAB_CONSULTATIONS + '/:consultation_id';
export const ROUTE_CLIENTS_DETAIL_TAB_DIGITAL_COACHING =
    ROUTE_CLIENTS_DETAIL + '/e-coaching';
export const ROUTE_CLIENTS_DETAIL_TAB_LIBRARY =
    ROUTE_CLIENTS_DETAIL + '/e-library';

export const ROUTE_CLIENTS_DETAIL_TAB_DIARY = ROUTE_CLIENTS_DETAIL + '/diary';
export const ROUTE_CLIENTS_DETAIL_TAB_DIARY_GENERAL =
    ROUTE_CLIENTS_DETAIL_TAB_DIARY + '/general';
export const ROUTE_CLIENTS_DETAIL_TAB_DIARY_GENERAL_DETAIL =
    ROUTE_CLIENTS_DETAIL_TAB_DIARY_GENERAL + '/:date';
export const ROUTE_CLIENTS_DETAIL_TAB_DIARY_FOOD =
    ROUTE_CLIENTS_DETAIL_TAB_DIARY + '/food';
export const ROUTE_CLIENTS_DETAIL_TAB_DIARY_FOOD_DETAIL =
    ROUTE_CLIENTS_DETAIL_TAB_DIARY_FOOD + '/:date';
export const ROUTE_CLIENTS_DETAIL_TAB_DIARY_MOVEMENTS =
    ROUTE_CLIENTS_DETAIL_TAB_DIARY + '/movement';
export const ROUTE_CLIENTS_DETAIL_TAB_DIARY_MOVEMENT_DETAIL =
    ROUTE_CLIENTS_DETAIL_TAB_DIARY_MOVEMENTS + '/:date';
export const ROUTE_CLIENTS_DETAIL_TAB_EVOLUTION =
    ROUTE_CLIENTS_DETAIL + '/evolution';

export const ROUTE_DIGITAL_COACHING = '/e-coaching';

export const ROUTE_LIBRARY = ROUTE_APP + 'e-library';
export const ROUTE_LIBRARY_DETAIL = ROUTE_LIBRARY + '/:id';

export const ROUTE_SETTINGS = '/settings';
export const ROUTE_SETTINGS_TAB_GENERAL = ROUTE_SETTINGS;
export const ROUTE_SETTINGS_TAB_CATEGORIES = ROUTE_SETTINGS + '/categories';
export const ROUTE_SETTINGS_TAB_TAGS = ROUTE_SETTINGS + '/tags';
export const ROUTE_SETTINGS_TAB_FIELDS = ROUTE_SETTINGS + '/fields';

export const ROUTE_PROFILE = '/profile';

export const ROUTE_SUBSCRIBERS = '/subscribers';

export const ROUTE_SUBSCRIPTION = '/subscription/:type';
export const ROUTE_SUBSCRIPTION_TYPE_EXTEND = 'extend';
export const ROUTE_SUBSCRIPTION_TYPE_CHOOSE = 'choose';
export const ROUTE_SUBSCRIPTION_CHOOSE = '/subscription/choose';
export const ROUTE_SUBSCRIPTION_EXTEND = '/subscription/extend';
export const ROUTE_SUBSCRIPTION_STEP_PLAN = ROUTE_SUBSCRIPTION + '/plan';
export const ROUTE_SUBSCRIPTION_STEP_INVOICE = ROUTE_SUBSCRIPTION + '/invoice';

export const history = createBrowserHistory({
    basename: '/'
});

export const SubscriberRoute = ({ user, ...props }) => {
    if (isValid(user.current_plan)) {
        return <Route {...props} />;
    }

    return <Redirect to={ROUTE_SUBSCRIPTION_CHOOSE} />;
};
