import React, { Fragment } from 'react';
import { connect } from 'react-redux';
import { t } from '@dive/localization-js';
import { request } from '@dive/react-redux-networking';

import DynamicForm from '../../shared/forms/DynamicForm';
import { resetPasswordRequest } from '../../../../core/actions/password';
import Card from '../../shared/card/Card';
import BackButton from 'app/components/shared/buttons/BackButton';
import { ROUTE_LOGIN } from 'app/platform/routing';
import Logo from '../../shared/logo/Logo';

const mapDispatchToProps = dispatch => {
    return {
        sendPasswordResetRequest: email => {
            return request(dispatch(resetPasswordRequest(email)));
        }
    };
};

class ResetPasswordContainer extends React.Component {
    constructor(props) {
        super(props);

        this.setFields();

        this.state = {
            emailSent: false
        };
    }

    setFields() {
        this.fields = [
            {
                name: 'email',
                label: t('reset.password.email.label'),
                type: 'email',
                required: true,
                size: '12'
            }
        ];
    }

    onSuccess = () => {
        this.setState({
            emailSent: true
        });
    };

    render() {
        return (
            <div className="container">
                <Card>
                    <div className="mb-5">
                        <BackButton
                            to={ROUTE_LOGIN}
                            label={t('reset.password.back')}
                        />
                        <Logo />
                    </div>
                    {this.state.emailSent ? (
                        <Fragment>
                            <h1>{t('reset.password.title.success')}</h1>
                            <p>{t('reset.password.description.success')}</p>
                        </Fragment>
                    ) : (
                        <Fragment>
                            <h1>{t('reset.password.title')}</h1>
                            <p>{t('reset.password.description')}</p>
                            <DynamicForm
                                model={{}}
                                fields={this.fields}
                                submitLabel={t('general.form.send')}
                                onSaveForm={model =>
                                    this.props.sendPasswordResetRequest(
                                        model.email
                                    )
                                }
                                onFinished={this.onSuccess}
                            />
                        </Fragment>
                    )}
                </Card>
            </div>
        );
    }
}

export default connect(
    undefined,
    mapDispatchToProps
)(ResetPasswordContainer);
