import { getError, AuthActions } from '@dive/react-redux-networking';

import { LibraryTypes as types } from '../actions/library';

const initialState = {
    isLoading: false,
    data: null,
    error: null,
    filters: {
        search: '',
        category: '',
        type: '',
        interval: {
            endDate: '',
            startDate: ''
        }
    }
};

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case types.GET_LIBRARY:
            return {
                ...state,
                error: null,
                isLoading: true
            };

        case types.GET_LIBRARY_SUCCESS:
            return {
                ...state,
                data: action.payload.data,
                isLoading: false,
                timestamp: Date.now()
            };

        case types.GET_LIBRARY_FAIL:
            return {
                ...state,
                error: getError(action).getMessage(),
                isLoading: false
            };

        case types.SET_FILTERS:
            return {
                ...state,
                data: null,
                filters: action.payload
            };

        case types.CLEAR_LIBRARY:
        case types.CREATE_ENTRY_SUCCESS:
        case types.UPDATE_ENTRY_SUCCESS:
        case types.REMOVE_ENTRY_SUCCESS:
        case types.RESET_FILTERS:
        case AuthActions.CLEAR_AUTH:
            return Object.assign({}, initialState);

        default:
            return state;
    }
};

export { reducer as libraryReducer };
