import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { t } from '@dive/localization-js';
import { ROUTE_CLIENTS_DETAIL } from 'app/platform/routing';
import Card from '../../shared/card/Card';
import LoadingIcon from '../../shared/loading/LoadingIcon';
import { fetchClients, getClients } from '../../../platform/actions/clients';
import SortableTable from '../../shared/table/SortableTable';
import Pagination from '../../shared/pagination/Pagination';
import ClientsUpcomingContainer from '../consultations/UpcomingConsultationsContainer';
import { history, ROUTE_SUBSCRIPTION_CHOOSE } from '../../../platform/routing';
import { Link } from 'react-router-dom';
import EmptyView from '../../shared/view/EmptyView';
import { getFormattedNumber, isLoadingData } from '../../../../core/utils/data';
import { getClientOverviewColumns } from '../../../../core/models/settings';
import moment from 'moment';
import ContentBody from '../../shared/content/ContentBody';
import ErrorView from '../../shared/view/ErrorView';
import { sortCategoriesFilter } from '../../../platform/model/category';

const mapStateToProps = state => {
    const { data, error } = state.clients.list;
    const clientLimit = state.user.data.current_plan.client_limit;

    return {
        isLoading: isLoadingData(state.clients.list),
        clients: data ? data.clients : null,
        exceedsPlan: data && data && data.total >= clientLimit,
        pagination: {
            currentPage: data ? data.page : null,
            pageSize: data ? data.page_size : null,
            count: data ? data.count : null
        },
        error: error,
        sort: {
            column: data && data.sort ? data.sort.column : null,
            order: data && data.sort ? data.sort.order : null
        },
        totalClients: data ? data.total : null,
        visibleColumns: getClientOverviewColumns(state.settings.data).split(',')
    };
};

const mapDispatchToProps = dispatch => {
    return {
        getClients: cat => {
            dispatch(getClients(cat));
        },
        fetchClients: (page, sortColumn, order, cat) => {
            dispatch(fetchClients(page, sortColumn, order, cat));
        }
    };
};

class ActiveClientsOverviewContainer extends Component {
    constructor(props) {
        super(props);

        this.state = {
            actions: {}
        };
    }

    componentDidMount() {
        this.props.getClients();
    }

    componentDidUpdate(prevProps) {
        // check if we need to reload clients
        if (!this.state.addClient) {
            this.props.getClients(this.props.category);
        }

        if (this.props.category !== prevProps.category) {
            this.props.fetchClients(
                this.props.pagination.currentPage,
                this.props.sort.column,
                this.props.sort.order
            );
        }
    }

    getColumns() {
        return [
            {
                title: t('clients.list.name'),
                key: 'name',
                sortable: true
            },
            ...this.props.visibleColumns.map(column => ({
                title: t(`clients.list.${column}`),
                key: column,
                sortable: this.isSortable(column)
            }))
        ];
    }

    isSortable = column => {
        return (
            column === 'weight' || column === 'bmi' || column === 'ideal_weight'
        );
    };

    fetchSort = (column, sort) => {
        this.props.fetchClients(
            this.props.pagination.currentPage,
            column,
            sort
        );
    };

    fetchPage = page => {
        this.props.fetchClients(
            page,
            this.props.sort.column,
            this.props.sort.order
        );
    };

    showClientDetail(id) {
        history.push(ROUTE_CLIENTS_DETAIL.replace(':id', id));
    }

    getColumn = (client, column) => {
        switch (column) {
            case 'weight':
            case 'ideal_weight':
                return (
                    <td key={column}>
                        {client[column]
                            ? `${getFormattedNumber(client[column], 'nl')}${t(
                                  'general.units.kg'
                              )}`
                            : ''}
                    </td>
                );

            case 'length':
                return (
                    <td key={column}>
                        {client[column]
                            ? `${getFormattedNumber(client[column], 'nl')}${t(
                                  'general.units.cm'
                              )}`
                            : ''}
                    </td>
                );

            case 'bmi':
                return (
                    <td key={column}>
                        {getFormattedNumber(client[column], 'nl')}
                    </td>
                );

            case 'coaching':
                return (
                    <td key={column}>
                        {t(client.coaching ? 'general.yes' : 'general.no')}

                        {client.coaching_starts_at && (
                            <span className="ml-2">
                                (
                                {client.coaching_ends_at
                                    ? ''
                                    : t('general.from') + ' '}
                                {moment
                                    .utc(client.coaching_starts_at)
                                    .format('DD/MM/YYYY')}
                                {client.coaching_ends_at &&
                                    ' ' + t('general.until') + ' '}
                                {client.coaching_ends_at &&
                                    moment
                                        .utc(client.coaching_ends_at)
                                        .format('DD/MM/YYYY')}
                                )
                            </span>
                        )}
                    </td>
                );

            case 'categories':
                return (
                    <td key={column}>
                        {client[column].length > 0 && (
                            <div className="badge-list">
                                {client[column]
                                    .sort(sortCategoriesFilter)
                                    .map(category => (
                                        <span
                                            className="badge badge-light"
                                            key={category.name}>
                                            {category.name}
                                        </span>
                                    ))}
                            </div>
                        )}
                    </td>
                );

            case 'birth_date':
                return (
                    <td key={column}>
                        {client.birth_date
                            ? moment.utc(client.birth_date).format('DD/MM/YYYY')
                            : ''}
                    </td>
                );

            default:
                return <td key={column}>{client[column]}</td>;
        }
    };

    isTableTooLarge = () => {
        return this.props.visibleColumns.length > 4;
    };

    render() {
        return this.props.isLoading ? (
            <LoadingIcon />
        ) : (
            <Fragment>
                <ContentBody>
                    {this.props.error ? (
                        <ErrorView error={this.props.error} />
                    ) : (
                        <div
                            className={`row d-flex flex-column-reverse ${
                                this.isTableTooLarge()
                                    ? 'flex-row'
                                    : 'flex-xxl-row'
                            }`}>
                            <div
                                className={`${
                                    this.props.clients.length > 0
                                        ? this.isTableTooLarge()
                                            ? 'col-xxl-12'
                                            : 'col-xxl-9'
                                        : 'col-lg-12'
                                }`}>
                                {this.props.exceedsPlan && (
                                    <div className="alert alert-warning d-flex justify-content-between align-items-center flex-wrap">
                                        <span className="mr-3">
                                            {t(
                                                'subscriptions.client_limit.exceeded'
                                            )}
                                        </span>
                                        <Link
                                            to={ROUTE_SUBSCRIPTION_CHOOSE}
                                            className="mt-2 mb-2">
                                            {t(
                                                'subscriptions.client_limit.exceeded.link'
                                            )}
                                        </Link>
                                    </div>
                                )}
                                <Card padding={this.props.clients.length === 0}>
                                    {this.props.clients.length > 0 ? (
                                        <Fragment>
                                            <SortableTable
                                                columns={this.getColumns()}
                                                sortColumn={
                                                    this.props.sort.column
                                                }
                                                order={this.props.sort.order}
                                                isLoading={this.props.isLoading}
                                                onFetch={this.fetchSort}>
                                                {this.props.clients.map(
                                                    client => (
                                                        <tr
                                                            className="tr-selectable"
                                                            key={client.id}
                                                            onClick={() =>
                                                                this.showClientDetail(
                                                                    client.id
                                                                )
                                                            }>
                                                            <td>
                                                                <span className="font-weight-semi-bold d-block">{`${client.name} ${client.first_name}`}</span>
                                                                <span>
                                                                    {
                                                                        client.email
                                                                    }
                                                                </span>
                                                            </td>
                                                            {this.props.visibleColumns.map(
                                                                column =>
                                                                    this.getColumn(
                                                                        client,
                                                                        column
                                                                    )
                                                            )}
                                                        </tr>
                                                    )
                                                )}
                                            </SortableTable>
                                            <Pagination
                                                currentPage={
                                                    this.props.pagination
                                                        .currentPage
                                                }
                                                count={
                                                    this.props.pagination.count
                                                }
                                                pageSize={
                                                    this.props.pagination
                                                        .pageSize
                                                }
                                                onFetch={this.fetchPage}
                                            />
                                        </Fragment>
                                    ) : this.props.totalClients === 0 ? (
                                        <div className="d-flex flex-column align-items-center">
                                            <EmptyView
                                                label={t('clients.list.empty')}
                                            />
                                            <button
                                                className="btn btn-secondary"
                                                onClick={this.props.addClient}>
                                                {t('clients.add')}
                                            </button>
                                        </div>
                                    ) : (
                                        <div className="d-flex flex-column align-items-center">
                                            <EmptyView
                                                label={t(
                                                    'clients.list.filter.empty'
                                                )}
                                            />
                                        </div>
                                    )}
                                </Card>
                            </div>
                            {this.props.clients.length > 0 ? (
                                <div
                                    className={`${
                                        this.isTableTooLarge()
                                            ? 'col-xxl-12'
                                            : 'col-xxl-3'
                                    } mb-4`}>
                                    <ClientsUpcomingContainer />
                                </div>
                            ) : null}
                        </div>
                    )}
                </ContentBody>
            </Fragment>
        );
    }
}

ActiveClientsOverviewContainer.propTypes = {
    addClient: PropTypes.func.isRequired
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(ActiveClientsOverviewContainer);
