import React from 'react';
import PropTypes from 'prop-types';
import { NavLink } from 'react-router-dom';

const Tabs = ({ tabs, color }) => {
    return (
        <nav className={`tabs`}>
            <ul className="nav nav-pills">
                {tabs.map((tab, index) => (
                    <li key={index} className="nav-item">
                        <NavLink
                            exact={tab.exact}
                            className={'nav-link'}
                            activeClassName="active"
                            to={tab.url}>
                            {tab.label}
                        </NavLink>
                    </li>
                ))}
            </ul>
        </nav>
    );
};

Tabs.propTypes = {
    tabs: PropTypes.array.isRequired
};

export default Tabs;
