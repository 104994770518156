import { shouldRefreshStateData } from '../utils/data';

export const GoalsActions = {
    GET_GOALS: '/goals/LOAD',
    GET_GOALS_SUCCESS: '/goals/LOAD_SUCCESS',
    GET_GOALS_FAIL: '/goals/LOAD_FAIL',

    GET_CLIENT_GOALS: '/client/goals/LOAD',
    GET_CLIENT_GOALS_SUCCESS: '/client/goals/LOAD_SUCCESS',
    GET_CLIENT_GOALS_FAIL: '/client/goals/LOAD_FAIL',

    CREATE_GOAL: '/goal/CREATE',
    CREATE_GOAL_SUCCESS: '/goal/CREATE_SUCCESS',
    CREATE_GOAL_FAIL: '/goal/CREATE_FAIL',

    UPDATE_GOAL: '/goal/UPDATE',
    UPDATE_GOAL_SUCCESS: '/goal/UPDATE_SUCCESS',
    UPDATE_GOAL_FAIL: '/goal/UPDATE_FAIL',

    REMOVE_GOAL: '/goal/REMOVE',
    REMOVE_GOAL_SUCCESS: '/goal/REMOVE_SUCCESS',
    REMOVE_GOAL_FAIL: '/goal/REMOVE_FAIL'
};

const GOALS_CACHE_INTERVAL = 1000 * 60 * 10;

export const getGoals = () => (dispatch, getState) => {
    if (shouldRefreshStateData(getState().goals, GOALS_CACHE_INTERVAL)) {
        return dispatch(fetchGoals());
    }
};

export const fetchGoals = () => ({
    type: GoalsActions.GET_GOALS,
    payload: {
        request: {
            url: `/goals`,
            method: 'GET'
        }
    }
});

export const getClientGoals = id => ({
    type: GoalsActions.GET_CLIENT_GOALS,
    payload: {
        request: {
            url: `/clients/${id}/treatment/goals`,
            method: 'GET'
        }
    }
});

export const updateClientGoal = (id, goal) => ({
    type: goal.id ? GoalsActions.UPDATE_GOAL : GoalsActions.CREATE_GOAL,
    payload: {
        request: {
            url: goal.id
                ? `/clients/${id}/treatment/goals/${goal.id}`
                : `/clients/${id}/treatment/goals/`,
            method: goal.id ? 'PUT' : 'POST',
            data: goal,
            id: goal.id
        }
    }
});

export const deleteClientGoal = (id, goalId) => ({
    type: GoalsActions.REMOVE_GOAL,
    payload: {
        request: {
            url: `/clients/${id}/treatment/goals/${goalId}`,
            method: 'DELETE',
            id: goalId
        }
    }
});
