import React from 'react';
import PropTypes from 'prop-types';
import DynamicForm from './DynamicForm';
import DialogModal from '../modal/DialogModal';

const DynamicFormDialog = props => {
    return (
        <DialogModal
            title={props.title}
            size={props.size}
            onDismissClicked={() => props.onDismissClicked()}>
            <DynamicForm dismissOnSuccess={true} {...props} />
        </DialogModal>
    );
};

DynamicFormDialog.propTypes = Object.assign({}, DynamicForm.propTypes, {
    title: PropTypes.string.isRequired,
    onDismissClicked: PropTypes.func.isRequired
});

export default DynamicFormDialog;
