import { t } from '@dive/localization-js';
import { isEmptyText } from '@dive/utils-js';
import React, { Component } from 'react';
import { connect } from 'react-redux';

import { isLoadingData } from '../../../../core/utils/data';
import {
    setClientCategoryFilter,
    setClientFilter
} from '../../../platform/actions/clients';
import { selectCategoriesAsOptions } from '../../../platform/model/category';
import RoundButton from '../../shared/buttons/RoundButton';
import ContentHeader from '../../shared/content/ContentHeader';
import PageFilter from '../../shared/filter/PageFilter';
import Select from '../../shared/forms/Select';
import ClientContactForm from '../detail/general/form/ClientContactForm';
import ActiveClientsOverviewContainer from './ActiveClientsOverviewContainer';
import InactiveClientsOverviewContainer from './InactiveClientsOverviewContainer';

class ClientsOverviewContainer extends Component {
    constructor(props) {
        super(props);

        this.state = {
            actions: {},
            filter: {
                type: props.filter
            }
        };

        this.tabs = ['active', 'deactive'];
    }

    addClient = () => {
        this.editClient({});
    };

    editClient(client) {
        this.setState({
            actions: { updateClient: client }
        });
    }

    handleCategory = val => {
        this.props.setClientCategoryFilter(val);
    };

    handleClear = e => {
        e.preventDefault();

        this.props.setClientCategoryFilter('');
    };

    onFilterChanged = (name, value) => {
        const filter = { ...this.state.filters, [name]: value };

        this.setState({ filter });

        this.props.setClientFilter(filter.type);
    };

    render() {
        const { exceedsPlan, isLoading, categories, category } = this.props;
        const {
            filter: { type },
            actions
        } = this.state;

        const hasCatFilter = !isEmptyText(category);

        return (
            <>
                <ContentHeader>
                    <div className="d-flex flex-wrap justify-content-between">
                        <h1 className="mr-5 mb-3">{t('clients')}</h1>

                        {!exceedsPlan && !isLoading && (
                            <RoundButton
                                icon="add"
                                className="btn-round mb-auto"
                                onClick={this.addClient}
                                label={t('clients.add')}
                            />
                        )}
                    </div>

                    <div className="tabs">
                        <ul className="nav nav-pills">
                            {this.tabs.map(tab => {
                                let classes = 'btn nav-link';

                                if (type === tab) {
                                    classes += ' active';
                                }

                                return (
                                    <li key={tab} className="nav-item">
                                        <button
                                            onClick={() =>
                                                this.onFilterChanged(
                                                    'type',
                                                    tab
                                                )
                                            }
                                            className={classes}>
                                            {t('clients.filter.state.' + tab)}
                                        </button>
                                    </li>
                                );
                            })}
                        </ul>
                    </div>

                    {type === 'active' && categories.length > 0 && (
                        <PageFilter
                            showClear={hasCatFilter}
                            onClearClick={this.handleClear}>
                            <Select
                                placeholder={t(
                                    'clients.filter.categories.select'
                                )}
                                onChange={this.handleCategory}
                                name="category"
                                options={categories}
                                value={this.props.category}
                            />
                        </PageFilter>
                    )}
                </ContentHeader>

                {type === 'active' ? (
                    <ActiveClientsOverviewContainer
                        category={this.props.category}
                        addClient={this.addClient}
                    />
                ) : (
                    <InactiveClientsOverviewContainer />
                )}

                {actions.updateClient && (
                    <ClientContactForm
                        client={actions.updateClient}
                        onDismissClicked={() => this.editClient(null)}
                        onFinished={() => this.editClient(null)}
                    />
                )}
            </>
        );
    }
}

const mapStateToProps = state => {
    const { data } = state.clients.list;
    const clientLimit = state.user.data.current_plan.client_limit;

    return {
        category: state.clients.category,
        filter: state.clients.filter,
        exceedsPlan: data && data.total >= clientLimit,
        isLoading: isLoadingData(state.clients.list),
        categories: selectCategoriesAsOptions(state)
    };
};

const mapDispatchToProps = {
    setClientCategoryFilter,
    setClientFilter
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(ClientsOverviewContainer);
