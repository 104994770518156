import {
    createLocalStorage,
    createNetworkingMiddleware,
    getError
} from '@dive/react-redux-networking';
import axios from 'axios';
import moment from 'moment-timezone';

import { markSubscriptionInvalid } from '../../platform/actions/subscriptions';

const client = axios.create({
    baseURL: process.env.REACT_APP_API_BASE_URL,
    responseType: 'json'
});

// custom on error method for subscriptions
const onError = ({ dispatch }, error) => {
    // invalid subscription
    if (error.response && getError({ error: error }).getStatus() === 402) {
        dispatch(markSubscriptionInvalid());
    }

    return Promise.reject(error);
};

const onLogin = ({ getState, dispatch }) => {
    // set timezone
    moment.tz.setDefault(getState().user.data.timezone);
};

export const networkingMiddleware = createNetworkingMiddleware({
    axiosClient: client,
    storage: createLocalStorage('FoodCoach'),
    config: {
        env: process.env.NODE_ENV,
        apiToken: process.env.REACT_APP_X_API_TOKEN,
        clientId: process.env.REACT_APP_OAUTH_CLIENT_ID,
        clientSecret: process.env.REACT_APP_OAUTH_GRANT_SECRET
    },
    interceptors: {
        onError,
        onLogin
    }
});
