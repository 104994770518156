import { isEmptyText, isVoid } from '@dive/utils-js';

import { createSelector } from 'reselect';
import moment from 'moment';
import { transformEntry } from '../../../core/models/diary';

export const selectClientDiaryDetailData = state => {
    const detail = state.clients.detail.diary.diaryDetail;

    return isVoid(detail) ? null : detail.data;
};

export const selectClientDiaryDetailEntries = createSelector(
    selectClientDiaryDetailData,
    data =>
        isVoid(data) ? [] : data.entries.map(entry => transformEntry(entry))
);

export const selectClientDiary = state => state.clients.detail.diary.diary;

export const selectClientDiaryData = state => {
    const { data } = selectClientDiary(state);

    return data;
};

export const selectClientDiaryError = state => {
    const { error } = selectClientDiary(state);

    return error;
};

export const selectClientDiaryFields = state => {
    const data = selectClientDiaryData(state);

    return isVoid(data) ? null : data.fields;
};

export const selectClientDiaryEntries = createSelector(
    selectClientDiaryData,
    data =>
        isVoid(data) || isVoid(data.entries)
            ? null
            : Object.keys(data.entries).reduce((acc, cur) => {
                  acc[cur] = Object.keys(data.entries[cur]).reduce(
                      (carry, item) => {
                          carry[item] = transformEntry(data.entries[cur][item]);
                          return carry;
                      },
                      {}
                  );
                  return acc;
              }, {})
);

export const selectClientDiaryTreatment = state => {
    const data = selectClientDiaryData(state);

    return isVoid(data) ? null : data.treatment;
};

export const selectClientDetail = state => state.clients.detail;

export const selectClientLibrary = state => state.clients.detail.library;

export const selectClientLibraryData = state => {
    const library = selectClientLibrary(state);

    return library.data;
};

export const selectClientLibraryFilters = state => {
    const library = selectClientLibrary(state);

    return library.filters;
};

export const selectClientLibraryEntries = state => {
    const data = selectClientLibraryData(state);

    return isVoid(data) ? null : data.entries;
};

export const selectClientLibraryError = state => {
    const library = selectClientLibrary(state);

    return library.error;
};

export const hasSetClientLibraryFilters = state => {
    const filters = selectClientLibraryFilters(state);

    return !isEmptyText(filters.search);
};

export const isCoachable = client => {
    const { coaching, coaching_ends_at } = client;

    if (!coaching) {
        return false;
    }

    if (isVoid(coaching_ends_at)) {
        return true;
    }

    return moment(coaching_ends_at).isAfter(moment());
};

export const hasCoachingEnd = client => {
    const { coaching, coaching_starts_at, coaching_ends_at } = client;

    if (!coaching || !coaching_starts_at) {
        return false;
    }

    return !isVoid(coaching_ends_at);
};

export const hasCoachingStart = client => {
    const { coaching, coaching_starts_at } = client;

    if (!coaching) {
        return false;
    }

    return !isVoid(coaching_starts_at);
};
