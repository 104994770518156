import React from 'react';
import moment from 'moment';
import PropTypes from 'prop-types';

import DayPickerInput from 'react-day-picker/DayPickerInput';

import { formatDate, parseDate } from 'react-day-picker/moment';
import { t } from '@dive/localization-js';

export default class DateRangePicker extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            from: undefined,
            to: undefined
        };
    }

    componentDidMount() {
        if (this.props.startDate || this.props.endDate) {
            this.setState({
                from: moment.utc(this.props.startDate).toDate(),
                to: moment.utc(this.props.endDate).toDate()
            });
        }
    }

    componentDidUpdate(prevProps) {
        if (
            prevProps.startDate !== this.props.startDate ||
            prevProps.endDate !== this.props.endDate
        ) {
            this.setState({
                from: this.props.startDate
                    ? moment.utc(this.props.startDate).toDate()
                    : undefined,
                to: this.props.endDate
                    ? moment.utc(this.props.endDate).toDate()
                    : undefined
            });
        }
    }

    showFromMonth() {
        const { from, to } = this.state;
        if (!from) {
            return;
        }
        if (moment(to).diff(moment(from), 'months') < 2) {
            this.to.getDayPicker().showMonth(from);
        }
    }

    handleFromChange = from => {
        // Change the from date and focus the "to" input field
        this.setState({ from }, this.checkData);
    };

    handleToChange = to => {
        this.setState({ to }, () => {
            this.showFromMonth();
            this.checkData();
        });
    };

    checkData = () => {
        // check if both exist
        if (this.state.from && this.state.to) {
            if (this.props.onDateChange) {
                this.props.onDateChange(
                    {
                        startDate: moment.utc(this.state.from).format(),
                        endDate: moment.utc(this.state.to).format()
                    },
                    this.props.name
                );
            }
        }
    };

    render() {
        const { from, to } = this.state;
        const modifiers = { start: from, end: to };
        return (
            <div
                className={`date-range-picker d-flex ${this.props.className ||
                    ''}`}>
                <DayPickerInput
                    inputProps={{ readOnly: true }}
                    value={from}
                    placeholder={t('general.filter.start_date')}
                    format="DD/MM/YYYY"
                    formatDate={formatDate}
                    parseDate={parseDate}
                    dayPickerProps={{
                        selectedDays: [from, { from, to }],
                        disabledDays: { after: to },
                        toMonth: to,
                        modifiers,
                        onDayClick: () => this.to.getInput().focus()
                    }}
                    onDayChange={this.handleFromChange}
                />
                <span className="d-none d-sm-block mt-1 ml-1 mr-1 text-body-light">
                    {' '}
                    -{' '}
                </span>
                <span className="mt-2 mt-sm-0">
                    <DayPickerInput
                        ref={el => (this.to = el)}
                        inputProps={{ readOnly: true }}
                        value={to}
                        placeholder={t('general.filter.end_date')}
                        format="DD/MM/YYYY"
                        formatDate={formatDate}
                        parseDate={parseDate}
                        dayPickerProps={{
                            selectedDays: [from, { from, to }],
                            disabledDays: { before: from },
                            modifiers,
                            month: from,
                            fromMonth: from
                        }}
                        onDayChange={this.handleToChange}
                    />
                </span>
            </div>
        );
    }
}

DateRangePicker.propTypes = {
    onDateChange: PropTypes.func,
    fromDate: PropTypes.string,
    endDate: PropTypes.string
};
