import { t } from '@dive/localization-js';
import React, { memo } from 'react';

import IconButton from '../buttons/IconButton';

const PageFilter = ({ children, onClearClick, showClear }) => (
    <div className="d-flex align-items-start align-items-sm-center mt-2">
        <p className="d-none d-md-block m-0 p-0">{t('general.filter')}:</p>

        <form className="form-filter ml-md-2 flex-column flex-md-row flex-wrap flex-sm-nowrap align-items-sm-center w-100">
            {children}

            {showClear && (
                <IconButton
                    className="ml-2 mt-2 mt-sm-1"
                    icon="clear"
                    onClick={onClearClick}
                />
            )}
        </form>
    </div>
);

export default memo(PageFilter);
