import React, { Component } from 'react';

import DynamicFormDialog from '../../../shared/forms/DynamicFormDialog';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { request } from '@dive/react-redux-networking';
import { t } from '@dive/localization-js';
import { updateTag } from '../../../../platform/actions/tags';

const mapDispatchToProps = dispatch => {
    return {
        saveTag: tag => {
            return request(dispatch(updateTag(tag)));
        }
    };
};

class TagForm extends Component {
    constructor(props) {
        super(props);

        this.setFields();
    }

    setFields() {
        this.fields = [
            {
                name: 'name',
                label: t('settings.tags.list.name'),
                type: 'string',
                required: true,
                size: '12'
            }
        ];
    }

    render() {
        return this.props.tag ? (
            <DynamicFormDialog
                title={t(
                    this.props.tag.id
                        ? 'settings.tags.edit'
                        : 'settings.tags.add'
                )}
                model={this.props.tag}
                onSaveForm={this.props.saveTag}
                fields={this.fields}
                onDismissClicked={this.props.onDismissClicked}
                onFinished={this.props.onFinished}
            />
        ) : null;
    }
}

TagForm.propTypes = {
    tag: PropTypes.object.isRequired,
    onDismissClicked: PropTypes.func.isRequired,
    onFinished: PropTypes.func.isRequired
};

export default connect(undefined, mapDispatchToProps)(TagForm);
