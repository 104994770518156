import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { t } from '@dive/localization-js';
import { fetchUser, request } from '@dive/react-redux-networking';
import { isVoid } from '@dive/utils-js';

import Card from '../../shared/card/Card';
import HeaderContainer from '../../shared/header/HeaderContainer';
import {
    fetchSubscriptions,
    getSubscriptions,
    preparePayment
} from '../../../platform/actions/subscriptions';
import LoadingIcon from '../../shared/loading/LoadingIcon';
import {
    history,
    ROUTE_SUBSCRIPTION_CHOOSE,
    ROUTE_SUBSCRIPTION_STEP_INVOICE,
    ROUTE_SUBSCRIPTION_STEP_PLAN,
    ROUTE_SUBSCRIPTION_TYPE_CHOOSE,
    ROUTE_SUBSCRIPTION_TYPE_EXTEND
} from '../../../platform/routing';
import {
    getId,
    isValid,
    getType,
    isTrial
} from '../../../platform/model/subscription';
import ErrorView from '../../shared/view/ErrorView';
import { isLoadingData } from '../../../../core/utils/data';
import ContentBody from '../../shared/content/ContentBody';
import PlanPicker from './plans/PlanPicker';
import { Route, Switch } from 'react-router-dom';
import BackButton from '../../shared/buttons/BackButton';
import { Redirect } from 'react-router-dom';
import ShoppingCart from './plans/ShoppingCart';
import MessageBar from '../../shared/message/MessageBar';
import CurrentPlan from './plans/CurrentPlan';
import ImpersonationBanner from '../../impersonation/ImpersonationBanner';
import SubmitButton from 'app/components/shared/buttons/SubmitButton';

const mapStateToProps = (state, props) => {
    const { data, error } = state.subscriptions;
    return {
        profile: state.user.data,
        error: error,
        type: props.match.params.type || ROUTE_SUBSCRIPTION_TYPE_CHOOSE,
        isLoading: isLoadingData(state.subscriptions),
        subscriptions: data ? data.available_plans : null,
        currentSubscription: data ? data.current_plan : null,
        tax: state.user.data && state.user.data.tax
    };
};

const mapDispatchToProps = dispatch => {
    return {
        getSubscriptions: () => {
            return dispatch(getSubscriptions());
        },
        fetchSubscriptions: () => {
            return dispatch(fetchSubscriptions());
        },
        refreshUser: () => {
            return dispatch(fetchUser());
        },
        preparePayment: (plan, coupon) => {
            return request(dispatch(preparePayment(plan, coupon)));
        }
    };
};

class SubscriptionContainer extends Component {
    constructor(props) {
        super(props);

        this.state = {
            step: 0,
            selectedPlan: null,
            isLoading: false,
            coupon: null,
            payment: null,
            errors: {}
        };
    }

    componentDidMount() {
        this.props.fetchSubscriptions();

        this.checkRoute();

        if (this.props.currentSubscription) {
            this.setData();
        }
    }

    componentDidUpdate(prevProps) {
        this.props.getSubscriptions();

        this.checkRoute();

        if (!prevProps.currentSubscription && this.props.currentSubscription) {
            this.setData();
        }
    }

    checkRoute = () => {
        // user can't extend a non-existent subscription
        if (
            this.props.type === ROUTE_SUBSCRIPTION_TYPE_EXTEND &&
            !this.props.isLoading &&
            !this.props.error
        ) {
            if (
                !this.props.currentSubscription ||
                (this.props.currentSubscription &&
                    !isValid(this.props.currentSubscription))
            ) {
                history.push(ROUTE_SUBSCRIPTION_CHOOSE);
            }
        }
    };

    setData = () => {
        if (
            !isTrial(this.props.currentSubscription) &&
            isValid(this.props.currentSubscription)
        ) {
            this.setState({
                selectedPaymentType: getType(this.props.currentSubscription),
                allowedPaymentTypes: [getType(this.props.currentSubscription)],
                selectedPlan:
                    this.props.type === ROUTE_SUBSCRIPTION_TYPE_EXTEND
                        ? this.props.subscriptions.find(
                              s =>
                                  getId(s) ===
                                  getId(this.props.currentSubscription)
                          )
                        : null
            });
        }
    };

    checkPayment = (callback, coupon) => {
        this.setState({ isLoading: true, errors: {} });

        this.props
            .preparePayment(getId(this.state.selectedPlan), coupon)
            .then(response => {
                this.setState(
                    {
                        isLoading: false,
                        payment: response
                    },
                    callback
                );
            })
            .catch(err => {
                this.setState({
                    isLoading: false,
                    errors: { general: err.getMessage() },
                    payment: null
                });
            });
    };

    onValueChanged(field, value) {
        this.setState({
            [field]: value,
            errors: {}
        });
    }

    goToStep = step => {
        this.setState({ errors: {} });
        switch (step) {
            case 1:
                history.push(
                    ROUTE_SUBSCRIPTION_STEP_PLAN.replace(
                        ':type',
                        this.props.type
                    )
                );
                break;

            case 2:
                history.push(
                    ROUTE_SUBSCRIPTION_STEP_INVOICE.replace(
                        ':type',
                        this.props.type
                    )
                );
                break;

            default:
                break;
        }
    };

    onCouponSelected = coupon => {
        this.setState({ payment: null });

        this.checkPayment(undefined, coupon);
    };

    paymentGateway = () => {
        if (isVoid(this.state.payment)) {
            return;
        }

        window.location.href = this.state.payment.redirect_url;
    };

    getTranslation(type) {
        if (
            this.props.currentSubscription &&
            isTrial(this.props.currentSubscription) &&
            !isValid(this.props.currentSubscription)
        ) {
            // trial
            return t(`subscriptions.trial.${type}`);
        } else if (
            this.props.currentSubscription &&
            isValid(this.props.currentSubscription)
        ) {
            // renew
            return t(`subscriptions.${this.props.type}_plan.${type}`);
        } else {
            return t(`subscriptions.expired.${type}`);
        }
    }

    render() {
        const { errors, selectedPlan, payment } = this.state;
        const {
            error,
            type,
            currentSubscription,
            subscriptions,
            tax
        } = this.props;

        return (
            <div className="app">
                <HeaderContainer basicMode={true} />
                <div className="app-content no-sidebar">
                    <ImpersonationBanner />
                    <ContentBody>
                        <div className="container">
                            <ErrorView error={error} />
                            <ErrorView error={errors.general} />
                            {errors.payment && (
                                <div className="alert alert-danger">
                                    <h3>
                                        {t(
                                            'subscriptions.errors.payment_failed'
                                        )}
                                    </h3>

                                    {errors.payment}
                                </div>
                            )}

                            {this.props.isLoading || this.state.isLoading ? (
                                <Card>
                                    <LoadingIcon />
                                </Card>
                            ) : (
                                <Card>
                                    <h1 className="mb-3">
                                        {this.getTranslation('title')}
                                    </h1>
                                    <Switch>
                                        <Route
                                            path={ROUTE_SUBSCRIPTION_STEP_PLAN}
                                            render={() =>
                                                type ===
                                                ROUTE_SUBSCRIPTION_TYPE_EXTEND ? (
                                                    <Fragment>
                                                        <p>
                                                            {this.getTranslation(
                                                                'description'
                                                            )}
                                                        </p>
                                                        <div className="mt-4">
                                                            <CurrentPlan
                                                                currentPlan={
                                                                    currentSubscription
                                                                }
                                                                onClick={() => {
                                                                    this.checkPayment(
                                                                        () =>
                                                                            this.goToStep(
                                                                                2
                                                                            )
                                                                    );
                                                                }}
                                                            />
                                                        </div>
                                                    </Fragment>
                                                ) : (
                                                    <Fragment>
                                                        <p>
                                                            {this.getTranslation(
                                                                'description'
                                                            )}
                                                        </p>
                                                        <h2 className="d-block mb-2 mt-5">
                                                            {t(
                                                                'subscriptions.choose_plan'
                                                            )}
                                                        </h2>
                                                        <PlanPicker
                                                            subscriptions={
                                                                subscriptions
                                                            }
                                                            onPlanChange={plan =>
                                                                this.onValueChanged(
                                                                    'selectedPlan',
                                                                    plan
                                                                )
                                                            }
                                                            currentPlan={
                                                                currentSubscription
                                                            }
                                                            selectedPlan={
                                                                selectedPlan
                                                            }
                                                            onSubmit={() =>
                                                                this.checkPayment(
                                                                    () =>
                                                                        this.goToStep(
                                                                            2
                                                                        )
                                                                )
                                                            }
                                                        />
                                                    </Fragment>
                                                )
                                            }
                                        />
                                        {selectedPlan && (
                                            <Route
                                                path={
                                                    ROUTE_SUBSCRIPTION_STEP_INVOICE
                                                }
                                                render={() => (
                                                    <Fragment>
                                                        <BackButton
                                                            label={t(
                                                                'general.prev'
                                                            )}
                                                            to={ROUTE_SUBSCRIPTION_STEP_PLAN.replace(
                                                                ':type',
                                                                type
                                                            )}
                                                        />

                                                        <ShoppingCart
                                                            plan={selectedPlan}
                                                            currentPlan={
                                                                currentSubscription
                                                            }
                                                            tax={tax}
                                                            onCouponSelect={
                                                                this
                                                                    .onCouponSelected
                                                            }
                                                            payment={payment}
                                                        />

                                                        <SubmitButton
                                                            classes="float-right"
                                                            label={t(
                                                                'subscriptions.next_to_payment'
                                                            )}
                                                            onClick={
                                                                this
                                                                    .paymentGateway
                                                            }
                                                        />
                                                    </Fragment>
                                                )}
                                            />
                                        )}
                                        <Redirect
                                            to={ROUTE_SUBSCRIPTION_STEP_PLAN.replace(
                                                ':type',
                                                type
                                            )}
                                        />
                                    </Switch>
                                </Card>
                            )}
                        </div>
                    </ContentBody>
                </div>
                <MessageBar />
            </div>
        );
    }
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(SubscriptionContainer);
