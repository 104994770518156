import { isVoid } from '@dive/utils-js';
import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

import Icon from 'app/components/shared/icon/Icon';

const BackButton = ({ className, label, to, style }) => {
    let classes = 'btn-to btn-back';

    if (!isVoid(className)) {
        classes += ` ${className}`;
    }

    return (
        <Link style={style} className={classes} to={to}>
            <Icon name="arrow-left" />

            <span className="d-block">{label}</span>
        </Link>
    );
};

BackButton.propTypes = {
    label: PropTypes.string.isRequired,
    to: PropTypes.string.isRequired
};

export default BackButton;
