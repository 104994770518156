import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { t } from '@dive/localization-js';
import { getFullName } from '../../../../../core/models/client';
import ContentHeader from '../../../shared/content/ContentHeader';
import BackButton from '../../../shared/buttons/BackButton';
import { ROUTE_CLIENTS } from '../../../../platform/routing';
import Tabs from '../../../shared/tabs/Tabs';
import moment from 'moment';
import { getFormattedNumber } from '../../../../../core/utils/data';

const ClientDetailHeader = ({ client, tabs }) => {
    return (
        <ContentHeader>
            <BackButton to={ROUTE_CLIENTS} label={t('general.detail.back')} />
            {client && (
                <Fragment>
                    <div className="d-flex align-items-end">
                        <h1
                            className={
                                client.is_active ? '' : 'text-body-light'
                            }>
                            {getFullName(client)}
                        </h1>
                        <p className="text-body-light m-0 ml-3 d-flex align-bottom">
                            {client.birth_date &&
                                `${moment().diff(
                                    client.birth_date,
                                    'years',
                                    false
                                )}${t('general.units.year')}`}
                            {client.height &&
                                `${client.birth_date ? ' • ' : ''}${
                                    client.height
                                }${t('general.units.cm')}`}
                            {client.weight && client.weight !== 0
                                ? `${
                                      client.birth_date || client.height
                                          ? ' • '
                                          : ''
                                  }${getFormattedNumber(
                                      client.weight,
                                      'nl'
                                  )}${t('general.units.kg')}`
                                : null}
                        </p>
                    </div>
                    <Tabs
                        tabs={tabs.map(tab => {
                            // replace ':id' part in url with the client id
                            return Object.assign({}, tab, {
                                url: tab.url.replace(':id', client.id)
                            });
                        })}
                    />
                </Fragment>
            )}
        </ContentHeader>
    );
};

ClientDetailHeader.propTypes = {
    client: PropTypes.object,
    tabs: PropTypes.arrayOf(PropTypes.object)
};

export default ClientDetailHeader;
