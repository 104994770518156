import React, { Component } from 'react';
import { isString, isVoid } from '@dive/utils-js';
import {
    selectClientDiary,
    selectClientDiaryEntries,
    selectClientDiaryError,
    selectClientDiaryFields,
    selectClientDiaryTreatment
} from '../../../../../platform/model/client';

import Card from '../../../../shared/card/Card';
import EmptyView from '../../../../shared/view/EmptyView';
import ErrorView from '../../../../shared/view/ErrorView';
import LoadingIcon from '../../../../shared/loading/LoadingIcon';
import ReadNotification from '../../../../shared/read/ReadNotification';
import SortableTable from '../../../../shared/table/SortableTable';
import { connect } from 'react-redux';
import { getClientDiary } from '../../../../../../core/actions/diary';
import { history } from '../../../../../platform/routing';
import { isLoadingData } from '../../../../../../core/utils/data';
import moment from 'moment';
import { t } from '@dive/localization-js';

class ClientGeneralDiaryOverview extends Component {
    constructor(props) {
        super(props);
        this.state = {
            errors: {}
        };
    }

    componentDidMount() {
        this.props.getClientDiary();
    }

    componentDidUpdate(prevProps) {
        this.props.getClientDiary();
    }

    get columns() {
        return [
            {
                key: 'read',
                sortable: false,
                className: 'read-th'
            },
            {
                title: t('diary.general.list.date'),
                key: 'date'
            },
            ...this.getAllColumnHeaders(),
            {
                title: t('diary.general.list.note'),
                key: 'note'
            }
        ];
    }

    showGeneralDiaryDetail(date) {
        history.push(
            `${this.props.location.pathname}/${moment(date).format('DDMMYYYY')}`
        );
    }

    getAllFields() {
        const { fields, treatment } = this.props;

        if (isVoid(treatment)) {
            return [];
        }

        if (treatment.use_simplified_diary) {
            return Object.keys(treatment)
                .filter(
                    key => key.indexOf('simple_question') >= 0 && treatment[key]
                )
                .concat(fields);
        }

        return ['simple_question_sleep', 'simple_question_stress'];
    }

    getAllColumnHeaders() {
        return this.getAllFields().map(key => ({
            title: isString(key) ? t('treatment.plan.' + key) : key.name,
            key: isString(key) ? key : key.internal_name
        }));
    }

    getSimpleDiaryFields(entries) {
        return this.getAllFields().map(key => {
            let value = null;

            if (isString(key)) {
                const field = key.replace('simple_question_', '');

                if (field.indexOf('bool_') === 0 && !isVoid(entries[field])) {
                    value = entries[field].value
                        ? t('general.yes')
                        : t('general.no');
                } else {
                    value = isVoid(entries[field])
                        ? null
                        : entries[field].value;
                }
            } else if (!isVoid(entries[key.internal_name])) {
                value = entries[key.internal_name].value;

                if (typeof value === 'boolean') {
                    value = value ? t('general.yes') : t('general.no');
                }
            }

            return (
                <td key={isString(key) ? key : key.internal_name}>{value}</td>
            );
        });
    }

    renderRow(date, entries) {
        return (
            <tr
                onClick={() => this.showGeneralDiaryDetail(date)}
                className="tr-selectable"
                key={date}>
                <td className="read-td">
                    <ReadNotification read={entries.read} />
                </td>
                <td>
                    <span className="font-weight-semi-bold">
                        {moment(date).format(`DD/MM/YYYY`)}
                    </span>
                    <span className="pl-2">({moment(date).format(`dd`)})</span>
                </td>

                {this.getSimpleDiaryFields(entries)}

                <td>{isVoid(entries.note) ? '' : entries.note.value}</td>
            </tr>
        );
    }

    render() {
        const { entries, error, isLoading } = this.props;

        return isLoading ? (
            <LoadingIcon />
        ) : (
            <Card padding={false} className="mt-3">
                {isVoid(entries) ? (
                    <EmptyView label={t('diary.general.list.empty')} />
                ) : isVoid(error) ? (
                    <SortableTable columns={this.columns} isLoading={isLoading}>
                        {Object.keys(entries).map(date =>
                            this.renderRow(date, entries[date])
                        )}
                    </SortableTable>
                ) : (
                    <ErrorView error={error} />
                )}
            </Card>
        );
    }
}

const mapStateToProps = state => ({
    isLoading: isLoadingData(selectClientDiary(state)),
    entries: selectClientDiaryEntries(state),
    error: selectClientDiaryError(state),
    fields: selectClientDiaryFields(state),
    treatment: selectClientDiaryTreatment(state)
});

const mapDispatchToProps = {
    getClientDiary
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(ClientGeneralDiaryOverview);
