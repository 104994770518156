import {isVoid} from '@dive/utils-js';
import moment from "moment";

export const getFullName = (client) => {
  return `${client.first_name} ${client.name}`;
};

export const isActivated = (client) => {
  var coachingEnded = !client.coaching;
  if (!isVoid(client.coaching_ends_at)) {
    coachingEnded = moment(client.coaching_ends_at).isSameOrBefore(moment());
  }
  return !client.user || client.user.accepted_mobile_invite || coachingEnded;
};

export const selectClientLibrary = (state) => state.clients.detail.library;

export const selectClientLibraryData = (state) => {
  const library = selectClientLibrary(state);

  return library.data;
};

export const selectClientLibraryEntries = (state) => {
  const data = selectClientLibraryData(state);

  return isVoid(data) ? null : data.entries;
};

export const selectClientLibraryError = (state) => {
  const library = selectClientLibrary(state);

  return library.error;
};
