import PropTypes from 'prop-types';
import React from 'react';

const Card = ({ children, className, bodyClassName, padding = true }) => {
    return (
        <div className={`card ${className || ''}`}>
            {padding ? (
                <div className={`card-body ${bodyClassName || ''}`}>
                    {children}
                </div>
            ) : (
                children
            )}
        </div>
    );
};

Card.propTypes = {
    padding: PropTypes.bool
};

export default Card;
