import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { t } from '@dive/localization-js';
import { updateUser } from '@dive/react-redux-networking';
import DynamicForm from '../../shared/forms/DynamicForm';

const mapDispatchToProps = dispatch => {
    return {
        saveProfile: profile => {
            return dispatch(updateUser(profile));
        }
    };
};

class ProfileForm extends Component {
    constructor(props) {
        super(props);
        this.state = {};
    }

    componentDidMount() {
        this.setState({
            profile: Object.assign({}, this.props.profile)
        });
    }

    getFields() {
        return [
            {
                name: 'email',
                label: t('register.form.email'),
                type: 'email',
                required: this.props.mode !== 'invoice',
                size: '12',
                checkVisibility: this.isFieldVisible
            },
            {
                name: 'first_name',
                label: t('register.form.first_name'),
                type: 'string',
                required: true,
                size: '6'
            },
            {
                name: 'name',
                label: t('register.form.name'),
                type: 'string',
                required: true,
                size: '6'
            },
            {
                name: 'company',
                label: t('profile.company'),
                type: 'string',
                size: '12'
            },
            {
                name: 'vat',
                label: t('profile.vat'),
                type: 'string',
                size: '12'
            },
            {
                name: 'address_street',
                label: t('profile.street'),
                type: 'string',
                size: '12',
                required: this.props.mode === 'invoice'
            },
            {
                name: 'address_postal_code',
                label: t('profile.postal_code'),
                type: 'string',
                size: '4',
                required: this.props.mode === 'invoice'
            },
            {
                name: 'address_city',
                label: t('profile.city'),
                type: 'string',
                size: '8',
                required: this.props.mode === 'invoice'
            },
            {
                name: 'address_country',
                label: t('profile.country'),
                type: 'select',
                required: true,
                size: '12',
                options: [
                    {
                        name: t('clients.detail.general.country.BE'),
                        value: 'BE'
                    },
                    {
                        name: t('clients.detail.general.country.NL'),
                        value: 'NL'
                    }
                ],
                config: {
                    hideEmptyValue: true
                }
            },
            {
                name: 'password_new',
                label: t('profile.form.new_password'),
                type: 'password',
                size: '12',
                min_length: 12,
                checkVisibility: this.isFieldVisible
            },
            {
                name: 'password_confirmation',
                label: t('register.form.password_confirm'),
                type: 'password',
                size: '12',
                equals: 'password_new',
                min_length: 12,
                checkVisibility: this.isFieldVisible
            }
        ];
    }

    isFieldVisible = (field, model) => {
        return this.props.mode === 'profile';
    };

    onSubmit = model => {
        model = Object.assign({}, model);
        // check if user wants a new password, otherwise remove it from call
        if (model.password_new && model.password_new.length > 0) {
            model.password = model.password_new;
        }
        delete model.new_password;

        if (
            !(
                model.password_confirmation &&
                model.password_confirmation.length > 0
            )
        ) {
            delete model.password_confirmation;
        }
        return this.props.saveProfile(model);
    };

    render() {
        const { profile } = this.state;
        return profile ? (
            <DynamicForm
                title={t('profile.edit')}
                model={profile}
                fields={this.getFields()}
                dismissOnSuccess={this.props.mode === 'invoice'}
                onFinished={this.props.onFinished}
                submitLabel={this.props.submitLabel}
                onSaveForm={this.onSubmit}>
                {this.props.children}
            </DynamicForm>
        ) : null;
    }
}

ProfileForm.propTypes = {
    profile: PropTypes.object.isRequired,
    onFinished: PropTypes.func.isRequired,
    submitLabel: PropTypes.string,
    mode: PropTypes.oneOf(['profile', 'invoice'])
};

export default connect(
    undefined,
    mapDispatchToProps
)(ProfileForm);
