import { t } from '@dive/localization-js';

const setFields = (
    model,
    data,
    type,
    labelPrefix,
    defaultFields = null,
    size = '12'
) => {
    let filteredList = [];

    if (type) {
        filteredList = filterList(data, type);
    } else {
        filteredList = data;
    }

    const groupedFields = { fields: [] };

    let field;
    for (let i = 0, count = filteredList.length; i < count; i++) {
        field = filteredList[i];
        model[field.internal_name] = field.value;

        // add groups in their own property
        if (getFieldGroup(field)) {
            if (!groupedFields[getFieldGroup(field)]) {
                groupedFields[getFieldGroup(field)] = [getFormField(field)];
            } else {
                groupedFields[getFieldGroup(field)].push(getFormField(field));
            }
        } else {
            groupedFields.fields.push(getFormField(field));
        }
    }

    // now create fields
    let fields = Object.keys(groupedFields).reduce((array, field) => {
        array.push({
            fieldGroups: [
                {
                    title: t(`${labelPrefix}.${field}`),
                    name: field,
                    size: field === 'fields' ? '12' : '6',
                    fields: groupedFields[field]
                }
            ],
            size: size
        });
        return array;
    }, []);

    if (defaultFields) {
        fields = [...defaultFields, ...fields];
    }

    return fields;
};

export const filterList = (data, type) => {
    return data.filter(field => field.group === type);
};

export const getFormField = field => {
    return {
        id: field.id,
        name: field.internal_name,
        label: field.name,
        type: field.type,
        size: getFieldSize(field),
        required: field.is_required,
        unit: getFieldUnit(field)
    };
};

export const getFieldGroup = ({ internal_name }) => {
    if (internal_name.indexOf('drinks_') === 0) {
        return 'drinks';
    } else if (internal_name.indexOf('meal_') === 0) {
        return 'meal';
    }
    return null;
};

export const getFieldSize = ({ internal_name }) => {
    return internal_name.indexOf('meal_') === 0 ? '12' : '6';
};

export const getFieldUnit = ({ internal_name }) => {
    if (internal_name.indexOf('drinks_') === 0) {
        if (internal_name === 'drinks_coffee_tea') {
            return t('general.units.cups');
        } else {
            return t('general.units.glasses');
        }
    }
    return null;
};

export default setFields;
