import React, { Component } from 'react';

import DynamicFormDialog from '../../shared/forms/DynamicFormDialog';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { getEmoji } from '../../../../core/models/coaching';
import { isVoid } from '@dive/utils-js';
import moment from 'moment';
import { request } from '@dive/react-redux-networking';
import { t } from '@dive/localization-js';
import { updateCoaching } from '../../../../core/actions/coaching';

const mapStateToProps = state => {
    return {
        categories: state.categories.data,
        tags: state.tags.data
    };
};

const mapDispatchToProps = dispatch => {
    return {
        saveCoaching: coaching => {
            return request(dispatch(updateCoaching(coaching)));
        }
    };
};

class DigitalCoachingForm extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isLoading: false,
            errors: {}
        };
    }

    componentDidMount() {
        const coaching = Object.assign({}, this.props.coaching);
        const { coaching_starts_at } = Object.assign({}, this.props.client);
        const dateOnly =
            !isVoid(coaching_starts_at) &&
            moment(coaching_starts_at).isAfter(moment());

        // set GET format to POST format
        if (coaching.receiver) {
            if (coaching.receiver.client) {
                coaching.receiver.client_id = coaching.receiver.client.id;
            } else if (coaching.receiver.categories) {
                coaching.category_ids = coaching.receiver.categories.map(
                    cat => cat.id
                );
            }
        } else {
            coaching.receiver = {};
            coaching.category_ids = [];
        }

        if (coaching.tags) {
            coaching.tag_ids = coaching.tags.map(tag => tag.id);
        } else {
            coaching.tag_ids = [];
        }

        // set correct time
        if (dateOnly || (!coaching.sent && coaching.scheduled)) {
            coaching.schedule_type = 'date';
        } else if (!isVoid(coaching.delay_days)) {
            coaching.schedule_type = 'delay';
            coaching.scheduled = moment.utc().format();
        } else {
            coaching.schedule_type = 'instant';
            coaching.scheduled = moment.utc().format();
        }

        this.setState({
            coaching,
            dateOnly
        });
    }

    getFields() {
        var schedule_options = [
            {
                value: 'instant',
                name: t('digitalcoaching.form.schedule.now')
            },
            {
                value: 'date',
                name: t('digitalcoaching.form.schedule.date')
            }
        ];

        if (
            !(
                this.props.coaching.receiver &&
                (this.props.coaching.receiver.client ||
                    this.props.coaching.receiver.client_id)
            )
        ) {
            schedule_options.push({
                value: 'delay',
                name: t('digitalcoaching.form.schedule.delay')
            });
        }

        const fields = [
            {
                name: 'schedule_type',
                label: t('digitalcoaching.form.schedule'),
                type: 'radiobutton',
                required: true,
                size: '12',
                options: schedule_options,
                checkVisibility: this.isInputFieldVisible
            },
            {
                name: 'scheduled',
                label: t('digitalcoaching.list.date'),
                type: 'datetime',
                required: true,
                size: '8',
                config: {
                    fromMonth: new Date(),
                    disabledDays: {
                        before: new Date()
                    }
                },
                checkVisibility: this.isInputFieldVisible
            },
            {
                name: 'delay_days',
                label: t('digitalcoaching.list.delay'),
                type: 'number',
                config: {
                    min: '0',
                    max: 60,
                    placeholder: '1'
                },
                checkVisibility: this.isInputFieldVisible
            },
            {
                name: 'category_ids',
                label: t('digitalcoaching.list.to'),
                type: 'categories',
                required: !(
                    this.props.coaching.receiver &&
                    (this.props.coaching.receiver.client ||
                        this.props.coaching.receiver.client_id)
                ),
                isArray: true,
                size: '12',
                checkVisibility: this.isInputFieldVisible
            },
            {
                name: 'tag_ids',
                label: t('digitalcoaching.list.tags'),
                type: 'tags',
                isArray: true,
                size: '12'
            },
            {
                name: 'message_food',
                label: t('digitalcoaching.list.food'),
                type: 'text',
                size: '12'
            },
            {
                name: 'image_food',
                label: null,
                type: 'file',
                size: '12',
                accept: 'image/*',
                accept_error: t('digitalcoaching.form.errors.image.upload'),
                upload_message: t('digitalcoaching.form.image.upload')
            },
            {
                name: 'message_movement',
                label: t('digitalcoaching.list.movement'),
                type: 'text',
                size: '12'
            },
            {
                name: 'image_movement',
                label: null,
                type: 'file',
                size: '12',
                accept: 'image/*',
                accept_error: t('digitalcoaching.form.errors.image.upload'),
                upload_message: t('digitalcoaching.form.image.upload')
            },
            {
                name: 'message_motivation',
                label: t('digitalcoaching.list.motivation'),
                type: 'text',
                size: '12'
            },
            {
                name: 'image_motivation',
                label: null,
                type: 'file',
                size: '12',
                accept: 'image/*',
                accept_error: t('digitalcoaching.form.errors.image.upload'),
                upload_message: t('digitalcoaching.form.image.upload')
            },
            {
                name: 'emotion',
                label: t('digitalcoaching.list.emotion'),
                type: 'radiobutton',
                size: '12',
                options: [
                    {
                        value: '',
                        name: t('digitalcoaching.form.emotion.none')
                    },
                    {
                        value: 'thumbs_up',
                        name: getEmoji({ emotion: 'thumbs_up' })
                    },
                    {
                        value: 'thumbs_down',
                        name: getEmoji({ emotion: 'thumbs_down' })
                    },
                    {
                        value: 'medal',
                        name: getEmoji({ emotion: 'medal' })
                    }
                ]
            }
        ];

        if (this.state.dateOnly) {
            fields[0].options.splice(0, 1);
        }

        if (
            !isVoid(this.props.client) &&
            !isVoid(this.props.client.coaching_starts_at)
        ) {
            fields[1].config.disabledDays.before = moment
                .max(moment(), moment(this.props.client.coaching_starts_at))
                .toDate();

            if (!isVoid(this.props.client.coaching_ends_at)) {
                fields[1].config.disabledDays.after = moment(
                    this.props.client.coaching_ends_at
                )
                    .subtract(2, 'hours')
                    .toDate();
            }
        }

        return fields;
    }

    isImage(type) {
        return type.match(/image\/.*/);
    }

    isInputFieldVisible = (field, model) => {
        if (
            field.name === 'category_ids' &&
            model.receiver &&
            model.receiver.client_id
        ) {
            // don't let user change receiver if there is a client id present
            return false;
        } else if (
            model.sent &&
            (field.name === 'scheduled' ||
                field.name === 'schedule_type' ||
                field.name === 'delay_days')
        ) {
            // don't let user edit schedule date if coaching is already sent
            return false;
        } else if (field.name === 'scheduled') {
            // if this is a scheduled message instead of 'now', show the date
            return model.schedule_type === 'date';
        } else if (field.name === 'delay_days') {
            // if this is a scheduled message instead of 'now', show the date
            return model.schedule_type === 'delay';
        }

        return true;
    };

    onSubmit = model => {
        const data = { ...model };

        // set category_ids if we're not sending to user
        if (!data.receiver.client_id && data.category_ids) {
            data.receiver.category_ids = data.category_ids.map(id =>
                parseInt(id, 10)
            );
        }
        // set the scheduled date to now if the user wants to send this message instantly
        if (!data.sent && data.schedule_type !== 'date') {
            data.scheduled = moment.utc().format();
        }
        // make sure the scheduled date is not in the past
        if (!data.sent && data.id && data.scheduled) {
            if (moment.utc(data.scheduled).isBefore(moment.utc())) {
                data.scheduled = moment.utc().format();
            }
        }

        if (data.schedule_type === 'delay') {
            data.scheduled = null;
        }

        // remove original images if edit
        if (this.props.coaching && this.props.coaching.id) {
            delete data.image_food;
            delete data.image_movement;
            delete data.image_motivation;
        }

        return this.props.saveCoaching(data);
    };

    onFinished = () => {
        // close dialog
        this.props.onFinished();
    };

    onValidate = (fields, model) => {
        const errors = {};

        if (
            !(model.image_foodFile || model.image_food) &&
            !(model.image_movementFile || model.image_movement) &&
            !(model.image_motivationFile || model.image_motivation) &&
            !model.message_food &&
            !model.message_movement &&
            !model.message_motivation &&
            !model.emotion
        ) {
            errors.image_food = t('digitalcoaching.form.errors.notes');
            errors.image_movement = t('digitalcoaching.form.errors.notes');
            errors.image_motivation = t('digitalcoaching.form.errors.notes');
            errors.emotion = t('digitalcoaching.form.errors.notes');
        }

        if (model.image_foodFile && !this.isImage(model.image_foodFile.type)) {
            errors.image_food = t('digitalcoaching.form.errors.image.upload');
        }

        if (
            model.image_movementFile &&
            !this.isImage(model.image_movementFile.type)
        ) {
            errors.image_movement = t(
                'digitalcoaching.form.errors.image.upload'
            );
        }

        if (
            model.image_motivationFile &&
            !this.isImage(model.image_motivationFile.type)
        ) {
            errors.image_motivation = t(
                'digitalcoaching.form.errors.image.upload'
            );
        }

        return errors;
    };

    render() {
        const { coaching } = this.state;

        return coaching ? (
            <DynamicFormDialog
                title={t(
                    coaching.id ? 'digitalcoaching.edit' : 'digitalcoaching.add'
                )}
                model={coaching}
                fields={this.getFields()}
                onDismissClicked={this.props.onDismissClicked}
                onFinished={this.props.onFinished}
                onValidate={this.onValidate}
                onSaveForm={this.onSubmit}
            />
        ) : null;
    }
}

DigitalCoachingForm.propTypes = {
    coaching: PropTypes.object.isRequired,
    onDismissClicked: PropTypes.func.isRequired,
    onFinished: PropTypes.func.isRequired
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(DigitalCoachingForm);
