import React from 'react';
import PropTypes from 'prop-types';
import Icon from './Icon';

const CircleIcon = ({ className, icon }) => {
    return (
        <div className={`icon-circle  ${className || ''}`}>
            {icon && <Icon name={icon} />}
        </div>
    );
};

CircleIcon.propTypes = {
    icon: PropTypes.string
};

export default CircleIcon;
