import {
    ROUTE_CLIENTS_DETAIL,
    ROUTE_CLIENTS_DETAIL_TAB_CONSULTATIONS_DETAIL,
    history
} from '../../../../../platform/routing';

import React from 'react';
import SortableTable from '../../../../shared/table/SortableTable';

const getColumns = cols => {
    const columns = [];
    for (let col = 0; col < cols.length; col++) {
        columns.push({
            title: cols[col],
            key: col,
            sortable: false
        });
    }

    return columns;
};

const goToDetail = (type = 'consultation', id, clientId, urlSuffix) => {
    switch (type) {
        case 'client':
            let route = ROUTE_CLIENTS_DETAIL.replace(':id', id);
            if (urlSuffix) {
                route = `${ROUTE_CLIENTS_DETAIL.replace(
                    ':id',
                    id
                )}${urlSuffix}`;
            }
            history.push(route);
            break;
        case 'consultation':
            history.push(
                ROUTE_CLIENTS_DETAIL_TAB_CONSULTATIONS_DETAIL.replace(
                    ':id',
                    clientId
                ).replace(':consultation_id', id)
            );
            break;
        default:
            break;
    }
};

export default function ListCard({ data, columns }) {
    return (
        <SortableTable columns={getColumns(columns)} isLoading={false}>
            {data.map((dataset, dataSetIndex) => (
                <tr className="tr-selectable" key={`tr-${dataSetIndex}`}>
                    {columns.map((column, columnIndex) => {
                        return (
                            <td
                                onClick={() =>
                                    goToDetail(
                                        dataset.type,
                                        dataset.id,
                                        dataset.client_id,
                                        dataset.url_suffix
                                    )
                                }
                                key={`td-${columnIndex}`}>
                                {dataset.data[columnIndex]}
                            </td>
                        );
                    })}
                </tr>
            ))}
        </SortableTable>
    );
}
