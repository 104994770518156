import React from 'react';
import { ROUTE_CLIENTS, ROUTE_CLIENTS_DETAIL } from 'app/platform/routing';
import { Route, Switch } from 'react-router-dom';
import ClientsOverviewContainer from './overview/ClientsOverviewContainer';
import ClientDetailContainer from './detail/ClientDetailContainer';

const ClientsContainer = () => (
    <Switch>
        <Route path={ROUTE_CLIENTS_DETAIL} component={ClientDetailContainer} />

        <Route path={ROUTE_CLIENTS} component={ClientsOverviewContainer} />
    </Switch>
);

export default ClientsContainer;
