import { isVoid } from '@dive/utils-js';
import { t } from '@dive/localization-js';

const CLIENT_LIMIT_UNLIMITED = 999999;

export const getId = subscription => {
    return subscription.id;
};

export const getType = subscription => {
    return subscription.type;
};

export const getName = subscription => {
    return t('subscriptions.plans.' + subscription.id);
};

export const getInterval = subscription => {
    if (getId(subscription).indexOf('_') >= 0) {
        return getId(subscription).split('_')[1];
    }
    return '';
};

export const getPrice = (subscription, discount, discountPercent) => {
    if (discountPercent) {
        return (
            subscription.price -
            Math.round(subscription.price * (discountPercent / 100))
        );
    } else {
        return discount ? subscription.price - discount : subscription.price;
    }
};

export const getPriceWithTax = (
    subscription,
    tax,
    discount,
    discountPercent
) => {
    return getPrice(subscription, discount, discountPercent) * (tax / 100 + 1);
};

export const getFormattedPrice = subscription => {
    return getPriceWithCurrency(
        subscription,
        parseFloat(
            (getInterval(subscription) === 'yearly'
                ? subscription.price / 12
                : subscription.price / 3) / 100
        ).toLocaleString('nl')
    );
};

export const getPriceWithCurrency = (subscription, price) => {
    switch (subscription.currency) {
        case 'eur':
            return `€${price}`;

        default:
            return price;
    }
};

export const getCurrency = subscription => {
    return subscription.currency;
};

export const getClientLimit = subscription => {
    return subscription.client_limit;
};

export const isUnlimited = subscription => {
    return subscription.client_limit >= CLIENT_LIMIT_UNLIMITED;
};

export const isInfinite = subscription => {
    return !subscription.ends_at;
};

export const getEndDate = subscription => {
    if (subscription.ends_at) {
        return subscription.ends_at;
    } else {
        return subscription.current_period_end;
    }
};

export const getStartDate = subscription => {
    if (subscription.starts_at) {
        return subscription.starts_at;
    }
    return null;
};

export const isValid = subscription => {
    return isVoid(subscription) ? false : subscription.is_valid;
};

export const setValid = (subscription, valid) => {
    return Object.assign({}, subscription, { is_valid: valid });
};

export const isTrial = subscription => {
    return subscription.id === 'trial';
};
