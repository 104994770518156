import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { t } from '@dive/localization-js';
import { request } from '@dive/react-redux-networking';

import {
    deleteConsultation,
    getConsultationDetail
} from '../../../../../core/actions/consultations';
import Card from '../../../shared/card/Card';
import BackButton from '../../../shared/buttons/BackButton';
import {
    history,
    ROUTE_CLIENTS,
    ROUTE_CLIENTS_DETAIL_TAB_CONSULTATIONS
} from '../../../../platform/routing';
import DeleteAlertModal from '../../../shared/modal/DeleteAlertModal';
import Icon from '../../../shared/icon/Icon';
import ClientConsultationInfo from './ClientConsultationInfo';
import ClientConsultationMeasurements from './ClientConsultationMeasurements';
import { isLoadingData } from '../../../../../core/utils/data';
import ConsultationForm from './form/ConsultationForm';
import ConsultationNotesForm from './form/ConsultationNotesForm';

const mapStateToProps = (state, props) => {
    const { consultationDetail } = state.clients.detail;
    const data = consultationDetail ? consultationDetail.data : null;
    return {
        // check if the current page id matches the user object we have
        shouldLoadData:
            (!consultationDetail ||
                (!consultationDetail.isLoading &&
                    (consultationDetail.id !==
                        parseInt(props.match.params.consultation_id, 10) ||
                        !data))) &&
            !(consultationDetail && consultationDetail.error),
        isLoading: isLoadingData(consultationDetail),
        consultation: data,
        error: consultationDetail ? consultationDetail.error : null,
        id: parseInt(props.match.params.consultation_id, 10)
    };
};

const mapDispatchToProps = dispatch => {
    return {
        getConsultationDetail: id => {
            dispatch(getConsultationDetail(id));
        },
        deleteConsultation: id => {
            return request(dispatch(deleteConsultation(id)));
        }
    };
};

class ClientConsultationsDetail extends Component {
    constructor(props) {
        super(props);
        this.state = {
            actions: {},
            errors: {}
        };
    }

    componentDidMount() {
        if (this.props.shouldLoadData) {
            this.fetchConsultation();
        }
    }

    componentDidUpdate(prevProps) {
        // check if we need to reload clients
        if (!prevProps.shouldLoadData && this.props.shouldLoadData) {
            this.fetchConsultation();
        }
    }

    editConsultation = consultation => {
        this.setState({
            actions: {
                updateConsultation: consultation
            }
        });
    };

    deleteConsultation = consultation => {
        this.setState({
            actions: {
                deleteConsultation: consultation
            }
        });
    };

    editNotes = consultation => {
        this.setState({
            actions: {
                updateNotes: consultation
            }
        });
    };

    onFinishAction(actionDelete) {
        // remove dialog
        this.setState({ actions: {} });
        if (!actionDelete) {
            // refresh consultation
            this.fetchConsultation();
        } else {
            // back to clients
            history.push(ROUTE_CLIENTS);
        }
    }

    fetchConsultation() {
        // refresh consultations
        this.props.getConsultationDetail(this.props.id);
    }

    render() {
        const { consultation } = this.props;
        const measurements = {};
        // transform measurements to object with keys
        if (consultation) {
            for (const m of consultation.measurements) {
                measurements[m.key] = m.value;
            }
        }
        return (
            <Fragment>
                {consultation ? (
                    <Fragment>
                        <BackButton
                            label={t('consultations.detail.back')}
                            to={ROUTE_CLIENTS_DETAIL_TAB_CONSULTATIONS.replace(
                                ':id',
                                this.props.client.id
                            )}
                        />
                        <div className="row mt-3  mb-4">
                            <div className="col-sm-3">
                                <h2>{t('consultations.detail.info')}</h2>
                                <ClientConsultationInfo
                                    readOnly={this.props.readOnly}
                                    consultation={consultation}
                                    onEditClicked={() =>
                                        this.editConsultation(consultation)
                                    }
                                />
                                {!this.props.readOnly && (
                                    <div className="row mt-2 mb-3">
                                        <div className="col-sm-12">
                                            <Card
                                                padding={false}
                                                className="mt-3">
                                                <button
                                                    onClick={() =>
                                                        this.deleteConsultation(
                                                            consultation
                                                        )
                                                    }
                                                    className="btn btn-alert btn-card">
                                                    <Icon name="delete" />{' '}
                                                    {t(
                                                        'consultations.detail.delete'
                                                    )}
                                                </button>
                                            </Card>
                                        </div>
                                    </div>
                                )}
                            </div>
                            <div className="col-sm-9">
                                <h2>
                                    {t('consultations.detail.measurements')}
                                </h2>
                                <ClientConsultationMeasurements
                                    readOnly={this.props.readOnly}
                                    consultation={consultation}
                                    measurements={measurements}
                                    onEditClicked={() =>
                                        this.editNotes(consultation)
                                    }
                                />
                            </div>
                        </div>
                    </Fragment>
                ) : null}
                {/* Create/edit consultation modal */}
                {this.state.actions.updateConsultation && (
                    <ConsultationForm
                        consultation={this.state.actions.updateConsultation}
                        onDismissClicked={() => this.editConsultation(null)}
                        client={this.props.client}
                        onFinished={() => this.onFinishAction(false)}
                    />
                )}
                {/* Create/edit consultation modal */}
                {this.state.actions.updateNotes && (
                    <ConsultationNotesForm
                        consultation={this.state.actions.updateNotes}
                        onDismissClicked={() => this.editNotes(null)}
                        client={this.props.client}
                        onFinished={() => this.onFinishAction()}
                    />
                )}
                {/* Delete consultation modal */}
                {this.state.actions.deleteConsultation && (
                    <DeleteAlertModal
                        title={t('consultations.delete.title')}
                        description={t('consultations.delete.description')}
                        onDelete={() =>
                            this.props.deleteConsultation(
                                this.state.actions.deleteConsultation.id
                            )
                        }
                        onDismissClicked={() => this.deleteConsultation(null)}
                        onFinished={() => this.onFinishAction(true)}
                    />
                )}
            </Fragment>
        );
    }
}

ClientConsultationsDetail.propTypes = {
    client: PropTypes.object.isRequired
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(ClientConsultationsDetail);
