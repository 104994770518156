import React, { Fragment } from 'react';
import { connect } from 'react-redux';
import Card from '../../shared/card/Card';
import { history, ROUTE_LOGIN } from 'app/platform/routing';
import Logo from '../../shared/logo/Logo';
import LoadingIcon from '../../shared/loading/LoadingIcon';
import ErrorView from '../../shared/view/ErrorView';
import { verifyEmail } from '@dive/react-redux-networking';
import { showMessage } from '../../../platform/actions/messages';
import qs from 'qs';
import { t } from '@dive/localization-js';

const mapStateToProps = (state, props) => {
    // Url format = expires=x&id=x&signature=x
    const query = qs.parse(props.location.search, { ignoreQueryPrefix: true });
    return {
        ...query
    };
};

const mapDispatchToProps = dispatch => {
    return {
        verify: (id, expires, signature) => {
            return dispatch(verifyEmail(id, expires, signature));
        },
        showMessage: message => {
            return dispatch(showMessage(message));
        }
    };
};

class EmailVerificationContainer extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            isLoading: true,
            errors: {}
        };
    }

    componentDidMount() {
        // validate
        if (this.props.expires && this.props.id && this.props.signature) {
            this.props
                .verify(this.props.id, this.props.expires, this.props.signature)
                .then(response => {
                    // show message (delay because of MessageBar issues)
                    setTimeout(() => {
                        this.props.showMessage(t('verify.email.success'));
                        // go to login screen
                        history.push(ROUTE_LOGIN);
                    }, 500);
                })
                .catch(err => {
                    this.setState({
                        isLoading: false,
                        errors: {
                            general: err.getMessage()
                        }
                    });
                });
        } else {
            history.push(ROUTE_LOGIN);
        }
    }

    render() {
        return (
            <div className="container">
                <Card>
                    <div className="mb-5">
                        <Logo />
                    </div>
                    <Fragment>
                        {this.state.isLoading && <LoadingIcon />}
                        <ErrorView error={this.state.errors.general} />
                    </Fragment>
                </Card>
            </div>
        );
    }
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(EmailVerificationContainer);
