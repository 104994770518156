import 'react-app-polyfill/ie11';
import 'core-js/features/array';
import 'core-js/features/object';
import './app/style/app.scss';

import * as Sentry from '@sentry/browser';

import { AuthActions, authReducers } from '@dive/react-redux-networking';
import {
    ConnectedRouter,
    connectRouter,
    routerMiddleware
} from 'connected-react-router';
import { applyMiddleware, combineReducers, compose, createStore } from 'redux';

import App from './app/components/App';
import { Provider } from 'react-redux';
import React from 'react';
import { Route } from 'react-router-dom';
import app from './core/reducers/app';
import categories from './app/platform/reducers/categories';
import tags from './app/platform/reducers/tags';
import clients from './app/platform/reducers/clients';
import coaching from './core/reducers/coaching';
import consultations from './core/reducers/consultations';
import dashboard from './app/platform/reducers/dashboard';
import { history } from './app/platform/routing';
import { initLocalizations } from './app/platform/localization/localization';
import { libraryReducer as library } from './core/reducers/library';
import measurements from './core/reducers/measurements';
import messages from './app/platform/reducers/messages';
import { networkingMiddleware } from './app/platform/networking/networkingMiddleware';
import { render } from 'react-dom';
import settings from './core/reducers/settings';
import subscribers from './app/platform/reducers/subscribers';
import subscriptions from './app/platform/reducers/subscriptions';
import svg4everybody from 'svg4everybody';
import thunkMiddleware from 'redux-thunk';
import userFields from './app/platform/reducers/fields';

// init Sentry with current version number
Sentry.init({
    dsn: process.env.REACT_APP_SENTRY_KEY,
    release: process.env.REACT_APP_CURRENT_GIT_SHA
});

// svg4everybody
svg4everybody();

// init localizations
initLocalizations('nl', 'nl');

// init reducers
const appReducer = combineReducers({
    app,
    ...authReducers,
    userFields,
    consultations,
    clients,
    categories,
    tags,
    coaching,
    dashboard,
    library,
    messages,
    measurements,
    subscribers,
    subscriptions,
    settings,
    router: connectRouter(history)
});

const rootReducer = (state, action) => {
    if (action.type === AuthActions.CLEAR_AUTH) {
        state = undefined;
    }

    return appReducer(state, action);
};

// init store
const composeEnhancer = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
const store = createStore(
    rootReducer,
    composeEnhancer(
        applyMiddleware(
            thunkMiddleware,
            ...networkingMiddleware,
            routerMiddleware(history)
        )
    )
);

render(
    <Provider store={store}>
        <ConnectedRouter history={history}>
            <Route path={'/'} component={App} />
        </ConnectedRouter>
    </Provider>,
    document.getElementById('root')
);
