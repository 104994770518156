import { isVoid } from '@dive/utils-js';

export const hasFoodTreatment = treatment => {
    return (
        !isVoid(treatment) &&
        (!isVoid(treatment.kcal_total) ||
            !isVoid(treatment.fat_count) ||
            !isVoid(treatment.protein_count) ||
            !isVoid(treatment.carbs_count))
    );
};

export const hasMovementTreatment = treatment => {
    return (
        !isVoid(treatment) &&
        (!isVoid(treatment.movement_total) &&
            !isVoid(treatment.movement_total) &&
            treatment.movement_total > 0)
    );
};

export const showNumbers = treatment => {
    return !isVoid(treatment) && treatment.show_nutrient_values;
};
