import { isVoid } from '@dive/utils-js';
import React, { Fragment } from 'react';
import PropTypes from 'prop-types';

const URL_REGEX = /(((https?:\/\/)|(www\.))[^\s]+)/g;

const generateLink = part =>
    part.split(URL_REGEX).reduce(
        (acc, cur) =>
            acc.concat(
                acc &&
                    acc.find(
                        part => part && part.key && part.key.indexOf(cur) >= 0
                    ) !== undefined ? (
                    ''
                ) : URL_REGEX.test(cur) ? (
                    <a
                        rel="noopener noreferrer"
                        title={cur}
                        target="_blank"
                        key={cur}
                        href={
                            cur.indexOf('http') === 0 ? cur : `http://${cur}`
                        }>
                        {cur}
                    </a>
                ) : (
                    cur
                )
            ),
        []
    );

const MultilineText = ({ children, allowLinks = false }) => {
    if (isVoid(children) || children.length < 1) {
        return null;
    }

    return children.split('\n').map((part, idx) => {
        const content = allowLinks ? generateLink(part) : part;

        return (
            <Fragment key={idx}>
                {content}

                <br />
            </Fragment>
        );
    });
};

MultilineText.propTypes = {
    children: PropTypes.string,
    allowLinks: PropTypes.bool
};

export default MultilineText;
