import React, { Fragment } from 'react';

import Card from 'app/components/shared/card/Card';
import EmptyView from '../../../shared/view/EmptyView';
import IconButton from '../../../shared/buttons/IconButton';
import MultilineText from '../../../shared/text/MultilineText';
import PropTypes from 'prop-types';
import { t } from '@dive/localization-js';

const ClientConsultationMeasurements = ({
    readOnly,
    measurements,
    consultation,
    onEditClicked
}) => {
    return (
        <Card>
            {Object.keys(measurements).length > 0 ||
            Object.keys(consultation).filter(
                key =>
                    !!consultation[key] &&
                    key !== 'id' &&
                    key !== 'client' &&
                    key !== 'scheduled' &&
                    key !== 'measurements'
            ).length > 0 ? (
                <Fragment>
                    <div className="row mt-2">
                        <div className="col-sm-12">
                            <dl className="row">
                                <div className="col-lg-3">
                                    <div className="row">
                                        <dt className="col mb-0">
                                            {t(
                                                `consultations.detail.measurements.weight`
                                            )}
                                        </dt>
                                        <dd className="col-12">
                                            {measurements.weight &&
                                            measurements.weight !== 0
                                                ? `${parseFloat(
                                                      measurements.weight
                                                  ).toLocaleString('nl')}${t(
                                                      'general.units.kg'
                                                  )}`
                                                : ''}
                                        </dd>
                                    </div>
                                    <div className="row">
                                        <dt className="col mb-0">
                                            {t(
                                                `consultations.detail.measurements.fat`
                                            )}
                                        </dt>
                                        <dd className="col-12">
                                            {measurements.fat
                                                ? `${parseFloat(
                                                      measurements.fat
                                                  ).toLocaleString('nl')}${t(
                                                      'general.units.kg'
                                                  )}`
                                                : ''}
                                            {measurements.fat &&
                                            measurements.fat_percentage
                                                ? ' / '
                                                : ''}
                                            {measurements.fat_percentage
                                                ? `${parseFloat(
                                                      measurements.fat_percentage
                                                  ).toLocaleString('nl')}%`
                                                : ''}
                                        </dd>
                                    </div>
                                    <div className="row">
                                        <dt className="col mb-0">
                                            {t(
                                                `consultations.detail.measurements.lean`
                                            )}
                                        </dt>
                                        <dd className="col-12">
                                            {measurements.lean
                                                ? `${parseFloat(
                                                      measurements.lean
                                                  ).toLocaleString('nl')}${t(
                                                      'general.units.kg'
                                                  )}`
                                                : ''}
                                            {measurements.lean &&
                                            measurements.lean_percentage
                                                ? ' / '
                                                : ''}
                                            {measurements.lean_percentage
                                                ? `${parseFloat(
                                                      measurements.lean_percentage
                                                  ).toLocaleString('nl')}%`
                                                : ''}
                                        </dd>
                                    </div>
                                    <div className="row">
                                        <dt className="col mb-0">
                                            {t(
                                                `consultations.detail.measurements.muscle`
                                            )}
                                        </dt>
                                        <dd className="col-12">
                                            {measurements.muscle
                                                ? `${parseFloat(
                                                      measurements.muscle
                                                  ).toLocaleString('nl')}${t(
                                                      'general.units.kg'
                                                  )}`
                                                : ''}
                                            {measurements.muscle &&
                                            measurements.muscle_percentage
                                                ? ' / '
                                                : ''}
                                            {measurements.muscle_percentage
                                                ? `${parseFloat(
                                                      measurements.muscle_percentage
                                                  ).toLocaleString('nl')}%`
                                                : ''}
                                        </dd>
                                    </div>
                                    <div className="row">
                                        <dt className="col mb-0">
                                            {t(
                                                `consultations.detail.measurements.visceral`
                                            )}
                                        </dt>
                                        <dd className="col-12">
                                            {measurements.visceral}
                                        </dd>
                                    </div>
                                    <div className="row">
                                        <dt className="col mb-0">
                                            {t(
                                                `consultations.detail.measurements.bmi`
                                            )}
                                        </dt>
                                        <dd className="col-12">
                                            {measurements.bmi
                                                ? `${parseFloat(
                                                      measurements.bmi
                                                  ).toLocaleString('nl')}`
                                                : ''}
                                        </dd>
                                    </div>
                                    <div className="row">
                                        <dt className="col mb-0">
                                            {t(
                                                `consultations.detail.measurements.bmr`
                                            )}
                                        </dt>
                                        <dd className="col-12">
                                            {measurements.bmr
                                                ? `${parseFloat(
                                                      measurements.bmr
                                                  ).toLocaleString('nl')}`
                                                : ''}
                                        </dd>
                                    </div>
                                    <div className="row">
                                        <dt className="col mb-0">
                                            {t(
                                                `consultations.detail.measurements.upper_arms`
                                            )}
                                        </dt>
                                        <dd className="col-12">
                                            {measurements.upper_arms
                                                ? `${parseFloat(
                                                      measurements.upper_arms
                                                  ).toLocaleString('nl')}${t(
                                                      'general.units.cm'
                                                  )}`
                                                : ''}
                                        </dd>
                                    </div>
                                    <div className="row">
                                        <dt className="col mb-0">
                                            {t(
                                                `consultations.detail.measurements.upper_legs`
                                            )}
                                        </dt>
                                        <dd className="col-12">
                                            {measurements.upper_legs
                                                ? `${parseFloat(
                                                      measurements.upper_legs
                                                  ).toLocaleString('nl')}${t(
                                                      'general.units.cm'
                                                  )}`
                                                : ''}
                                        </dd>
                                    </div>
                                    <div className="row">
                                        <dt className="col mb-0">
                                            {t(
                                                `consultations.detail.measurements.waist`
                                            )}
                                        </dt>
                                        <dd className="col-12">
                                            {measurements.waist
                                                ? `${parseFloat(
                                                      measurements.waist
                                                  ).toLocaleString('nl')}${t(
                                                      'general.units.cm'
                                                  )}`
                                                : ''}
                                        </dd>
                                    </div>
                                    <div className="row">
                                        <dt className="col mb-0">
                                            {t(
                                                `consultations.detail.measurements.hips`
                                            )}
                                        </dt>
                                        <dd className="col-12">
                                            {measurements.hips
                                                ? `${parseFloat(
                                                      measurements.hips
                                                  ).toLocaleString('nl')}${t(
                                                      'general.units.cm'
                                                  )}`
                                                : ''}
                                        </dd>
                                    </div>
                                </div>
                                <div className="col-lg-9">
                                    <div className="row">
                                        <dt className="col mb-0">
                                            {t(
                                                `consultations.detail.measurements.clothing_size`
                                            )}
                                        </dt>
                                        <dd className="col-12">
                                            {measurements.clothing_size}
                                        </dd>
                                    </div>
                                    <div className="row">
                                        <dt className="col mb-0">
                                            {t(
                                                `consultations.detail.measurements.blood_pressure`
                                            )}
                                        </dt>
                                        <dd className="col-12">
                                            {measurements.blood_pressure}
                                        </dd>
                                    </div>
                                    {consultation.data &&
                                        consultation.data.map(field => {
                                            return (
                                                <div
                                                    key={field.id}
                                                    className="row">
                                                    <dt className="col mb-0">
                                                        {field.name}
                                                    </dt>
                                                    <dd className="col-12">
                                                        {field.value}
                                                    </dd>
                                                </div>
                                            );
                                        })}
                                </div>
                            </dl>
                        </div>
                    </div>
                    {!readOnly && (
                        <div className="card-buttons">
                            <IconButton
                                icon="edit"
                                onClick={() => onEditClicked()}
                            />
                        </div>
                    )}
                    <div className="row">
                        <div className="col-sm-12 col-lg-6">
                            <dl className="row">
                                {consultation.note_food && (
                                    <Fragment>
                                        <dt className="col-sm-4">
                                            {t('consultations.list.note_food')}
                                        </dt>
                                        <dd className="col-sm-8">
                                            <MultilineText>
                                                {consultation.note_food}
                                            </MultilineText>
                                        </dd>
                                    </Fragment>
                                )}
                                {consultation.note_movement && (
                                    <Fragment>
                                        <dt className="col-sm-4">
                                            {t(
                                                'consultations.list.note_movement'
                                            )}
                                        </dt>
                                        <dd className="col-sm-8">
                                            <MultilineText>
                                                {consultation.note_movement}
                                            </MultilineText>
                                        </dd>
                                    </Fragment>
                                )}
                                {consultation.extra && (
                                    <Fragment>
                                        <dt className="col-sm-4">
                                            {t('consultations.list.extra')}
                                        </dt>
                                        <dd className="col-sm-8">
                                            <MultilineText>
                                                {consultation.extra}
                                            </MultilineText>
                                        </dd>
                                    </Fragment>
                                )}
                                {consultation.attention_points && (
                                    <Fragment>
                                        <dt className="col-sm-4">
                                            {t(
                                                'consultations.list.attention_points'
                                            )}
                                        </dt>
                                        <dd className="col-sm-8">
                                            <MultilineText>
                                                {consultation.attention_points}
                                            </MultilineText>
                                        </dd>
                                    </Fragment>
                                )}
                                {consultation.next_goal && (
                                    <Fragment>
                                        <dt className="col-sm-4">
                                            {t('consultations.list.next_goal')}
                                        </dt>
                                        <dd className="col-sm-8">
                                            <MultilineText>
                                                {consultation.next_goal}
                                            </MultilineText>
                                        </dd>
                                    </Fragment>
                                )}
                            </dl>
                        </div>
                    </div>
                </Fragment>
            ) : (
                <div className="d-flex flex-column align-items-center mb-3">
                    <EmptyView
                        label={t('consultations.detail.measurements.empty')}
                    />
                    {!readOnly && (
                        <button
                            className="btn btn-secondary"
                            onClick={() => onEditClicked()}>
                            {t('consultations.detail.measurements.add')}
                        </button>
                    )}
                </div>
            )}
        </Card>
    );
};

ClientConsultationMeasurements.propTypes = {
    measurements: PropTypes.object.isRequired,
    consultation: PropTypes.object.isRequired,
    onEditClicked: PropTypes.func.isRequired
};

export default ClientConsultationMeasurements;
