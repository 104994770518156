import { getError, AuthActions } from '@dive/react-redux-networking';

import { SettingsActions } from '../actions/settings';

const DEFAULT = {
    isLoading: false
};

const settings = (state = Object.assign({}, DEFAULT), action) => {
    switch (action.type) {
        case SettingsActions.GET_SETTINGS:
            return Object.assign({}, state, { isLoading: true, error: null });
        case SettingsActions.GET_SETTINGS_SUCCESS:
            return Object.assign({}, state, {
                data: action.payload.data,
                isLoading: false,
                timestamp: new Date().getTime()
            });
        case SettingsActions.GET_SETTINGS_FAIL:
            return Object.assign({}, state, {
                isLoading: false,
                error: getError(action).getMessage()
            });
        case SettingsActions.CLEAR_SETTINGS:
            return Object.assign({}, DEFAULT);

        case SettingsActions.SET_SETTINGS:
            return Object.assign({}, state, {
                data: action.settings
            });

        /* LOGOUT */
        case AuthActions.CLEAR_AUTH:
            // remove list
            return Object.assign({}, DEFAULT);

        default:
            return state;
    }
};

export default settings;
