import { isVoid } from '@dive/utils-js';

export const shouldRefreshStateData = (state, timeout) => {
    if (isVoid(state)) {
        return true;
    }

    const { data, timestamp, isLoading, error } = state;
    const now = Date.now();

    return (
        ((!isLoading && isVoid(data)) ||
            (!isLoading && !isVoid(timestamp) && now - timestamp > timeout)) &&
        isVoid(error)
    );
};

export const isStateDataForKey = (state, key, value) => {
    if (state) {
        if (state[key] && state[key] === value) {
            return true;
        }
    }
    return false;
};

export const isLoadingData = state => {
    return (
        isVoid(state) ||
        ((state.isLoading && isVoid(state.data)) ||
            (isVoid(state.data) && isVoid(state.error)))
    );
};

export const getFormattedNumber = (number, locale) => {
    if (number !== undefined && number != null) {
        // fixme, toLocaleString does not work on React Native
        if (locale === 'nl') {
            return `${parseFloat(Math.round(number * 100) / 100)}`.replace(
                '.',
                ','
            );
        } else {
            return parseFloat(Math.round(number * 100) / 100).toLocaleString(
                locale
            );
        }
    }
    return null;
};
