import { t } from '@dive/localization-js';
import { isEmptyText, isVoid } from '@dive/utils-js';
import moment from 'moment';
import React, { Component } from 'react';

import { FileType } from '../../../../core/models/library';
import { history, ROUTE_LIBRARY_DETAIL } from '../../../platform/routing';
import IconButton from '../../shared/buttons/IconButton';
import Card from '../../shared/card/Card';
import Lightbox from '../../shared/lightbox/Lightbox';
import LoadingIcon from '../../shared/loading/LoadingIcon';
import Pagination from '../../shared/pagination/Pagination';
import SortableTable from '../../shared/table/SortableTable';
import EmptyView from '../../shared/view/EmptyView';
import FilePreview from '../preview/FilePreview';
import PublishStatus from '../status/PublishStatus';

class LibraryTable extends Component {
    state = {
        image: null
    };
    columns = [
        {
            title: '',
            key: 'file',
            render: (entry, key) => (
                <td style={{ width: '10rem' }} key={key}>
                    <div
                        onClick={e => this.handleFile(e, entry.file)}
                        className="d-flex justify-content-center">
                        {!isVoid(entry.file) && (
                            <FilePreview
                                className="bg-light"
                                style={{ height: '5rem', width: '3.5rem' }}
                                small>
                                {entry.file}
                            </FilePreview>
                        )}
                    </div>
                </td>
            )
        },
        {
            title: t('library.table.file'),
            key: 'media_name',
            sortable: true,
            render: (entry, key) => (
                <td className="align-middle" key={key}>
                    <p className="m-0 d-flex flex-column">
                        {isVoid(entry.file) ? (
                            <span className="text-muted font-italic">
                                {t('library.no_file')}
                            </span>
                        ) : (
                            <>
                                <span className="font-weight-bolder text-body">
                                    {entry.file.name}
                                </span>

                                {t('library.type.' + entry.file.type)}

                                {entry.tags.length > 0 && (
                                    <span>
                                        {t('digitalcoaching.list.tags')}:{' '}
                                        {entry.tags
                                            .map(tag => tag.name)
                                            .join(', ')}
                                    </span>
                                )}
                            </>
                        )}
                    </p>
                </td>
            )
        },
        {
            title: t('library.table.publish_date'),
            key: 'published_at',
            sortable: !isEmptyText(this.props.client),
            render: (_, key, receiver) => (
                <td className="align-middle" key={key}>
                    {isVoid(receiver.published_at)
                        ? isVoid(receiver.delay_days)
                            ? ''
                            : t('digitalcoaching.list.delay') +
                              ': ' +
                              receiver.delay_days
                        : moment(receiver.published_at).format(
                              'DD/MM/YYYY HH:mm'
                          )}
                </td>
            )
        },
        {
            title: t('library.table.status'),
            key: 'status',
            render: (_, key, receiver) => (
                <td className="align-middle" key={key}>
                    <PublishStatus>
                        {isVoid(receiver.published_at)
                            ? isVoid(receiver.delay_days)
                                ? ''
                                : 'delayed'
                            : receiver.published_at}
                    </PublishStatus>
                </td>
            )
        },
        {
            title: t('library.table.created_at'),
            key: 'created_at',
            sortable: true,
            render: (entry, key) => (
                <td className="align-middle" key={key}>
                    {moment(entry.created_at).format('DD/MM/YYYY HH:mm')}
                </td>
            )
        },
        {
            title: ' ',
            key: 'actions',
            render: (entry, key, _, type) => (
                <td
                    style={{ width: '5rem' }}
                    onClick={e => e.stopPropagation()}
                    className="align-middle"
                    key={key}>
                    <div className="d-flex p-1">
                        {!isVoid(this.props.actions) &&
                            !(
                                !isEmptyText(this.props.client) &&
                                type === 'category'
                            ) &&
                            this.props.actions.map(action => {
                                let classes = 'm-1';

                                if (!isVoid(action.className)) {
                                    classes += ` ${action.className}`;
                                }

                                return (
                                    <IconButton
                                        key={action.icon}
                                        className={classes}
                                        icon={action.icon}
                                        onClick={() => action.onClick(entry)}
                                    />
                                );
                            })}
                    </div>
                </td>
            )
        }
    ];

    // ///////////////////////////////////////////////////////////////////////
    // EVENT HANDLERS
    // ///////////////////////////////////////////////////////////////////////

    handleClose = () => {
        this.setState({ image: null });
    };

    handleRowClick = (e, entry) => {
        if (this.props.noNavigate) {
            this.handleFile(e, entry.file);
        } else if (isVoid(this.props.onSelect)) {
            history.push(ROUTE_LIBRARY_DETAIL.replace(':id', entry.id));
        } else {
            this.props.onSelect(entry);
        }
    };

    handleFile(e, file) {
        e.stopPropagation();

        if (file.type !== FileType.IMAGE) {
            window.open(file.versions.original);
            return;
        }

        this.setState({ image: file.versions.large || file.versions.original });
    }

    // ///////////////////////////////////////////////////////////////////////
    // OTHERS
    // ///////////////////////////////////////////////////////////////////////

    getColumns() {
        const columns = this.columns.slice();

        if (
            isEmptyText(this.props.category) &&
            isEmptyText(this.props.client)
        ) {
            columns.splice(2, 2);
        } else {
            columns.splice(columns.length - 2, 1);
        }

        if (this.props.hideActions) {
            columns.pop();
        }

        return columns;
    }

    render() {
        const {
            category,
            client,
            library,
            isLoading,
            onPageChanged,
            onSort,
            pagination,
            sort
        } = this.props;

        if (isLoading) {
            return <LoadingIcon />;
        }

        const columns = this.getColumns();

        const sorting = isVoid(sort) ? { column: null, order: null } : sort;

        let rows;

        if (!isVoid(library) && library.length > 0) {
            rows = library.map(entry => {
                let receiver;
                let type = 'client';

                console.log('Entry: ', entry);

                if (!isEmptyText(client)) {
                    receiver = entry.receivers.clients.find(
                        c => c.id === +client
                    );
                }
                console.log('Receiver: ', receiver, type);

                if (isVoid(receiver)) {
                    if (isEmptyText(category)) {
                        receiver = entry.receivers.categories.find(cat =>
                            moment(cat.published_at).isSame(
                                entry.published_at,
                                'day'
                            )
                        );
                    } else {
                        receiver = entry.receivers.categories.find(
                            cat => cat.id === +category
                        );
                    }

                    if (isVoid(receiver)) {
                        // Failsafe fallback
                        receiver = entry.receivers.categories.find(
                            cat => cat !== undefined
                        );
                    }
                    type = 'category';
                }

                console.log('Receiver: ', receiver, type);

                return (
                    <tr
                        onClick={e => this.handleRowClick(e, entry)}
                        className="tr-selectable"
                        key={entry.id}>
                        {columns.map(column =>
                            column.render(entry, column.key, receiver, type)
                        )}
                    </tr>
                );
            });
        }

        return (
            <Card padding={false}>
                {library.length === 0 ? (
                    <EmptyView label={t('library.empty')} />
                ) : (
                    <>
                        <SortableTable
                            columns={columns}
                            sortColumn={sorting.column}
                            order={sorting.order}
                            isLoading={isLoading}
                            onFetch={onSort}>
                            {rows}
                        </SortableTable>

                        <Pagination
                            currentPage={pagination.page}
                            count={pagination.count}
                            pageSize={pagination.page_size}
                            onFetch={onPageChanged}
                        />

                        {!isVoid(this.state.image) && (
                            <Lightbox
                                onClose={this.handleClose}
                                url={this.state.image}
                            />
                        )}
                    </>
                )}
            </Card>
        );
    }
}

export default LibraryTable;
