import { shouldRefreshStateData } from '../utils/data';

export const MeasurementsActions = {
    GET_LATEST_MEASUREMENTS: '/measurements/latest/LOAD',
    GET_LATEST_MEASUREMENTS_SUCCESS: '/measurements/latest/LOAD_SUCCESS',
    GET_LATEST_MEASUREMENTS_FAIL: '/measurements/latest/LOAD_FAIL',

    GET_GRAPH: '/evolution/LOAD',
    GET_GRAPH_SUCCESS: '/evolution/LOAD_SUCCESS',
    GET_GRAPH_FAIL: '/evolution/LOAD_FAIL',

    SET_MONTHS_FILTER: '/evolution/MONTHS',

    GET_CLIENT_GRAPH: '/client/evolution/LOAD',
    GET_CLIENT_GRAPH_SUCCESS: '/client/evolution/LOAD_SUCCESS',
    GET_CLIENT_GRAPH_FAIL: '/client/evolution/LOAD_FAIL',

    CLEAR_CLIENT_GRAPH: '/client/evolution/CLEAR'
};

const MEASUREMENTS_CACHE_INTERVAL = 1000 * 60 * 5;

export const getLatestMeasurements = () => (dispatch, getState) => {
    if (
        shouldRefreshStateData(
            getState().measurements.latest,
            MEASUREMENTS_CACHE_INTERVAL
        )
    ) {
        return dispatch(fetchLatestMeasurements());
    }
};

export const fetchLatestMeasurements = () => ({
    type: MeasurementsActions.GET_LATEST_MEASUREMENTS,
    payload: {
        request: {
            url: `/measurements/latest`,
            method: 'GET'
        }
    }
});

export const getMeasurements = type => (dispatch, getState) => {
    if (
        shouldRefreshStateData(
            getState().measurements[type],
            MEASUREMENTS_CACHE_INTERVAL
        )
    ) {
        return dispatch(fetchMeasurements(type));
    }
};

export const fetchMeasurements = type => (dispatch, getState) => {
    const months = getState().measurements.months;

    return dispatch({
        type: MeasurementsActions.GET_GRAPH,
        payload: {
            request: {
                url: `/measurements/graph/${type}?months=${months}`,
                method: 'GET',
                typeOfData: type
            }
        }
    });
};

export const setMonthsFilter = months => ({
    type: MeasurementsActions.SET_MONTHS_FILTER,
    months
});

export const getClientMeasurements = type => (dispatch, getState) => {
    if (
        shouldRefreshStateData(
            getState().measurements[type],
            MEASUREMENTS_CACHE_INTERVAL
        )
    ) {
        return dispatch(
            fetchClientMeasurements(
                getState().clients.detail.id,
                type,
                getState().measurements.months
            )
        );
    }
};

export const fetchClientMeasurements = (client, type, months) => ({
    type: MeasurementsActions.GET_CLIENT_GRAPH,
    payload: {
        request: {
            url: `/clients/${client}/measurements/graph/${type}?months=${months}`,
            method: 'GET',
            typeOfData: type
        }
    }
});
