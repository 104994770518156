const KILOBYTE = 1000;
const MEGABYTE = 1000000;

const convertBytes = bytes => {
    if (bytes < KILOBYTE) {
        return bytes + ' B';
    }

    if (bytes < MEGABYTE) {
        return (bytes / KILOBYTE).toFixed(2) + ' kB';
    }

    return (bytes / MEGABYTE).toFixed(2) + ' MB';
};

export { convertBytes };
