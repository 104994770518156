import React from 'react';
import PropTypes from 'prop-types';
import { Route, Switch } from 'react-router-dom';
import {
    ROUTE_CLIENTS_DETAIL_TAB_CONSULTATIONS,
    ROUTE_CLIENTS_DETAIL_TAB_CONSULTATIONS_DETAIL
} from '../../../../platform/routing';
import ClientConsultationsOverview from './ClientConsultationsOverview';
import ClientConsultationsDetail from './ClientConsultationsDetail';

const ClientConsultationsTab = ({ client, readOnly }) => (
    <Switch>
        <Route
            path={ROUTE_CLIENTS_DETAIL_TAB_CONSULTATIONS_DETAIL}
            render={props => (
                <ClientConsultationsDetail
                    readOnly={readOnly}
                    client={client}
                    {...props}
                />
            )}
        />
        <Route
            path={ROUTE_CLIENTS_DETAIL_TAB_CONSULTATIONS}
            render={props => (
                <ClientConsultationsOverview
                    readOnly={readOnly}
                    client={client}
                    {...props}
                />
            )}
        />
    </Switch>
);

ClientConsultationsTab.propTypes = {
    client: PropTypes.object.isRequired
};

export default ClientConsultationsTab;
