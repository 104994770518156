import { t } from '@dive/localization-js';
import { isEmptyText, isVoid } from '@dive/utils-js';
import { createSelector } from 'reselect';

export const MealType = Object.freeze({
    BREAKFAST: 'breakfast',
    LUNCH: 'lunch',
    DINNER: 'dinner',
    SNACKS: 'snacks'
});

export const EntryType = Object.freeze({
    PHOTO: 'PHOTO',
    TEXT: 'TEXT',
    LOG: 'LOG'
});

// SELECTORS

export const selectDate = state => state.diary.date;

export const selectDiary = state => state.diary.diary;

export const selectDiaryData = state => {
    const diary = selectDiary(state);

    return diary.data;
};

export const selectDiaryError = state => {
    const diary = selectDiary(state);

    return diary.error;
};

export const selectDiaryEntries = createSelector(
    selectDiaryData,
    data =>
        isVoid(data) || data.entries.length === 0
            ? null
            : data.entries.reduce((acc, cur) => {
                  let { key, ...entry } = cur;

                  key = isEmptyText(key) ? entry.field.internal_name : key;

                  acc[key] = transformEntry(entry);

                  return acc;
              }, {})
);

export const selectDiaryFields = state => {
    const data = selectDiaryData(state);

    return isVoid(data) ? [] : data.fields;
};

export const selectDiaryTreatment = state => {
    const data = selectDiaryData(state);

    return isVoid(data) ? null : data.treatment;
};

export const selectDiaryCustomFields = state => {
    const data = selectDiaryData(state);

    return isVoid(data) ? [] : data.fields;
};

export const selectFood = state => state.diary.food;

export const selectFoodData = state => {
    const food = selectFood(state);

    return food.data;
};

export const selectFoodError = state => {
    const food = selectFood(state);

    return food.error;
};

export const selectEntries = state => {
    const data = selectFoodData(state);

    return isVoid(data) ? null : data.entries;
};

export const selectNutrients = state => {
    const data = selectFoodData(state);

    return isVoid(data) ? null : data.nutrients;
};

export const selectTreatment = state => {
    const data = selectFoodData(state);

    return isVoid(data) ? null : data.treatment;
};

// HELPERS

// Custom field values are always in string
export const transformEntry = entry => {
    if (isVoid(entry.field)) {
        return entry;
    }

    const transformed = { ...entry };

    switch (entry.field.type) {
        case 'number':
            transformed.value = isVoid(transformed.value)
                ? null
                : parseInt(transformed.value);
            break;

        case 'checkbox':
            transformed.value = !!+transformed.value;
            break;

        default:
            transformed.value = String(transformed.value);
    }

    return transformed;
};

export const hasFeedback = movement => {
    return !isVoid(movement.feedback) && !isVoid(movement.feedback.note);
};

export const showFullDiary = diary => {
    return (
        !isVoid(diary) &&
        !isVoid(diary.treatment) &&
        !diary.treatment.use_simplified_diary
    );
};

export const isPositiveFeedback = feedback => {
    return feedback.score > 0;
};

export const isNegativeFeedback = feedback => {
    return feedback.score < 0;
};

export const getDuration = duration => {
    if (duration >= 60) {
        return `${Math.floor(duration / 60)}${t('general.units.hour')}${
            duration % 60 < 10 ? '0' : ''
        }${duration % 60}`;
    } else {
        return `${duration} ${t('general.units.minutes')}`;
    }
};

export const getVisibleTreatmentBools = treatment =>
    Object.keys(treatment)
        .filter(field => field.match(/simple_question_bool/))
        .reduce((acc, cur) => {
            if (treatment[cur]) {
                acc.push(cur.replace('simple_question_', ''));
            }

            return acc;
        }, []);

export const getVisibleFieldBools = fields =>
    isVoid(fields) ? [] : fields.filter(field => field.type === 'checkbox');
