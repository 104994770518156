import React, { Fragment } from 'react';

import PropTypes from 'prop-types';
import { createPortal } from 'react-dom';

class Modal extends React.Component {
    componentDidMount() {
        document.addEventListener('keydown', this.onKeyDown, false);
    }

    componentWillUnmount() {
        document.removeEventListener('keydown', this.onKeyDown, false);
    }

    onKeyDown = e => {
        switch (e.keyCode) {
            case 27:
                if (this.props.onDismissClicked) {
                    this.props.onDismissClicked();
                }
                break;

            default:
                break;
        }
    };

    onModalClicked(e) {
        // don't let event bubble up to onBackdropClicked
        e.stopPropagation();
        return false;
    }

    render() {
        const html = (
            <Fragment>
                <div
                    className={`modal fade show ${this.props.className}`}
                    style={{ display: 'block' }}>
                    <div
                        className={`modal-dialog modal-dialog-centered ${
                            this.props.size
                                ? `modal-lg modal-${this.props.size}`
                                : ''
                        }`}
                        role="document">
                        <div
                            className="modal-content"
                            onClick={e => this.onModalClicked(e)}>
                            {this.props.children}
                        </div>
                    </div>
                </div>
                <div className="modal-backdrop fade show" />
            </Fragment>
        );

        /* Create the div outside of this element, so we can position properly */
        return createPortal(html, document.getElementById('overlay'));
    }
}

Modal.propTypes = {
    onDismissClicked: PropTypes.func,
    size: PropTypes.string
};

export default Modal;
