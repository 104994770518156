import { Route, Switch } from 'react-router-dom';

import DashboardOverviewContainer from './overview/DashboardOverviewContainer';
import { ROUTE_DASHBOARD } from 'app/platform/routing';
import React from 'react';

const DashboardContainer = () => (
    <Switch>
        <Route path={ROUTE_DASHBOARD} component={DashboardOverviewContainer} />
    </Switch>
);

export default DashboardContainer;
