import React from 'react';
import Tabs from '../../../../shared/tabs/Tabs';
import { t } from '@dive/localization-js';
import {
    ROUTE_CLIENTS_DETAIL_TAB_DIARY_FOOD,
    ROUTE_CLIENTS_DETAIL_TAB_DIARY_GENERAL,
    ROUTE_CLIENTS_DETAIL_TAB_DIARY_MOVEMENTS
} from '../../../../../platform/routing';

const DiaryTabs = props => {
    const TAB_ITEMS = [
        {
            label: t('diary.tabs.general'),
            url: ROUTE_CLIENTS_DETAIL_TAB_DIARY_GENERAL,
            exact: true
        },
        {
            label: t('diary.tabs.food'),
            url: ROUTE_CLIENTS_DETAIL_TAB_DIARY_FOOD
        },
        {
            label: t('diary.tabs.movement'),
            url: ROUTE_CLIENTS_DETAIL_TAB_DIARY_MOVEMENTS
        }
    ];
    return (
        <Tabs
            tabs={TAB_ITEMS.map(tab => {
                // replace ':id' part in url with the client id
                return Object.assign({}, tab, {
                    url: tab.url.replace(':id', props.client.id)
                });
            })}
            {...props}
        />
    );
};

export default DiaryTabs;
