import React from 'react';
import { connect } from 'react-redux';
import SubscriptionStatus from './SubscriptionStatus';
import { t } from '@dive/localization-js';
import moment from 'moment';
import { getEndDate, isTrial } from '../../../platform/model/subscription';
import { isAdmin } from '../../../../core/models/user';

const mapStateToProps = state => {
    return {
        user: state.user.data
    };
};

class SubscriptionStatusContainer extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            message: null
        };
    }

    componentDidMount() {
        if (this.props.user) {
            this.checkStatus();
        }
    }

    componentDidUpdate(prevProps) {
        // check if we need to reload clients
        if (this.props.user) {
            if (
                !prevProps.user ||
                getEndDate(prevProps.user.current_plan) !==
                    getEndDate(this.props.user.current_plan)
            ) {
                this.checkStatus();
            }
        }
    }

    checkStatus() {
        // let user know how many days left in trial period or in his soon to be expiring subscription
        if (!isAdmin(this.props.user)) {
            if (isTrial(this.props.user.current_plan)) {
                // this.setState({
                //     message: t('general.trial_period', {
                //         days:
                //             moment(
                //                 getEndDate(this.props.user.current_plan)
                //             ).diff(moment(), 'days') + 1
                //     })
                // });
                return;
            } else {
                if (
                    moment(getEndDate(this.props.user.current_plan)).diff(
                        moment(),
                        'days'
                    ) < 7
                ) {
                    this.setState({
                        message: t('general.plan_expires', {
                            days:
                                moment(
                                    getEndDate(this.props.user.current_plan)
                                ).diff(moment(), 'days') + 1
                        })
                    });
                }
                return;
            }
        }
        // otherwise set message
        this.setState({
            message: null
        });
    }

    render() {
        return <SubscriptionStatus message={this.state.message} />;
    }
}

export default connect(mapStateToProps)(SubscriptionStatusContainer);
