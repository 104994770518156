import { t } from '@dive/localization-js';
import React, { Component } from 'react';
import PropTypes from 'prop-types';

import LoadingIcon from '../loading/LoadingIcon';
import Icon from '../icon/Icon';
import EmptyView from '../view/EmptyView';
import ErrorView from '../view/ErrorView';
import { MAX_FILE_SIZE } from '../../../platform/constants';

class UploadForm extends Component {
    static defaultProps = {
        maxFileSize: MAX_FILE_SIZE
    };

    constructor(props) {
        super(props);

        this.inputField = React.createRef();

        this.state = {
            isLoading: false,
            file: null,
            errors: {
                file: null
            }
        };
    }

    openDialog = () => {
        this.inputField.current.click();
    };

    onChange = e => {
        const file = e.target.files[0];
        if (file) {
            if (file.size > this.props.maxFileSize) {
                window.alert(t('general.form.errors.max_size'));
            } else if (!this.validateFile(file.type)) {
                window.alert(this.props.acceptError);
            } else {
                this.onSubmit(file);
            }
        }
    };

    onSubmit = file => {
        this.setState({
            isLoading: true,
            errors: {}
        });
        return this.props
            .onSubmit(file)
            .then(response => {
                this.setState({
                    isLoading: false
                });
            })
            .catch(err => {
                this.setState({
                    isLoading: false,
                    errors: {
                        file: err.getMessage()
                    }
                });
            });
    };

    validateFile(fileType) {
        if (fileType === 'application/pdf') {
            if (fileType === this.props.type) return true;
        } else if (fileType.split('/')[0] === 'image') {
            if (fileType.split('/')[0] === this.props.type.split('/')[0]) {
                return true;
            }
        }
    }

    openFile = link => {
        window.open(link, '_blank');
    };

    render() {
        return (
            <>
                {this.state.errors.file && (
                    <ErrorView
                        className="d-block mt-4 col-12 col-md-10 offset-md-1 col-xl-8 offset-xl-2"
                        error={this.state.errors.file}
                    />
                )}

                <div className="full-width d-flex justify-content-center">
                    {this.props.value && !this.props.isLoading ? (
                        <button
                            className="btn btn-tertiary mb-3 d-flex flex-wrap align-items-center justify-content-center"
                            onClick={() => this.openFile(this.props.value)}>
                            <Icon className="ml-0" name="pdf" />
                            <span className="ml-2">
                                {t('general.file.open')}
                            </span>
                        </button>
                    ) : this.state.isLoading ? (
                        <LoadingIcon />
                    ) : (
                        !this.props.readOnly && (
                            <div className="form-group d-flex flex-column align-items-center">
                                <EmptyView
                                    className="d-block mb-2"
                                    label={this.props.label}
                                />
                                <label
                                    className="btn btn-tertiary"
                                    htmlFor={this.props.id}>
                                    {t('general.form.upload')}
                                </label>
                            </div>
                        )
                    )}

                    <input
                        onChange={this.onChange}
                        id={this.props.id}
                        type="file"
                        accept={this.props.type || '*'}
                        hidden
                        ref={this.inputField}
                    />
                </div>
            </>
        );
    }
}

UploadForm.propTypes = {
    id: PropTypes.string,
    label: PropTypes.string,
    onSubmit: PropTypes.func.isRequired,
    value: PropTypes.string,
    error: PropTypes.string,
    readOnly: PropTypes.bool
};

export default UploadForm;
