import { t } from '@dive/localization-js';
import { isVoid } from '@dive/utils-js';

import { getFormattedNumber } from '../utils/data';

export const selectNutrients = state => state.nutrients;

export const selectSelectedItems = state => {
    const nutrients = selectNutrients(state);

    return nutrients.selectedItems;
};

export const selectNutrientsLoading = state => {
    const nutrients = selectNutrients(state);

    return nutrients.isLoading;
};

export const selectNutrientsData = state => {
    const nutrients = selectNutrients(state);

    return nutrients.data;
};

export const selectNutrientsList = state => {
    const nutrients = selectNutrients(state);

    return isVoid(nutrients.data) ? null : nutrients.data.nutrients;
};

export const selectNutrientsError = state => {
    const nutrients = selectNutrients(state);

    return nutrients.error;
};

export const selectNutrientsQuery = state => {
    const nutrients = selectNutrients(state);

    return nutrients.query;
};

export const selectNutrientsSource = state => {
    const nutrients = selectNutrients(state);

    return nutrients.source;
};

export const selectNutrientsMeal = state => {
    const nutrients = selectNutrients(state);

    return nutrients.meal;
};

export const getUnitLabel = unit => {
    return t(`general.units.${unit}`);
};

export const getName = nutrient => {
    if (nutrient.manufacturer) {
        return `${nutrient.manufacturer} ${nutrient.name}`;
    } else {
        return nutrient.name;
    }
};

export const getAmountLabel = (nutrient, amount) => {
    return `${getFormattedNumber(amount, 'nl')}${
        nutrient.unit_of_measurement === 'ml' ||
        nutrient.unit_of_measurement === 'g'
            ? ''
            : ' '
    }${getUnitLabel(nutrient.unit_of_measurement)}`;
};

export const getNutritionalValues = (nutrient, amount) => {
    const multiply = amount ? amount / nutrient.amount : 1;
    return t(
        amount
            ? 'diary.food.nutrient.nutritional_value.calculated'
            : 'diary.food.nutrient.nutritional_value',
        {
            amount: getFormattedNumber(amount || nutrient.amount, 'nl'),
            unit: getUnitLabel(nutrient.unit_of_measurement),
            cal: getFormattedNumber(multiply * nutrient.energy, 'nl'),
            protein: getFormattedNumber(multiply * nutrient.protein, 'nl'),
            fat: getFormattedNumber(multiply * nutrient.fat, 'nl'),
            carbs: getFormattedNumber(multiply * nutrient.carbs, 'nl')
        }
    );
};

export const NutrientSource = {
    ALL: 'all',
    USER: 'user'
};
