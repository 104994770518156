import React, { Component, Fragment } from 'react';
import {
    isValidEmail,
    isValidNumber
} from '../../../../core/validation/validation';

import ErrorView from '../view/ErrorView';
import LoadingIcon from '../loading/LoadingIcon';
import { MAX_FILE_SIZE } from '../../../platform/constants';
import PropTypes from 'prop-types';
import SubmitButton from '../buttons/SubmitButton';
import { connect } from 'react-redux';
import { getInputField } from './FormInputField';
import moment from 'moment/moment';
import { t } from '@dive/localization-js';

const mapStateToProps = state => {
    return {
        categories: state.categories.data,
        tags: state.tags.data
    };
};

class DynamicForm extends Component {
    static defaultProps = {
        maxFileSize: MAX_FILE_SIZE
    };

    constructor(props) {
        super(props);

        this.state = {
            isLoading: false,
            errors: {}
        };
    }

    componentDidMount() {
        this.setState({
            model: Object.assign({}, this.props.model)
        });
    }

    parseDate = (field, date) => {
        let value;
        if (field.parseInput) {
            value = field.parseInput(field, date);
        } else {
            if (date) {
                if (field.type === 'date') {
                    // remove hour information from date
                    value = moment
                        .utc(date.toDateString())
                        .startOf('day')
                        .format();
                } else if (field.type === 'time') {
                    value = moment.utc(date).format();
                } else {
                    value = moment.utc(date).format();
                }
            }
        }
        return value;
    };

    onFieldChanged = (field, value, checked) => {
        if (
            field.type === 'date' ||
            field.type === 'time' ||
            field.type === 'datetime'
        ) {
            this.saveInputField(field, this.parseDate(field, value));
        } else if (field.type === 'file') {
            if (value) {
                if (value.size > this.props.maxFileSize) {
                    window.alert(t('general.form.errors.max_size'));
                } else if (value.type.match(new RegExp(field.accept))) {
                    // set nameFile
                    this.saveInputField(
                        Object.assign({}, field, { name: `${field.name}File` }),
                        value
                    );
                } else {
                    window.alert(t('general.form.errors.' + field.accept));
                }
            } else {
                // delete nameFile if exist, otherwise delete name
                this.saveInputField(
                    Object.assign({}, field, {
                        name: this.state.model[`${field.name}File`]
                            ? `${field.name}File`
                            : field.name
                    }),
                    null
                );
            }
        } else {
            if (!field.parseInput) {
                // multiple
                if (!field.isArray) {
                    this.saveInputField(
                        field,
                        checked !== undefined ? checked : value
                    );
                } else {
                    const val =
                        field.type === 'categories' || field.type === 'tags'
                            ? parseInt(value, 10)
                            : value;

                    // single
                    let array = [...this.state.model[field.name]];
                    if (checked === true) {
                        // add
                        array.push(val);
                    } else {
                        // remove
                        array = [...array.filter(v => v !== val)];
                    }
                    this.saveInputField(field, array);
                }
            } else {
                // custom validation
                this.saveInputField(
                    field,
                    field.parseInput(
                        field,
                        checked !== undefined ? checked : value
                    )
                );
            }
        }
    };

    saveInputField(field, value) {
        let { model } = this.state;
        // set value
        if (field.saveInput) {
            model = field.saveInput(field, model, value);
        } else {
            if (field.getProperty) {
                field.getProperty(model)[field.name] = value;
            } else {
                model[field.name] = value;
            }
        }
        // save state
        this.setState({
            model: model,
            errors: {}
        });
    }

    handleSubmit(e) {
        e.preventDefault();
        // set as loading
        if (!this.isValid()) {
            return;
        }
        // set as loading
        this.setState({ isLoading: true, errors: {} });
        // save model on API
        this.props
            .onSaveForm(Object.assign({}, this.state.model))
            .then(response => {
                this.props.onFinished(response);
                // set state if not dismissed
                if (!this.props.dismissOnSuccess) {
                    this.setState({
                        isLoading: false
                    });
                }
            })
            .catch(err => {
                // stop loading and set error
                this.setState({
                    isLoading: false,
                    errors: { general: String(err) }
                });
            });
    }

    getField(key, value) {
        return this.getFields(this.props).find(f => f[key] === value);
    }

    isValid() {
        // validate fields
        const errors = {};
        const { model } = this.state;
        // loop general fields
        const fields = this.getFields(this.props);
        let field;
        for (const key in fields) {
            field = fields[key];
            if (field.required) {
                if (
                    !this.getProperty(field, model)[field.name] ||
                    (field.isArray &&
                        this.getProperty(field, model)[field.name].length === 0)
                ) {
                    errors[field.name] = t('general.form.errors.required');
                }
            }
            // this logic will only happen if there is an actual value filled in
            if (
                this.getProperty(field, model)[field.name] &&
                this.getProperty(field, model)[field.name].length > 0
            ) {
                if (field.equals && !errors[field.name]) {
                    if (
                        this.getProperty(field, model)[field.name] !==
                        this.state.model[field.equals]
                    ) {
                        errors[field.name] = t('general.form.errors.equals', {
                            label: this.getField('name', field.equals).label
                        });
                    }
                }
                if (field.type === 'number' && !errors[field.name]) {
                    if (
                        !isValidNumber(
                            this.getProperty(field, model)[field.name]
                        )
                    ) {
                        errors[field.name] = t('general.form.errors.number');
                    }
                }
                if (field.type === 'email' && !errors[field.name]) {
                    if (
                        !isValidEmail(
                            this.getProperty(field, model)[field.name]
                        )
                    ) {
                        errors[field.name] = t('general.form.errors.email');
                    }
                }
                if (field.length && !errors[field.name]) {
                    if (
                        this.getProperty(field, model)[field.name].length !==
                        field.length
                    ) {
                        errors[field.name] = t('general.form.errors.length', {
                            equality: '',
                            characters: field.length
                        });
                    }
                }
                if (field.min_length && !errors[field.name]) {
                    if (
                        this.getProperty(field, model)[field.name].length <
                        field.min_length
                    ) {
                        errors[field.name] = t('general.form.errors.length', {
                            equality: t('general.form.errors.min'),
                            characters: field.min_length
                        });
                    }
                }
                if (field.max_length && !errors[field.name]) {
                    if (
                        this.getProperty(field, model)[field.name].length >
                        field.max_length
                    ) {
                        errors[field.name] = t('general.form.errors.length', {
                            equality: t('general.form.errors.max'),
                            characters: field.max_length
                        });
                    }
                }
            }
        }
        if (this.props.onValidate) {
            Object.assign(
                errors,
                this.props.onValidate(fields, this.state.model)
            );
        }
        // check if the errors object has any properties
        if (Object.keys(errors).length > 0) {
            this.setState({
                errors: errors
            });
            return false;
        }
        return true;
    }

    getFields(group, array = []) {
        // get all fields within all groups
        if (group.fieldGroups) {
            for (const g of group.fieldGroups) {
                this.getFields(g, array);
            }
        } else {
            array.push(...group.fields);
        }
        return array;
    }

    getProperty(field, model) {
        if (field.getProperty) {
            return field.getProperty(model);
        } else {
            if (field.type === 'file') {
                if (model[`${field.name}File`]) {
                    return Object.assign({}, model, {
                        [field.name]: model[`${field.name}File`]
                    });
                }
            }
            return model;
        }
    }

    getMappedFields(fieldConfig, model) {
        if (fieldConfig.fieldGroups) {
            return this.getMappedGroupFields(fieldConfig.fieldGroups, model);
        } else {
            return this.getMappedSingleFields(fieldConfig.fields, model);
        }
    }

    getMappedGroupFields(fieldGroups, model) {
        return fieldGroups.map((group, i) => {
            if (!group.checkVisibility || group.checkVisibility(group, model)) {
                return (
                    <div
                        className={`mb-4 col-lg-${group.size}`}
                        key={`group-${i}-${group.title}`}>
                        {group.title && (
                            <p className="form-group-title mb-1">
                                {group.title}
                            </p>
                        )}
                        <div
                            className={`${
                                group.title ? 'form-child-group' : ''
                            }`}>
                            <div className="row">
                                {this.getMappedFields(group, model)}
                            </div>
                        </div>
                    </div>
                );
            } else {
                return null;
            }
        });
    }

    getMappedSingleFields(fields, model) {
        return fields.map(field => {
            if (field.fieldGroups) {
                return this.getMappedFields(field, model);
            } else {
                if (field.type === 'categories') {
                    field.options = this.props.categories || [];

                    if (field.disable) {
                        field.options = field.options.map(cat => ({
                            ...cat,
                            disabled: field.disable.some(c => c.id === cat.id)
                        }));
                    }
                }
                if (field.type === 'tags') {
                    field.options = this.props.tags || [];

                    if (field.disable) {
                        field.options = field.options.map(cat => ({
                            ...cat,
                            disabled: field.disable.some(c => c.id === cat.id)
                        }));
                    }
                }
                if (
                    !field.checkVisibility ||
                    field.checkVisibility(field, model)
                ) {
                    return (
                        <div
                            className={`form-group col-sm-${
                                field.size || '12'
                            }`}
                            key={field.name}>
                            {(field.type !== 'checkbox' || field.isArray) &&
                                field.label && (
                                    <label htmlFor={field.name}>
                                        {field.label}
                                        {field.required && (
                                            <span className="asterisk">*</span>
                                        )}
                                        &nbsp;
                                    </label>
                                )}
                            <div className="input-group">
                                {getInputField(
                                    field,
                                    this.getProperty(field, model)[field.name],
                                    this.state.errors[field.name],
                                    this.onFieldChanged
                                )}
                                {field.unit && (
                                    <div className="input-group-append">
                                        <span className="input-group-text">
                                            {field.unit}
                                        </span>
                                    </div>
                                )}
                            </div>
                            {this.state.errors[field.name] && (
                                <div className="invalid-feedback">
                                    {this.state.errors[field.name]}
                                </div>
                            )}
                        </div>
                    );
                } else {
                    return null;
                }
            }
        });
    }

    render() {
        const { model } = this.state;
        return !this.state.isLoading && model ? (
            <form
                className="form"
                onSubmit={e => this.handleSubmit(e)}
                noValidate={true}>
                <ErrorView error={this.state.errors.general} />
                {/* GENERAL */}
                {
                    <Fragment>
                        <div className="row">
                            {this.getMappedFields(this.props, model)}
                        </div>
                    </Fragment>
                }
                {this.props.children}
                <div className="d-flex justify-content-end">
                    <SubmitButton
                        label={
                            this.props.submitLabel ||
                            t(
                                model.id
                                    ? 'general.form.update'
                                    : 'general.form.create'
                            )
                        }
                        type="submit"
                    />
                </div>
            </form>
        ) : (
            <LoadingIcon />
        );
    }
}

DynamicForm.propTypes = {
    model: PropTypes.object.isRequired,
    fields: PropTypes.arrayOf(PropTypes.object),
    fieldGroups: PropTypes.arrayOf(PropTypes.object),
    submitLabel: PropTypes.string,
    onFinished: PropTypes.func.isRequired,
    onValidate: PropTypes.func,
    onSaveForm: PropTypes.func.isRequired,
    dismissOnSuccess: PropTypes.bool
};

export default connect(mapStateToProps)(DynamicForm);
