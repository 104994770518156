import FeedbackView from '../../../feedback/FeedbackView';
import MultilineText from '../../../../../../shared/text/MultilineText';
import PropTypes from 'prop-types';
import React from 'react';
import ReadNotification from '../../../../../../shared/read/ReadNotification';

const NoteListItem = ({ entry, onFeedBackClick }) => {
    return (
        <li className="d-flex pb-2 pt-2 pl-3 pr-3">
            <ReadNotification read={entry.read} />
            <div
                style={{
                    minWidth: '180px'
                }}>
                <span className="m-0">
                    <MultilineText>{entry.note}</MultilineText>
                </span>
            </div>
            <FeedbackView
                className="ml-auto"
                feedback={entry.feedback}
                onEditClick={feedback => onFeedBackClick(entry.id, feedback)}
            />
        </li>
    );
};

NoteListItem.propTypes = {
    entry: PropTypes.object,
    onFeedBackClick: PropTypes.func
};

export default NoteListItem;
