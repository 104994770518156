import {
    ROUTE_CLIENTS_DETAIL_TAB_DIARY_MOVEMENTS,
    ROUTE_CLIENTS_DETAIL_TAB_DIARY_MOVEMENT_DETAIL
} from '../../../../../../platform/routing';
import React, { Component, Fragment } from 'react';
import {
    fetchClientMovementsForDate,
    getClientMovementsForDate
} from '../../../../../../../core/actions/diary';

import BackButton from '../../../../../shared/buttons/BackButton';
import Card from '../../../../../shared/card/Card';
import EmptyView from '../../../../../shared/view/EmptyView';
import ErrorView from '../../../../../shared/view/ErrorView';
import FeedbackForm from '../../feedback/FeedbackForm';
import FeedbackView from '../../feedback/FeedbackView';
import LoadingIcon from '../../../../../shared/loading/LoadingIcon';
import PrevNextButtons from '../../navigation/PrevNextRow';
import ProgressBar from '../../../../../shared/progress/ProgressBar';
import ReadNotification from '../../../../../shared/read/ReadNotification';
import { connect } from 'react-redux';
import { getDuration } from '../../../../../../../core/models/diary';
import { history } from 'app/platform/routing';
import { isLoadingData } from '../../../../../../../core/utils/data';
import moment from 'moment/moment';
import { t } from '@dive/localization-js';

const mapStateToProps = (state, props) => {
    const { movementDetail } = state.clients.detail.diary;
    const data = movementDetail ? movementDetail.data : null;
    return {
        isLoading: isLoadingData(movementDetail),
        movements: data ? data.movements : null,
        treatment: data ? data.treatment : null,
        prev: data ? data.prev_week : null,
        next: data ? data.next_week : null,
        error: movementDetail.error,
        clientId: state.clients.detail.id,
        date: moment.utc(props.match.params.date, 'DDMMYYYY').format()
    };
};

const mapDispatchToProps = dispatch => {
    return {
        getClientMovementsForDate: date => {
            dispatch(getClientMovementsForDate(date));
        },
        fetchClientsMovementForDate: date => {
            dispatch(fetchClientMovementsForDate(date));
        }
    };
};

class ClientMovementDetail extends Component {
    constructor(props) {
        super(props);
        this.state = {
            endDate: null,
            startDate: null,
            actions: {}
        };
    }

    componentDidMount() {
        // set date
        this.setDate();
        // set data
        this.getData();
    }

    componentDidUpdate(prevProps) {
        if (prevProps.date !== this.props.date) {
            this.setDate();
        }
        this.getData();
    }

    setDate() {
        this.setState({
            startDate: moment(this.props.date)
                .startOf('isoWeek')
                .format(),
            endDate: moment(this.props.date)
                .endOf('isoWeek')
                .format()
        });
    }

    getData() {
        this.props.getClientMovementsForDate(this.props.date);
    }

    editFeedback = (id, feedback) => {
        this.setState({
            actions: {
                updateFeedback: feedback
                    ? Object.assign({}, feedback, { id: id })
                    : null
            }
        });
    };

    goToDetail = date => {
        history.push(
            ROUTE_CLIENTS_DETAIL_TAB_DIARY_MOVEMENT_DETAIL.replace(
                ':id',
                this.props.clientId
            ).replace(':date', moment(date).format('DDMMYYYY'))
        );
    };

    onFinishAction() {
        // remove dialog
        this.setState({ actions: {} });
        // refresh consultation
        this.props.fetchClientsMovementForDate(this.props.date);
    }

    render() {
        return (
            <Fragment>
                <div className="d-flex justify-content-between flex-wrap">
                    <BackButton
                        label={t('diary.movement.detail.back')}
                        to={ROUTE_CLIENTS_DETAIL_TAB_DIARY_MOVEMENTS.replace(
                            ':id',
                            this.props.clientId
                        )}
                    />
                    <PrevNextButtons
                        prev={this.props.prev}
                        next={this.props.next}
                        showDetail={this.goToDetail}
                    />
                </div>
                <div className="row mt-3">
                    <div className="col-sm-4 mb-3">
                        <h2>{t('diary.movement.detail.general')}</h2>
                        <Card>
                            <h3>
                                {t('diary.movement.list.week.value', {
                                    week: moment(this.props.date).week()
                                })}
                            </h3>
                            <p className="text-body-light">
                                {this.state.startDate
                                    ? `${moment(this.state.startDate).format(
                                          `dd`
                                      )} ${moment(this.state.startDate).format(
                                          'DD/MM/YY'
                                      )} - ${moment(this.state.endDate).format(
                                          `dd`
                                      )} ${moment(this.state.endDate).format(
                                          'DD/MM/YY'
                                      )}`
                                    : ''}
                            </p>
                            {this.props.treatment && (
                                <Fragment>
                                    {this.props.treatment.movement_total && (
                                        <p className="text-center mb-1">
                                            <span className="font-weight-semi-bold">
                                                {this.props.movements.length}
                                            </span>
                                            /
                                            {
                                                this.props.treatment
                                                    .movement_total
                                            }
                                        </p>
                                    )}
                                    {this.props.treatment.movement_total && (
                                        <ProgressBar
                                            current={
                                                this.props.movements.length
                                            }
                                            total={
                                                this.props.treatment
                                                    .movement_total
                                            }
                                            defaultColor={'secondary'}
                                            completeColor={'primary'}
                                        />
                                    )}
                                    {this.props.treatment.movement_duration !=
                                        null && (
                                        <p className="text-center mt-1 mb-1">
                                            {`${t(
                                                'diary.movement.detail.duration'
                                            )}: `}
                                            <span className="text-body-light">
                                                {t(
                                                    'diary.movement.detail.duration.value',
                                                    {
                                                        minutes: this.props
                                                            .treatment
                                                            .movement_duration
                                                    }
                                                )}
                                            </span>
                                        </p>
                                    )}
                                    {this.props.treatment.movement_type !=
                                        null && (
                                        <p className="text-center">
                                            {`${t(
                                                'diary.movement.detail.type'
                                            )}: `}
                                            <span className="text-body-light">
                                                {
                                                    this.props.treatment
                                                        .movement_type
                                                }
                                            </span>
                                        </p>
                                    )}
                                </Fragment>
                            )}
                        </Card>
                    </div>
                    <div className="col-sm-8 mb-3">
                        <h2>{t('diary.movement.detail.overview')}</h2>
                        <Card
                            padding={
                                !(
                                    this.props.movements &&
                                    this.props.movements.length > 0
                                )
                            }>
                            {this.props.isLoading ? (
                                <LoadingIcon />
                            ) : this.props.error ? (
                                <ErrorView error={this.props.error} />
                            ) : this.props.movements.length > 0 ? (
                                <ul className="bordered-list mb-0 pt-1 pb-1">
                                    {this.props.movements.map(movement => (
                                        <li
                                            className="d-flex pb-2 pt-2 pl-3 pr-3"
                                            key={movement.id}>
                                            <ReadNotification
                                                read={movement.read}
                                            />
                                            <div style={{ minWidth: '180px' }}>
                                                <p className="font-weight-semi-bold mb-0">
                                                    {movement.type === 'other'
                                                        ? movement.note
                                                        : t(
                                                              `diary.movement.activities.${movement.type}`
                                                          )}
                                                </p>
                                                <span className="text-body-light">
                                                    {moment
                                                        .utc(movement.date)
                                                        .format('DD/MM/YY')}
                                                </span>
                                            </div>
                                            <p className="d-flex text-body-light align-items-center mb-0">
                                                {getDuration(movement.duration)}
                                            </p>
                                            <FeedbackView
                                                className="ml-auto"
                                                feedback={movement.feedback}
                                                onEditClick={feedback =>
                                                    this.editFeedback(
                                                        movement.id,
                                                        feedback
                                                    )
                                                }
                                            />
                                        </li>
                                    ))}
                                </ul>
                            ) : (
                                <EmptyView
                                    label={t('diary.movement.detail.empty')}
                                />
                            )}
                        </Card>
                    </div>
                </div>
                {/* Create/edit feedback modal */}
                {this.state.actions.updateFeedback && (
                    <FeedbackForm
                        feedback={this.state.actions.updateFeedback}
                        type={'movements'}
                        onDismissClicked={() => this.editFeedback(null, null)}
                        onFinished={() => this.onFinishAction()}
                    />
                )}
            </Fragment>
        );
    }
}

ClientMovementDetail.propTypes = {};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(ClientMovementDetail);
