import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { t } from '@dive/localization-js';
import { request } from '@dive/react-redux-networking';

import Card from '../../../../../shared/card/Card';
import IconButton from '../../../../../shared/buttons/IconButton';
import Icon from '../../../../../shared/icon/Icon';
import DropDown from '../../../../../shared/dropdown/DropDown';
import DropDownMenu from '../../../../../shared/dropdown/DropDownMenu';
import DropDownToggle from '../../../../../shared/dropdown/DropDownToggle';
import UploadForm from '../../../../../shared/forms/UploadForm';
import {
    uploadMealPlan,
    deleteMealPlan
} from '../../../../../../platform/actions/treatment';
import DeleteAlertModal from '../../../../../shared/modal/DeleteAlertModal';

const mapDispatchToProps = dispatch => {
    return {
        uploadMealPlan: (id, mealplan) => {
            return request(dispatch(uploadMealPlan(id, mealplan)));
        },
        deleteMealPlan: id => {
            return request(dispatch(deleteMealPlan(id)));
        }
    };
};

class TreatmentPlan extends Component {
    constructor(props) {
        super(props);
        this.state = {
            actions: {},
            errors: {
                file: null
            }
        };
    }

    componentDidMount() {
        this.setState({
            mealplan: this.props.mealplan
        });
    }

    componentDidUpdate(prevProps) {
        if (prevProps.mealplan !== this.props.mealplan) {
            this.setState({
                mealplan: this.props.mealplan
            });
        }
    }

    onSubmit = file => {
        return this.props
            .uploadMealPlan(this.props.client.id, file)
            .then(response => {
                this.setState({
                    mealplan: response.meal_plan
                });
                this.props.onFinished();
            });
    };

    deleteMealPlan = plan => {
        this.setState({
            actions: {
                deleteMealPlan: plan
            }
        });
    };

    editMealPlan = () => {
        this.uploadForm.openDialog();
    };

    onFinishedDelete = () => {
        this.setState({
            actions: {},
            mealplan: null
        });
        this.props.onFinished();
    };

    render() {
        return (
            <Fragment>
                <h2>{t('treatment.mealplan')}</h2>
                <Card>
                    <div className="d-flex flex-column align-items-center">
                        <Icon
                            name="mealplan"
                            className="icon-xxl icon-lighter"
                        />
                        <UploadForm
                            id="upload"
                            label={t('treatment.mealplan.form.upload.empty')}
                            acceptError={t(
                                'treatment.mealplan.errors.file_type'
                            )}
                            ref={ref => (this.uploadForm = ref)}
                            onSubmit={this.onSubmit}
                            value={this.state.mealplan}
                            error={this.state.errors.file}
                            readOnly={this.props.readOnly}
                            type="application/pdf"
                        />
                    </div>
                    <div className="card-buttons d-flex">
                        {this.state.mealplan && !this.props.readOnly && (
                            <Fragment>
                                <IconButton
                                    icon="edit"
                                    onClick={this.editMealPlan}
                                />
                                <DropDown className="d-inline delete-toggle">
                                    <DropDownToggle className="btn btn-icon">
                                        <Icon name="overflow" />
                                    </DropDownToggle>
                                    <DropDownMenu>
                                        <button
                                            className="dropdown-item btn-alert"
                                            onClick={() =>
                                                this.deleteMealPlan(
                                                    this.state.mealplan
                                                )
                                            }>
                                            <Icon name="delete" />{' '}
                                            {t('general.remove')}
                                        </button>
                                    </DropDownMenu>
                                </DropDown>
                            </Fragment>
                        )}
                    </div>
                </Card>
                {/* Delete user modal */}
                {this.state.actions.deleteMealPlan && (
                    <DeleteAlertModal
                        title={t('treatment.mealplan.delete.title')}
                        description={t('treatment.mealplan.delete.description')}
                        onDelete={() =>
                            this.props.deleteMealPlan(this.props.client.id)
                        }
                        onDismissClicked={() => this.deleteMealPlan(null)}
                        onFinished={() => this.onFinishedDelete()}
                    />
                )}
            </Fragment>
        );
    }
}

export default connect(
    undefined,
    mapDispatchToProps
)(TreatmentPlan);
