import { isEmptyText, isVoid } from '@dive/utils-js';

export const FileType = Object.freeze({
    IMAGE: 'image',
    VIDEO: 'video',
    PDF: 'pdf',
    DOCUMENT: 'document'
});

export const selectLibrary = state => state.library;

export const selectLibraryData = state => {
    const library = selectLibrary(state);

    return library.data;
};

export const selectLibraryEntries = state => {
    const data = selectLibraryData(state);

    return isVoid(data) ? null : data.entries;
};

export const selectLibraryError = state => {
    const library = selectLibrary(state);

    return library.error;
};

export const selectLibraryAPIFilters = state => {
    const data = selectLibraryData(state);

    return isVoid(data) ? null : data.filters;
};


export const selectLibraryFilters = state => {
    const library = selectLibrary(state);

    return library.filters;
};

export const selectLibraryLoading = state => {
    const library = selectLibrary(state);

    return library.isLoading;
};

export const hasSetFilters = state => {
    const filters = selectLibraryFilters(state);

    return (
        !isEmptyText(filters.search) ||
        !isEmptyText(filters.category) ||
        !isEmptyText(filters.type) ||
        !isEmptyText(filters.interval.startDate) ||
        !isEmptyText(filters.interval.endDate)
    );
};
