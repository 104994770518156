import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { ROUTE_PROFILE } from '../../../platform/routing';

const SubscriptionStatus = ({ message }) => {
    return (
        message && (
            <Link className="alert-header ml-1" to={ROUTE_PROFILE}>
                {message}
            </Link>
        )
    );
};

SubscriptionStatus.propTypes = {
    message: PropTypes.string
};

export default SubscriptionStatus;
