import React from 'react';
import Icon from '../../../../shared/icon/Icon';

const PrevNextButtons = ({ prev, next, showDetail }) => {
    return (
        <div className="d-flex mt-3 mt-sm-2 mb-2 justify-content-center justify-content-sm-end flex-grow flex-grow-1">
            <div className="d-flex">
                <button
                    onClick={() => showDetail(prev)}
                    disabled={prev ? false : true}
                    className="btn btn-light bg-white mr-1 d-flex align-items-center">
                    <Icon
                        name="arrow-left"
                        className={`icon-xs ${!prev && 'icon-light'}`}
                    />
                </button>
            </div>
            <div className="d-flex">
                <button
                    onClick={() => showDetail(next)}
                    disabled={next ? false : true}
                    className="btn btn-light bg-white ml-1 d-flex align-items-center">
                    <Icon
                        name="arrow-right"
                        className={`icon-xs ${!next && 'icon-light'}`}
                    />
                </button>
            </div>
        </div>
    );
};

export default PrevNextButtons;
