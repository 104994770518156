import React from 'react';

const DropDownToggle = props => (
    <button
        className={`dropdown-toggle ${props.className}`}
        onClick={props.onClick}>
        {props.children}
    </button>
);

export default DropDownToggle;
