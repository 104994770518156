import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { t } from '@dive/localization-js';
import moment from 'moment';
import CircleIcon from '../../../../../shared/icon/CircleIcon';
import {
    isCompleted,
    isFailed,
    isFutureGoal
} from '../../../../../../../core/models/goal';
import DropDownToggle from '../../../../../shared/dropdown/DropDownToggle';
import DropDown from '../../../../../shared/dropdown/DropDown';
import DropDownMenu from '../../../../../shared/dropdown/DropDownMenu';
import IconButton from '../../../../../shared/buttons/IconButton';
import MultilineText from '../../../../../shared/text/MultilineText';
import Icon from '../../../../../shared/icon/Icon';
import { getFormattedNumber } from '../../../../../../../core/utils/data';

const GoalItemView = ({
    goal,
    readOnly,
    editGoal,
    deleteGoal,
    changeStatus
}) => {
    return (
        <li className="d-flex w-100 pt-3 pb-3" key={goal.id}>
            <div className="mr-2">
                <CircleIcon
                    className={
                        goal.status
                            ? isCompleted(goal)
                                ? 'icon-success'
                                : 'icon-error'
                            : ''
                    }
                    icon={
                        goal.status
                            ? isCompleted(goal)
                                ? 'check'
                                : 'error'
                            : null
                    }
                />
            </div>
            <div className="flex-grow-1">
                <span
                    className={`font-weight-semi-bold mb-3 ${
                        isCompleted(goal)
                            ? 'text-success'
                            : isFailed(goal)
                            ? 'color-alert'
                            : ''
                    }`}>
                    {moment(goal.scheduled).format('DD/MM/YYYY')}
                </span>
                <div>
                    {goal.weight || goal.fat ? (
                        <div className="d-flex align-items-center mt-1 text-body-light">
                            {goal.weight && (
                                <Fragment>
                                    <Icon
                                        className="icon-sm icon-light mr-1"
                                        name={'weight'}
                                    />
                                    <span className="mr-4">
                                        {getFormattedNumber(goal.weight, 'nl')}
                                        {t('general.units.kg')}
                                    </span>
                                </Fragment>
                            )}
                            {goal.fat && (
                                <Fragment>
                                    <Icon
                                        className="icon-sm icon-light mr-1"
                                        name={'fat'}
                                    />
                                    <span className="mr-3">
                                        {getFormattedNumber(goal.fat, 'nl')}%{' '}
                                        {t('general.units.fat')}
                                    </span>
                                </Fragment>
                            )}
                        </div>
                    ) : null}
                    {goal.note ? (
                        <p className="text-body-light d-flex align-items-center mt-1 mb-0">
                            <Icon
                                className="icon-sm icon-light mr-1"
                                name={'message'}
                            />
                            <MultilineText>{goal.note}</MultilineText>
                        </p>
                    ) : null}
                </div>
                {isFutureGoal(goal) && !readOnly && (
                    <div className="d-flex mt-2">
                        <button
                            onClick={() => changeStatus(goal, 'completed')}
                            className="btn btn-icon btn-success mr-1">
                            <Icon
                                name="check-circle"
                                className=" text-success"
                            />
                        </button>
                        <button
                            onClick={() => changeStatus(goal, 'failed')}
                            className="btn btn-icon btn-alert">
                            <Icon
                                name="error-circle"
                                className=" text-danger"
                            />
                        </button>
                    </div>
                )}
            </div>
            <div className="d-flex">
                {!goal.status && !readOnly && (
                    <IconButton icon="edit" onClick={() => editGoal(goal)} />
                )}
                {!readOnly && (
                    <DropDown className="d-inline delete-toggle">
                        <DropDownToggle className="btn btn-icon">
                            <Icon name="overflow" />
                        </DropDownToggle>
                        <DropDownMenu>
                            <button
                                className="dropdown-item btn-alert"
                                onClick={() => deleteGoal(goal)}>
                                <Icon name="delete" /> {t('general.remove')}
                            </button>
                        </DropDownMenu>
                    </DropDown>
                )}
            </div>
        </li>
    );
};

GoalItemView.propTypes = {
    readOnly: PropTypes.bool,
    goal: PropTypes.object,
    editGoal: PropTypes.func,
    deleteGoal: PropTypes.func
};

export default GoalItemView;
