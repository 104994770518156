import React, { Component } from 'react';

import DeleteAlertModal from '../../shared/modal/DeleteAlertModal';
import DigitalCoachingForm from '../form/DigitalCoachingForm';
import DigitalCoachingOverview from './DigitalCoachingOverview';
import Lightbox from '../../shared/lightbox/Lightbox';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { deleteCoaching } from '../../../../core/actions/coaching';
import { isVoid } from '@dive/utils-js';
import { request } from '@dive/react-redux-networking';
import { t } from '@dive/localization-js';

const mapDispatchToProps = dispatch => {
    return {
        deleteCoaching: id => {
            return request(dispatch(deleteCoaching(id)));
        }
    };
};

class DigitalCoachingOverviewContainer extends Component {
    constructor(props) {
        super(props);
        this.state = {
            actions: {},
            image: null
        };
    }

    componentDidMount() {
        // set coachings
        this.setCoachings();
    }

    componentDidUpdate(prevProps) {
        if (this.props.coachings !== prevProps.coachings) {
            this.setCoachings();
        }
    }

    addCoaching = () => {
        if (this.props.receiver) {
            this.editCoaching({
                receiver: this.props.receiver
            });
        } else {
            this.editCoaching({});
        }
    };

    setCoachings() {
        // split coaching in future and history
        let coachings = null;
        if (this.props.coachings) {
            coachings = {};
            coachings.scheduled = this.props.coachings.filter(
                coaching => !coaching.sent
            );
            coachings.sent = this.props.coachings.filter(
                coaching => coaching.sent
            );
        }
        this.setState({
            coachings: coachings
        });
    }

    editCoaching = coaching => {
        this.setState({
            actions: {
                updateCoaching: coaching
            }
        });
    };

    deleteCoaching = coaching => {
        this.setState({
            actions: {
                deleteCoaching: coaching
            }
        });
    };

    duplicateCoaching = coaching => {
        // duplicate but remove some values
        const duplicate = Object.assign({}, coaching, {
            scheduled: null,
            id: null,
            receiver: this.props.receiver || null,
            sent: false
        });
        this.setState({
            actions: {
                updateCoaching: duplicate
            }
        });
    };

    onFinishAction() {
        // remove dialog
        this.setState({ actions: {} });
        // refresh coaching
        this.props.onFetch(this.props.pagination.currentPage);
    }

    handleClose = () => {
        this.setState({ image: null });
    };

    handleImageClick = image => {
        this.setState({ image });
    };

    render() {
        return (
            <>
                <DigitalCoachingOverview
                    {...this.props}
                    readOnly={this.props.readOnly}
                    onEditClicked={this.editCoaching}
                    onDeleteClicked={this.deleteCoaching}
                    onDuplicateClicked={this.duplicateCoaching}
                    onImageClick={this.handleImageClick}
                    coachings={this.state.coachings}
                />

                {this.state.actions.updateCoaching && (
                    <DigitalCoachingForm
                        client={this.props.client}
                        coaching={this.state.actions.updateCoaching}
                        onDismissClicked={() => this.editCoaching(null)}
                        onFinished={() => this.onFinishAction()}
                    />
                )}

                {this.state.actions.deleteCoaching && (
                    <DeleteAlertModal
                        title={t('digitalcoaching.delete.title')}
                        description={t('digitalcoaching.delete.description')}
                        onDelete={() =>
                            this.props.deleteCoaching(
                                this.state.actions.deleteCoaching.id
                            )
                        }
                        onDismissClicked={() => this.deleteCoaching(null)}
                        onFinished={() => this.onFinishAction()}
                    />
                )}

                {!isVoid(this.state.image) && (
                    <Lightbox
                        url={this.state.image}
                        onClose={this.handleClose}
                    />
                )}
            </>
        );
    }
}

DigitalCoachingOverviewContainer.propTypes = {
    isLoading: PropTypes.bool.isRequired,
    coachings: PropTypes.arrayOf(PropTypes.object),
    error: PropTypes.string,
    hasFilter: PropTypes.bool,
    pagination: PropTypes.shape({
        currentPage: PropTypes.number,
        pageSize: PropTypes.number,
        count: PropTypes.number
    }),
    receiver: PropTypes.object,
    onFetch: PropTypes.func.isRequired
};

export default connect(null, mapDispatchToProps, null, { forwardRef: true })(
    DigitalCoachingOverviewContainer
);
