import { createSelector } from 'reselect';

export const sortTagsFilter = (tag1, tag2) =>
    tag1.name.localeCompare(tag2.name);

export const selectTags = state => state.tags.data;

export const selectTagsAsOptions = createSelector(selectTags, tags =>
    tags.map(({ id, name }) => ({ display: name, value: id }))
);
