import { getError, AuthActions } from '@dive/react-redux-networking';
import { SubscribersActions } from '../actions/subscribers';

const DEFAULT = { isLoading: false };

const subscribers = (state = Object.assign({}, DEFAULT), action) => {
    switch (action.type) {
        /*
         * SUBSCRIBERS
         */
        case SubscribersActions.GET_SUBSCRIBERS:
            return Object.assign({}, state, { isLoading: true, error: null });
        case SubscribersActions.GET_SUBSCRIBERS_SUCCESS:
            return Object.assign({}, state, {
                data: action.payload.data,
                isLoading: false,
                timestamp: new Date().getTime()
            });
        case SubscribersActions.GET_SUBSCRIBERS_FAIL:
            return Object.assign({}, state, {
                isLoading: false,
                error: getError(action).getMessage()
            });
        case SubscribersActions.CLEAR_SUBSCRIBERS:
            return Object.assign({}, DEFAULT);

        /* LOGOUT */
        case AuthActions.CLEAR_AUTH:
            // remove list
            return Object.assign({}, DEFAULT);

        default:
            return state;
    }
};

export default subscribers;
