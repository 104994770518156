import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { t } from '@dive/localization-js';
import MeasurementGraph from './graph/MeasurementGraph';
import Card from '../../../shared/card/Card';
import { setMonthsFilter } from '../../../../../core/actions/measurements';

const mapStateToProps = state => {
    return {
        months: state.measurements.months
    };
};

const mapDispatchToProps = dispatch => {
    return {
        setMonths: months => {
            dispatch(setMonthsFilter(months));
        }
    };
};

class ClientEvolutionTab extends Component {
    onFilterChanged = (name, e) => {
        this.props.setMonths(e.target.value);
        e.target.blur();
    };

    render() {
        return (
            <Fragment>
                <div className="d-flex align-items-center justify-content-start mt-2 mb-4 flex-wrap">
                    <p className="m-0 mt-2 mb-2 mr-2">{`${t(
                        'general.filter'
                    )}:`}</p>
                    <div className="d-flex flex-wrap align-items-center">
                        <form className="form-filter mt-2 mb-2 mr-1">
                            <select
                                className="form-control"
                                value={this.props.months}
                                onChange={e =>
                                    this.onFilterChanged('range', e)
                                }>
                                <option value="2">
                                    {t('clients.evolution.filter.two_months')}
                                </option>
                                <option value="6">
                                    {t('clients.evolution.filter.six_months')}
                                </option>
                                <option value="12">
                                    {t('clients.evolution.filter.year')}
                                </option>
                            </select>
                        </form>
                        <p className="m-0 ml-2 label">
                            {t('clients.evolution.filter.year.info')}
                        </p>
                    </div>
                </div>
                <div className="row">
                    <div className="mb-5 col-xl-6">
                        <h2>{t('graphs.title.weight')}</h2>
                        <Card>
                            <MeasurementGraph type="weight" />
                        </Card>
                    </div>
                    <div className="mb-5 col-xl-6">
                        <h2>{t('graphs.title.mass')}</h2>
                        <Card>
                            <MeasurementGraph type="mass" />
                        </Card>
                    </div>
                    <div className="mb-5 col-xl-6">
                        <h2>{t('graphs.title.contour')}</h2>
                        <Card>
                            <MeasurementGraph type="contour" />
                        </Card>
                    </div>
                </div>
            </Fragment>
        );
    }
}

ClientEvolutionTab.propTypes = {
    client: PropTypes.object.isRequired
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(ClientEvolutionTab);
