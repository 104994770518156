import React from 'react';
import PropTypes from 'prop-types';

const ErrorView = ({ error, className }) => {
    return error ? (
        <p className={`alert alert-danger text-center ${className || ''}`}>
            {error}
        </p>
    ) : null;
};

ErrorView.propTypes = {
    error: PropTypes.string
};

export default ErrorView;
