import React, { Component } from 'react';
import { connect } from 'react-redux';
import AppContainer from 'app/components/app/AppContainer';
import { Route, Switch } from 'react-router-dom';
import {
    ROUTE_APP,
    ROUTE_ONBOARDING,
    ROUTE_SUBSCRIPTION
} from '../../platform/routing';
import SubscriptionContainer from '../clients/subscription/SubscriptionContainer';
import OnboardingContainer from '../onboarding/OnboardingContainer';
import LoadingIcon from '../shared/loading/LoadingIcon';
import { isAdmin, isDietician } from '../../../core/models/user';
import { isValid } from '../../platform/model/subscription';
import {
    getUser,
    logout,
    UserStates,
    isAuthenticating
} from '@dive/react-redux-networking';

const mapStateToProps = state => {
    return {
        authenticated:
            state.user.state === UserStates.LOGGED_IN &&
            (isDietician(state.user.data) || isAdmin(state.user.data)),
        isAppLoading: isAuthenticating(state.user),
        hasValidSubscription:
            !state.user.data ||
            (state.user.data.current_plan &&
                isValid(state.user.data.current_plan))
    };
};

const mapDispatchToProps = dispatch => {
    return {
        getUser: () => dispatch(getUser()),
        logout: () => {
            dispatch(logout());
        }
    };
};

class AuthContainer extends Component {
    componentDidMount() {
        this.props.getUser();
    }

    render() {
        const { authenticated, isAppLoading } = this.props;

        if (isAppLoading) {
            return <LoadingIcon />;
        }

        return authenticated ? (
            <Switch>
                <Route
                    path={ROUTE_SUBSCRIPTION}
                    component={SubscriptionContainer}
                />

                <Route path={ROUTE_APP} component={AppContainer} />
            </Switch>
        ) : (
            <Route path={ROUTE_ONBOARDING} component={OnboardingContainer} />
        );
    }
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(AuthContainer);
