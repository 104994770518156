import React from 'react';
import PropTypes from 'prop-types';

const EmptyView = ({ label, className }) => {
    return label ? (
        <p className={`alert alert-empty text-center ${className || ''}`}>
            {label}
        </p>
    ) : null;
};

EmptyView.propTypes = {
    label: PropTypes.string
};

export default EmptyView;
