import { t } from '@dive/localization-js';
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import moment from 'moment';
import { request } from '@dive/react-redux-networking';
import { isVoid } from '@dive/utils-js';

import DynamicFormDialog from '../../../../shared/forms/DynamicFormDialog';
import setFields from '../../../../../../core/models/fields';
import { updateConsultation } from '../../../../../../core/actions/consultations';

const NAMES = ['fat', 'lean', 'muscle'];

class ConsultationNotesForm extends Component {
    constructor(props) {
        super(props);

        const consultation = this.initConsultation(props);

        this.state = {
            consultation,
            isLoading: false,
            errors: {}
        };
    }

    get impedanceFields() {
        return [
            {
                name: 'weight',
                label: t('consultations.detail.measurements.weight'),
                type: 'number',
                size: '12',
                saveInput: this.checkWeight,
                unit: t('general.units.kg'),
                getProperty: this.getMeasurementProperty,
                config: {
                    step: '0.01'
                }
            },
            {
                name: 'fat',
                label: t('consultations.detail.measurements.fat'),
                size: '6',
                saveInput: this.checkWeight,
                type: 'number',
                unit: t('general.units.kg'),
                getProperty: this.getMeasurementProperty,
                config: {
                    step: '0.01'
                }
            },
            {
                name: 'fat_percentage',
                label: '  ',
                type: 'number',
                size: '6',
                saveInput: this.checkWeight,
                unit: '%',
                getProperty: this.getMeasurementProperty
            },
            {
                name: 'lean',
                label: t('consultations.detail.measurements.lean'),
                size: '6',
                saveInput: this.checkWeight,
                type: 'number',
                unit: t('general.units.kg'),
                getProperty: this.getMeasurementProperty,
                config: {
                    step: '0.01'
                }
            },
            {
                name: 'lean_percentage',
                label: '  ',
                size: '6',
                saveInput: this.checkWeight,
                type: 'number',
                unit: '%',
                getProperty: this.getMeasurementProperty
            },
            {
                name: 'muscle',
                label: t('consultations.detail.measurements.muscle'),
                size: '6',
                saveInput: this.checkWeight,
                type: 'number',
                unit: t('general.units.kg'),
                getProperty: this.getMeasurementProperty,
                config: {
                    step: '0.01'
                }
            },
            {
                name: 'muscle_percentage',
                label: '  ',
                size: '6',
                saveInput: this.checkWeight,
                type: 'number',
                unit: '%',
                getProperty: this.getMeasurementProperty
            },
            {
                name: 'visceral',
                label: t('consultations.detail.measurements.visceral'),
                size: '12',
                type: 'number',
                unit: '',
                getProperty: this.getMeasurementProperty,
                config: {
                    step: '0.01'
                }
            },
            {
                name: 'bmi',
                label: t('consultations.detail.measurements.bmi'),
                size: '6',
                type: 'number',
                unit: '',
                getProperty: this.getMeasurementProperty
            },
            {
                name: 'bmr',
                label: t('consultations.detail.measurements.bmr'),
                size: '6',
                type: 'number',
                unit: '',
                getProperty: this.getMeasurementProperty
            }
        ];
    }

    get circumferenceFields() {
        return [
            {
                name: 'upper_arms',
                label: t('consultations.detail.measurements.upper_arms'),
                type: 'number',
                size: '6',
                unit: t('general.units.cm'),
                getProperty: this.getMeasurementProperty,
                config: {
                    step: '0.01'
                }
            },
            {
                name: 'upper_legs',
                label: t('consultations.detail.measurements.upper_legs'),
                size: '6',
                type: 'number',
                unit: t('general.units.cm'),
                getProperty: this.getMeasurementProperty,
                config: {
                    step: '0.01'
                }
            },
            {
                name: 'waist',
                label: t('consultations.detail.measurements.waist'),
                size: '6',
                type: 'number',
                unit: t('general.units.cm'),
                getProperty: this.getMeasurementProperty,
                config: {
                    step: '0.01'
                }
            },
            {
                name: 'hips',
                label: t('consultations.detail.measurements.hips'),
                size: '6',
                type: 'number',
                unit: t('general.units.cm'),
                getProperty: this.getMeasurementProperty,
                config: {
                    step: '0.01'
                }
            }
        ];
    }

    get otherFields() {
        return [
            {
                name: 'clothing_size',
                label: t('consultations.detail.measurements.clothing_size'),
                type: 'string',
                size: '12',
                getProperty: this.getMeasurementProperty
            },
            {
                name: 'blood_pressure',
                label: t('consultations.detail.measurements.blood_pressure'),
                size: '12',
                type: 'string',
                getProperty: this.getMeasurementProperty
            }
        ];
    }

    get defaultFields() {
        return [
            {
                title: t('consultations.form.impedance'),
                size: '6',
                fields: this.impedanceFields
            },
            {
                size: '6',
                fieldGroups: [
                    {
                        title: t('consultations.form.circumference'),
                        size: '12',
                        fields: this.circumferenceFields
                    },
                    {
                        title: t('consultations.form.other'),
                        size: '12',
                        fields: this.otherFields
                    }
                ]
            }
        ];
    }

    initConsultation(props) {
        const { consultation, type } = props;

        this.fields = setFields(
            { id: consultation.id },
            consultation.data,
            type,
            'clients.detail.general.diagnose.form',
            this.defaultFields
        );

        // convert measurements from array to object
        const consult = {
            ...consultation,
            client_id: consultation.client.id,
            scheduled: consultation.scheduled || moment.utc().format(),
            measurements: consultation.measurements.reduce((acc, cur) => {
                acc[cur.key] = cur.value;

                return acc;
            }, {})
        };

        consult.data.forEach(field => {
            consult[field.internal_name] = field.value;
        });

        delete consult.data;
        delete consult.client;

        return consult;
    }

    checkWeight = (field, model, value) => {
        this.calculateWeightPercentage(field.name, model, value);
        // auto calculate the BMI
        // first check weight (wins if filled in), otherwise check lean + fat
        model.measurements[field.name] = value;
        if (this.props.client.height && this.props.client.height > 0) {
            const val =
                !!model.measurements.weight || field.name === 'weight'
                    ? model.measurements.weight
                    : model.measurements.lean && model.measurements.fat
                    ? parseFloat(model.measurements.lean) +
                      parseFloat(model.measurements.fat)
                    : null;
            if (val) {
                model.measurements.bmi = (
                    parseFloat(val) /
                    Math.pow(parseFloat(this.props.client.height) / 100, 2)
                ).toPrecision(4);
            }
        }
        return model;
    };

    calculateWeightPercentage = (name, model, value) => {
        switch (name) {
            case 'weight':
                NAMES.map(name => {
                    if (model.measurements[name] && value > 0) {
                        model.measurements[`${name}_percentage`] = (
                            (model.measurements[name] / value) *
                            100
                        ).toFixed(2);
                    } else if (!value) {
                        model.measurements[`${name}_percentage`] = null;
                    }
                    return model;
                });
                break;
            case 'fat':
            case 'lean':
            case 'muscle':
                if (model.measurements.weight) {
                    model.measurements[`${name}_percentage`] = (
                        (value / model.measurements.weight) *
                        100
                    ).toFixed(2);
                }
                break;
            case 'fat_percentage':
            case 'lean_percentage':
            case 'muscle_percentage':
                if (model.measurements.weight) {
                    model.measurements[name.split('_')[0]] = (
                        (value / 100) *
                        model.measurements.weight
                    ).toFixed(2);
                }
                break;

            default:
                break;
        }
    };

    getMeasurementProperty = model => {
        // measurements should be saved in model.measurements instead of model
        return model.measurements;
    };

    handleSubmit = model => {
        // convert measurements object to array
        const measurements = Object.keys(model.measurements).map(key => ({
            key,
            value: model.measurements[key]
        }));

        // convert fields back to consultation model
        const data = this.props.consultation.data.map(field => ({
            id: field.id,
            value: model[field.internal_name]
        }));

        const consultation = { ...model, measurements, data };

        return this.props.updateConsultation(consultation);
    };

    handleValidate = (fields, model) => {
        if (
            model.measurements.fat &&
            model.measurements.lean &&
            model.measurements.weight
        ) {
            // lean + fat should be = weight
            if (
                parseFloat(model.measurements.weight).toFixed(3) !==
                (
                    parseFloat(model.measurements.fat) +
                    parseFloat(model.measurements.lean)
                ).toFixed(3)
            ) {
                return {
                    weight: t('consultations.form.errors.calc'),
                    fat: t('consultations.form.errors.calc'),
                    lean: t('consultations.form.errors.calc')
                };
            }
        }
        return {};
    };

    render() {
        const { consultation } = this.state;
        const { onDismissClicked, onFinished } = this.props;

        if (isVoid(consultation)) {
            return null;
        }

        return (
            <DynamicFormDialog
                size="xl"
                title={t('consultations.notes.edit')}
                model={consultation}
                fieldGroups={this.fields}
                onDismissClicked={onDismissClicked}
                onFinished={onFinished}
                onValidate={this.handleValidate}
                onSaveForm={this.handleSubmit}
            />
        );
    }
}

ConsultationNotesForm.propTypes = {
    consultation: PropTypes.object.isRequired,
    client: PropTypes.object.isRequired,
    onDismissClicked: PropTypes.func.isRequired,
    onFinished: PropTypes.func.isRequired
};

const mapDispatchToProps = dispatch => ({
    updateConsultation: consultation =>
        request(dispatch(updateConsultation(consultation)))
});

export default connect(
    undefined,
    mapDispatchToProps
)(ConsultationNotesForm);
