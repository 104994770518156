import { ROUTE_DASHBOARD, history } from 'app/platform/routing';
import React, { Component } from 'react';

import SubscribersOverviewContainer from './SubscribersOverviewContainer';
import { connect } from 'react-redux';
import { isAdmin } from '../../../core/models/user';

const mapStateToProps = state => {
    return {
        isAdmin: state.user ? isAdmin(state.user.data) : false
    };
};

class SubscribersContainer extends Component {
    componentDidMount() {
        if (!this.props.isAdmin) {
            history.push(ROUTE_DASHBOARD);
        }
    }

    render() {
        return this.props.isAdmin ? <SubscribersOverviewContainer /> : null;
    }
}

export default connect(mapStateToProps)(SubscribersContainer);
