import React from 'react';

import DropDownMenu from './DropDownMenu';
import DropDownToggle from './DropDownToggle';

class DropDown extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            isOpen: false
        };
    }

    componentDidMount() {
        document.addEventListener('mouseup', this.handleClick);
    }

    componentWillUnmount() {
        document.removeEventListener('mouseup', this.handleClick);
        if (this.timeout) {
            clearTimeout(this.timeout);
        }
    }

    toggleMenu = (e, timeout = 50) => {
        // prevent a link
        if (e) {
            e.preventDefault();
        }
        this.timeout = setTimeout(() => {
            // set state
            this.setState({
                isOpen: !this.state.isOpen
            });
        }, timeout);
    };

    handleClick = event => {
        // if there is a click outside of the dropdown while opened, close it
        if (
            this.element &&
            !this.element.contains(event.target) &&
            this.state.isOpen
        ) {
            this.toggleMenu();
        } else if (
            this.element &&
            this.element.contains(event.target) &&
            !event.target.classList.contains('dropdown-toggle') &&
            (event.target.tagName.toLowerCase() === 'a' ||
                event.target.tagName.toLowerCase() === 'button')
        ) {
            // clicked on a link in menu, close as well
            this.toggleMenu(null, 150);
        }
    };

    render() {
        return (
            <div
                className={`dropdown ${this.state.isOpen ? 'show' : ''} ${this
                    .props.className || ''}`}
                ref={el => {
                    this.element = el;
                }}>
                {React.Children.map(this.props.children, child => {
                    if (child.type === DropDownMenu) {
                        return React.cloneElement(child, {
                            isOpen: this.state.isOpen
                        });
                    } else if (child.type === DropDownToggle) {
                        return React.cloneElement(child, {
                            onClick: this.toggleMenu
                        });
                    } else {
                        return null;
                    }
                })}
            </div>
        );
    }
}

export default DropDown;
