import React from 'react';
import { t } from '@dive/localization-js';
import UserFieldsContainer from './user-fields/UserFieldsContainer';

const FieldsSettingsContainer = () => (
    <>
        <div className="mb-4">
            <UserFieldsContainer
                model="client"
                label={t('clients.detail.general.diagnose.general')}
            />
        </div>

        <div className="mb-4">
            <UserFieldsContainer
                model="consultation"
                label={t('clients.detail.tabs.consultations')}
            />
        </div>

        <div className="mb-4">
            <UserFieldsContainer
                model="diary_simple"
                label={`${t('diary.tabs.general')} ${t(
                    'clients.detail.tabs.diary'
                )} `}
            />
        </div>
    </>
);

export default FieldsSettingsContainer;
