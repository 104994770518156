import { createSelector } from 'reselect';

export const sortCategoriesFilter = (cat1, cat2) =>
    cat1.name.localeCompare(cat2.name);

export const selectCategories = state => state.categories.data;

export const selectCategoriesAsOptions = createSelector(
    selectCategories,
    categories =>
        categories.map(({ id, name }) => ({ display: name, value: id }))
);
