import React from 'react';
import { connect } from 'react-redux';
import { clearMessage } from '../../../platform/actions/messages';

const mapStateToProps = state => {
    return {
        message: state.messages.general || state.messages.error || null,
        messageKey: state.messages.general
            ? 'general'
            : state.messages.error
            ? 'error'
            : ''
    };
};

const mapDispatchToProps = dispatch => {
    return {
        clearMessage: messageKey => {
            return dispatch(clearMessage(messageKey));
        }
    };
};

class MessageBar extends React.PureComponent {
    constructor(props) {
        super(props);
        this.state = {};
    }

    componentDidMount() {
        this.checkMessage();
    }

    componentDidUpdate(prevProps) {
        if (!prevProps.message && this.props.message) {
            this.checkMessage();
        } else if (prevProps.message && !this.props.message) {
            this.checkMessage();
        }
    }

    componentWillUnmount() {
        if (this.timeout) {
            clearTimeout(this.timeout);
        }
        if (this.renderTimeout) {
            clearTimeout(this.renderTimeout);
        }
        if (this.messageTimeout) {
            clearTimeout(this.messageTimeout);
        }
    }

    checkMessage() {
        // FIXME: when clearing and setting message, it is not shown because of the timeouts in setMessage::null and setRender::false
        if (this.props.message) {
            this.stopTimeout();
            this.setMessage(this.props.message);
            this.setRender(true);
            this.startTimeout();
        } else {
            this.stopTimeout();
            this.setMessage(null);
            this.setRender(false);
        }
    }

    startTimeout() {
        this.stopTimeout();
        this.timeout = setTimeout(
            () => {
                this.props.clearMessage(this.props.messageKey);
            },
            this.props.messageKey === 'error' ? 4000 : 3000
        );
    }

    stopTimeout() {
        if (this.timeout) {
            clearTimeout(this.timeout);
        }
    }

    setRender(render) {
        this.renderTimeout = setTimeout(
            () => {
                this.setState({
                    render
                });
            },
            render ? 0 : 400
        );
    }

    setMessage(message) {
        this.messageTimeout = setTimeout(
            () => {
                this.setState({
                    message
                });
            },
            message ? 400 : 0
        );
    }

    render() {
        return this.state.render ? (
            <div
                className={`message-bar ${
                    this.props.messageKey === 'error' ? 'message-bar-alert' : ''
                } ${this.state.message ? 'visible' : ''}`}>
                <p>{this.state.message}</p>
            </div>
        ) : null;
    }
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(MessageBar);
