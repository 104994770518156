import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { t } from '@dive/localization-js';
import { request } from '@dive/react-redux-networking';

import {
    deleteUserField,
    fetchUserFields,
    getUserFields
} from '../../../../platform/actions/fields';
import DeleteAlertModal from '../../../shared/modal/DeleteAlertModal';
import UserFieldForm from './form/UserFieldForm';
import Icon from '../../../shared/icon/Icon';
import RoundButton from '../../../shared/buttons/RoundButton';
import Card from '../../../shared/card/Card';
import LoadingIcon from '../../../shared/loading/LoadingIcon';
import SortableTable from '../../../shared/table/SortableTable';
import { isLoadingData } from '../../../../../core/utils/data';
import ErrorView from '../../../shared/view/ErrorView';
import EmptyView from '../../../shared/view/EmptyView';
import { selectField } from 'app/platform/model/fields';

const mapStateToProps = (state, props) => {
    const field = selectField(state, props.model);

    return {
        isLoading: isLoadingData(field),
        error: field.error,
        fields: field.data
    };
};

const mapDispatchToProps = dispatch => {
    return {
        getUserFields: model => {
            return dispatch(getUserFields(model));
        },
        fetchUserFields: model => {
            return dispatch(fetchUserFields(model));
        },
        deleteUserField: id => {
            return request(dispatch(deleteUserField(id)));
        }
    };
};

class UserFieldsContainer extends Component {
    componentDidMount() {
        this.props.getUserFields(this.props.model);
    }

    componentDidUpdate(prevProps) {
        this.props.getUserFields(this.props.model);
    }

    constructor(props) {
        super(props);
        this.state = {
            actions: {
                deleteField: null,
                updateField: null
            },
            errors: {}
        };
        this.columns = [
            {
                title: t('settings.user_fields.name'),
                key: 'name',
                sortable: false
            },
            {
                title: t('settings.user_fields.type'),
                key: 'type',
                sortable: false
            },
            {
                title: t('settings.user_fields.group'),
                key: 'group',
                sortable: false
            },
            {
                title: t('settings.user_fields.actions'),
                key: 'actions',
                sortable: false
            }
        ];

        if (this.props.model !== 'client') {
            this.columns = this.columns.filter(
                column => column.key !== 'group'
            );
        }
    }

    addField = model => {
        this.editField({ model: model });
    };

    deleteField(category) {
        // show or hide the delete modal
        this.setState({
            actions: { deleteField: category }
        });
    }

    editField(category) {
        this.setState({
            actions: { updateField: category }
        });
    }

    onFinishAction() {
        // remove dialog
        this.setState({ actions: {} });
        // refetch data
        this.props.fetchUserFields(this.props.model);
    }

    render() {
        const fields = this.props.fields
            ? this.props.fields.filter(field => !field.default)
            : null;
        return (
            <Fragment>
                {this.props.isLoading ? (
                    <Card>
                        <LoadingIcon />
                    </Card>
                ) : (
                    <Fragment>
                        <div className="d-flex align-items-end mb-2">
                            <h2 className="mb-0">
                                {t('settings.user_fields', {
                                    label: this.props.label
                                })}
                            </h2>
                            <RoundButton
                                icon="add"
                                className="btn-round ml-auto"
                                onClick={() => this.addField(this.props.model)}
                                label={t('settings.user_fields.add')}
                            />
                        </div>
                        <Card padding={!fields || fields.length === 0}>
                            {fields && fields.length > 0 ? (
                                <SortableTable
                                    columns={this.columns}
                                    isLoading={this.props.isLoading}>
                                    {fields.map(field => (
                                        <tr key={field.id}>
                                            <td className="font-weight-semi-bold">
                                                {field.name}
                                            </td>
                                            <td>
                                                {t(
                                                    `settings.user_fields.type.${field.type}`
                                                )}
                                            </td>
                                            {this.props.model === 'client' && (
                                                <td>
                                                    {t(
                                                        `clients.detail.general.diagnose.${field.group}`
                                                    )}
                                                </td>
                                            )}
                                            <td>
                                                <div className="d-flex">
                                                    <button
                                                        className="btn btn-icon"
                                                        onClick={() =>
                                                            this.editField(
                                                                field
                                                            )
                                                        }>
                                                        <Icon name="edit" />
                                                    </button>
                                                    <button
                                                        className="btn btn-icon btn-alert ml-2"
                                                        onClick={() =>
                                                            this.deleteField(
                                                                field
                                                            )
                                                        }>
                                                        <Icon name="delete" />
                                                    </button>
                                                </div>
                                            </td>
                                        </tr>
                                    ))}
                                </SortableTable>
                            ) : this.props.error ? (
                                <ErrorView error={this.props.error} />
                            ) : (
                                <EmptyView
                                    label={t('settings.user_fields.empty', {
                                        label: this.props.label
                                    })}
                                />
                            )}
                        </Card>
                    </Fragment>
                )}

                {this.state.actions.updateField && (
                    <UserFieldForm
                        field={this.state.actions.updateField}
                        onDismissClicked={() => this.editField(null)}
                        onFinished={() => this.onFinishAction()}
                    />
                )}
                {this.state.actions.deleteField && (
                    <DeleteAlertModal
                        title={t('settings.user_fields.delete.title')}
                        description={t(
                            'settings.user_fields.delete.description'
                        )}
                        onDelete={() =>
                            this.props.deleteUserField(
                                this.state.actions.deleteField.id
                            )
                        }
                        onDismissClicked={() => this.deleteField(null)}
                        onFinished={() => this.onFinishAction()}
                    />
                )}
            </Fragment>
        );
    }
}

UserFieldsContainer.propTypes = {};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(UserFieldsContainer);
