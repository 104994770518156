import { t } from '@dive/localization-js';
import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';

import Card from '../../../shared/card/Card';
import SubmitButton from '../../../shared/buttons/SubmitButton';
import { getEndDate, getName } from '../../../../platform/model/subscription';

const CurrentPlan = ({ currentPlan, onClick }) => {
    return (
        <Card className="card-bordered">
            <div>
                <h3>{getName(currentPlan)}</h3>
                <p>
                    {t('subscriptions.current_plan.end_date', {
                        date: moment(getEndDate(currentPlan)).format(
                            `DD/MM/YYYY`
                        )
                    })}
                </p>
            </div>
            <div className="d-flex justify-content-end">
                <SubmitButton label={t('general.next')} onClick={onClick} />
            </div>
        </Card>
    );
};

CurrentPlan.propTypes = {
    currentPlan: PropTypes.object,
    onClick: PropTypes.func.isRequired
};

export default CurrentPlan;
