import React from 'react';
import PropTypes from 'prop-types';
import {
    ROUTE_CLIENTS_DETAIL_TAB_DIARY,
    ROUTE_CLIENTS_DETAIL_TAB_DIARY_GENERAL_DETAIL,
    ROUTE_CLIENTS_DETAIL_TAB_DIARY_FOOD_DETAIL,
    ROUTE_CLIENTS_DETAIL_TAB_DIARY_MOVEMENT_DETAIL
} from '../../../../platform/routing';
import { Route, Switch } from 'react-router-dom';
import ClientGeneralDiaryDetail from './general/detail/ClientGeneralDiaryDetail';
import ClientMovementDetail from './movement/detail/ClientMovementDetail';
import ClientDiaryOverview from './overview/ClientDiaryOverview';
import ClientFoodDetail from './food/detail/ClientFoodDetail';
const ClientDiaryTab = ({ client }) => (
    <Switch>
        <Route
            path={ROUTE_CLIENTS_DETAIL_TAB_DIARY_GENERAL_DETAIL}
            component={ClientGeneralDiaryDetail}
        />

        <Route
            path={ROUTE_CLIENTS_DETAIL_TAB_DIARY_MOVEMENT_DETAIL}
            component={ClientMovementDetail}
        />

        <Route
            path={ROUTE_CLIENTS_DETAIL_TAB_DIARY_FOOD_DETAIL}
            component={ClientFoodDetail}
        />

        <Route
            path={ROUTE_CLIENTS_DETAIL_TAB_DIARY}
            render={props => <ClientDiaryOverview client={client} {...props} />}
        />
    </Switch>
);

ClientDiaryTab.propTypes = {
    client: PropTypes.object.isRequired
};

export default ClientDiaryTab;
