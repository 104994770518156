import { isVoid } from '@dive/utils-js';

export const isCompleted = goal => {
    return goal.status === 'completed';
};

export const isFailed = goal => {
    return goal.status === 'failed';
};

export const isFutureGoal = goal => {
    return isVoid(goal.status);
};
