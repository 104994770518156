import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import Card from '../../shared/card/Card';
import { t } from '@dive/localization-js';
import LoadingIcon from '../../shared/loading/LoadingIcon';
import moment from 'moment/moment';
import Icon from 'app/components/shared/icon/Icon';
import { ROUTE_CLIENTS_DETAIL } from 'app/platform/routing';
import { Link } from 'react-router-dom';
import { getFullName } from '../../../../core/models/client';
import { isLoadingData } from '../../../../core/utils/data';
import { getUpcomingConsultations } from '../../../../core/actions/consultations';
import ErrorView from '../../shared/view/ErrorView';
import EmptyView from '../../shared/view/EmptyView';

const mapStateToProps = state => {
    const { data } = state.consultations.upcoming;

    return {
        isLoading: isLoadingData(state.consultations.upcoming),
        error: state.consultations.upcoming.error,
        upcomingConsultations: data ? data.consultations : null
    };
};
const mapDispatchToProps = dispatch => {
    return {
        getUpcomingConsultations: () => {
            dispatch(getUpcomingConsultations());
        }
    };
};

class UpcomingConsultationsContainer extends Component {
    componentDidMount() {
        this.props.getUpcomingConsultations();
    }

    componentDidUpdate() {
        this.props.getUpcomingConsultations();
    }

    render() {
        return this.props.isLoading ? (
            <LoadingIcon />
        ) : (
            <Fragment>
                <h2 className="mb-3 pl-0">{t('clients.upcoming.title')}</h2>
                <Card
                    padding={
                        this.props.upcomingConsultations &&
                        this.props.upcomingConsultations.length > 0
                    }>
                    {this.props.upcomingConsultations &&
                    this.props.upcomingConsultations.length > 0 ? (
                        <div className="bordered-list">
                            {this.props.upcomingConsultations.map(
                                consultation => (
                                    <li key={consultation.id}>
                                        <Link
                                            to={ROUTE_CLIENTS_DETAIL.replace(
                                                ':id',
                                                consultation.client.id
                                            )}
                                            className="row list-item">
                                            <div className="col-4 text-body-light">
                                                {moment(
                                                    consultation.scheduled
                                                ).format('DD/MM HH:mm')}
                                            </div>
                                            <div className="col list-description font-weight-semi-bold">
                                                {getFullName(
                                                    consultation.client
                                                )}
                                            </div>
                                            <div className="col-auto d-flex align-items-center font-weight-semi-bold more-icon">
                                                <Icon name="arrow-right" />
                                            </div>
                                        </Link>
                                    </li>
                                )
                            )}
                        </div>
                    ) : this.props.error ? (
                        <ErrorView error={this.props.error} />
                    ) : (
                        <EmptyView label={t('clients.upcoming.empty')} />
                    )}
                </Card>
            </Fragment>
        );
    }
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(UpcomingConsultationsContainer);
