import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { t } from '@dive/localization-js';
import Card from 'app/components/shared/card/Card';
import IconButton from 'app/components/shared/buttons/IconButton';
import MultilineText from '../../../shared/text/MultilineText';
import EmptyView from '../../../shared/view/EmptyView';

const ClientDiagnosisInfo = ({ readOnly, fields, type, onEditClicked }) => {
    const getField = field => {
        return field.value !== null && field.group === type ? (
            <Fragment key={field.name}>
                <dt className={field.type === 'text' ? 'col-sm-5' : 'col-sm-5'}>
                    {field.name}
                </dt>
                <dd className={field.type === 'text' ? 'col-sm-7' : 'col-sm-7'}>
                    <MultilineText>
                        {field.type === 'checkbox'
                            ? t(field.value ? 'general.yes' : 'general.no')
                            : field.value}
                    </MultilineText>
                </dd>
            </Fragment>
        ) : null;
    };

    return (
        <Card>
            {fields.length > 0 ? (
                <Fragment>
                    <div className="row">
                        <div className="col-sm-6 col-md-5">
                            <dl className="row mt-3">
                                {fields
                                    .slice(0, Math.ceil(fields.length / 2))
                                    .map(field => getField(field))}
                            </dl>
                        </div>
                        <div className="col-sm-6 col-md-5">
                            <dl className="row mt-3">
                                {fields
                                    .slice(
                                        Math.ceil(fields.length / 2),
                                        fields.length
                                    )
                                    .map(field => getField(field))}
                            </dl>
                        </div>
                    </div>
                    {!readOnly && (
                        <div className="card-buttons">
                            <IconButton
                                icon="edit"
                                onClick={() => onEditClicked(type)}
                            />
                        </div>
                    )}
                </Fragment>
            ) : (
                <div className="d-flex flex-column align-items-center">
                    <EmptyView
                        label={t('clients.detail.general.diagnose.empty')}
                    />
                    {!readOnly && (
                        <button
                            className="btn btn-secondary"
                            onClick={() => onEditClicked(type)}>
                            {t('clients.detail.general.diagnose.add')}
                        </button>
                    )}
                </div>
            )}
        </Card>
    );
};

ClientDiagnosisInfo.propTypes = {
    fields: PropTypes.arrayOf(PropTypes.object).isRequired,
    type: PropTypes.string.isRequired,
    onEditClicked: PropTypes.func.isRequired
};

export default ClientDiagnosisInfo;
