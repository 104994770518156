import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { t } from '@dive/localization-js';
import { request } from '@dive/react-redux-networking';

import Card from 'app/components/shared/card/Card';
import Icon from 'app/components/shared/icon/Icon';
import { deleteClient } from 'app/platform/actions/clients';
import { history, ROUTE_CLIENTS } from 'app/platform/routing';
import ClientContactInfo from './ClientContactInfo';
import ClientDiagnosisInfo from './ClientDiagnosisInfo';
import DeleteAlertModal from '../../../shared/modal/DeleteAlertModal';
import ClientContactForm from './form/ClientContactForm';
import ClientDiagnosisForm from './form/ClientDiagnosisForm';
import { updateClient } from '../../../../platform/actions/clients';
import ConfirmAlertModal from '../../../shared/modal/ConfirmAlertModal';
import ClientMeasurementGraph from './graph/ClientMeasurementGraph';
import ClientActivationStatus from './status/ClientActivationStatus';

export const DiagnosisTypes = {
    GENERAL: 'general',
    FOOD: 'food'
};

const mapDispatchToProps = dispatch => {
    return {
        deleteClient: id => {
            return request(dispatch(deleteClient(id)));
        },
        updateClient: client => {
            return request(dispatch(updateClient(client)));
        }
    };
};

class ClientGeneralTab extends Component {
    constructor(props) {
        super(props);
        this.state = {
            actions: {},
            editGroup: null,
            errors: {}
        };
    }

    deleteClient(client) {
        // show or hide the delete modal
        this.setState({
            actions: { deleteClient: client }
        });
    }

    editGroup(group) {
        this.setState({
            actions: { updateGroup: group }
        });
    }

    editClient(client) {
        this.setState({
            actions: { updateClient: client }
        });
    }

    activateClient(client) {
        this.setState({
            actions: { activateClient: client }
        });
    }

    permanentlyDeleteClient(client) {
        return this.props.deleteClient(client);
    }

    toggleClientActive(client) {
        client = Object.assign({}, client, { is_active: !client.is_active });
        return this.props.updateClient(client);
    }

    onFinished() {
        this.setState({
            actions: {}
        });
    }

    onFinishedDelete = () => {
        // back to clients
        this.setState({
            actions: {}
        });

        history.push(ROUTE_CLIENTS);
    };

    render() {
        const { client } = this.props;
        return (
            <Fragment>
                <div className="row">
                    <div className="col-sm-12">
                        <ClientActivationStatus client={client} />
                    </div>
                    <div className="col-md-6 col-xl-7 col-xxl-8">
                        {/* General info */}
                        <h2>{t('clients.detail.general.overview')}</h2>
                        <ClientContactInfo
                            readOnly={this.props.readOnly}
                            client={client}
                            onEditClick={() => this.editClient(client)}
                        />
                    </div>
                    <div className="col-md-6 col-xl-5 col-xxl-4 mt-5 mt-md-0">
                        {/* Weight info */}
                        <h2>{t('clients.detail.general.evolution')}</h2>
                        <ClientMeasurementGraph client={client} />
                    </div>
                </div>
                <div className="row mt-4">
                    <div className="col-sm-12">
                        {/* General Info */}
                        <h2>{t('clients.detail.general.diagnose.general')}</h2>
                        <ClientDiagnosisInfo
                            readOnly={this.props.readOnly}
                            fields={client.data.filter(
                                field =>
                                    field.group === DiagnosisTypes.GENERAL &&
                                    field.value !== null
                            )}
                            onEditClicked={type => this.editGroup(type)}
                            type={DiagnosisTypes.GENERAL}
                        />
                    </div>
                    <div className="col-sm-12 mt-4">
                        {/* Food info */}
                        <h2>{t('clients.detail.general.diagnose.food')}</h2>
                        <ClientDiagnosisInfo
                            readOnly={this.props.readOnly}
                            fields={client.data.filter(
                                field =>
                                    field.group === DiagnosisTypes.FOOD &&
                                    field.value !== null
                            )}
                            client={client}
                            onEditClicked={type => this.editGroup(type)}
                            type={DiagnosisTypes.FOOD}
                        />
                    </div>
                </div>
                <div className="row mt-5">
                    <div className="col-sm-12">
                        <Card padding={false}>
                            {this.props.readOnly ? (
                                <button
                                    className="btn btn-card"
                                    onClick={() => this.activateClient(client)}>
                                    <Icon name="activate" />{' '}
                                    {t('clients.detail.general.activate')}
                                </button>
                            ) : (
                                <button
                                    className="btn btn-card"
                                    onClick={() => this.activateClient(client)}>
                                    <Icon name="deactivate" />{' '}
                                    {t('clients.detail.general.deactivate')}
                                </button>
                            )}
                        </Card>
                        <Card padding={false} className="mt-3">
                            <button
                                onClick={() => this.deleteClient(client)}
                                className="btn btn-alert btn-card">
                                <Icon name="delete" />{' '}
                                {t('clients.detail.general.delete')}
                            </button>
                        </Card>
                    </div>
                </div>

                {/* Delete user modal */}
                {this.state.actions.deleteClient && (
                    <DeleteAlertModal
                        title={t('clients.delete.title')}
                        description={t('clients.delete.description')}
                        onDelete={() =>
                            this.permanentlyDeleteClient(
                                this.state.actions.deleteClient.id
                            )
                        }
                        onDismissClicked={() => this.deleteClient(null)}
                        onFinished={() => this.onFinishedDelete()}
                    />
                )}
                {/* Activate or deactivate user modal */}
                {this.state.actions.activateClient && (
                    <ConfirmAlertModal
                        title={t(
                            this.props.client.is_active
                                ? 'clients.deactivate.title'
                                : 'clients.activate.title'
                        )}
                        description={t(
                            this.props.client.is_active
                                ? 'clients.deactivate.description'
                                : 'clients.activate.description'
                        )}
                        onConfirm={() =>
                            this.toggleClientActive(
                                this.state.actions.activateClient
                            )
                        }
                        onDismissClicked={() => this.activateClient(null)}
                        onFinished={() => this.onFinished()}
                    />
                )}

                {/* Edit user */}
                {client && this.state.actions.updateClient && (
                    <ClientContactForm
                        client={client}
                        onDismissClicked={() => this.editClient(null)}
                        onFinished={() => this.editClient(null)}
                    />
                )}

                {/* Edit user */}
                {client && this.state.actions.updateGroup && (
                    <ClientDiagnosisForm
                        client={client}
                        type={this.state.actions.updateGroup}
                        onDismissClicked={() => this.editGroup(null)}
                        onFinished={() => this.editGroup(null)}
                    />
                )}
            </Fragment>
        );
    }
}

ClientGeneralTab.propTypes = {
    client: PropTypes.object.isRequired
};

export default connect(
    undefined,
    mapDispatchToProps
)(ClientGeneralTab);
