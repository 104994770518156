import React, { Component } from 'react';
import { getSettings, updateSettings } from '../../../../core/actions/settings';
import {
    selectSettings,
    selectSettingsData,
    selectSettingsError
} from '../../../../core/models/settings';

import Card from '../../shared/card/Card';
import ErrorView from '../../shared/view/ErrorView';
import GeneralSettingsForm from './form/GeneralSettingsForm';
import LoadingIcon from '../../shared/loading/LoadingIcon';
import { connect } from 'react-redux';
import { getUserFields } from '../../../platform/actions/fields';
import { isLoadingData } from '../../../../core/utils/data';
import { selectFieldData } from '../../../platform/model/fields';
import { t } from '@dive/localization-js';

class GeneralSettingsContainer extends Component {
    componentDidMount() {
        this.props.getSettings();
        this.props.getUserFields('consultation');
    }

    componentDidUpdate() {
        this.props.getSettings();
        this.props.getUserFields('consultation');
    }

    render() {
        const { error, fields, isLoading, settings } = this.props;
        return (
            <>
                <h2>{t('settings.general')}</h2>

                {isLoading ? (
                    <Card>
                        <LoadingIcon />
                    </Card>
                ) : (
                    <>
                        {settings ? (
                            <Card>
                                <GeneralSettingsForm
                                    fields={fields}
                                    settings={settings}
                                />
                            </Card>
                        ) : error ? (
                            <Card>
                                <ErrorView error={error} />
                            </Card>
                        ) : null}
                    </>
                )}
            </>
        );
    }
}

const mapStateToProps = state => ({
    error: selectSettingsError(state),
    fields: selectFieldData(state, 'consultation'),
    isLoading: isLoadingData(selectSettings(state)),
    settings: selectSettingsData(state)
});

const mapDispatchToProps = {
    getSettings,
    updateSettings,
    getUserFields
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(GeneralSettingsContainer);
