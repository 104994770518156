import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import Modal from './Modal';
import { t } from '@dive/localization-js';
import LoadingIcon from 'app/components/shared/loading/LoadingIcon';
import ErrorView from '../view/ErrorView';

const AlertModal = ({
    title,
    alertType,
    description,
    confirmButtonLabel,
    cancelButtonLabel = t('general.cancel'),
    onConfirmClicked,
    onDismissClicked,
    isLoading = false,
    error
}) => {
    return (
        <Modal className={`modal-alert modal-${alertType}`}>
            {!isLoading ? (
                <Fragment>
                    <div className="modal-header">
                        <h3>{title}</h3>
                    </div>
                    <div className="modal-body">
                        {error && <ErrorView error={error} />}
                        {description}
                    </div>
                    <div className="modal-footer">
                        <button
                            type="button"
                            className="btn btn-link btn-cancel"
                            onClick={() => onDismissClicked()}>
                            {cancelButtonLabel}
                        </button>
                        <button
                            type="button"
                            className={`btn btn-${alertType}`}
                            onClick={() => onConfirmClicked()}>
                            {confirmButtonLabel}
                        </button>
                    </div>
                </Fragment>
            ) : (
                <LoadingIcon />
            )}
        </Modal>
    );
};

AlertModal.propTypes = {
    title: PropTypes.string.isRequired,
    alertType: PropTypes.string.isRequired,
    description: PropTypes.string.isRequired,
    confirmButtonLabel: PropTypes.string.isRequired,
    cancelButtonLabel: PropTypes.string,
    onConfirmClicked: PropTypes.func.isRequired,
    onDismissClicked: PropTypes.func.isRequired,
    isLoading: PropTypes.bool,
    error: PropTypes.string
};

export default AlertModal;
