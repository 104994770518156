import { AuthActions } from '@dive/react-redux-networking';
import { CategoryActions } from '../actions/categories';
import { ClientsActions } from '../actions/clients';
import { CoachingActions } from '../../../core/actions/coaching';
import { ConsultationsActions } from '../../../core/actions/consultations';
import { GoalsActions } from '../../../core/actions/goals';
import { LibraryTypes } from '../../../core/actions/library';
import { MessagesActions } from '../actions/messages';
import { PasswordActions } from '../../../core/actions/password';
import { SettingsActions } from '../../../core/actions/settings';
import { SubscriptionsActions } from '../../platform/actions/subscriptions';
import { TreatmentActions } from '../actions/treatment';
import { UserFieldsActions } from '../actions/fields';
import { t } from '@dive/localization-js';

const DEFAULT = {};

const messages = (state = Object.assign({}, DEFAULT), action) => {
    switch (action.type) {
        case MessagesActions.SET_MESSAGE:
            return Object.assign({}, state, {
                [action.data.key]: action.data.message
            });

        case PasswordActions.RESET_PASSWORD_SUCCESS:
            return Object.assign({}, state, {
                general: t('reset.new_password.success')
            });
        case PasswordActions.ACCEPT_INVITATION_SUCCESS:
            return Object.assign({}, state, {
                general: t('reset.new_password.invitation.success')
            });
        case AuthActions.GET_AUTH_SUCCESS:
            return Object.assign({}, state, { loginMessage: '' });

        // Clients
        case ClientsActions.UPDATE_CLIENT_SUCCESS:
            return Object.assign({}, state, {
                general: t('clients.edit.success')
            });
        case ClientsActions.REMOVE_CLIENT_SUCCESS:
            return Object.assign({}, state, {
                general: t('clients.delete.success')
            });
        case PasswordActions.REQUEST_INVITATION_SUCCESS:
            return Object.assign({}, state, {
                general: t('clients.detail.general.no_user.resend.success')
            });
        case PasswordActions.REQUEST_INVITATION_FAIL:
            return Object.assign({}, state, {
                error: t('clients.detail.general.no_user.resend.failed')
            });

        // Coachings
        case CoachingActions.CREATE_CLIENT_SUCCESS:
            return Object.assign({}, state, {
                general: t('digitalcoaching.add.success')
            });
        case CoachingActions.UPDATE_CLIENT_SUCCESS:
            return Object.assign({}, state, {
                general: t('digitalcoaching.edit.success')
            });
        case CoachingActions.REMOVE_CLIENT_SUCCESS:
            return Object.assign({}, state, {
                general: t('digitalcoaching.delete.success')
            });

        // Library
        case LibraryTypes.CREATE_ENTRY_SUCCESS:
            return {
                ...state,
                general: t('library.add.success')
            };

        case LibraryTypes.UPDATE_ENTRY_SUCCESS:
            return {
                ...state,
                general: t('library.edit.success')
            };

        case LibraryTypes.REMOVE_ENTRY_SUCCESS:
            return {
                ...state,
                general: t('library.delete.success')
            };

        case LibraryTypes.UPDATE_ENTRY_RECEIVERS_SUCCESS:
            return {
                ...state,
                general: t('library.add.link.success')
            };

        case LibraryTypes.REMOVE_ENTRY_RECEIVERS_SUCCESS:
            return {
                ...state,
                general: t('library.delete.link.success')
            };

        // Goals
        case GoalsActions.CREATE_GOAL_SUCCESS:
            return Object.assign({}, state, {
                general: t('treatment.goals.add.success')
            });
        case GoalsActions.UPDATE_GOAL_SUCCESS:
            return Object.assign({}, state, {
                general: t('treatment.goals.edit.success')
            });
        case GoalsActions.REMOVE_GOAL_SUCCESS:
            return Object.assign({}, state, {
                general: t('treatment.goals.delete.success')
            });

        // Settings
        case SettingsActions.UPDATE_SETTINGS_SUCCESS:
            return Object.assign({}, state, {
                general: t('settings.edit.success')
            });
        case SettingsActions.UPDATE_SETTINGS_FAIL:
            return Object.assign({}, state, {
                error: t('settings.edit.failed')
            });

        // Fields
        case UserFieldsActions.CREATE_USER_FIELD_SUCCESS:
            return Object.assign({}, state, {
                general: t('settings.user_fields.add.success')
            });
        case UserFieldsActions.UPDATE_USER_FIELD_SUCCESS:
            return Object.assign({}, state, {
                general: t('settings.user_fields.edit.success')
            });
        case UserFieldsActions.REMOVE_USER_FIELD_SUCCESS:
            return Object.assign({}, state, {
                general: t('settings.user_fields.delete.success')
            });

        // Categories
        case CategoryActions.CREATE_CATEGORY_SUCCESS:
            return Object.assign({}, state, {
                general: t('settings.categories.add.success')
            });
        case CategoryActions.UPDATE_CATEGORY_SUCCESS:
            return Object.assign({}, state, {
                general: t('settings.categories.edit.success')
            });
        case CategoryActions.DELETE_CATEGORY_SUCCESS:
            return Object.assign({}, state, {
                general: t('settings.categories.delete.success')
            });

        // Consultations
        case ConsultationsActions.CREATE_CONSULTATION_SUCCESS:
            return Object.assign({}, state, {
                general: t('consultations.add.success')
            });
        case ConsultationsActions.UPDATE_CONSULTATION_SUCCESS:
            return Object.assign({}, state, {
                general: t('consultations.edit.success')
            });
        case ConsultationsActions.REMOVE_CONSULTATION_SUCCESS:
            return Object.assign({}, state, {
                general: t('consultations.delete.success')
            });

        // Subscriptions
        case SubscriptionsActions.UPDATE_SUBSCRIPTION_SUCCESS:
            return Object.assign({}, state, {
                general: t('subscriptions.success')
            });
        case SubscriptionsActions.REMOVE_SUBSCRIPTION_SUCCESS:
            return Object.assign({}, state, {
                general: t('subscription.delete.success')
            });
        case SubscriptionsActions.RESUME_SUBSCRIPTION_SUCCESS:
            return Object.assign({}, state, {
                general: t('subscription.resume.success')
            });

        // Treatment
        case TreatmentActions.CREATE_TREATMENT_SUCCESS:
            return Object.assign({}, state, {
                general: t('treatment.plan.edit.success')
            });
        case TreatmentActions.UPLOAD_TREATMENT_FILE_SUCCESS:
            return Object.assign({}, state, {
                general: t('treatment.mealplan.add.success')
            });
        case TreatmentActions.REMOVE_TREATMENT_SUCCESS:
            return Object.assign({}, state, {
                general: t('treatment.mealplan.delete.success')
            });

        // Clear message
        case MessagesActions.CLEAR_MESSAGE:
            return Object.assign({}, state, { [action.key]: '' });
        default:
            return state;
    }
};

export default messages;
