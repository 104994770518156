import { shouldRefreshStateData } from '../utils/data';

export const SettingsActions = {
    GET_SETTINGS: '/settings/LOAD',
    GET_SETTINGS_SUCCESS: '/settings/LOAD_SUCCESS',
    GET_SETTINGS_FAIL: '/settings/LOAD_FAIL',

    SET_SETTINGS: '/settings/SET',

    CLEAR_SETTINGS: 'CLEAR',

    UPDATE_SETTINGS: '/settings/UPDATE',
    UPDATE_SETTINGS_SUCCESS: '/settings/UPDATE_SUCCESS',
    UPDATE_SETTINGS_FAIL: '/settings/UPDATE_FAIL'
};

const SETTINGS_CACHE_INTERVAL = 1000 * 60 * 5;

export const getSettings = () => (dispatch, getState) => {
    if (shouldRefreshStateData(getState().settings, SETTINGS_CACHE_INTERVAL)) {
        return dispatch(fetchSettings());
    }
};

export const fetchSettings = () => ({
    type: SettingsActions.GET_SETTINGS,
    payload: {
        request: {
            url: `/user/settings`,
            method: 'GET'
        }
    }
});

export const updateSetting = (key, value) => (dispatch, getState) => {
    const settings = Object.assign({}, getState().settings.data);

    // save local
    settings[key] = value;
    dispatch(setSettings(settings));

    // save on server
    return dispatch(updateSettings(settings));
};

const setSettings = settings => ({
    type: SettingsActions.SET_SETTINGS,
    settings
});

export const updateSettings = settings => ({
    type: SettingsActions.UPDATE_SETTINGS,
    payload: {
        request: {
            url: `/user/settings`,
            method: 'PUT',
            data: settings
        }
    }
});
