import { shouldRefreshStateData } from '../../../core/utils/data';

export const TagActions = {
    GET_TAGS: '/tags/LOAD',
    GET_TAGS_SUCCESS: '/tags/LOAD_SUCCESS',
    GET_TAGS_FAIL: '/tags/LOAD_FAIL',

    CLEAR_TAGS: '/tags/CLEAR',

    CREATE_TAG: '/tags/CREATE',
    CREATE_TAG_SUCCESS: '/tags/CREATE_SUCCESS',
    CREATE_TAG_FAIL: '/tags/CREATE_FAIL',

    UPDATE_TAG: '/tags/UPDATE',
    UPDATE_TAG_SUCCESS: '/tags/UPDATE_SUCCESS',
    UPDATE_TAG_FAIL: '/tags/UPDATE_FAIL',

    DELETE_TAG: '/tags/REMOVE',
    DELETE_TAG_SUCCESS: '/tags/REMOVE_SUCCESS',
    DELETE_TAG_FAIL: '/tags/REMOVE_FAIL'
};

const TAGS_CACHE_INTERVAL = 1000 * 60 * 2;

export const getTags = () => (dispatch, getState) => {
    const { tags } = getState();

    if (shouldRefreshStateData(tags, TAGS_CACHE_INTERVAL)) {
        return dispatch(fetchTags());
    }
};

export const fetchTags = () => ({
    type: TagActions.GET_TAGS,
    payload: {
        request: {
            url: `/tags`,
            method: 'GET'
        }
    }
});

export const updateTag = tag => ({
    type: tag.id ? TagActions.UPDATE_TAG : TagActions.CREATE_TAG,
    payload: {
        request: {
            url: tag.id ? `/tags/${tag.id}` : '/tags',
            method: tag.id ? 'PUT' : 'POST',
            data: tag,
            id: tag.id
        }
    }
});

export const deleteTag = id => ({
    type: TagActions.DELETE_TAG,
    payload: {
        request: {
            url: `/tags/${id}`,
            method: 'DELETE',
            id
        }
    }
});
