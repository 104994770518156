import React from 'react';
import TreatmentContainer from './treatment/TreatmentContainer';

const ClientTreatmentTab = props => (
    <div>
        <TreatmentContainer readOnly={props.readOnly} client={props.client} />
    </div>
);

export default ClientTreatmentTab;
