import React from 'react';
import { t } from '@dive/localization-js';
import Icon from '../icon/Icon';

const Footer = () => {
    return (
        <div className="app-footer">
            <a
                href="https://myfoodcoachandme.com/knowledge-base/"
                rel="noopener noreferrer"
                target="_blank">
                <Icon name="help" />
                {t('help')}
            </a>
        </div>
    );
};

export default Footer;
