export function isValidEmail(input) {
    // Regular expression to compare user input with
    const emailValidation = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
    return emailValidation.test(input);
}

export function isValidNumber(input) {
    // return Number(parseFloat(input)) === parseFloat(input);
    return !isNaN(input) && !isNaN(parseFloat(input));
}

export function isDecimalNumber(input) {
    return (
        input && (`${input}`.indexOf(',') > 0 || `${input}`.indexOf('.') > 0)
    );
}
