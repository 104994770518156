import { buildQuery } from '@dive/utils-js';
import React from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';

import {
    ROUTE_LOGIN,
    ROUTE_ONBOARDING,
    ROUTE_REGISTER,
    ROUTE_PASSWORD_RESET,
    ROUTE_NEW_PASSWORD,
    ROUTE_EMAIL_VERIFICATION,
    ROUTE_ACTIVATE_MOBILE_ACCOUNT,
    ROUTE_MOBILE_APP
} from 'app/platform/routing';
import LoginContainer from './login/LoginContainer';
import RegisterContainer from './register/RegisterContainer';
import ResetPasswordContainer from './password/ResetPasswordContainer';
import NewPasswordContainer from './password/NewPasswordContainer';
import MobileAppContainer from './mobile/MobileAppContainer';
import MessageBar from '../shared/message/MessageBar';
import EmailVerificationContainer from './activation/EmailVerificationContainer';

const OnboardingContainer = ({ location }) => (
    <>
        <div className="onboarding-split justify-content-center flex-md-row">
            <div className="onboarding-image" />

            <div className="onboarding-content d-flex">
                <Switch>
                    <Route path={ROUTE_LOGIN} component={LoginContainer} />

                    <Route
                        path={ROUTE_REGISTER}
                        component={RegisterContainer}
                    />

                    <Route
                        path={ROUTE_NEW_PASSWORD}
                        component={NewPasswordContainer}
                    />

                    <Route
                        path={ROUTE_EMAIL_VERIFICATION}
                        component={EmailVerificationContainer}
                    />

                    <Route
                        path={ROUTE_ACTIVATE_MOBILE_ACCOUNT}
                        component={NewPasswordContainer}
                    />

                    <Route
                        path={ROUTE_PASSWORD_RESET}
                        component={ResetPasswordContainer}
                    />

                    <Route
                        path={ROUTE_MOBILE_APP}
                        component={MobileAppContainer}
                    />

                    <Redirect
                        from={ROUTE_ONBOARDING}
                        to={{
                            pathname: ROUTE_LOGIN,
                            search: buildQuery({
                                redirect_url: location.pathname
                            })
                        }}
                    />
                </Switch>
            </div>
        </div>

        <MessageBar />
    </>
);

export default OnboardingContainer;
