import React from 'react';
import { t } from '@dive/localization-js';

export default function FilterDropdown({
    filters,
    onFilterChanged,
    defaultSelected
}) {
    return (
        <form className="form-filter mt-2 mb-2 mr-1">
            <select
                className="form-control"
                onChange={onFilterChanged}
                value={defaultSelected}>
                {filters.map((filter, index) => (
                    <option key={index} value={filter}>
                        {t(`dashboard.filters.${filter}`)}
                    </option>
                ))}
            </select>
        </form>
    );
}
