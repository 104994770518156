import React, { Component } from 'react';
import { connect } from 'react-redux';
import { request } from '@dive/react-redux-networking';

import GoalsView from './GoalsView';
import {
    getClientGoals,
    updateClientGoal,
    deleteClientGoal
} from '../../../../../../core/actions/goals';
import { isLoadingData } from '../../../../../../core/utils/data';

const mapStateToProps = state => {
    const { goals } = state.clients.detail;
    const data = goals ? goals.data : null;

    return {
        shouldLoadData: !goals || (!goals.isLoading && !data && !goals.error),
        isLoading: isLoadingData(goals),
        goals: data ? data.goals : null,
        error: goals && goals.error
    };
};

const mapDispatchToProps = dispatch => {
    return {
        getGoals: id => {
            return dispatch(getClientGoals(id));
        },
        deleteClientGoals: (id, goalId) => {
            return request(dispatch(deleteClientGoal(id, goalId)));
        },
        saveGoal: (clientId, goal) => {
            return request(dispatch(updateClientGoal(clientId, goal)));
        }
    };
};

class GoalsContainer extends Component {
    constructor(props) {
        super(props);
        this.state = {
            actions: {}
        };
    }

    componentDidMount() {
        if (this.props.shouldLoadData) {
            this.props.getGoals(this.props.client.id);
        }
    }

    addGoal = () => {
        this.editGoal({});
    };

    editGoal = goal => {
        // show or hide the delete modal
        this.setState({
            actions: { updateGoal: goal }
        });
    };

    deleteGoal = goal => {
        // show or hide the delete modal
        this.setState({
            actions: { deleteGoal: goal }
        });
    };

    changeGoalStatus = (goal, status) => {
        const newGoal = Object.assign({}, goal, { status: status });
        this.props
            .saveGoal(this.props.client.id, newGoal)
            .then(this.onFinishAction);
    };

    onFinishAction = () => {
        // remove dialog
        this.setState({ actions: {} });
        // refetch data
        this.props.getGoals(this.props.client.id);
    };

    render() {
        return (
            <GoalsView
                readOnly={this.props.readOnly}
                client={this.props.client}
                goals={this.props.goals}
                error={this.props.error}
                isLoading={this.props.isLoading}
                updateGoalInfo={this.state.actions.updateGoal}
                deleteGoalInfo={this.state.actions.deleteGoal}
                addGoal={this.addGoal}
                editGoal={this.editGoal}
                deleteGoal={this.deleteGoal}
                changeStatus={this.changeGoalStatus}
                deleteClientGoals={this.props.deleteClientGoals}
                onFinishAction={this.onFinishAction}
            />
        );
    }
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(GoalsContainer);
