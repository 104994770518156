export const isDietician = user => {
    return !!user.is_dietician;
};

export const isAdmin = user => {
    return user.is_administrator;
};

export const getFullName = user => {
    return `${user.first_name} ${user.name}`;
};

export const getAddress = user => {
    if (user.address_street) {
        return `${user.address_street}\n${user.address_postal_code ||
            ''} ${user.address_city || ''}`;
    } else {
        return '';
    }
};

export const selectUser = state => state.user.data;

export const selectUserLoading = state => state.user.isLoading;

export const selectUserPlan = state => {
    const user = selectUser(state);

    return user.current_plan;
};

export const selectUserState = state => state.user.state;
