import EmptyView from '../../../shared/view/EmptyView';
import GraphCard from './types/GraphCard';
import ListCard from './types/ListCard';
import PieGraph from './types/PieGraph';
import React from 'react';
import ValueCard from './types/ValueCard';
import { t } from '@dive/localization-js';

const selectCardType = card => {
    switch (card.type) {
        case 'value':
            return card.data ? (
                <ValueCard data={card.data} unit={card.unit} />
            ) : (
                <EmptyView label={t(`dashboard.empty.${card.id}`)} />
            );
        case 'graph':
            return card.data ? (
                <GraphCard
                    data={card.data}
                    title={card.title}
                    className="my-3"
                />
            ) : (
                <EmptyView label={t(`dashboard.empty.${card.id}`)} />
            );
        case 'list':
            return card.data.datasets.length ? (
                <ListCard
                    data={card.data.datasets}
                    columns={card.data.labels}
                />
            ) : (
                <EmptyView label={t(`dashboard.empty.${card.id}`)} />
            );
        case 'pie':
            return card.data ? (
                <PieGraph
                    data={card.data}
                    colors={card.data.datasets[0].backgroundColor}
                    className="my-3"
                />
            ) : (
                <EmptyView label={t(`dashboard.empty.${card.id}`)} />
            );
        default:
            return <h3>Please add card type for this data</h3>;
    }
};

export default function DashboardCardContent({ card }) {
    return selectCardType(card);
}
