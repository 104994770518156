import FeedbackView from '../../../feedback/FeedbackView';
import PropTypes from 'prop-types';
import React from 'react';
import ReadNotification from '../../../../../../shared/read/ReadNotification';

const ImagesListItem = ({ entry, onFeedBackClick, onImageClick }) => {
    return (
        <li className="d-flex pb-2 pt-2 pl-3 pr-3">
            <ReadNotification read={entry.read} />
            <div
                className="d-flex align-items-start flex-wrap pt-2 pb-2"
                style={{
                    minWidth: '180px'
                }}>
                {entry.images.map(image => (
                    <div
                        key={image.id}
                        className="thumbnail mr-2"
                        style={{
                            backgroundImage: `url('${
                                image.versions['thumb-small']
                            }')`,
                            width: 80,
                            height: 80,
                            cursor: 'pointer'
                        }}
                        onClick={() => onImageClick(image.versions['large'])}
                    />
                ))}
            </div>
            <FeedbackView
                className="ml-auto"
                feedback={entry.feedback}
                onEditClick={feedback => onFeedBackClick(entry.id, feedback)}
            />
        </li>
    );
};

ImagesListItem.propTypes = {
    entry: PropTypes.object,
    onFeedBackClick: PropTypes.func,
    onImageClick: PropTypes.func
};

export default ImagesListItem;
