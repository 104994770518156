import React, { Component } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { connect } from 'react-redux';
import { t } from '@dive/localization-js';
import { request } from '@dive/react-redux-networking';

import { updateConsultation } from 'core/actions/consultations';
import DynamicFormDialog from '../../../../shared/forms/DynamicFormDialog';

const mapDispatchToProps = dispatch => {
    return {
        saveConsultation: consultation => {
            return request(dispatch(updateConsultation(consultation)));
        }
    };
};

class ConsultationForm extends Component {
    constructor(props) {
        super(props);

        this.setFields();

        this.state = {
            isLoading: false,
            errors: {}
        };
    }

    componentDidMount() {
        this.setState({
            consultation: Object.assign({}, this.props.consultation, {
                client_id:
                    this.props.consultation.client_id ||
                    this.props.consultation.client.id,
                scheduled:
                    this.props.consultation.scheduled || moment.utc().format()
            })
        });
    }

    setFields() {
        this.fields = [
            {
                name: 'scheduled',
                label: t('consultations.list.date'),
                type: 'datetime',
                required: true,
                size: '12'
            },
            {
                name: 'note',
                label: t('consultations.list.note'),
                type: 'text',
                size: '12'
            }
        ];
    }
    onSubmit = model => {
        return this.props.saveConsultation(model);
    };

    render() {
        const { consultation } = this.state;
        return consultation ? (
            <DynamicFormDialog
                size="lg"
                title={t(
                    consultation.id ? 'consultations.edit' : 'consultations.add'
                )}
                model={consultation}
                fields={this.fields}
                onDismissClicked={this.props.onDismissClicked}
                onFinished={this.props.onFinished}
                onSaveForm={this.onSubmit}
            />
        ) : null;
    }
}

ConsultationForm.propTypes = {
    consultation: PropTypes.object.isRequired,
    onDismissClicked: PropTypes.func.isRequired,
    onFinished: PropTypes.func.isRequired
};

export default connect(
    undefined,
    mapDispatchToProps
)(ConsultationForm);
