import { buildQuery, isEmptyText, isVoid } from '@dive/utils-js';

import { selectPage, selectSort } from '../models/app';
import { selectLibraryFilters } from '../models/library';
import { shouldRefreshStateData } from '../utils/data';
import { selectClientLibrary } from '../models/client';

export const LibraryTypes = {
    CLEAR_LIBRARY: '/library/CLEAR',

    GET_LIBRARY: '/library/LOAD',
    GET_LIBRARY_SUCCESS: '/library/LOAD_SUCCESS',
    GET_LIBRARY_FAIL: '/library/LOAD_FAIL',

    GET_CLIENT_LIBRARY: '/client/library/LOAD',
    GET_CLIENT_LIBRARY_SUCCESS: '/client/library/LOAD_SUCCESS',
    GET_CLIENT_LIBRARY_FAIL: '/client/library/LOAD_FAIL',

    RESET_CLIENT_FILTERS: '/client/library/RESET_FILTERS',
    SET_CLIENT_FILTERS: '/client/library/SET_FILTERS',

    GET_LIBRARY_ENTRY: '/library/entry/LOAD',
    GET_LIBRARY_ENTRY_SUCCESS: '/library/entry/LOAD_SUCCESS',
    GET_LIBRARY_ENTRY_FAIL: '/library/entry/LOAD_FAIL',

    CREATE_ENTRY: '/library/entry/CREATE',
    CREATE_ENTRY_SUCCESS: '/library/entry/CREATE_SUCCESS',
    CREATE_ENTRY_FAIL: '/library/entry/CREATE_FAIL',

    UPDATE_ENTRY: '/library/entry/UPDATE',
    UPDATE_ENTRY_SUCCESS: '/library/entry/UPDATE_SUCCESS',
    UPDATE_ENTRY_FAIL: '/library/entry/UPDATE_FAIL',

    UPDATE_ENTRY_RECEIVERS: '/library/entry/receivers/UPDATE',
    UPDATE_ENTRY_RECEIVERS_SUCCESS: '/library/entry/receivers/UPDATE_SUCCESS',
    UPDATE_ENTRY_RECEIVERS_FAIL: '/library/entry/receivers/UPDATE_FAIL',

    REMOVE_ENTRY: '/library/entry/REMOVE',
    REMOVE_ENTRY_SUCCESS: '/library/entry/REMOVE_SUCCESS',
    REMOVE_ENTRY_FAIL: '/library/entry/REMOVE_FAIL',

    REMOVE_ENTRY_RECEIVERS: '/library/entry/receivers/REMOVE',
    REMOVE_ENTRY_RECEIVERS_SUCCESS: '/library/entry/receivers/REMOVE_SUCCESS',
    REMOVE_ENTRY_RECEIVERS_FAIL: '/library/entry/receivers/REMOVE_FAIL',

    RESET_FILTERS: '/library/RESET_FILTERS',
    SET_FILTERS: '/library/SET_FILTERS'
};

export const BASE_URL = '/library/entries';
export const LIBRARY_CACHE_INTERVAL = 300000;

export const clearLibrary = () => ({ type: LibraryTypes.CLEAR_LIBRARY });

export const createLibraryEntry = data => ({
    type: LibraryTypes.CREATE_ENTRY,
    payload: {
        request: {
            data,
            url: BASE_URL,
            method: 'POST'
        }
    }
});

export const deleteLibraryEntry = id => ({
    type: LibraryTypes.REMOVE_ENTRY,
    payload: {
        request: {
            url: BASE_URL + '/' + id,
            method: 'DELETE'
        }
    }
});

export const deleteEntryReceivers = (id, data) => ({
    type: LibraryTypes.REMOVE_ENTRY_RECEIVERS,
    payload: {
        request: {
            data,
            url: BASE_URL + '/' + id + '/receivers',
            method: 'DELETE'
        }
    }
});

export const fetchClientLibrary = (id, page = 1, sort) => dispatch => {
    const qs = { page };

    if (!isVoid(sort)) {
        qs.sort = sort.column + ':' + sort.order;
    }

    return dispatch({
        type: LibraryTypes.GET_CLIENT_LIBRARY,
        payload: {
            request: {
                url: '/clients/' + id + BASE_URL + buildQuery(qs),
                method: 'GET'
            }
        }
    });
};

export const fetchLibrary = (page = 1, sort) => (dispatch, getState) => {
    const filters = selectLibraryFilters(getState());
    const qs = { page };

    if (!isEmptyText(filters.category)) {
        qs.category = filters.category;
    }

    if (!isEmptyText(filters.tag)) {
        qs.tag = filters.tag;
    }

    if (!isEmptyText(filters.type)) {
        qs.type = filters.type;
    }

    if (!isEmptyText(filters.interval.startDate)) {
        qs.start_date = filters.interval.startDate;
        qs.end_date = filters.interval.endDate;
    }

    if (!isEmptyText(filters.search)) {
        qs.search = filters.search;
    }

    if (!isVoid(sort)) {
        qs.sort = sort.column + ':' + sort.order;
    }

    return dispatch({
        type: LibraryTypes.GET_LIBRARY,
        payload: {
            request: {
                url: BASE_URL + buildQuery(qs),
                method: 'GET'
            }
        }
    });
};

export const fetchLibraryEntry = id => ({
    type: LibraryTypes.GET_LIBRARY_ENTRY,
    payload: {
        request: {
            url: BASE_URL + '/' + id,
            method: 'GET'
        }
    }
});

export const getClientLibrary = id => (dispatch, getState) => {
    const library = selectClientLibrary(getState());

    if (shouldRefreshStateData(library, LIBRARY_CACHE_INTERVAL)) {
        return dispatch(
            fetchClientLibrary(
                id,
                selectPage(library.data),
                selectSort(library.data)
            )
        );
    }
};

export const getLibrary = () => (dispatch, getState) => {
    const { library } = getState();

    if (shouldRefreshStateData(library, LIBRARY_CACHE_INTERVAL)) {
        return dispatch(
            fetchLibrary(selectPage(library.data), selectSort(library.data))
        );
    }
};

export const resetFilters = () => ({ type: LibraryTypes.RESET_FILTERS });

export const resetClientLibraryFilters = () => ({ type: LibraryTypes.RESET_CLIENT_FILTERS });

export const updateLibraryEntry = (id, data) => ({
    type: LibraryTypes.UPDATE_ENTRY,
    payload: {
        request: {
            data,
            url: BASE_URL + '/' + id,
            method: 'POST'
        }
    }
});

export const updateEntryReceivers = (id, data) => ({
    type: LibraryTypes.UPDATE_ENTRY_RECEIVERS,
    payload: {
        request: {
            data,
            url: BASE_URL + '/' + id + '/receivers',
            method: 'PUT'
        }
    }
});

export const setFilters = filters => ({
    type: LibraryTypes.SET_FILTERS,
    payload: filters
});

export const setClientLibraryFilters = filters => ({
    type: LibraryTypes.SET_CLIENT_FILTERS,
    payload: filters
});
