import React, { memo } from 'react';
import { connect } from 'react-redux';

import Sidebar from './Sidebar';
import { isAdmin } from '../../../../core/models/user';

const mapStateToProps = state => {
    return {
        isAdmin: state.user.data ? isAdmin(state.user.data) : false
    };
};

const SidebarContainer = props => <Sidebar {...props} />;

export default connect(mapStateToProps)(memo(SidebarContainer));
