import React from 'react';

export default function ValueCard({ data, unit }) {
    return (
        <div>
            <span className="font-size-large font-weight-bold">{data}</span>
            {unit && (
                <small className="text-uppercase text-muted"> {unit}</small>
            )}
        </div>
    );
}
