import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { impersonateUser } from '@dive/react-redux-networking';
import { t } from '@dive/localization-js';
import Card from '../shared/card/Card';
import SortableTable from '../shared/table/SortableTable';
import LoadingIcon from '../shared/loading/LoadingIcon';
import ContentHeader from '../../../app/components/shared/content/ContentHeader';
import ContentBody from '../../../app/components/shared/content/ContentBody';
import Pagination from '../shared/pagination/Pagination';
import {
    fetchSubscribers,
    getSubscribers
} from '../../platform/actions/subscribers';
import { getFullName, isAdmin } from '../../../core/models/user';
import { getName } from '../../platform/model/subscription';
import { isLoadingData } from '../../../core/utils/data';
import ErrorView from '../shared/view/ErrorView';
import EmptyView from '../shared/view/EmptyView';
import * as moment from 'moment';
import IconButton from '../shared/buttons/IconButton';

const mapStateToProps = state => {
    const { data } = state.subscribers;
    return {
        isLoading: isLoadingData(state.subscribers),
        error: state.subscribers.error,
        subscribers: data ? data.users : null,
        pagination: {
            currentPage: data ? data.page : null,
            pageSize: data ? data.page_size : null,
            count: data ? data.count : null
        },
        sort: {
            column: data && data.sort ? data.sort.column : null,
            order: data && data.sort ? data.sort.order : null
        }
    };
};

const mapDispatchToProps = dispatch => {
    return {
        getSubscribers: () => {
            return dispatch(getSubscribers());
        },
        fetchSubscribers: (page, sortColumn, order) => {
            return dispatch(fetchSubscribers(page, sortColumn, order));
        },
        impersonateUser: id => {
            return dispatch(impersonateUser(id));
        }
    };
};

class SubscribersOverviewContainer extends Component {
    constructor(props) {
        super(props);
        this.columns = [
            {
                title: t('subscribers.list.name'),
                key: 'name',
                sortable: true
            },
            {
                title: t('subscribers.list.email'),
                key: 'email',
                sortable: true
            },
            {
                title: t('subscribers.list.plan'),
                key: 'plan',
                sortable: false
            },
            {
                title: t('subscribers.list.end_date'),
                key: 'plan',
                sortable: false
            },
            {
                title: t('general.actions'),
                key: 'impersonation',
                sortable: false
            }
        ];
    }

    componentDidMount() {
        this.props.getSubscribers();
    }

    componentDidUpdate(prevProps) {
        // check if we need to reload cache
        this.props.getSubscribers();
    }

    handleImpersonate(id) {
        this.props.impersonateUser(id).then(() => window.location.reload());
    }

    fetchSort = (column, sort) => {
        this.props.fetchSubscribers(
            this.props.pagination.currentPage,
            column,
            sort
        );
    };

    fetchPage = page => {
        this.props.fetchSubscribers(
            page,
            this.props.sort.column,
            this.props.sort.order
        );
    };

    render() {
        const subscribers = this.props.subscribers;
        return (
            <Fragment>
                {this.props.isLoading ? (
                    <LoadingIcon />
                ) : (
                    <Fragment>
                        <ContentHeader>
                            <div className="d-flex flex-wrap justify-content-between">
                                <h1 className="mr-5 mb-3">
                                    {t('subscribers')}{' '}
                                    {subscribers.length > 0
                                        ? `(${subscribers.length})`
                                        : ''}
                                </h1>
                            </div>
                        </ContentHeader>
                        <ContentBody>
                            <Card padding={subscribers.length === 0}>
                                {subscribers && subscribers.length > 0 ? (
                                    <Fragment>
                                        <SortableTable
                                            columns={this.columns}
                                            sortColumn={this.props.sort.column}
                                            order={this.props.sort.order}
                                            isLoading={this.props.isLoading}
                                            onFetch={this.fetchSort}>
                                            {subscribers.map(user => (
                                                <tr key={user.id}>
                                                    <td className="font-weight-semi-bold">{`${getFullName(
                                                        user
                                                    )} ${
                                                        isAdmin(user)
                                                            ? '(admin)'
                                                            : ''
                                                    }`}</td>
                                                    <td>{user.email}</td>
                                                    <td>
                                                        {isAdmin(user)
                                                            ? 'Admin'
                                                            : getName(
                                                                  user.current_plan
                                                              )}
                                                    </td>
                                                    <td>
                                                        {user.current_plan &&
                                                        user.current_plan
                                                            .ends_at
                                                            ? moment(
                                                                  user
                                                                      .current_plan
                                                                      .ends_at
                                                              ).format(
                                                                  `DD/MM/YYYY [-] HH[${t(
                                                                      'general.units.hour'
                                                                  )}]mm`
                                                              )
                                                            : ''}
                                                    </td>
                                                    <td>
                                                        {!isAdmin(user) && (
                                                            <IconButton
                                                                onClick={() =>
                                                                    this.handleImpersonate(
                                                                        user.id
                                                                    )
                                                                }
                                                                className="btn-sm"
                                                                icon="mask"
                                                                title={t(
                                                                    'clients.list.impersonate'
                                                                )}
                                                            />
                                                        )}
                                                    </td>
                                                </tr>
                                            ))}
                                        </SortableTable>
                                        <Pagination
                                            currentPage={
                                                this.props.pagination
                                                    .currentPage
                                            }
                                            count={this.props.pagination.count}
                                            pageSize={
                                                this.props.pagination.pageSize
                                            }
                                            onFetch={this.fetchPage}
                                        />
                                    </Fragment>
                                ) : this.props.error ? (
                                    <ErrorView error={this.props.error} />
                                ) : (
                                    <EmptyView
                                        label={t('subscribers.list.empty')}
                                    />
                                )}
                            </Card>
                        </ContentBody>
                    </Fragment>
                )}
            </Fragment>
        );
    }
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(SubscribersOverviewContainer);
