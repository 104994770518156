import { request } from '@dive/react-redux-networking';

import { isStateDataForKey, shouldRefreshStateData } from '../utils/data';

export const DiaryActions = {
    CLEAR_ALL: '/diary/CLEAR_ALL',

    /*
     * GENERAL
     */
    GET_DIARY: '/diary/LOAD',
    GET_DIARY_SUCCESS: '/diary/LOAD_SUCCESS',
    GET_DIARY_FAIL: '/diary/LOAD_FAIL',

    UPDATE_DIARY: '/diary/UPDATE',
    UPDATE_DIARY_SUCCESS: '/diary/UPDATE_SUCCESS',
    UPDATE_DIARY_FAIL: '/diary/UPDATE_FAIL',

    CLEAR_DIARY: '/diary/CLEAR',
    SET_DATE: '/diary/SET_DATE',

    GET_CLIENT_DIARY: '/client/diary/LOAD',
    GET_CLIENT_DIARY_SUCCESS: '/client/diary/LOAD_SUCCESS',
    GET_CLIENT_DIARY_FAIL: '/client/diary/LOAD_FAIL',

    /*
     * GENERAL DIARY DETAIL
     */
    GET_CLIENT_GENERAL_DIARY_DETAIL: '/client/diary/general/LOAD',
    GET_CLIENT_GENERAL_DIARY_DETAIL_SUCCESS:
        '/client/diary/general/LOAD_SUCCESS',
    GET_CLIENT_GENERAL_DIARY_DETAIL_FAIL: '/client/diary/general/LOAD_FAIL',

    CLEAR_CLIENT_GENERAL_DIARY_DETAIL: '/client/diary/general/data/CLEAR',

    /*
     * MOVEMENTS
     */
    GET_MOVEMENTS: '/diary/movements/LOAD',
    GET_MOVEMENTS_SUCCESS: '/diary/movements/LOAD_SUCCESS',
    GET_MOVEMENTS_FAIL: '/diary/movements/LOAD_FAIL',

    CLEAR_MOVEMENTS: '/diary/movements/CLEAR',

    CREATE_MOVEMENT: '/diary/movements/CREATE',
    CREATE_MOVEMENT_SUCCESS: '/diary/movements/CREATE_SUCCESS',
    CREATE_MOVEMENT_FAIL: '/diary/movements/CREATE_FAIL',

    UPDATE_MOVEMENT: '/diary/movements/UPDATE',
    UPDATE_MOVEMENT_SUCCESS: '/diary/movements/UPDATE_SUCCESS',
    UPDATE_MOVEMENT_FAIL: '/diary/movements/UPDATE_FAIL',

    REMOVE_MOVEMENT: '/diary/movements/REMOVE',
    REMOVE_MOVEMENT_SUCCESS: '/diary/movements/REMOVE_SUCCESS',
    REMOVE_MOVEMENT_FAIL: '/diary/movements/REMOVE_FAIL',

    GET_CLIENT_MOVEMENTS: '/client/diary/movements/LOAD',
    GET_CLIENT_MOVEMENTS_SUCCESS: '/client/diary/movements/LOAD_SUCCESS',
    GET_CLIENT_MOVEMENTS_FAIL: '/client/diary/movements/LOAD_FAIL',

    GET_CLIENT_MOVEMENT_DETAIL: '/client/diary/movements/date/LOAD',
    GET_CLIENT_MOVEMENT_DETAIL_SUCCESS:
        '/client/diary/movements/date/LOAD_SUCCESS',
    GET_CLIENT_MOVEMENT_DETAIL_FAIL: '/client/diary/movements/date/LOAD_FAIL',

    CLEAR_CLIENT_MOVEMENT_DETAIL: '/client/diary/movements/date/CLEAR',

    /*
     * FOOD
     */
    GET_FOOD_DIARY: '/diary/food/LOAD',
    GET_FOOD_DIARY_SUCCESS: '/diary/food/LOAD_SUCCESS',
    GET_FOOD_DIARY_FAIL: '/diary/food/LOAD_FAIL',

    CLEAR_FOOD_DIARY: '/diary/food/CLEAR',

    CREATE_FOOD_DIARY: '/diary/food/CREATE',
    CREATE_FOOD_DIARY_SUCCESS: '/diary/food/CREATE_SUCCESS',
    CREATE_FOOD_DIARY_FAIL: '/diary/food/CREATE_FAIL',

    UPDATE_FOOD_DIARY: '/diary/food/UPDATE',
    UPDATE_FOOD_DIARY_SUCCESS: '/diary/food/UPDATE_SUCCESS',
    UPDATE_FOOD_DIARY_FAIL: '/diary/food/UPDATE_FAIL',

    REMOVE_FOOD_DIARY: '/diary/food/REMOVE',
    REMOVE_FOOD_DIARY_SUCCESS: '/diary/food/REMOVE_SUCCESS',
    REMOVE_FOOD_DIARY_FAIL: '/diary/food/REMOVE_FAIL',

    GET_CLIENT_FOOD_DIARY: '/client/diary/food/LOAD',
    GET_CLIENT_FOOD_DIARY_SUCCESS: '/client/diary/food/LOAD_SUCCESS',
    GET_CLIENT_FOOD_DIARY_FAIL: '/client/diary/food/LOAD_FAIL',

    GET_CLIENT_FOOD_DIARY_DETAIL: '/client/diary/food/date/LOAD',
    GET_CLIENT_FOOD_DIARY_DETAIL_SUCCESS:
        '/client/diary/food/date/LOAD_SUCCESS',
    GET_CLIENT_FOOD_DIARY_DETAIL_FAIL: '/client/diary/food/date/LOAD_FAIL',

    CLEAR_CLIENT_FOOD_DIARY_DETAIL: '/client/diary/food/date/CLEAR',

    /*
     * FOOD ENTRY
     */
    DELETE_DIARY_FOOD_NOTE: '/diary/food/note/DELETE',
    DELETE_DIARY_FOOD_NOTE_SUCCESS: '/diary/food/note/DELETE_SUCCESS',
    DELETE_DIARY_FOOD_NOTE_FAIL: '/diary/food/note/DELETE_FAIL',

    DELETE_DIARY_FOOD_PICTURE: '/diary/food/picture/DELETE',
    DELETE_DIARY_FOOD_PICTURE_SUCCESS: '/diary/food/picture/DELETE_SUCCESS',
    DELETE_DIARY_FOOD_PICTURE_FAIL: '/diary/food/picture/DELETE_FAIL',

    UPDATE_DIARY_FOOD_NOTE: '/diary/food/note/UPDATE',
    UPDATE_DIARY_FOOD_NOTE_SUCCESS: '/diary/food/note/UPDATE_SUCCESS',
    UPDATE_DIARY_FOOD_NOTE_FAIL: '/diary/food/note/UPDATE_FAIL',

    CREATE_DIARY_FOOD_ENTRY: '/diary/food/entry/CREATE',
    CREATE_DIARY_FOOD_ENTRY_SUCCESS: '/diary/food/entry/CREATE_SUCCESS',
    CREATE_DIARY_FOOD_ENTRY_FAIL: '/diary/food/entry/CREATE_FAIL',

    /*
     * FEEDBACK
     */
    UPDATE_DIARY_FEEDBACK: '/diary/feedback/UPDATE',
    UPDATE_DIARY_FEEDBACK_SUCCESS: '/diary/feedback/UPDATE_SUCCESS',
    UPDATE_DIARY_FEEDBACK_FAIL: '/diary/feedback/UPDATE_FAIL'
};

const DIARY_CACHE_INTERVAL = 1000 * 60 * 5;

export const setDate = date => ({
    type: DiaryActions.SET_DATE,
    date
});

export const getDiary = () => (dispatch, getState) => {
    if (shouldRefreshStateData(getState().diary.diary, DIARY_CACHE_INTERVAL)) {
        return dispatch(fetchDiary());
    }
};

export const clearFullDiary = () => ({
    type: DiaryActions.CLEAR_ALL
});

export const fetchDiary = () => (dispatch, getState) =>
    dispatch({
        type: DiaryActions.GET_DIARY,
        payload: {
            request: {
                url: `/diary/entries?date=${getState().diary.date}`,
                method: 'GET'
            }
        }
    });

export const getClientDiary = () => (dispatch, getState) => {
    const { detail } = getState().clients;

    if (shouldRefreshStateData(detail.diary.diary, DIARY_CACHE_INTERVAL)) {
        return dispatch(fetchClientDiary(detail.id));
    }
};

export const fetchClientDiary = (id, page = 1) => ({
    type: DiaryActions.GET_CLIENT_DIARY,
    payload: {
        request: {
            url: `/clients/${id}/diary/entries`,
            method: 'GET'
        }
    }
});

export const updateDiary = diary => ({
    type: DiaryActions.UPDATE_DIARY,
    payload: {
        request: {
            url: `/diary/entries`,
            method: 'PUT',
            data: diary
        }
    }
});

export const clearDiary = () => ({
    type: DiaryActions.CLEAR_DIARY
});

export const getMovements = () => (dispatch, getState) => {
    if (
        shouldRefreshStateData(getState().diary.movements, DIARY_CACHE_INTERVAL)
    ) {
        return dispatch(fetchMovements());
    }
};

export const fetchMovements = () => (dispatch, getState) =>
    dispatch({
        type: DiaryActions.GET_MOVEMENTS,
        payload: {
            request: {
                url: `/diary/movements?date=${getState().diary.date}`,
                method: 'GET'
            }
        }
    });

export const getClientMovements = () => (dispatch, getState) => {
    const { detail } = getState().clients;

    if (shouldRefreshStateData(detail.diary.movements, DIARY_CACHE_INTERVAL)) {
        return dispatch(fetchClientMovements(detail.id));
    }
};

export const fetchClientMovements = (id, page = 1) => ({
    type: DiaryActions.GET_CLIENT_MOVEMENTS,
    payload: {
        request: {
            url: `/clients/${id}/diary/movements`,
            method: 'GET'
        }
    }
});

export const clearClientMovementDetail = () => ({
    type: DiaryActions.CLEAR_CLIENT_MOVEMENT_DETAIL
});

export const getClientMovementsForDate = date => (dispatch, getState) => {
    const { movementDetail } = getState().clients.detail.diary;

    if (
        shouldRefreshStateData(movementDetail, DIARY_CACHE_INTERVAL) ||
        !isStateDataForKey(movementDetail, 'date', date)
    ) {
        if (!isStateDataForKey(movementDetail, 'date', date)) {
            dispatch(clearClientMovementDetail());
        }

        return dispatch(fetchClientMovementsForDate(date));
    }
};

export const fetchClientMovementsForDate = date => (dispatch, getState) =>
    dispatch({
        type: DiaryActions.GET_CLIENT_MOVEMENT_DETAIL,
        payload: {
            request: {
                url: `/clients/${
                    getState().clients.detail.id
                }/diary/movements/${date}`,
                method: 'GET',
                date
            }
        }
    });

export const updateMovement = movement => ({
    type: movement.id
        ? DiaryActions.UPDATE_MOVEMENT
        : DiaryActions.CREATE_MOVEMENT,
    payload: {
        request: {
            url: movement.id
                ? `/diary/movements/${movement.id}`
                : '/diary/movements',
            method: movement.id ? 'PUT' : 'POST',
            data: movement,
            id: movement.id
        }
    }
});

export const deleteMovement = id => ({
    type: DiaryActions.REMOVE_MOVEMENT,
    payload: {
        request: {
            url: `/diary/movements/${id}`,
            method: 'DELETE',
            id
        }
    }
});

export const clearMovements = () => ({
    type: DiaryActions.CLEAR_MOVEMENTS
});

export const getFoodDiary = () => (dispatch, getState) => {
    if (shouldRefreshStateData(getState().diary.food, DIARY_CACHE_INTERVAL)) {
        return dispatch(fetchFoodDiary());
    }
};

export const fetchFoodDiary = () => (dispatch, getState) =>
    dispatch({
        type: DiaryActions.GET_FOOD_DIARY,
        payload: {
            request: {
                url: `/diary/food?date=${getState().diary.date}`,
                method: 'GET'
            }
        }
    });

export const clearClientGeneralDiaryDetail = () => ({
    type: DiaryActions.CLEAR_CLIENT_GENERAL_DIARY_DETAIL
});

export const getClientGeneralDiaryForDate = date => (dispatch, getState) => {
    const { diaryDetail } = getState().clients.detail.diary;

    if (
        shouldRefreshStateData(diaryDetail, DIARY_CACHE_INTERVAL) ||
        !isStateDataForKey(diaryDetail, 'date', date)
    ) {
        if (!isStateDataForKey(diaryDetail, 'date', date)) {
            dispatch(clearClientGeneralDiaryDetail());
        }

        return dispatch(fetchClientGeneralDiaryForDate(date));
    }
};

export const fetchClientGeneralDiaryForDate = date => (dispatch, getState) =>
    dispatch({
        type: DiaryActions.GET_CLIENT_GENERAL_DIARY_DETAIL,
        payload: {
            request: {
                url: `/clients/${
                    getState().clients.detail.id
                }/diary/entries/${date}`,
                method: 'GET',
                date
            }
        }
    });

export const getClientFoodDiary = () => (dispatch, getState) => {
    const { detail } = getState().clients;

    if (shouldRefreshStateData(detail.diary.food, DIARY_CACHE_INTERVAL)) {
        return dispatch(fetchClientFoodDiary(detail.id));
    }
};

export const fetchClientFoodDiary = id => ({
    type: DiaryActions.GET_CLIENT_FOOD_DIARY,
    payload: {
        request: {
            url: `/clients/${id}/diary/food`,
            method: 'GET'
        }
    }
});

export const clearClientFoodDiaryDetail = () => ({
    type: DiaryActions.CLEAR_CLIENT_FOOD_DIARY_DETAIL
});

export const getClientFoodDiaryForDate = date => (dispatch, getState) => {
    const { foodDetail } = getState().clients.detail.diary;

    if (
        shouldRefreshStateData(foodDetail, DIARY_CACHE_INTERVAL) ||
        !isStateDataForKey(foodDetail, 'date', date)
    ) {
        if (!isStateDataForKey(foodDetail, 'date', date)) {
            dispatch(clearClientFoodDiaryDetail());
        }

        return dispatch(fetchClientFoodDiaryForDate(date));
    }
};

export const fetchClientFoodDiaryForDate = date => (dispatch, getState) =>
    dispatch({
        type: DiaryActions.GET_CLIENT_FOOD_DIARY_DETAIL,
        payload: {
            request: {
                url: `/clients/${
                    getState().clients.detail.id
                }/diary/food/${date}`,
                method: 'GET',
                date
            }
        }
    });

export const createFoodDiary = nutrients => ({
    type: DiaryActions.CREATE_FOOD_DIARY,
    payload: {
        request: {
            url: `/diary/food/nutrients`,
            method: 'POST',
            data: { nutrients }
        }
    }
});

export const updateFoodDiary = nutrient => ({
    type: DiaryActions.UPDATE_FOOD_DIARY,
    payload: {
        request: {
            url: `/diary/food/nutrients/${nutrient.id}`,
            method: 'PUT',
            data: nutrient,
            id: nutrient.id
        }
    }
});

export const deleteFoodDiary = id => ({
    type: DiaryActions.REMOVE_FOOD_DIARY,
    payload: {
        request: {
            url: `/diary/food/nutrients/${id}`,
            method: 'DELETE',
            id
        }
    }
});

export const clearFoodDiary = () => ({
    type: DiaryActions.CLEAR_FOOD_DIARY
});

export const updateFeedback = (type, feedback) => (dispatch, getState) =>
    dispatch({
        type: DiaryActions.UPDATE_DIARY_FEEDBACK,
        payload: {
            request: {
                url: `/clients/${
                    getState().clients.detail.id
                }/diary/${type}/feedback`,
                method: 'PUT',
                data: {
                    feedback: [feedback]
                }
            }
        }
    });

export const addDiaryFoodPictures = pictures => dispatch =>
    Promise.all(
        pictures.map(pictureData =>
            request(dispatch(createDiaryFoodEntry(pictureData))).catch(
                err => err
            )
        )
    );

export const createDiaryFoodEntry = data => ({
    type: DiaryActions.CREATE_DIARY_FOOD_ENTRY,
    payload: {
        request: {
            data,
            url: '/diary/food/entries',
            method: 'POST'
        }
    }
});

export const deleteDiaryFoodNote = id => ({
    type: DiaryActions.DELETE_DIARY_FOOD_NOTE,
    payload: {
        request: {
            url: '/diary/food/entries/' + id,
            method: 'DELETE'
        }
    }
});

export const deleteDiaryFoodPicture = (id, pictureId) => ({
    type: DiaryActions.DELETE_DIARY_FOOD_PICTURE,
    payload: {
        request: {
            url: '/diary/food/entries/' + id + '/media/' + pictureId,
            method: 'DELETE'
        }
    }
});

export const updateDiaryFoodNote = (id, data) => ({
    type: DiaryActions.UPDATE_DIARY_FOOD_NOTE,
    payload: {
        request: {
            url: '/diary/food/entries/' + id,
            method: 'PUT',
            data
        }
    }
});
