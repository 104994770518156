import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import Card from '../../../../shared/card/Card';
import {
    getClientTreatment,
    updateClientTreatment
} from 'app/platform/actions/treatment';
import LoadingIcon from '../../../../shared/loading/LoadingIcon';
import TreatmentForm from './form/TreatmentForm';
import TreatmentInfo from './info/TreatmentInfo';
import TreatmentPlan from './mealplan/TreatmentPlan';
import ErrorView from '../../../../shared/view/ErrorView';
import GoalsContainer from '../goals/GoalsContainer';
import { isLoadingData } from '../../../../../../core/utils/data';

const mapStateToProps = state => {
    const { treatment } = state.clients.detail;
    const data = treatment ? treatment.data : null;

    return {
        shouldLoadData:
            !treatment || (!treatment.isLoading && !data && !treatment.error),
        treatment: data,
        isLoading: isLoadingData(treatment),
        mealplan: data ? data.meal_plan : null,
        error: treatment ? treatment.error : null,
        state: state
    };
};

const mapDispatchToProps = dispatch => {
    return {
        getTreatment: id => {
            return dispatch(getClientTreatment(id));
        },
        updateTreatment: (id, treatment) => {
            return dispatch(updateClientTreatment(id, treatment));
        }
    };
};

class TreatmentContainer extends Component {
    constructor(props) {
        super(props);
        this.state = {
            actions: {}
        };
    }

    componentDidMount() {
        if (this.props.shouldLoadData) {
            this.props.getTreatment(this.props.client.id);
        }
    }

    editTreatment = treatment => {
        // show or hide the delete modal
        this.setState({
            actions: { updateTreatment: treatment }
        });
    };

    onFinishAction = () => {
        // remove dialog
        this.setState({ actions: {} });
        // refetch data
        this.props.getTreatment(this.props.client.id);
    };

    render() {
        const { treatment } = this.props;

        return (
            <Fragment>
                {this.props.isLoading ? (
                    <Card className="mt-3 mb-3">
                        <LoadingIcon />
                    </Card>
                ) : this.props.error ? (
                    <Card>
                        <ErrorView error={this.props.error} />
                    </Card>
                ) : (
                    <Fragment>
                        <div className="row mb-5">
                            <div className="col-sm-8">
                                <GoalsContainer
                                    readOnly={this.props.readOnly}
                                    client={this.props.client}
                                />
                            </div>
                            <div className="col-sm-4 mt-3">
                                <TreatmentPlan
                                    mealplan={this.props.mealplan}
                                    client={this.props.client}
                                    onFinished={() => this.onFinishAction()}
                                    readOnly={this.props.readOnly}
                                />
                            </div>
                        </div>
                        <TreatmentInfo
                            onEditClicked={() => this.editTreatment(treatment)}
                            treatment={treatment}
                            readOnly={this.props.readOnly}
                        />
                    </Fragment>
                )}
                {this.state.actions.updateTreatment && (
                    <TreatmentForm
                        client={this.props.client}
                        treatment={this.state.actions.updateTreatment}
                        onDismissClicked={() => this.editTreatment(null)}
                        onFinished={this.onFinishAction}
                    />
                )}
            </Fragment>
        );
    }
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(TreatmentContainer);
