import { isVoid } from '@dive/utils-js';
import { t } from '@dive/localization-js';
import moment from 'moment';
import React, { Component } from 'react';

import { convertBytes } from '../../../../core/utils/convert-bytes';
import Card from '../../shared/card/Card';
import DropDown from '../../shared/dropdown/DropDown';
import DropDownMenu from '../../shared/dropdown/DropDownMenu';
import DropDownToggle from '../../shared/dropdown/DropDownToggle';
import Icon from '../../shared/icon/Icon';
import Receiver from '../../shared/receiver/Receiver';
import EmptyView from '../../shared/view/EmptyView';
import FilePreview from '../preview/FilePreview';
import PublishStatus from '../status/PublishStatus';
import DeleteAlertModal from '../../shared/modal/DeleteAlertModal';

class LibraryDetail extends Component {
    state = {
        receivers: []
    };

    // ///////////////////////////////////////////////////////////////////////
    // LIFECYCLE METHODS
    // ///////////////////////////////////////////////////////////////////////

    static getDerivedStateFromProps(nextProps, prevState) {
        if (nextProps.receivers === prevState.receivers) return null;

        const clients = nextProps.entry.receivers.clients.map(client => ({
            type: 'clients',
            ...client
        }));

        const categories = nextProps.entry.receivers.categories.map(cat => ({
            type: 'categories',
            ...cat
        }));

        const receivers = clients
            .concat(categories)
            .sort((a, b) => {
                if (isVoid(a.published_at)) {
                    return 1;
                }

                return a.published_at.localeCompare(b.published_at);
            })
            .reverse();

        return { receivers };
    }

    // ///////////////////////////////////////////////////////////////////////
    // EVENT HANDLERS
    // ///////////////////////////////////////////////////////////////////////

    handleDelete = () => {
        return this.props.onDelete(this.state.delete);
    };

    // ///////////////////////////////////////////////////////////////////////
    // OTHERS
    // ///////////////////////////////////////////////////////////////////////

    hideAlert = () => {
        this.setState({ delete: null });
    };

    showAlert = receiver => {
        this.setState({ delete: receiver });
    };

    render() {
        const { entry, onEdit, onPreview } = this.props;
        const { delete: del, receivers } = this.state;

        const { file, tags } = entry;

        const li = receivers.map(r => (
            <li className="row py-3 text-muted" key={r.id}>
                <span className="col-6 col-sm mb-2 mb-sm-0">
                    <Receiver className="m-0">{r}</Receiver>
                </span>

                <PublishStatus className="col-6 col-sm">
                    {isVoid(r.published_at)
                        ? isVoid(r.delay_days)
                            ? ''
                            : 'delayed'
                        : r.published_at}
                </PublishStatus>

                <time className="col-6 col-sm">
                    {isVoid(r.published_at)
                        ? isVoid(r.delay_days)
                            ? ''
                            : t('digitalcoaching.list.delay') +
                              ': ' +
                              r.delay_days
                        : moment(r.published_at).format('DD/MM - HH:mm')}
                </time>

                <DropDown className="ml-auto px-3">
                    <DropDownToggle className="btn btn-icon">
                        <Icon name="overflow" />
                    </DropDownToggle>

                    <DropDownMenu>
                        <button
                            className="dropdown-item"
                            onClick={() => onEdit(r)}>
                            <Icon name="edit" /> {t('library.edit')}
                        </button>

                        <button
                            className="dropdown-item btn-alert"
                            onClick={() => this.showAlert(r)}>
                            <Icon name="unlink" /> {t('library.unlink')}
                        </button>
                    </DropDownMenu>
                </DropDown>
            </li>
        ));

        return (
            <>
                <div className="row">
                    <section className="col-xl-3 mb-2">
                        <h2>{t('library.detail.file')}</h2>

                        <Card>
                            <button
                                onClick={onPreview}
                                className="btn btn-light border-0 d-block w-100">
                                <FilePreview className="mw-100 py-2">
                                    {file}
                                </FilePreview>
                            </button>

                            <dl className="mt-3">
                                <dt>{t('library.detail.file')}</dt>
                                <dd>{file.file_name}</dd>

                                <dt>{t('library.detail.file.type')}</dt>
                                <dd>{t('library.type.' + file.type)}</dd>

                                <dt>{t('library.detail.file.size')}</dt>
                                <dd>{convertBytes(file.size)}</dd>

                                {tags.length > 0 && (
                                    <>
                                        <dt>
                                            {t('digitalcoaching.list.tags')}
                                        </dt>
                                        <dd>
                                            {tags
                                                .map(tag => tag.name)
                                                .join(', ')}
                                        </dd>
                                    </>
                                )}
                            </dl>
                        </Card>
                    </section>

                    <section className="col-xl">
                        <h2>{t('library.detail.links')}</h2>

                        <Card padding={false}>
                            {receivers.length === 0 ? (
                                <EmptyView
                                    className="mb-0"
                                    label={t('library.detail.empty')}
                                />
                            ) : (
                                <ol className="bordered-list p-3 mb-0">{li}</ol>
                            )}
                        </Card>
                    </section>
                </div>

                {!isVoid(del) && (
                    <DeleteAlertModal
                        title={t('library.link.delete.title')}
                        description={t('library.link.delete.description')}
                        onDelete={this.handleDelete}
                        onDismissClicked={this.hideAlert}
                        onFinished={this.hideAlert}
                    />
                )}
            </>
        );
    }
}

export default LibraryDetail;
