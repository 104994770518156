import { shouldRefreshStateData } from '../../../core/utils/data';

export const UserFieldsActions = {
    GET_USER_FIELDS: '/user/fields/LOAD',
    GET_USER_FIELDS_SUCCESS: '/user/fields/LOAD_SUCCESS',
    GET_USER_FIELDS_FAIL: '/user/fields/LOAD_FAIL',

    CLEAR_USER_FIELDS: '/user/fields/CLEAR',

    CREATE_USER_FIELD: '/user/fields/CREATE',
    CREATE_USER_FIELD_SUCCESS: '/user/fields/CREATE_SUCCESS',
    CREATE_USER_FIELD_FAIL: '/user/fields/CREATE_FAIL',

    UPDATE_USER_FIELD: '/user/fields/UPDATE',
    UPDATE_USER_FIELD_SUCCESS: '/user/fields/UPDATE_SUCCESS',
    UPDATE_USER_FIELD_FAIL: '/user/fields/UPDATE_FAIL',

    REMOVE_USER_FIELD: '/user/fields/REMOVE',
    REMOVE_USER_FIELD_SUCCESS: '/user/fields/REMOVE_SUCCESS',
    REMOVE_USER_FIELD_FAIL: '/user/fields/REMOVE_FAIL'
};

const USER_FIELDS_CACHE_INTERVAL = 300000;

export const getUserFields = model => (dispatch, getState) => {
    if (
        shouldRefreshStateData(
            getState().userFields[model],
            USER_FIELDS_CACHE_INTERVAL
        )
    ) {
        return dispatch(fetchUserFields(model));
    }
};

export const fetchUserFields = model => ({
    model,
    type: UserFieldsActions.GET_USER_FIELDS,
    payload: {
        request: {
            url: `/user/fields/${model}`,
            method: 'GET',
            model
        }
    }
});

export const updateUserField = field => ({
    type: field.id
        ? UserFieldsActions.UPDATE_USER_FIELD
        : UserFieldsActions.CREATE_USER_FIELD,
    payload: {
        request: {
            url: field.id ? `/user/fields/${field.id}` : '/user/fields',
            method: field.id ? 'PUT' : 'POST',
            data: field,
            id: field.id
        }
    }
});

export const deleteUserField = id => ({
    type: UserFieldsActions.REMOVE_USER_FIELD,
    payload: {
        request: {
            url: `/user/fields/${id}`,
            method: 'DELETE',
            id
        }
    }
});
