import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { t } from '@dive/localization-js';
import { getCoachings } from 'core/actions/coaching';
import ContentHeader from 'app/components/shared/content/ContentHeader';
import ContentBody from 'app/components/shared/content/ContentBody';
import RoundButton from '../shared/buttons/RoundButton';
import DigitalCoachingOverviewContainer from './overview/DigitalCoachingOverviewContainer';
import { fetchCoachings, setFilters } from '../../../core/actions/coaching';
import { isLoadingData } from '../../../core/utils/data';
import DateRangePicker from '../shared/datepicker/DateRangePicker';
import IconButton from '../shared/buttons/IconButton';

const mapStateToProps = state => {
    const { data } = state.coaching;

    return {
        isLoading: isLoadingData(state.coaching),
        coachings: data ? data.coaching : null,
        categories: state.categories.data,
        tags: state.tags.data,
        error: state.coaching.error,
        filters: state.coaching.filters,
        pagination: {
            currentPage: data ? data.page : null,
            pageSize: data ? data.page_size : null,
            count: data ? data.count : null
        }
    };
};

const mapDispatchToProps = dispatch => {
    return {
        checkCoachings: () => dispatch(getCoachings()),
        fetchCoachings: page => dispatch(fetchCoachings(page)),
        setFilters: filters => dispatch(setFilters(filters))
    };
};

class DigitalCoachingContainer extends Component {
    overviewContainer = null;

    addCoaching = () => {
        this.overviewContainer.addCoaching();
    };

    componentDidMount() {
        this.props.checkCoachings();
    }

    componentDidUpdate(prevProps) {
        this.props.checkCoachings();
    }

    fetchData = page => {
        this.props.fetchCoachings(page);
    };

    onFilterChanged = (filter, e) => {
        // set filter
        const filters = Object.assign({}, this.props.filters, filter);
        // blur input
        if (e) {
            e.target.blur();
        }

        this.props.setFilters(filters);
    };

    hasFilter = () => {
        return (
            !!this.props.filters.category ||
            !!this.props.filters.tag ||
            !!this.props.filters.endDate ||
            !!this.props.filters.startDate
        );
    };

    handleRef = ref => {
        this.overviewContainer = ref ? ref : null;
    };

    clearFilters = () => {
        // clear filter values
        this.props.setFilters({});
    };

    render() {
        return (
            <Fragment>
                <ContentHeader>
                    <div className="d-flex flex-wrap justify-content-between">
                        <h1 className="mr-5 mb-3">{t('digitalcoaching')}</h1>
                        <RoundButton
                            icon="add"
                            className="btn-round mb-auto"
                            onClick={this.addCoaching}
                            label={t('digitalcoaching.add')}
                        />
                    </div>
                    <div className="d-flex align-items-start align-items-sm-center mt-2">
                        <p className="d-none d-md-block m-0 p-0">{`${t(
                            'general.filter'
                        )}:`}</p>
                        <form className="form-filter ml-md-2 flex-column flex-md-row flex-wrap flex-sm-nowrap">
                            <select
                                className="form-control"
                                value={this.props.filters.category || ''}
                                onChange={e =>
                                    this.onFilterChanged(
                                        { category: e.target.value },
                                        e
                                    )
                                }>
                                <option>
                                    {t('digitalcoaching.filter.category')}
                                </option>
                                {this.props.categories &&
                                    this.props.categories.map(category => (
                                        <option
                                            key={category.id}
                                            value={category.id}>
                                            {category.name}
                                        </option>
                                    ))}
                            </select>
                            {this.props.tags && this.props.tags.length > 0 && (
                                <select
                                    className="form-control"
                                    value={this.props.filters.tag || ''}
                                    onChange={e =>
                                        this.onFilterChanged(
                                            { tag: e.target.value },
                                            e
                                        )
                                    }>
                                    <option>
                                        {t('digitalcoaching.filter.tag')}
                                    </option>
                                    {this.props.tags.map(tag => (
                                        <option key={tag.id} value={tag.id}>
                                            {tag.name}
                                        </option>
                                    ))}
                                </select>
                            )}
                            <div className="d-flex align-items-center">
                                <DateRangePicker
                                    className="flex-column flex-sm-row mt-2 mt-md-0 ml-0 ml-md-2"
                                    onDateChange={range =>
                                        this.onFilterChanged(range)
                                    }
                                    startDate={this.props.filters.startDate}
                                    endDate={this.props.filters.endDate}
                                />
                                {this.hasFilter() && (
                                    <IconButton
                                        className="ml-2 mt-2 mt-sm-1"
                                        icon={'clear'}
                                        onClick={this.clearFilters}
                                    />
                                )}
                            </div>
                        </form>
                    </div>
                </ContentHeader>
                <ContentBody>
                    <DigitalCoachingOverviewContainer
                        isLoading={this.props.isLoading}
                        coachings={this.props.coachings}
                        pagination={this.props.pagination}
                        error={this.props.error}
                        onFetch={this.fetchData}
                        hasFilter={this.hasFilter()}
                        ref={this.handleRef}
                    />
                </ContentBody>
            </Fragment>
        );
    }
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(DigitalCoachingContainer);
