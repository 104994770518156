import Card from 'app/components/shared/card/Card';
import DigitalCoachingItems from './DigitalCoachingItems';
import DropDown from '../../shared/dropdown/DropDown';
import DropDownMenu from '../../shared/dropdown/DropDownMenu';
import DropDownToggle from '../../shared/dropdown/DropDownToggle';
import Icon from '../../shared/icon/Icon';
import IconButton from 'app/components/shared/buttons/IconButton';
import PropTypes from 'prop-types';
import React from 'react';
import ReceiverList from '../../shared/list/ReceiverList';
import { getEmoji } from '../../../../core/models/coaching';
import moment from 'moment/moment';
import { t } from '@dive/localization-js';

const DigitalCoachingCard = ({
    readOnly,
    coaching,
    showDelete = false,
    showReceiver = true,
    onEditClicked,
    onDeleteClicked,
    onDuplicateClicked,
    onImageClick
}) => (
    <div className="col-sm-12 col-lg-4 mb-3">
        <Card className={'h-100 ' + (coaching.disabled ? 'disabled' : '')}>
            <div className="d-flex justify-content-between">
                {coaching.scheduled ? (
                    <h3>
                        {moment(coaching.scheduled).format('DD/MM/YYYY')}

                        <span className="extra-info ml-2">
                            -{' '}
                            {moment(coaching.scheduled).format(
                                `HH[${t('general.units.hour')}]mm`
                            )}
                        </span>
                    </h3>
                ) : (
                    <h3>
                        {t('digitalcoaching.list.delay')}: {coaching.delay_days}
                    </h3>
                )}

                <div className="btn-group">
                    {!coaching.sent && !readOnly && (
                        <IconButton
                            icon="edit"
                            onClick={() => onEditClicked(coaching)}
                        />
                    )}

                    {(showDelete || !readOnly) && (
                        <DropDown className="d-inline">
                            <DropDownToggle className="btn btn-icon">
                                <Icon name="overflow" />
                            </DropDownToggle>

                            <DropDownMenu>
                                {!readOnly && (
                                    <button
                                        className="dropdown-item"
                                        onClick={() =>
                                            onDuplicateClicked(coaching)
                                        }>
                                        <Icon name="duplicate" />{' '}
                                        {t('general.duplicate')}
                                    </button>
                                )}

                                {!showDelete && !coaching.sent && (
                                    <div className="dropdown-divider" />
                                )}

                                {(showDelete || !coaching.sent) && (
                                    <button
                                        className="dropdown-item btn-alert"
                                        onClick={() =>
                                            onDeleteClicked(coaching)
                                        }>
                                        <Icon name="delete" />{' '}
                                        {t('general.remove')}
                                    </button>
                                )}
                            </DropDownMenu>
                        </DropDown>
                    )}
                </div>
            </div>

            {showReceiver && (
                <div className="d-flex mt-2">
                    <span className="label mr-2">{`${t(
                        'digitalcoaching.list.to'
                    )}:`}</span>

                    <ReceiverList className="d-flex flex-grow-1 flex-wrap">
                        {coaching.receiver}
                    </ReceiverList>
                </div>
            )}

            <div className="divider mt-3 mb-3" />

            <div className="d-flex">
                <DigitalCoachingItems
                    className="flex-grow-1 m-0"
                    coaching={coaching}
                    onImageClick={onImageClick}
                />

                {coaching.emotion && (
                    <span className="emoji mt-1">{getEmoji(coaching)}</span>
                )}
            </div>
        </Card>
    </div>
);

DigitalCoachingCard.propTypes = {
    coaching: PropTypes.object.isRequired,
    showReceiver: PropTypes.bool,
    onEditClicked: PropTypes.func.isRequired,
    onDeleteClicked: PropTypes.func.isRequired
};

export default DigitalCoachingCard;
