import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { t } from '@dive/localization-js';
import { request } from '@dive/react-redux-networking';

import { updateUserField } from '../../../../../platform/actions/fields';
import DynamicFormDialog from '../../../../shared/forms/DynamicFormDialog';
import { DiagnosisTypes } from '../../../../clients/detail/general/ClientGeneralTab';

const mapDispatchToProps = dispatch => {
    return {
        saveUserField: field => {
            return request(dispatch(updateUserField(field)));
        }
    };
};

class UserFieldForm extends Component {
    constructor(props) {
        super(props);

        this.setFields();
    }

    setFields() {
        this.fields = [
            {
                name: 'name',
                label: t('settings.user_fields.name'),
                type: 'string',
                required: true,
                size: '12'
            },
            {
                name: 'group',
                label: t('settings.user_fields.group'),
                type: 'select',
                required: true,
                size: '12',
                options: [
                    {
                        name: t('clients.detail.general.diagnose.general'),
                        value: DiagnosisTypes.GENERAL
                    },
                    {
                        name: t('clients.detail.general.diagnose.food'),
                        value: DiagnosisTypes.FOOD
                    }
                ]
            },
            {
                name: 'type',
                label: t('settings.user_fields.type'),
                type: 'select',
                required: true,
                size: '12',
                options: [
                    {
                        name: t('settings.user_fields.type.string'),
                        value: 'string'
                    },
                    {
                        name: t('settings.user_fields.type.text'),
                        value: 'text'
                    },
                    {
                        name: t('settings.user_fields.type.number'),
                        value: 'number'
                    },
                    {
                        name: t('settings.user_fields.type.checkbox'),
                        value: 'checkbox'
                    }
                ]
            }
        ];

        if (this.props.field.model !== 'client') {
            this.fields = this.fields.filter(field => field.name !== 'group');
            this.props.field.group = 'general';
        }
    }

    render() {
        return this.props.field ? (
            <DynamicFormDialog
                title={t(
                    this.props.field.id
                        ? 'settings.user_fields.edit'
                        : 'settings.user_fields.add'
                )}
                model={this.props.field}
                onSaveForm={this.props.saveUserField}
                fields={this.fields}
                onDismissClicked={this.props.onDismissClicked}
                onFinished={this.props.onFinished}
            />
        ) : null;
    }
}

UserFieldForm.propTypes = {
    field: PropTypes.object.isRequired,
    onDismissClicked: PropTypes.func.isRequired,
    onFinished: PropTypes.func.isRequired
};

export default connect(
    undefined,
    mapDispatchToProps
)(UserFieldForm);
