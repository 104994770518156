import React from 'react';
import { t } from '@dive/localization-js';

export default function ReadNotification({ read = true }) {
    return (
        !read && (
            <div title={t('general.unread')} className="text-center">
                <div className="read"></div>
            </div>
        )
    );
}
