import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { t } from '@dive/localization-js';
import { request } from '@dive/react-redux-networking';

import Card from '../../shared/card/Card';
import SortableTable from '../../shared/table/SortableTable';
import Icon from '../../shared/icon/Icon';
import RoundButton from '../../shared/buttons/RoundButton';
import LoadingIcon from '../../shared/loading/LoadingIcon';
import DeleteAlertModal from '../../shared/modal/DeleteAlertModal';
import {
    deleteCategory,
    fetchCategories,
    getCategories
} from '../../../platform/actions/categories';
import CategoryForm from './form/CategoryForm';
import { isLoadingData } from '../../../../core/utils/data';
import ErrorView from '../../shared/view/ErrorView';
import EmptyView from '../../shared/view/EmptyView';

const mapStateToProps = state => {
    return {
        isLoading: isLoadingData(state.categories),
        error: state.categories.error,
        categories: state.categories.data
    };
};

const mapDispatchToProps = dispatch => {
    return {
        getCategories: () => {
            return dispatch(getCategories());
        },
        fetchCategories: () => {
            return dispatch(fetchCategories());
        },
        deleteCategory: id => {
            return request(dispatch(deleteCategory(id)));
        }
    };
};

class SettingsCategoriesTab extends Component {
    constructor(props) {
        super(props);
        this.state = {
            actions: {
                deleteCategory: null,
                updateCategory: null
            },
            errors: {}
        };
        this.columns = [
            {
                title: t('settings.categories.list.name'),
                key: 'name',
                sortable: false
            },
            {
                title: t('settings.categories.list.actions'),
                key: '',
                sortable: false
            }
        ];
    }

    componentDidMount() {
        this.props.getCategories();
    }

    componentDidUpdate(prevProps) {
        this.props.getCategories();
    }

    addCategory = () => {
        this.editCategory({});
    };

    deleteCategory(category) {
        // show or hide the delete modal
        this.setState({
            actions: { deleteCategory: category }
        });
    }

    editCategory(category) {
        this.setState({
            actions: { updateCategory: category }
        });
    }

    onFinishAction() {
        // remove dialog
        this.setState({ actions: {} });
        // refetch data
        this.props.fetchCategories();
    }

    render() {
        return (
            <Fragment>
                {this.props.isLoading ? (
                    <LoadingIcon />
                ) : (
                    <Fragment>
                        <div className="d-flex align-items-center mb-3">
                            <RoundButton
                                icon="add"
                                className="btn-round ml-auto"
                                onClick={this.addCategory}
                                label={t('settings.categories.add')}
                            />
                        </div>
                        <Card
                            padding={
                                !this.props.categories ||
                                this.props.categories.length === 0
                            }>
                            {this.props.categories &&
                            this.props.categories.length > 0 ? (
                                <SortableTable
                                    columns={this.columns}
                                    isLoading={this.props.isLoading}>
                                    {this.props.categories.map(category => (
                                        <tr key={category.id}>
                                            <td>
                                                <span className="font-weight-semi-bold">
                                                    {category.name}
                                                </span>
                                                <span>{` (${t(
                                                    'settings.categories.list.clients',
                                                    {
                                                        count:
                                                            category.clients_count
                                                    }
                                                )})`}</span>
                                            </td>
                                            <td>
                                                <div className="d-flex">
                                                    <button
                                                        className="btn btn-icon"
                                                        onClick={() =>
                                                            this.editCategory(
                                                                category
                                                            )
                                                        }>
                                                        <Icon name="edit" />
                                                    </button>
                                                    <button
                                                        className="btn btn-icon btn-alert ml-2"
                                                        onClick={() =>
                                                            this.deleteCategory(
                                                                category
                                                            )
                                                        }>
                                                        <Icon name="delete" />
                                                    </button>
                                                </div>
                                            </td>
                                        </tr>
                                    ))}
                                </SortableTable>
                            ) : this.props.error ? (
                                <ErrorView error={this.props.error} />
                            ) : (
                                <EmptyView
                                    label={t('settings.categories.list.empty')}
                                />
                            )}
                        </Card>
                    </Fragment>
                )}

                {this.state.actions.updateCategory && (
                    <CategoryForm
                        category={this.state.actions.updateCategory}
                        onDismissClicked={() => this.editCategory(null)}
                        onFinished={() => this.onFinishAction()}
                    />
                )}
                {this.state.actions.deleteCategory && (
                    <DeleteAlertModal
                        title={t('settings.categories.delete.title')}
                        description={t(
                            'settings.categories.delete.description'
                        )}
                        onDelete={() =>
                            this.props.deleteCategory(
                                this.state.actions.deleteCategory.id
                            )
                        }
                        onDismissClicked={() => this.deleteCategory(null)}
                        onFinished={() => this.onFinishAction()}
                    />
                )}
            </Fragment>
        );
    }
}

SettingsCategoriesTab.propTypes = {};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(SettingsCategoriesTab);
