import React, { memo } from 'react';

const PageHeader = ({ before, children, className, title }) => {
    return (
        <div className={className}>
            {before ? children : null}

            <h1 className="mr-5 mb-3">{title}</h1>

            {before ? null : children}
        </div>
    );
};

export default memo(PageHeader);
