export const MAX_FILE_SIZE = 41943040; // 40 MB in B

export const DASHBOARD_FILTERS = [
    'consultations',
    'inactive-mobile-clients',
    'clients-unread-entries',
    'coaching-unreceived',
    'number-of-consultations',
    'number-of-new-clients',
    'number-of-active-clients',
    'clients-gender',
    'clients-age',
    'clients-categories'
];

export const CHART_COLORS = [
    '#2bc340',
    '#f5a302',
    '#fbf4be',
    '#c1dbe3',
    '#df928e',
    '#36453b',
    '#a9e5bb',
    '#af9164',
    '#e3170a',
    '#06aed5'
];

export const GRAPH_OPTIONS = {
    legend: {
        position: 'bottom',
        labels: {
            boxWidth: 10
        },
        padding: 20
    }
};
