import React from 'react';
import PropTypes from 'prop-types';

import Icon from '../icon/Icon';

class Pagination extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            currentPage: 0,
            totalPages: 0,
            pages: []
        };
    }

    componentDidMount() {
        this.setPageData();
    }

    componentDidUpdate(prevProps) {
        // check if we need to reload clients
        if (prevProps.currentPage !== this.props.currentPage) {
            this.setPageData();
        }
    }

    setPageData() {
        const totalPages = Math.ceil(this.props.count / this.props.pageSize);
        this.setState({
            currentPage: this.props.currentPage,
            totalPages: totalPages,
            pages: this.getPagination(this.props.currentPage, totalPages)
        });
    }

    getPagination(currentPage, totalPages) {
        let pagination = [];

        if (totalPages > 5) {
            if (3 < currentPage && currentPage < totalPages - 2) {
                // active page is in the middle of the pagination
                pagination = [
                    1,
                    '...',
                    currentPage - 1,
                    currentPage,
                    currentPage + 1,
                    '...',
                    totalPages
                ];
            } else {
                switch (currentPage) {
                    // first or second page is active
                    case 1:
                    case 2:
                        pagination = [
                            1,
                            2,
                            3,
                            '...',
                            totalPages - 1,
                            totalPages
                        ];
                        break;
                    // third page is active
                    case 3:
                        pagination = [1, 2, 3, 4, '...', totalPages];
                        break;
                    // second to last or last page is active
                    case totalPages - 1:
                    case totalPages:
                        pagination = [
                            1,
                            2,
                            '...',
                            totalPages - 2,
                            totalPages - 1,
                            totalPages
                        ];
                        break;
                    // third last page is active
                    case totalPages - 2:
                        pagination = [
                            1,
                            '...',
                            totalPages - 3,
                            totalPages - 2,
                            totalPages - 1,
                            totalPages
                        ];
                        break;

                    default:
                        break;
                }
            }
        } else {
            for (let i = 1; i <= totalPages; i++) {
                pagination.push(i);
            }
        }
        return pagination;
    }

    onPageClicked = page => {
        if (
            page > 0 &&
            page <= Math.ceil(this.props.count / this.props.pageSize)
        ) {
            this.setState({
                currentPage: page
            });
            this.props.onFetch(page);
        }
    };

    render() {
        return this.state.totalPages > 1 ? (
            <div className="pagination">
                <li
                    className={`page-item page-item-button ${
                        this.state.currentPage === 1 ? 'disabled' : ''
                    }`}>
                    <button
                        className="btn btn-icon"
                        onClick={() => this.onPageClicked(1)}>
                        <Icon name="page-first" />
                    </button>
                </li>
                <li
                    className={`page-item page-item-button ${
                        this.state.currentPage > 1 ? '' : 'disabled'
                    }`}>
                    <button
                        className="btn btn-icon"
                        onClick={() =>
                            this.onPageClicked(this.state.currentPage - 1)
                        }>
                        <Icon name="page-previous" />
                    </button>
                </li>
                {this.state.pages.map((page, index) =>
                    page !== '...' ? (
                        <li
                            className={`page-item page-item-page ${
                                this.state.currentPage === page ? 'active' : ''
                            }`}
                            key={`page-${index}`}>
                            <button
                                className="btn btn-page"
                                onClick={() => this.onPageClicked(page)}>
                                {page}
                            </button>
                        </li>
                    ) : (
                        <li
                            className="page-item page-item-page"
                            key={`dots-${index}`}>
                            ...
                        </li>
                    )
                )}
                <li
                    className={`page-item page-item-button ${
                        this.state.currentPage < this.state.totalPages
                            ? ''
                            : 'disabled'
                    }`}>
                    <button
                        className="btn btn-icon"
                        onClick={() =>
                            this.onPageClicked(this.state.currentPage + 1)
                        }>
                        <Icon name="page-next" />
                    </button>
                </li>
                <li
                    className={`page-item page-item-button ${
                        this.state.currentPage === this.state.totalPages
                            ? 'disabled'
                            : ''
                    }`}>
                    <button
                        className="btn btn-icon"
                        onClick={() =>
                            this.onPageClicked(this.state.totalPages)
                        }>
                        <Icon name="page-last" />
                    </button>
                </li>
            </div>
        ) : null;
    }
}

Pagination.propTypes = {
    currentPage: PropTypes.number.isRequired,
    pageSize: PropTypes.number.isRequired,
    count: PropTypes.number.isRequired,
    onFetch: PropTypes.func.isRequired
};

export default Pagination;
