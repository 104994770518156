import { CHART_COLORS, GRAPH_OPTIONS } from '../../../../../platform/constants';

import { Line } from 'react-chartjs-2';
import React from 'react';
import moment from 'moment';

const GRAPH_DATA = {
    fill: false,
    lineTension: 0.1,
    borderColor: '',
    borderCapStyle: 'butt',
    borderDash: [],
    borderDashOffset: 0.0,
    borderJoinStyle: 'miter',
    pointBackgroundColor: '#fff',
    pointBorderWidth: 1,
    pointHoverRadius: 5,
    pointHoverBorderColor: 'rgba(220,220,220,1)',
    pointHoverBorderWidth: 2,
    backgroundColor: 'transparent'
};

const setGraphData = (data, title) => {
    const datasets = data.datasets.map((dataset, key) => {
        return Object.assign({}, GRAPH_DATA, {
            label: dataset.label ? dataset.label : title,
            data: dataset.data,
            borderColor: CHART_COLORS[key],
            backgroundColor: CHART_COLORS[key]
        });
    });
    data.datasets = datasets;
};

const setOptions = (numberOfDataSets, title) => {
    let legend = null;

    if (numberOfDataSets > 1) {
        legend = { ...GRAPH_OPTIONS.legend };
    }

    return {
        legend: legend,
        scales: {
            xAxes: [
                {
                    type: 'time',
                    stacked: false,
                    ticks: {
                        time: {
                            unit: 'day',
                            stepSize: 7,
                            tooltipFormat: 'll'
                        }
                    }
                }
            ],
            yAxes: [
                {
                    ticks: {
                        beginAtZero: true,
                        precision: 0
                    }
                }
            ]
        },
        tooltips: {
            callbacks: {
                title: function(tooltipItem) {
                    return moment(tooltipItem[0].label).format('DD/MM/YYYY');
                },
                label: function(tooltipItem) {
                    return `${title}: ${tooltipItem.yLabel}`;
                }
            }
        }
    };
};

export default function GraphCard({ data, title, className }) {
    setGraphData(data, title);
    return (
        <div className={className}>
            <Line
                data={data}
                options={setOptions(data.datasets.length, title)}
            />
        </div>
    );
}
