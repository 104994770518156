import React, { Fragment } from 'react';

import Card from '../../shared/card/Card';
import DigitalCoachingCard from '../components/DigitalCoachingCard';
import EmptyView from '../../shared/view/EmptyView';
import ErrorView from '../../shared/view/ErrorView';
import Icon from '../../shared/icon/Icon';
import LoadingIcon from '../../shared/loading/LoadingIcon';
import Pagination from '../../shared/pagination/Pagination';
import PropTypes from 'prop-types';
import { t } from '@dive/localization-js';

const DigitalCoachingOverview = ({
    readOnly,
    isLoading,
    coachings,
    error,
    showReceiver = true,
    hasFilter = false,
    onEditClicked,
    onImageClick,
    onDeleteClicked,
    onDuplicateClicked,
    pagination,
    onFetch
}) => {
    return isLoading ? (
        <LoadingIcon />
    ) : (
        <Fragment>
            {coachings ? (
                coachings.scheduled.length === 0 &&
                coachings.sent.length === 0 ? (
                    <Card padding={true}>
                        <EmptyView
                            label={t(
                                hasFilter
                                    ? 'digitalcoaching.list.empty.filter'
                                    : 'digitalcoaching.list.empty'
                            )}
                        />
                    </Card>
                ) : (
                    <Fragment>
                        {coachings.scheduled.length > 0 ? (
                            <Fragment>
                                <h2>
                                    <Icon name="scheduled" />{' '}
                                    {t('digitalcoaching.list.scheduled')}
                                </h2>
                                <div className="row mb-2">
                                    {coachings.scheduled.map(coaching => (
                                        <DigitalCoachingCard
                                            readOnly={
                                                readOnly || coaching.disabled
                                            }
                                            key={coaching.id}
                                            coaching={coaching}
                                            showDelete={!coaching.disabled}
                                            showReceiver={
                                                showReceiver ||
                                                coaching.disabled
                                            }
                                            onEditClicked={onEditClicked}
                                            onDeleteClicked={onDeleteClicked}
                                            onImageClick={onImageClick}
                                            onDuplicateClicked={
                                                onDuplicateClicked
                                            }
                                        />
                                    ))}
                                </div>
                            </Fragment>
                        ) : null}
                        {coachings.sent.length > 0 ? (
                            <Fragment>
                                <h2>
                                    <Icon name="sent" />{' '}
                                    {t('digitalcoaching.list.sent')}
                                </h2>
                                <div className="row mb-2">
                                    {coachings.sent.map(coaching => (
                                        <DigitalCoachingCard
                                            readOnly={readOnly}
                                            key={coaching.id}
                                            coaching={coaching}
                                            showReceiver={
                                                showReceiver ||
                                                coaching.disabled
                                            }
                                            onImageClick={onImageClick}
                                            onEditClicked={onEditClicked}
                                            onDeleteClicked={onDeleteClicked}
                                            onDuplicateClicked={
                                                onDuplicateClicked
                                            }
                                        />
                                    ))}
                                </div>
                            </Fragment>
                        ) : null}
                        <Pagination
                            currentPage={pagination.currentPage}
                            count={pagination.count}
                            pageSize={pagination.pageSize}
                            onFetch={onFetch}
                        />
                    </Fragment>
                )
            ) : error ? (
                <ErrorView error={error} />
            ) : null}
        </Fragment>
    );
};

DigitalCoachingOverview.propTypes = {
    isLoading: PropTypes.bool.isRequired,
    error: PropTypes.string,
    coachings: PropTypes.object,
    showReceiver: PropTypes.bool,
    hasFilter: PropTypes.bool,
    onEditClicked: PropTypes.func.isRequired,
    onDeleteClicked: PropTypes.func.isRequired,
    pagination: PropTypes.shape({
        currentPage: PropTypes.number,
        pageSize: PropTypes.number,
        count: PropTypes.number
    }),
    onFetch: PropTypes.func.isRequired
};

export default DigitalCoachingOverview;
