import React from 'react';
import PropTypes from 'prop-types';
import Icon from 'app/components/shared/icon/Icon';

const SubmitButton = ({
    label,
    type,
    classes,
    className = 'btn-primary',
    disabled,
    onClick
}) => {
    return (
        <button
            onClick={onClick ? () => onClick() : null}
            type={type}
            className={`btn btn-submit ${classes ? classes : ''} ${className}`}
            disabled={!!disabled}>
            {label} <Icon name="arrow-right" />{' '}
        </button>
    );
};

SubmitButton.propTypes = {
    label: PropTypes.string.isRequired
};

export default SubmitButton;
