import {
    ROUTE_CLIENTS_DETAIL_TAB_DIARY_GENERAL,
    ROUTE_CLIENTS_DETAIL_TAB_DIARY_GENERAL_DETAIL,
    history
} from '../../../../../../platform/routing';
import React, { Component, Fragment } from 'react';
import {
    fetchClientGeneralDiaryForDate,
    getClientGeneralDiaryForDate
} from '../../../../../../../core/actions/diary';

import BackButton from '../../../../../shared/buttons/BackButton';
import Card from '../../../../../shared/card/Card';
import EmptyView from '../../../../../shared/view/EmptyView';
import ErrorView from '../../../../../shared/view/ErrorView';
import FeedbackForm from '../../feedback/FeedbackForm';
import FeedbackView from '../../feedback/FeedbackView';
import LoadingIcon from '../../../../../shared/loading/LoadingIcon';
import PrevNextButtons from '../../navigation/PrevNextRow';
import ReadNotification from '../../../../../shared/read/ReadNotification';
import { connect } from 'react-redux';
import { isEmptyText } from '@dive/utils-js';
import { isLoadingData } from '../../../../../../../core/utils/data';
import moment from 'moment/moment';
import { selectClientDiaryDetailEntries } from '../../../../../../platform/model/client';
import { t } from '@dive/localization-js';

class ClientGeneralDiaryDetail extends Component {
    constructor(props) {
        super(props);

        this.state = {
            actions: {}
        };
    }

    componentDidMount() {
        // set data
        this.getData();
    }

    componentDidUpdate(prevProps) {
        this.getData();
    }

    getData() {
        this.props.getClientGeneralDiaryForDate(this.props.date);
    }

    getValue(value) {
        if (typeof value === 'boolean') {
            return t('general.' + (value ? 'yes' : 'no'));
        }

        return value;
    }

    editFeedback = (id, feedback) => {
        this.setState({
            actions: {
                updateFeedback: feedback
                    ? Object.assign({}, feedback, { id: id })
                    : null
            }
        });
    };

    onFinishAction() {
        // remove dialog
        this.setState({ actions: {} });
        // refresh consultation
        this.props.fetchClientGeneralDiaryForDate(this.props.date);
    }

    goToDetail = date => {
        history.push(
            ROUTE_CLIENTS_DETAIL_TAB_DIARY_GENERAL_DETAIL.replace(
                ':id',
                this.props.clientId
            ).replace(':date', moment(date).format('DDMMYYYY'))
        );
    };

    render() {
        return (
            <>
                <div className="d-flex justify-content-between flex-wrap">
                    <BackButton
                        label={t('diary.simple.detail.back')}
                        to={ROUTE_CLIENTS_DETAIL_TAB_DIARY_GENERAL.replace(
                            ':id',
                            this.props.clientId
                        )}
                    />
                    <PrevNextButtons
                        prev={this.props.prev}
                        next={this.props.next}
                        showDetail={this.goToDetail}
                    />
                </div>
                <div className="row mt-3">
                    <div className="col-sm-4 mb-3">
                        <h2>{t('diary.simple.detail.general')}</h2>
                        <Card>
                            <h3 className="mb-0">
                                {`${moment
                                    .utc(this.props.date)
                                    .format(`dddd`)} ${moment
                                    .utc(this.props.date)
                                    .format('DD/MM/YYYY')}`}
                            </h3>
                        </Card>
                    </div>
                    <div className="col-sm-8 mb-3">
                        <>
                            <h2>{t('diary.movement.detail.overview')}</h2>
                            <Card
                                padding={
                                    !(
                                        this.props.entries &&
                                        this.props.entries.length > 0
                                    )
                                }>
                                {this.props.isLoading ? (
                                    <LoadingIcon />
                                ) : this.props.error ? (
                                    <ErrorView error={this.props.error} />
                                ) : this.props.entries.length > 0 ? (
                                    <ul className="bordered-list mb-0 pt-1 pb-1">
                                        {this.props.entries.map(entry => {
                                            return (
                                                <Fragment key={entry.id}>
                                                    <li className="d-flex pb-2 pt-2 pl-3 pr-3">
                                                        <ReadNotification
                                                            read={entry.read}
                                                        />
                                                        <div
                                                            style={{
                                                                minWidth:
                                                                    '180px'
                                                            }}>
                                                            <p className="font-weight-semi-bold mb-0">
                                                                {isEmptyText(
                                                                    entry.key
                                                                )
                                                                    ? entry
                                                                          .field
                                                                          .name
                                                                    : t(
                                                                          `treatment.plan.simple_question_${entry.key}`
                                                                      )}
                                                            </p>
                                                            <span className="text-body-light">
                                                                {this.getValue(
                                                                    entry.value
                                                                )}
                                                            </span>
                                                        </div>
                                                        <FeedbackView
                                                            feedback={
                                                                entry.feedback
                                                            }
                                                            onEditClick={feedback =>
                                                                this.editFeedback(
                                                                    entry.id,
                                                                    feedback
                                                                )
                                                            }
                                                            className="ml-auto"
                                                        />
                                                    </li>
                                                </Fragment>
                                            );
                                        })}
                                    </ul>
                                ) : (
                                    <EmptyView
                                        label={t('diary.movement.detail.empty')}
                                    />
                                )}
                            </Card>
                        </>
                    </div>
                </div>
                {/* Create/edit feedback modal */}
                {this.state.actions.updateFeedback && (
                    <FeedbackForm
                        feedback={this.state.actions.updateFeedback}
                        type={'entries'}
                        onDismissClicked={() => this.editFeedback(null, null)}
                        onFinished={() => this.onFinishAction()}
                    />
                )}
            </>
        );
    }
}

const mapStateToProps = (state, props) => {
    const { diaryDetail } = state.clients.detail.diary;
    const data = diaryDetail ? diaryDetail.data : null;

    return {
        isLoading: isLoadingData(diaryDetail),
        entries: selectClientDiaryDetailEntries(state),
        prev: data ? data.prev_day : null,
        next: data ? data.next_day : null,
        error: diaryDetail.error,
        clientId: state.clients.detail.id,
        date: moment.utc(props.match.params.date, 'DDMMYYYY').format()
    };
};

const mapDispatchToProps = {
    getClientGeneralDiaryForDate,
    fetchClientGeneralDiaryForDate
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(ClientGeneralDiaryDetail);
