import { t } from '@dive/localization-js';
import { isVoid } from '@dive/utils-js';
import React from 'react';

import MultilineText from '../../shared/text/MultilineText';

const CoachingItem = ({ message, onImageClick, image, type }) => {
    if (isVoid(message) && isVoid(image)) {
        return null;
    }

    return (
        <>
            <dt>
                {t(
                    'digitalcoaching.list.' +
                        type +
                        (isVoid(message) ? '_image' : '')
                )}
            </dt>

            {!isVoid(message) && (
                <dd>
                    <MultilineText allowLinks>{message}</MultilineText>
                </dd>
            )}

            {!isVoid(image) && (
                <dd>
                    <button
                        onClick={() =>
                            onImageClick(
                                image.versions.large || image.versions.original
                            )
                        }
                        className="btn p-0">
                        <img
                            alt={t('digitalcoaching.list.' + type + '_image')}
                            src={image.versions['thumb-small']}
                            className="img-preview"
                        />
                    </button>
                </dd>
            )}
        </>
    );
};

export default CoachingItem;
