import PropTypes from 'prop-types';
import React from 'react';
import { NavLink } from 'react-router-dom';

import Icon from 'app/components/shared/icon/Icon';

const SidebarItem = ({ icon, label, link }) => (
    <li>
        <NavLink to={link}>
            <Icon name={icon} />

            <span className="label">{label}</span>
        </NavLink>
    </li>
);

SidebarItem.propTypes = {
    icon: PropTypes.string.isRequired,
    label: PropTypes.string.isRequired,
    link: PropTypes.string.isRequired
};

export default SidebarItem;
