import React, { Component, Fragment } from 'react';
import {
    fetchClientFoodDiary,
    getClientFoodDiary
} from '../../../../../../core/actions/diary';
import {
    getFormattedNumber,
    isLoadingData
} from '../../../../../../core/utils/data';

import Card from '../../../../shared/card/Card';
import EmptyView from '../../../../shared/view/EmptyView';
import ErrorView from '../../../../shared/view/ErrorView';
import LoadingIcon from '../../../../shared/loading/LoadingIcon';
import ReadNotification from 'app/components/shared/read/ReadNotification';
import SortableTable from '../../../../shared/table/SortableTable';
import { connect } from 'react-redux';
import { history } from '../../../../../platform/routing';
import moment from 'moment/moment';
import { t } from '@dive/localization-js';

const mapStateToProps = state => {
    const { food } = state.clients.detail.diary;
    const data = food ? food.data : null;
    return {
        isLoading: isLoadingData(food),
        diary: data ? data.nutrients : null,
        treatment: data ? data.treatment : null,
        userId: state.clients.detail.id,
        error: food.error
    };
};

const mapDispatchToProps = dispatch => {
    return {
        getClientFoodDiary: () => {
            dispatch(getClientFoodDiary());
        },
        fetchClientFoodDiary: id => {
            dispatch(fetchClientFoodDiary(id));
        }
    };
};

class ClientFoodOverview extends Component {
    constructor(props) {
        super(props);
        this.state = {
            errors: {}
        };
        this.columns = [
            {
                key: 'food-read',
                sortable: false,
                className: 'read-th'
            },
            {
                title: t('diary.general.list.date'),
                key: 'date',
                sortable: false
            },
            {
                title: t('diary.food.list.kcal'),
                key: 'kcal',
                sortable: false
            },
            {
                title: t('diary.food.list.fat'),
                key: 'fat',
                sortable: false
            },
            {
                title: t('diary.food.list.protein'),
                key: 'protein',
                sortable: false
            },
            {
                title: t('diary.food.list.carbs'),
                key: 'carbs',
                sortable: false
            }
        ];
    }

    componentDidMount() {
        this.props.getClientFoodDiary();
    }

    componentDidUpdate(prevProps) {
        if (prevProps !== this.props) {
            this.props.getClientFoodDiary();
        }
    }

    showFoodDetail(date, read) {
        history.push(
            `${this.props.location.pathname}/${moment(date).format('DDMMYYYY')}`
        );
    }

    getValueColumn = (log, count, total) => {
        return (
            <td>
                <span
                    className={
                        this.props.treatment &&
                        this.props.treatment[total] &&
                        this.props.treatment[total] > 0 &&
                        log[count] > this.props.treatment[total]
                            ? 'alert-color'
                            : ''
                    }>
                    {getFormattedNumber(log[count], 'nl')}
                </span>
                {this.props.treatment &&
                this.props.treatment[total] !== null &&
                this.props.treatment[total] > 0
                    ? ` / ${getFormattedNumber(
                          this.props.treatment[total],
                          'nl'
                      )}`
                    : ''}
            </td>
        );
    };

    render() {
        return this.props.isLoading ? (
            <LoadingIcon />
        ) : (
            <Fragment>
                <Card padding={false} className="mt-3">
                    {this.props.diary && this.props.diary.length > 0 ? (
                        <Fragment>
                            <SortableTable
                                columns={this.columns}
                                sortColumn={''}
                                order={''}
                                isLoading={this.props.isLoading}
                                onFetch={() => {}}>
                                {this.props.diary.map(log => {
                                    return (
                                        <tr
                                            className="tr-selectable"
                                            onClick={() =>
                                                this.showFoodDetail(
                                                    log.date,
                                                    log.read
                                                )
                                            }
                                            key={log.date}>
                                            <td className="read-td">
                                                <ReadNotification
                                                    read={log.read}
                                                />
                                            </td>
                                            <td>
                                                <span className="font-weight-semi-bold">
                                                    {moment(log.date).format(
                                                        `DD/MM/YYYY`
                                                    )}
                                                </span>
                                                <span className="pl-2">
                                                    (
                                                    {moment(log.date).format(
                                                        `dd`
                                                    )}
                                                    )
                                                </span>
                                            </td>
                                            {this.getValueColumn(
                                                log,
                                                'kcal_total_count',
                                                'kcal_total'
                                            )}
                                            {this.getValueColumn(
                                                log,
                                                'fat_count',
                                                'fat'
                                            )}
                                            {this.getValueColumn(
                                                log,
                                                'protein_count',
                                                'protein'
                                            )}
                                            {this.getValueColumn(
                                                log,
                                                'carbs_count',
                                                'carbs'
                                            )}
                                        </tr>
                                    );
                                })}
                            </SortableTable>
                        </Fragment>
                    ) : this.props.error ? (
                        <ErrorView error={this.props.error} />
                    ) : (
                        <EmptyView label={t('diary.food.list.empty')} />
                    )}
                </Card>
            </Fragment>
        );
    }
}

ClientFoodOverview.propTypes = {};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(ClientFoodOverview);
