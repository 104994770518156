import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { t } from '@dive/localization-js';
import { getClientDetail } from 'app/platform/actions/clients';
import {
    ROUTE_CLIENTS_DETAIL_TAB_CONSULTATIONS,
    ROUTE_CLIENTS_DETAIL_TAB_DIARY,
    ROUTE_CLIENTS_DETAIL_TAB_DIGITAL_COACHING,
    ROUTE_CLIENTS_DETAIL_TAB_EVOLUTION,
    ROUTE_CLIENTS_DETAIL_TAB_GENERAL,
    ROUTE_CLIENTS_DETAIL_TAB_TREATMENT,
    ROUTE_CLIENTS_DETAIL_TAB_LIBRARY
} from 'app/platform/routing';
import { Route, Switch } from 'react-router-dom';
import ClientGeneralTab from './general/ClientGeneralTab';
import ClientDigitalCoachingTab from './digital-coaching/ClientDigitalCoachingTab';
import LoadingIcon from 'app/components/shared/loading/LoadingIcon';
import ContentBody from 'app/components/shared/content/ContentBody';
import ClientConsultationsTab from './consultations/ClientConsultationsTab';
import ClientEvolutionTab from './evolution/ClientEvolutionTab';
import ClientTreatmentTab from './treatment/ClientTreatmentTab';
import ClientDiaryTab from './diary/ClientDiaryTab';
import ErrorView from '../../shared/view/ErrorView';
import ClientDetailHeader from './header/ClientDetailHeader';
import ClientLibraryTab from './library/ClientLibraryTab';

const mapStateToProps = (state, props) => {
    const { detail } = state.clients;
    return {
        // check if the current page id matches the user object we have
        client:
            detail.id === parseInt(props.match.params.id, 10)
                ? detail.data
                : null,
        error: detail.error,
        id: parseInt(props.match.params.id, 10)
    };
};

const mapDispatchToProps = dispatch => {
    return {
        getClient: id => {
            dispatch(getClientDetail(id));
        }
    };
};

/**
 * Client Detail Container
 */
class ClientDetailContainer extends Component {
    constructor(props) {
        super(props);
        this.TAB_ITEMS = [
            {
                label: t('clients.detail.tabs.general'),
                url: ROUTE_CLIENTS_DETAIL_TAB_GENERAL,
                exact: true
            },
            {
                label: t('clients.detail.tabs.treatment'),
                url: ROUTE_CLIENTS_DETAIL_TAB_TREATMENT
            },
            {
                label: t('clients.detail.tabs.consultations'),
                url: ROUTE_CLIENTS_DETAIL_TAB_CONSULTATIONS
            },
            {
                label: t('clients.detail.tabs.digitalcoaching'),
                url: ROUTE_CLIENTS_DETAIL_TAB_DIGITAL_COACHING
            },
            {
                label: t('clients.detail.tabs.library'),
                url: ROUTE_CLIENTS_DETAIL_TAB_LIBRARY
            },
            {
                label: t('clients.detail.tabs.diary'),
                url: ROUTE_CLIENTS_DETAIL_TAB_DIARY
            },
            {
                label: t('clients.detail.tabs.evolution'),
                url: ROUTE_CLIENTS_DETAIL_TAB_EVOLUTION
            }
        ];
    }

    componentDidMount() {
        this.fetchClient();
    }

    componentDidUpdate(prevProps) {
        this.fetchClient();
    }

    fetchClient() {
        this.props.getClient(this.props.id);
    }

    render() {
        const { client } = this.props;
        return (
            <Fragment>
                <ClientDetailHeader client={client} tabs={this.TAB_ITEMS} />
                <ContentBody>
                    {client && client.data ? (
                        /* TABS */
                        <Switch>
                            <Route
                                path={ROUTE_CLIENTS_DETAIL_TAB_TREATMENT}
                                render={props => (
                                    <ClientTreatmentTab
                                        client={client}
                                        readOnly={!client.is_active}
                                        {...props}
                                    />
                                )}
                            />
                            <Route
                                path={ROUTE_CLIENTS_DETAIL_TAB_EVOLUTION}
                                render={props => (
                                    <ClientEvolutionTab
                                        client={client}
                                        readOnly={!client.is_active}
                                        {...props}
                                    />
                                )}
                            />
                            <Route
                                path={ROUTE_CLIENTS_DETAIL_TAB_DIGITAL_COACHING}
                                render={props => (
                                    <ClientDigitalCoachingTab
                                        client={client}
                                        readOnly={!client.is_active}
                                        {...props}
                                    />
                                )}
                            />
                            <Route
                                path={ROUTE_CLIENTS_DETAIL_TAB_LIBRARY}
                                render={() => (
                                    <ClientLibraryTab
                                        client={client}
                                        readOnly={!client.is_active}
                                    />
                                )}
                            />
                            <Route
                                path={ROUTE_CLIENTS_DETAIL_TAB_CONSULTATIONS}
                                render={props => (
                                    <ClientConsultationsTab
                                        client={client}
                                        readOnly={!client.is_active}
                                        {...props}
                                    />
                                )}
                            />
                            <Route
                                path={ROUTE_CLIENTS_DETAIL_TAB_DIARY}
                                render={props => (
                                    <ClientDiaryTab
                                        client={client}
                                        readOnly={!client.is_active}
                                        {...props}
                                    />
                                )}
                            />
                            <Route
                                path={ROUTE_CLIENTS_DETAIL_TAB_GENERAL}
                                render={props => (
                                    <ClientGeneralTab
                                        client={client}
                                        readOnly={!client.is_active}
                                        {...props}
                                    />
                                )}
                            />
                        </Switch>
                    ) : this.props.error ? (
                        <ErrorView error={this.props.error} />
                    ) : (
                        <LoadingIcon />
                    )}
                </ContentBody>
            </Fragment>
        );
    }
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(ClientDetailContainer);
