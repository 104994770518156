export const getEmoji = coaching => {
    switch (coaching.emotion) {
        case 'thumbs_up':
            return '🙂';
        case 'thumbs_down':
            return '🙁';
        case 'medal':
            return '🏅';
        default:
            return '';
    }
};

export const getMessage = (coaching, type) => {
    return coaching[`message_${type}`];
};

export const getImage = (coaching, type) => {
    return coaching[`image_${type}`];
};

export const getMessagePreview = (coaching, type) => {
    return getMessage(coaching, type).length > 100
        ? getMessage(coaching, type).substring(0, 100) + '…'
        : getMessage(coaching, type);
};
