import Icon from '../icon/Icon';
import PropTypes from 'prop-types';
import React from 'react';

const Order = {
    ASC: 'asc',
    DESC: 'desc'
};

class SortableTable extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            activeColumn: null,
            order: null
        };
    }

    componentDidMount() {
        this.setSortData();
    }

    componentDidUpdate(prevProps) {
        // check if we need to reload clients
        if (
            prevProps.sortColumn !== this.props.sortColumn ||
            prevProps.order !== this.props.order
        ) {
            this.setSortData();
        }
    }

    setSortData() {
        this.setState({
            activeColumn: this.props.sortColumn,
            order: this.props.order
        });
    }

    onColumnClicked = key => {
        let activeColumn;
        let order;
        // set state
        if (
            this.state.activeColumn === key &&
            this.state.order === Order.DESC
        ) {
            activeColumn = null;
            order = null;
        } else {
            activeColumn = key;
            order =
                this.state.order === Order.ASC
                    ? Order.DESC
                    : this.state.order
                    ? null
                    : Order.ASC;
        }
        // fetch from API
        this.props.onFetch(activeColumn, order);
        // set state
        this.setState({
            activeColumn: activeColumn,
            order: order
        });
    };

    render() {
        return this.props.columns ? (
            <div className="table-responsive">
                <table
                    className={`table-sortable ${
                        this.props.isLoading ? 'disabled' : ''
                    } ${this.props.className || ''}`}>
                    <thead>
                        <tr>
                            {this.props.columns.map(column => (
                                <th
                                    className={`${
                                        column.sortable ? 'sortable' : ''
                                    } ${
                                        this.state.activeColumn === column.key
                                            ? 'active'
                                            : ''
                                    } ${column.className || ''}`}
                                    onClick={
                                        column.sortable
                                            ? () =>
                                                  this.onColumnClicked(
                                                      column.key
                                                  )
                                            : null
                                    }
                                    key={column.title + column.key}>
                                    <div className="d-flex align-items-center">
                                        {column.title}
                                        {column.sortable && (
                                            <div className="sort-icon">
                                                <Icon
                                                    className={
                                                        this.state
                                                            .activeColumn ===
                                                            column.key &&
                                                        this.state.order &&
                                                        this.state.order ===
                                                            Order.ASC
                                                            ? 'active'
                                                            : ''
                                                    }
                                                    name="order-asc"
                                                />
                                                <Icon
                                                    className={
                                                        this.state
                                                            .activeColumn ===
                                                            column.key &&
                                                        this.state.order &&
                                                        this.state.order ===
                                                            Order.DESC
                                                            ? 'active'
                                                            : ''
                                                    }
                                                    name="order-desc"
                                                />
                                            </div>
                                        )}
                                    </div>
                                </th>
                            ))}
                        </tr>
                    </thead>
                    <tbody>{this.props.children}</tbody>
                </table>
            </div>
        ) : null;
    }
}

SortableTable.propTypes = {
    columns: PropTypes.arrayOf(PropTypes.object).isRequired,
    sortColumn: PropTypes.string,
    order: PropTypes.string,
    onFetch: PropTypes.func,
    isLoading: PropTypes.bool.isRequired
};

export default SortableTable;
