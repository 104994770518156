import React from 'react';
import PropTypes from 'prop-types';
import {
    ROUTE_CLIENTS_DETAIL_TAB_DIARY_FOOD,
    ROUTE_CLIENTS_DETAIL_TAB_DIARY_GENERAL,
    ROUTE_CLIENTS_DETAIL_TAB_DIARY_MOVEMENTS
} from '../../../../../platform/routing';
import { Redirect, Route, Switch } from 'react-router-dom';
import DiaryTabs from '../tabs/DiaryTabs';
import ClientFoodOverview from '../food/ClientFoodOverview';
import ClientGeneralDiaryOverview from '../general/ClientGeneralDiaryOverview';
import ClientMovementsOverview from '../movement/ClientMovementsOverview';

const ClientDiaryOverview = props => (
    <>
        <DiaryTabs {...props} />

        <Switch>
            <Route
                path={ROUTE_CLIENTS_DETAIL_TAB_DIARY_GENERAL}
                component={ClientGeneralDiaryOverview}
            />

            <Route
                path={ROUTE_CLIENTS_DETAIL_TAB_DIARY_FOOD}
                component={ClientFoodOverview}
            />

            <Route
                path={ROUTE_CLIENTS_DETAIL_TAB_DIARY_MOVEMENTS}
                component={ClientMovementsOverview}
            />

            <Redirect to={ROUTE_CLIENTS_DETAIL_TAB_DIARY_GENERAL} />
        </Switch>
    </>
);

ClientDiaryOverview.propTypes = {
    client: PropTypes.object.isRequired
};

export default ClientDiaryOverview;
