import { buildQuery } from '@dive/utils-js';
import { setUser } from '@dive/react-redux-networking';

import { setValid } from '../model/subscription';
import { shouldRefreshStateData } from '../../../core/utils/data';

export const SubscriptionsActions = {
    GET_SUBSCRIPTIONS: '/subscriptions/LOAD',
    GET_SUBSCRIPTIONS_SUCCESS: '/subscriptions/LOAD_SUCCESS',
    GET_SUBSCRIPTIONS_FAIL: '/subscriptions/LOAD_FAIL',

    CLEAR_SUBSCRIPTIONS: '/subscriptions/CLEAR',

    UPDATE_SUBSCRIPTION: '/subscription/UPDATE',
    UPDATE_SUBSCRIPTION_SUCCESS: '/subscription/UPDATE_SUCCESS',
    UPDATE_SUBSCRIPTION_FAIL: '/subscription/UPDATE_FAIL',

    GET_PREPARE_PAYMENT: '/subscription/prepare/LOAD',

    CLEAR_SUBSCRIPTION: '/subscription/CLEAR'
};

const SUBSCRIPTION_CACHE_INTERVAL = 300000;

export const getSubscriptions = () => (dispatch, getState) => {
    if (
        shouldRefreshStateData(
            getState().subscriptions,
            SUBSCRIPTION_CACHE_INTERVAL
        )
    ) {
        return dispatch(fetchSubscriptions());
    }
};

export const fetchSubscriptions = () => ({
    type: SubscriptionsActions.GET_SUBSCRIPTIONS,
    payload: {
        request: {
            url: '/subscriptions',
            method: 'GET'
        }
    }
});

export const markSubscriptionInvalid = () => (dispatch, getState) => {
    const user = getState().user.data;

    // set plan as valid false
    const plan = setValid(user.current_plan, false);

    // save to store
    return dispatch(
        setUser(
            Object.assign({}, user, {
                current_plan: plan
            })
        )
    );
};

export const preparePayment = (plan, coupon = '') => {
    const url = 'subscriptions/prepare' + buildQuery({ plan, coupon });

    return {
        type: SubscriptionsActions.GET_PREPARE_PAYMENT,
        payload: {
            request: {
                url,
                method: 'GET'
            }
        }
    };
};
