import AuthContainer from './auth/AuthContainer';
import * as Sentry from '@sentry/browser';

import React, { Component } from 'react';
import ReactGA from 'react-ga';

class App extends Component {
    componentDidMount() {
        if (
            process.env.NODE_ENV !== 'development' &&
            process.env.REACT_APP_GA
        ) {
            this.trackData();
        }
    }

    componentDidCatch(error, errorInfo) {
        Sentry.withScope(scope => {
            Object.keys(errorInfo).forEach(key => {
                scope.setExtra(key, errorInfo[key]);
            });
            Sentry.captureException(error);
        });
    }

    trackData = () => {
        ReactGA.initialize(process.env.REACT_APP_GA.toString(), {
            debug: false,
            titleCase: false,
            gaOptions: {
                userId: this.props.user ? this.props.user.id : null
            }
        });
        ReactGA.pageview(this.props.history.location.pathname.toString());
        this.props.history.listen(location =>
            ReactGA.pageview(location.pathname.toString())
        );
    };

    render() {
        return <AuthContainer {...this.props} />;
    }
}

export default App;
