import React, { Component } from 'react';
import { t } from '@dive/localization-js';
import {
    ROUTE_SETTINGS_TAB_CATEGORIES,
    ROUTE_SETTINGS_TAB_GENERAL,
    ROUTE_SETTINGS_TAB_TAGS,
    ROUTE_SETTINGS_TAB_FIELDS
} from 'app/platform/routing';
import Tabs from 'app/components/shared/tabs/Tabs';
import ContentHeader from 'app/components/shared/content/ContentHeader';
import ContentBody from 'app/components/shared/content/ContentBody';
import { Route, Switch } from 'react-router-dom';
import SettingsCategoriesTab from './categories/SettingsCategoriesTab';
import SettingsTagsTab from './tags/SettingsTagsTab';
import SettingsGeneralTab from './general/SettingsGeneralTab';
import FieldsSettingsContainer from './fields/FieldsSettingsContainer';

class SettingsContainer extends Component {
    constructor(props) {
        super(props);

        this.TAB_ITEMS = [
            {
                label: t('settings.tabs.general'),
                url: ROUTE_SETTINGS_TAB_GENERAL,
                exact: true
            },
            {
                label: t('settings.tabs.categories'),
                url: ROUTE_SETTINGS_TAB_CATEGORIES
            },
            {
                label: t('settings.tabs.tags'),
                url: ROUTE_SETTINGS_TAB_TAGS
            },
            {
                label: t('settings.tabs.fields'),
                url: ROUTE_SETTINGS_TAB_FIELDS
            }
        ];
    }

    render() {
        return (
            <>
                <ContentHeader>
                    <h1 className="mr-5 mb-3">{t('settings')}</h1>

                    <Tabs tabs={this.TAB_ITEMS} />
                </ContentHeader>

                <ContentBody>
                    <Switch>
                        <Route
                            exact
                            path={ROUTE_SETTINGS_TAB_GENERAL}
                            component={SettingsGeneralTab}
                        />

                        <Route
                            path={ROUTE_SETTINGS_TAB_CATEGORIES}
                            component={SettingsCategoriesTab}
                        />

                        <Route
                            path={ROUTE_SETTINGS_TAB_TAGS}
                            component={SettingsTagsTab}
                        />

                        <Route
                            path={ROUTE_SETTINGS_TAB_FIELDS}
                            component={FieldsSettingsContainer}
                        />
                    </Switch>
                </ContentBody>
            </>
        );
    }
}

export default SettingsContainer;
