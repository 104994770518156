import {
    ROUTE_CLIENTS_DETAIL_TAB_DIARY_FOOD,
    ROUTE_CLIENTS_DETAIL_TAB_DIARY_FOOD_DETAIL
} from '../../../../../../platform/routing';
import React, { Component, Fragment } from 'react';
import {
    fetchClientFoodDiaryForDate,
    getClientFoodDiaryForDate
} from '../../../../../../../core/actions/diary';
import { isEmptyText, isVoid } from '@dive/utils-js';

import BackButton from '../../../../../shared/buttons/BackButton';
import Card from '../../../../../shared/card/Card';
import ErrorView from '../../../../../shared/view/ErrorView';
import FeedbackForm from '../../feedback/FeedbackForm';
import FoodTreatment from './treatment/FoodTreatment';
import ImagesListItem from './item/ImagesListItem';
import Lightbox from '../../../../../shared/lightbox/Lightbox';
import LoadingIcon from '../../../../../shared/loading/LoadingIcon';
import MealHeaderListItem from './item/MealHeaderListItem';
import { MealType } from '../../../../../../../core/models/diary';
import NoteListItem from './item/NoteListItem';
import NutrientListItem from './item/NutrientListItem';
import PrevNextButtons from '../../navigation/PrevNextRow';
import { connect } from 'react-redux';
import { history } from 'app/platform/routing';
import { isLoadingData } from '../../../../../../../core/utils/data';
import moment from 'moment/moment';
import { t } from '@dive/localization-js';

const mapStateToProps = (state, props) => {
    const { foodDetail } = state.clients.detail.diary;
    const data = foodDetail ? foodDetail.data : null;
    return {
        isLoading: isLoadingData(foodDetail),
        data: data,
        treatment: data ? data.treatment : null,
        prev: data ? data.prev_day : null,
        next: data ? data.next_day : null,
        error: foodDetail.error,
        clientId: state.clients.detail.id,
        date: moment.utc(props.match.params.date, 'DDMMYYYY').format()
    };
};

const mapDispatchToProps = dispatch => {
    return {
        getClientFoodDiaryForDate: date => {
            dispatch(getClientFoodDiaryForDate(date));
        },
        fetchClientFoodDiaryForDate: date => {
            dispatch(fetchClientFoodDiaryForDate(date));
        }
    };
};

class ClientFoodDetail extends Component {
    constructor(props) {
        super(props);
        this.state = {
            endDate: null,
            startDate: null,
            actions: {},
            image: null
        };
    }

    componentDidMount() {
        // set data
        this.getData();
    }

    componentDidUpdate(prevProps) {
        this.getData();
    }

    getData() {
        this.props.getClientFoodDiaryForDate(this.props.date);
    }

    editFeedback = (id, feedback) => {
        this.setState({
            actions: {
                updateFeedback: feedback
                    ? Object.assign({}, feedback, { id: id })
                    : null
            }
        });
    };

    onFinishAction() {
        // remove dialog
        this.setState({ actions: {} });
        // refresh consultation
        this.props.fetchClientFoodDiaryForDate(this.props.date);
    }

    handleImageClick = url => {
        this.setState({
            image: url
        });
    };

    handleImageClose = () => {
        this.setState({
            image: null
        });
    };

    goToDetail = date => {
        history.push(
            ROUTE_CLIENTS_DETAIL_TAB_DIARY_FOOD_DETAIL.replace(
                ':id',
                this.props.clientId
            ).replace(':date', moment(date).format('DDMMYYYY'))
        );
    };

    render() {
        const meals = [
            MealType.BREAKFAST,
            MealType.LUNCH,
            MealType.DINNER,
            MealType.SNACKS
        ].map(item => ({
            title: t(`diary.food.meal.${item}`),
            kcal: this.props.treatment
                ? this.props.treatment[`kcal_${item}`]
                : null,
            kcal_count: this.props.treatment
                ? this.props.treatment[`kcal_${item}_count`]
                : null,
            carbs_count: this.props.treatment
                ? this.props.treatment[`carbs_${item}_count`]
                : null,
            protein_count: this.props.treatment
                ? this.props.treatment[`protein_${item}_count`]
                : null,
            fat_count: this.props.treatment
                ? this.props.treatment[`fat_${item}_count`]
                : null,
            data: this.props.data
                ? [
                      ...this.props.data.nutrients.filter(
                          nutrient => nutrient.meal === item
                      ),
                      ...this.props.data.entries.filter(
                          entry => entry.meal === item
                      )
                  ]
                : []
        }));

        return (
            <Fragment>
                <div className="d-flex justify-content-between flex-wrap">
                    <BackButton
                        label={t('diary.food.detail.back')}
                        to={ROUTE_CLIENTS_DETAIL_TAB_DIARY_FOOD.replace(
                            ':id',
                            this.props.clientId
                        )}
                    />
                    <PrevNextButtons
                        prev={this.props.prev}
                        next={this.props.next}
                        showDetail={this.goToDetail}
                    />
                </div>
                <div className="row mt-3">
                    <div className="col-sm-4 mb-3">
                        <h2>{t('diary.food.detail.general')}</h2>
                        <Card>
                            <h3 className="mb-3">
                                {`${moment
                                    .utc(this.props.date)
                                    .format(`dddd`)} ${moment
                                    .utc(this.props.date)
                                    .format('DD/MM/YYYY')}`}
                            </h3>
                            <FoodTreatment treatment={this.props.treatment} />
                        </Card>
                    </div>
                    <div className="col-sm-8 mb-3">
                        {this.props.isLoading ? (
                            <Card className="mt-4">
                                <LoadingIcon />
                            </Card>
                        ) : this.props.error ? (
                            <Card className="mt-4">
                                <ErrorView error={this.props.error} />
                            </Card>
                        ) : (
                            meals.map(meal => (
                                <Fragment key={meal.title}>
                                    <MealHeaderListItem meal={meal} />
                                    {meal.data.length > 0 && (
                                        <Card padding={false} className="mb-3">
                                            <ul className="bordered-list mb-0 pt-1 pb-1">
                                                {meal.data.map(item =>
                                                    item.nutrient ? (
                                                        <NutrientListItem
                                                            key={item.id}
                                                            nutrient={item}
                                                            onFeedBackClick={
                                                                this
                                                                    .editFeedback
                                                            }
                                                        />
                                                    ) : !isEmptyText(
                                                          item.note
                                                      ) ? (
                                                        <NoteListItem
                                                            key={item.id}
                                                            entry={item}
                                                            onFeedBackClick={
                                                                this
                                                                    .editFeedback
                                                            }
                                                        />
                                                    ) : (
                                                        <ImagesListItem
                                                            key={item.id}
                                                            entry={item}
                                                            onFeedBackClick={
                                                                this
                                                                    .editFeedback
                                                            }
                                                            onImageClick={
                                                                this
                                                                    .handleImageClick
                                                            }
                                                        />
                                                    )
                                                )}
                                            </ul>
                                        </Card>
                                    )}
                                </Fragment>
                            ))
                        )}
                    </div>
                </div>
                {/* Create/edit feedback modal */}
                {this.state.actions.updateFeedback && (
                    <FeedbackForm
                        feedback={this.state.actions.updateFeedback}
                        type={'food'}
                        onDismissClicked={() => this.editFeedback(null, null)}
                        onFinished={() => this.onFinishAction()}
                    />
                )}
                {!isVoid(this.state.image) && (
                    <Lightbox
                        url={this.state.image}
                        onClose={this.handleImageClose}
                    />
                )}
            </Fragment>
        );
    }
}

ClientFoodDetail.propTypes = {};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(ClientFoodDetail);
