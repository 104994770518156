import React from 'react';
import PropTypes from 'prop-types';
import {
    getClientLimit,
    getFormattedPrice,
    getId,
    getName,
    isUnlimited
} from '../../../../platform/model/subscription';
import Card from '../../../shared/card/Card';
import { t } from '@dive/localization-js';

const PlanItem = ({ plan, isActive, isCurrent, disabled, onClick }) => {
    return (
        <div className="col-sm-4 col-md-4" key={getId(plan)}>
            <button
                className="btn-div h-100"
                disabled={disabled}
                onClick={onClick}>
                <Card
                    className={`card-bordered card-selectable h-100 ${
                        isActive ? 'active' : ''
                    }`}>
                    <div className="text-center">
                        {isCurrent && (
                            <span className="badge badge-light mb-2">
                                {t('subscriptions.plans.current')}
                            </span>
                        )}
                        <p className="font-weight-semi-bold mb-2">
                            {getFormattedPrice(plan)}
                            <span className="label">*</span>/{' '}
                            {t(`subscriptions.plans.month`)}
                        </p>
                        <h3>{getName(plan)}</h3>
                        {isUnlimited(plan) ? (
                            <p className="label">
                                {t('subscriptions.client_limit.unlimited')}
                            </p>
                        ) : (
                            <p className="label">
                                {t('subscriptions.client_limit.amount', {
                                    limit: getClientLimit(plan)
                                })}
                            </p>
                        )}
                    </div>
                </Card>
            </button>
        </div>
    );
};

PlanItem.propTypes = {
    plan: PropTypes.object.isRequired,
    disabled: PropTypes.bool,
    isActive: PropTypes.bool,
    isCurrent: PropTypes.bool,
    onClick: PropTypes.func.isRequired
};

export default PlanItem;
