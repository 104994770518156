import React from 'react';
import PropTypes from 'prop-types';
import { hasFoodTreatment } from '../../../../../../../../core/models/treatment';
import { getFormattedNumber } from '../../../../../../../../core/utils/data';
import ProgressBar from '../../../../../../shared/progress/ProgressBar';
import { t } from '@dive/localization-js';

const Item = ({ count, total, unit, type }) => {
    return (
        <div className="col-xs-6 col-md-12 col-lg-6 mb-3 text-center d-flex flex-column align-items-center">
            <p className="mb-0">
                <span className="font-weight-semi-bold">{`${count} `}</span>
                {total ? (
                    <span className="text-body-light">/{total}</span>
                ) : (
                    unit
                )}
            </p>
            {total ? (
                <ProgressBar
                    className="mt-1 mb-1"
                    total={parseInt(total, 10)}
                    current={parseInt(count, 10)}
                    defaultColor={'primary'}
                    completeColor={'alert'}
                />
            ) : null}
            <span className="text-body-light font-size-extra-small">
                {type.toUpperCase()}
            </span>
        </div>
    );
};

const FoodTreatment = ({ treatment }) => {
    if (hasFoodTreatment(treatment)) {
        return (
            <div className="row">
                <Item
                    count={getFormattedNumber(treatment.kcal_total_count, 'nl')}
                    total={
                        treatment.kcal_total
                            ? getFormattedNumber(treatment.kcal_total, 'nl')
                            : null
                    }
                    unit=""
                    type={t('diary.food.detail.kcal')}
                />
                <Item
                    count={getFormattedNumber(treatment.fat_count, 'nl')}
                    total={
                        treatment.fat
                            ? getFormattedNumber(treatment.fat, 'nl')
                            : null
                    }
                    unit={t('general.units.g')}
                    type={t('diary.food.detail.fat')}
                />
                <Item
                    count={getFormattedNumber(treatment.protein_count, 'nl')}
                    total={
                        treatment.protein
                            ? getFormattedNumber(treatment.protein, 'nl')
                            : null
                    }
                    unit={t('general.units.g')}
                    type={t('diary.food.detail.protein')}
                />
                <Item
                    count={getFormattedNumber(treatment.carbs_count, 'nl')}
                    total={
                        treatment.carbs
                            ? getFormattedNumber(treatment.carbs, 'nl')
                            : null
                    }
                    unit={t('general.units.g')}
                    type={t('diary.food.detail.carbs')}
                />
            </div>
        );
    } else {
        return null;
    }
};

FoodTreatment.propTypes = {
    treatment: PropTypes.object
};

export default FoodTreatment;
