export const selectSettings = state => state.settings;

export const selectSettingsData = state => {
    const settings = selectSettings(state);

    return settings.data;
};

export const selectSettingsError = state => {
    const settings = selectSettings(state);

    return settings.error;
};

export const getClientDetailGraphType = settings =>
    settings.client_detail_graph_type || 'weight';

export const getClientOverviewColumns = settings =>
    settings.client_overview_columns || 'weight,bmi,ideal_weight,categories';

export const getConsultationOverviewColumns = settings =>
    settings.consultation_overview_columns ||
    'note,weight,bmi,fat,lean,muscle,bmr';
