import { MeasurementsActions } from '../../../core/actions/measurements';
import { shouldRefreshStateData } from '../../../core/utils/data';

export const ClientsActions = {
    GET_CLIENTS: '/clients/LOAD',
    GET_CLIENTS_SUCCESS: '/clients/LOAD_SUCCESS',
    GET_CLIENTS_FAIL: '/clients/LOAD_FAIL',

    CLEAR_CLIENTS: '/clients/CLEAR',

    GET_INACTIVE_CLIENTS: '/clients/inactive/LOAD',
    GET_INACTIVE_CLIENTS_SUCCESS: '/clients/inactive/LOAD_SUCCESS',
    GET_INACTIVE_CLIENTS_ERROR: '/clients/inactive/LOAD_FAIL',

    CLEAR_INACTIVE_CLIENTS: '/clients/inactive/CLEAR',

    GET_CLIENT: '/client/LOAD',
    GET_CLIENT_SUCCESS: '/client/LOAD_SUCCESS',
    GET_CLIENT_FAIL: '/client/LOAD_FAIL',

    CLEAR_CLIENT: '/clients/CLEAR',

    SET_CLIENT_FILTER: '/clients/filter/SET',
    SET_CLIENT_CATEGORY_FILTER: '/clients/category/filter/SET',

    GET_SEARCH: '/clients/search/LOAD',
    GET_SEARCH_SUCCESS: '/clients/search/LOAD_SUCCESS',
    GET_SEARCH_FAIL: '/clients/search/LOAD_FAIL',

    CLEAR_SEARCH: '/clients/search/CLEAR',

    CREATE_CLIENT: '/client/CREATE',
    CREATE_CLIENT_SUCCESS: '/client/CREATE_SUCCESS',
    CREATE_CLIENT_FAIL: '/client/CREATE_FAIL',

    UPDATE_CLIENT: '/client/UPDATE',
    UPDATE_CLIENT_SUCCESS: '/client/UPDATE_SUCCESS',
    UPDATE_CLIENT_FAIL: '/client/UPDATE_FAIL',

    REMOVE_CLIENT: '/client/REMOVE',
    REMOVE_CLIENT_SUCCESS: '/client/REMOVE_SUCCESS',
    REMOVE_CLIENT_FAIL: '/client/REMOVE_FAIL'
};

const CLIENTS_CACHE_INTERVAL = 1000 * 60 * 5;
const CLIENT_DETAIL_CACHE_INTERVAL = 1000 * 60 * 5;

export const getClients = () => (dispatch, getState) => {
    const { list } = getState().clients;

    if (shouldRefreshStateData(list, CLIENTS_CACHE_INTERVAL)) {
        const { data } = list;

        return dispatch(
            fetchClients(
                data ? data.page : 1,
                data && data.sort ? data.sort.column : undefined,
                data && data.sort ? data.sort.order : undefined
            )
        );
    }
};

export const fetchClients = (page = 1, sortColumn, order) => (
    dispatch,
    getState
) => {
    const { category } = getState().clients;

    const sortQuery = sortColumn ? `&sort=${sortColumn}:${order}` : '';
    const catQuery = category ? '&category_id=' + category : '';

    return dispatch({
        type: ClientsActions.GET_CLIENTS,
        payload: {
            request: {
                url: `/clients?page=${page}${sortQuery}${catQuery}`,
                method: 'GET'
            }
        }
    });
};

export const getInactiveClients = () => (dispatch, getState) => {
    const { inactive } = getState().clients;

    if (shouldRefreshStateData(inactive, CLIENTS_CACHE_INTERVAL)) {
        const { data } = inactive;

        return dispatch(
            fetchInactiveClients(
                data ? data.page : 1,
                data && data.sort ? data.sort.column : undefined,
                data && data.sort ? data.sort.order : undefined
            )
        );
    }
};

export const fetchInactiveClients = (page = 1, sortColumn, order) => {
    const sortQuery = sortColumn ? `&sort=${sortColumn}:${order}` : '';

    return {
        type: ClientsActions.GET_INACTIVE_CLIENTS,
        payload: {
            request: {
                url: `/clients/inactive?page=${page}${sortQuery}`,
                method: 'GET'
            }
        }
    };
};

export const searchClients = query => ({
    type: ClientsActions.GET_SEARCH,
    payload: {
        request: {
            url: `/clients/search?query=${query}`,
            method: 'GET'
        }
    }
});

export const getClientDetail = id => (dispatch, getState) => {
    id = parseInt(id, 10);

    const { detail } = getState().clients;

    if (
        shouldRefreshStateData(detail, CLIENT_DETAIL_CACHE_INTERVAL) ||
        detail.id !== id
    ) {
        return dispatch(fetchClientDetail(id));
    }
};

export const clearClientGraph = () => ({
    type: MeasurementsActions.CLEAR_CLIENT_GRAPH
});

export const fetchClientDetail = id => dispatch =>
    dispatch({
        type: ClientsActions.GET_CLIENT,
        payload: {
            request: {
                url: `/clients/${id}`,
                method: 'GET',
                id
            }
        }
    }).then(response => {
        dispatch(clearClientGraph());

        return response;
    });

export const updateClient = client => ({
    type: client.id
        ? ClientsActions.UPDATE_CLIENT
        : ClientsActions.CREATE_CLIENT,
    payload: {
        request: {
            url: client.id ? `/clients/${client.id}` : '/clients',
            method: client.id ? 'PUT' : 'POST',
            data: client,
            id: client.id
        }
    }
});

export const deleteClient = id => ({
    type: ClientsActions.REMOVE_CLIENT,
    payload: {
        request: {
            url: `/clients/${id}`,
            method: 'DELETE',
            id
        }
    }
});

export const setClientFilter = filter => ({
    type: ClientsActions.SET_CLIENT_FILTER,
    filter
});

export const setClientCategoryFilter = payload => ({
    type: ClientsActions.SET_CLIENT_CATEGORY_FILTER,
    payload
});
