import {
    getAmountLabel,
    getName,
    getNutritionalValues
} from '../../../../../../../../core/models/nutrient';

import FeedbackView from '../../../feedback/FeedbackView';
import PropTypes from 'prop-types';
import React from 'react';
import ReadNotification from '../../../../../../shared/read/ReadNotification';

const NutrientListItem = ({ nutrient, onFeedBackClick }) => {
    return (
        <li className="d-flex pb-2 pt-2 pl-3 pr-3">
            <ReadNotification read={nutrient.read} />
            <div
                style={{
                    minWidth: '180px'
                }}>
                <p className="font-weight-semi-bold mb-0">
                    {getAmountLabel(nutrient.nutrient, nutrient.quantity)}{' '}
                    {getName(nutrient.nutrient)}
                </p>
                <span className="text-body-light">
                    {getNutritionalValues(nutrient.nutrient, nutrient.quantity)}
                </span>
            </div>
            <FeedbackView
                className="ml-auto"
                feedback={nutrient.feedback}
                onEditClick={feedback => onFeedBackClick(nutrient.id, feedback)}
            />
        </li>
    );
};

NutrientListItem.propTypes = {
    nutrient: PropTypes.object,
    onFeedBackClick: PropTypes.func
};

export default NutrientListItem;
