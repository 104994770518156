import {
    ROUTE_CLIENTS,
    ROUTE_DASHBOARD,
    ROUTE_DIGITAL_COACHING,
    ROUTE_LIBRARY,
    ROUTE_SETTINGS,
    SubscriberRoute
} from 'app/platform/routing';
import { ROUTE_PROFILE, ROUTE_SUBSCRIBERS } from '../../platform/routing';
import React, { Component } from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';

import ClientsContainer from '../clients/ClientsContainer';
import DashboardContainer from '../dashboard/DashboardContainer';
import DigitalCoachingContainer from '../digital-coaching/DigitalCoachingContainer';
import Footer from '../shared/footer/Footer';
import HeaderContainer from 'app/components/shared/header/HeaderContainer';
import ImpersonationBanner from '../impersonation/ImpersonationBanner';
import LibraryScene from '../library/LibraryScene';
import LoadingIcon from '../shared/loading/LoadingIcon';
import MessageBar from '../shared/message/MessageBar';
import ProfileContainer from '../profile/ProfileContainer';
import SettingsContainer from '../settings/SettingsContainer';
import SidebarContainer from 'app/components/shared/sidebar/SidebarContainer';
import SubscribersContainer from '../subscribers/SubscribersContainer';
import SubscriptionStatusContainer from 'app/components/shared/subscription/SubscriptionStatusContainer';
import { connect } from 'react-redux';
import { fetchInitialData } from '../../platform/actions/app';
import { isAdmin } from '../../../core/models/user';
import { isValid } from 'app/platform/model/subscription';

class AppContainer extends Component {
    componentDidMount() {
        if (
            !isAdmin(this.props.user) &&
            isValid(this.props.user.current_plan)
        ) {
            this.props.fetchInitialData();
        }
    }

    componentDidUpdate() {
        if (
            this.props.isAppLoading &&
            !isAdmin(this.props.user) &&
            isValid(this.props.user.current_plan)
        ) {
            this.props.fetchInitialData();
        }
    }

    render() {
        const { user, isAppLoading, ...props } = this.props;

        if (isAppLoading) {
            return <LoadingIcon />;
        }

        const isSubscribed = isValid(user.current_plan);

        const style = isSubscribed ? null : { left: 0 };

        return (
            <div className="app">
                <HeaderContainer showSearch={isSubscribed} />

                <div className="app-container">
                    {isSubscribed && <SidebarContainer {...props} />}

                    <div>
                        <div style={style} className="app-content">
                            <ImpersonationBanner />

                            <Switch>
                                <Route
                                    path={ROUTE_PROFILE}
                                    component={ProfileContainer}
                                />

                                <SubscriberRoute
                                    path={ROUTE_DIGITAL_COACHING}
                                    component={DigitalCoachingContainer}
                                    user={user}
                                />

                                <SubscriberRoute
                                    path={ROUTE_LIBRARY}
                                    component={LibraryScene}
                                    user={user}
                                />

                                <SubscriberRoute
                                    path={ROUTE_SETTINGS}
                                    component={SettingsContainer}
                                    user={user}
                                />

                                <SubscriberRoute
                                    path={ROUTE_SUBSCRIBERS}
                                    component={SubscribersContainer}
                                    user={user}
                                />

                                <SubscriberRoute
                                    path={ROUTE_CLIENTS}
                                    component={ClientsContainer}
                                    user={user}
                                />

                                <SubscriberRoute
                                    path={ROUTE_DASHBOARD}
                                    component={DashboardContainer}
                                    user={user}
                                />

                                <Redirect
                                    to={
                                        isAdmin(user)
                                            ? ROUTE_SUBSCRIBERS
                                            : ROUTE_DASHBOARD
                                    }
                                />
                            </Switch>
                        </div>

                        <Footer />
                    </div>

                    {isSubscribed && <SubscriptionStatusContainer />}
                </div>

                <MessageBar />
            </div>
        );
    }
}

const mapStateToProps = state => ({
    isAppLoading:
        !isAdmin(state.user.data) &&
        isValid(state.user.data.current_plan) &&
        (!state.settings.data || !state.categories.data || !state.tags.data),
    user: state.user.data
});

const mapDispatchToProps = {
    fetchInitialData
};

export default connect(mapStateToProps, mapDispatchToProps)(AppContainer);
