import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { t } from '@dive/localization-js';
import { request } from '@dive/react-redux-networking';

import { updateClientGoal } from '../../../../../../../core/actions/goals';
import DynamicFormDialog from '../../../../../shared/forms/DynamicFormDialog';

const mapDispatchToProps = dispatch => {
    return {
        saveGoal: (clientId, goal) => {
            return request(dispatch(updateClientGoal(clientId, goal)));
        }
    };
};

class GoalForm extends Component {
    constructor(props) {
        super(props);

        this.setFields();

        this.state = {};
    }

    setFields() {
        this.fields = [
            {
                name: 'scheduled',
                label: t('treatment.goals.form.date'),
                type: 'date',
                required: true,
                size: '6',
                config: {
                    fromMonth: new Date()
                }
            },
            {
                name: 'status',
                label: t('treatment.goals.form.state'),
                type: 'select',
                required: false,
                size: '12',
                options: [
                    {
                        name: t('treatment.goals.form.state.failed'),
                        value: 'failed'
                    },
                    {
                        name: t('treatment.goals.form.state.completed'),
                        value: 'completed'
                    }
                ]
            },
            {
                name: 'weight',
                label: `${t('treatment.goals.form.weight')}`,
                type: 'number',
                size: '6',
                unit: t('general.units.kg'),
                config: {
                    step: '0.01'
                }
            },
            {
                name: 'fat',
                label: `${t('treatment.goals.form.fat')}`,
                type: 'number',
                size: '6',
                unit: '%'
            },
            {
                name: 'note',
                label: t('treatment.goals.form.note'),
                type: 'text',
                required: false,
                size: '12'
            }
        ];
    }

    componentDidMount() {
        this.setState({
            goal: Object.assign({}, this.props.goal)
        });
    }

    onValidate = (fields, model) => {
        // we should have at least one input
        if (!model.weight && !model.fat && !model.note) {
            return {
                weight: t('treatment.goals.form.errors.weight'),
                fat: t('treatment.goals.form.errors.weight'),
                note: t('treatment.goals.form.errors.weight')
            };
        }
        return {};
    };

    render() {
        return this.state.goal ? (
            <DynamicFormDialog
                title={t(
                    this.state.goal.id
                        ? 'treatment.goals.edit'
                        : 'treatment.goals.add'
                )}
                model={this.state.goal}
                onSaveForm={model =>
                    this.props.saveGoal(this.props.client.id, model)
                }
                fields={this.fields}
                onDismissClicked={this.props.onDismissClicked}
                onValidate={this.onValidate}
                onFinished={this.props.onFinished}
            />
        ) : null;
    }
}

GoalForm.propTypes = {
    goal: PropTypes.object.isRequired,
    onDismissClicked: PropTypes.func.isRequired,
    onFinished: PropTypes.func.isRequired
};

export default connect(
    undefined,
    mapDispatchToProps
)(GoalForm);
