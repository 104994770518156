import { ROUTE_CLIENTS_DETAIL, history } from '../../../../../platform/routing';
import React, { Component } from 'react';

import DynamicFormDialog from '../../../../shared/forms/DynamicFormDialog';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { request } from '@dive/react-redux-networking';
import { t } from '@dive/localization-js';
import { updateClient } from 'app/platform/actions/clients';

const mapStateToProps = state => {
    return {
        categories: state.categories.data
    };
};

const mapDispatchToProps = dispatch => {
    return {
        saveClient: client => {
            return request(dispatch(updateClient(client)));
        }
    };
};

class ClientContactForm extends Component {
    constructor(props) {
        super(props);

        this.setFields();

        this.state = {};
    }

    componentDidMount() {
        const client = Object.assign({}, this.props.client);
        if (client.categories && client.categories.length > 0) {
            client.categories = client.categories.map(cat => cat.id);
        } else {
            client.categories = [];
        }
        this.setState({
            client: client
        });
    }

    setFields() {
        this.fields = [
            {
                name: 'gender',
                label: t('clients.detail.general.gender'),
                type: 'select',
                required: true,
                size: '3',
                options: [
                    {
                        name: t('clients.detail.general.gender.male'),
                        value: 'M'
                    },
                    {
                        name: t('clients.detail.general.gender.female'),
                        value: 'F'
                    }
                ]
            },
            {
                name: 'first_name',
                label: t('clients.detail.general.first_name'),
                type: 'string',
                required: true,
                size: '4'
            },
            {
                name: 'name',
                label: t('clients.detail.general.name'),
                type: 'string',
                required: true,
                size: '5'
            },
            {
                name: 'email',
                label: t('clients.detail.general.email'),
                type: 'email',
                required: false,
                size: '6',
                checkVisibility: this.isInputFieldVisible
            },
            {
                name: 'telephone_number',
                label: t('clients.detail.general.telephone'),
                type: 'string',
                size: '6'
            },
            {
                name: 'address_street',
                label: t('clients.detail.general.street'),
                type: 'string',
                size: '12'
            },
            {
                name: 'address_postal_code',
                label: t('clients.detail.general.postalcode'),
                type: 'string',
                size: '4'
            },
            {
                name: 'address_city',
                label: t('clients.detail.general.city'),
                type: 'string',
                size: '8'
            },
            {
                name: 'birth_date',
                label: t('clients.detail.general.birthdate'),
                type: 'date',
                size: '6',
                config: {
                    showYears: true,
                    disabledDays: {
                        after: new Date()
                    }
                }
            },
            {
                name: 'height',
                label: t('clients.detail.general.height'),
                type: 'number',
                size: '12'
            },
            {
                name: 'coaching',
                label: t('clients.detail.general.coaching'),
                type: 'checkbox',
                size: '12',
                checkVisibility: this.isInputFieldVisible
            },
            {
                name: 'coaching_starts_at',
                label: t('clients.detail.general.coaching_starts_at'),
                type: 'date',
                size: '4',
                checkVisibility: this.isInputFieldVisible,
                config: {
                    showYears: true,
                    disabledDays: {
                        before: new Date()
                    }
                }
            },
            {
                name: 'coaching_ends_at',
                label: t('clients.detail.general.coaching_ends_at'),
                type: 'date',
                size: '8',
                checkVisibility: this.isInputFieldVisible,
                config: {
                    showYears: true,
                    disabledDays: {
                        before: new Date()
                    }
                }
            },
            {
                name: 'categories',
                label: t('clients.detail.general.categories'),
                type: 'categories',
                isArray: true,
                size: '12'
            }
        ];
    }

    isInputFieldVisible = (field, model) => {
        switch (field.name) {
            case 'coaching':
                return !!model.email;
            case 'coaching_starts_at':
                return model.coaching;
            case 'coaching_ends_at':
                return model.coaching && !!model.coaching_starts_at;
            default:
                return true;
        }
    };

    onSubmit = data => {
        data = Object.assign({}, data);

        // set categories
        data.categories = data.categories.map(id => ({ id }));

        // no coaching without an email address
        if (!data.email) {
            data.coaching = false;
        }

        if (!data.coaching) {
            data.coaching_starts_at = null;
            data.coaching_ends_at = null;
        }

        return this.props.saveClient(data);
    };

    onFinished = response => {
        if (this.state.client.id) {
            // close dialog
            this.props.onFinished();
        } else {
            // we've created a client, let's show the detail
            history.push(ROUTE_CLIENTS_DETAIL.replace(':id', response.id));
        }
    };

    render() {
        const { client } = this.state;
        return client ? (
            <DynamicFormDialog
                title={t(client.id ? 'clients.edit' : 'clients.add')}
                model={client}
                fields={this.fields}
                onDismissClicked={this.props.onDismissClicked}
                onFinished={this.onFinished}
                onSaveForm={this.onSubmit}
            />
        ) : null;
    }
}

ClientContactForm.propTypes = {
    client: PropTypes.object.isRequired,
    onDismissClicked: PropTypes.func.isRequired,
    onFinished: PropTypes.func.isRequired
};

export default connect(mapStateToProps, mapDispatchToProps)(ClientContactForm);
