import React from 'react';

const ContentHeader = ({ children, title }) => {
    return (
        <div className="content-header">
            {title && (
                <div className="d-flex flex-wrap justify-content-between">
                    <h1 className="mr-5 mb-3">{title}</h1>
                </div>
            )}
            {children}
        </div>
    );
};

ContentHeader.propTypes = {};

export default ContentHeader;
