import React, { Fragment } from 'react';
import { formatDate, parseDate } from 'react-day-picker/moment';

import DayPickerInput from 'react-day-picker/DayPickerInput';
import EmptyView from '../view/EmptyView';
import { FileType } from '../../../../core/models/library';
import Icon from '../icon/Icon';
import { YearMonthForm } from '../datepicker/YearMonthForm';
import { t } from '@dive/localization-js';

const onHourChanged = (field, value, e, onFieldChanged) => {
    const date = new Date(value);
    date.setHours(parseInt(e.target.value, 10));
    onFieldChanged(field, date);
};

const onMinutesChanged = (field, value, e, onFieldChanged) => {
    const date = new Date(value);
    date.setMinutes(parseInt(e.target.value, 10));
    onFieldChanged(field, date);
};

const shouldFixNumbers = () => {
    return window.navigator.userAgent.indexOf('Edge') >= 0;
};

const isFirefox = navigator.userAgent.toLowerCase().indexOf('firefox') > -1;

export const getInputField = (field, value, error, onFieldChanged, model) => {
    const inputField = React.createRef();

    const openDialog = () => {
        inputField.current.click();
    };

    switch (field.type) {
        case 'date':
        case 'datetime':
        case 'time':
            const hours = [];
            const minutes = [];
            if (field.type === 'datetime' || field.type === 'time') {
                for (let i = 0; i < 24; i++) {
                    hours.push(`${i}`);
                }
                for (let i = 0; i < 60; i++) {
                    minutes.push(`${i}`);
                }
            }
            const captionElement =
                field.config && field.config.showYears
                    ? {
                          captionElement: ({ date, localeUtils }) => (
                              <YearMonthForm
                                  date={date}
                                  localeUtils={localeUtils}
                                  onChange={month =>
                                      onFieldChanged(field, month)
                                  }
                              />
                          )
                      }
                    : {};
            return (
                <div className={`form-datetime ${error ? 'is-invalid' : ''}`}>
                    <DayPickerInput
                        inputProps={{ readOnly: true }}
                        value={value ? new Date(value) : undefined}
                        onDayChange={selectedDay =>
                            onFieldChanged(field, selectedDay)
                        }
                        formatDate={formatDate}
                        parseDate={parseDate}
                        format="DD/MM/YYYY"
                        placeholder="DD/MM/YYYY"
                        keepFocus={
                            !isFirefox && !!captionElement.captionElement
                        }
                        dayPickerProps={{
                            selectedDays: value ? new Date(value) : '',
                            fromMonth: field.config
                                ? field.config.fromMonth
                                : null,
                            month: value ? new Date(value) : null,
                            disabledDays: field.config
                                ? field.config.disabledDays
                                : null,
                            ...captionElement
                        }}
                    />
                    {field.type === 'datetime' || field.type === 'time' ? (
                        <Fragment>
                            <div className="time d-flex">
                                <select
                                    className="form-control"
                                    name="hour"
                                    value={
                                        value
                                            ? `${new Date(value).getHours()}`
                                            : ''
                                    }
                                    onChange={e =>
                                        onHourChanged(
                                            field,
                                            value,
                                            e,
                                            onFieldChanged
                                        )
                                    }>
                                    {hours.map(hour => (
                                        <option
                                            key={`hour_${hour}`}
                                            value={hour}>
                                            {hour.length === 1
                                                ? `0${hour}`
                                                : hour}
                                        </option>
                                    ))}
                                </select>
                                <span className="d-block align-self-center text-center p-1">
                                    :
                                </span>
                                <select
                                    className="form-control"
                                    name="minutes"
                                    value={
                                        value
                                            ? `${new Date(value).getMinutes()}`
                                            : ''
                                    }
                                    onChange={e =>
                                        onMinutesChanged(
                                            field,
                                            value,
                                            e,
                                            onFieldChanged
                                        )
                                    }>
                                    {minutes.map(minute => (
                                        <option
                                            key={`minute_${minute}`}
                                            value={minute}>
                                            {minute.length === 1
                                                ? `0${minute}`
                                                : minute}
                                        </option>
                                    ))}
                                </select>
                            </div>
                        </Fragment>
                    ) : null}
                </div>
            );

        case 'select':
            return (
                <select
                    className={`form-control ${error ? 'is-invalid' : ''}`}
                    id={field.name}
                    value={value || ''}
                    name={field.name}
                    onChange={e => onFieldChanged(field, e.target.value)}>
                    {!(field.config && field.config.hideEmptyValue) && (
                        <option value="">--</option>
                    )}
                    {field.options.map(option => (
                        <option key={option.value} value={option.value}>
                            {option.name}
                        </option>
                    ))}
                </select>
            );

        case 'radiobutton':
            return (
                <div
                    className={`form-control d-flex flex-wrap ${
                        error ? 'is-invalid' : ''
                    }`}
                    id={field.name}>
                    {field.options.map(option => (
                        <div className="form-check" key={option.value}>
                            <label className="d-flex align-items-center">
                                <input
                                    type="radio"
                                    name={field.name}
                                    value={option.value}
                                    onChange={e =>
                                        onFieldChanged(field, e.target.value)
                                    }
                                    checked={
                                        (!value && !option.value) ||
                                        value === option.value
                                    }
                                />
                                {option.name}
                            </label>
                        </div>
                    ))}
                </div>
            );

        case 'number':
        case 'string':
        case 'email':
        case 'password':
            return (
                <input
                    type={
                        field.type === 'string' ||
                        (field.type === 'number' && shouldFixNumbers())
                            ? 'text'
                            : field.type
                    }
                    className={`form-control ${error ? 'is-invalid' : ''}`}
                    id={field.name}
                    name={field.name}
                    value={
                        value !== undefined && value !== null
                            ? shouldFixNumbers() && field.type === 'number'
                                ? `${value}`.replace('.', ',')
                                : value
                            : ''
                    }
                    lang="be-nl"
                    step={
                        field.config && field.config.step
                            ? field.config.step
                            : null
                    }
                    min={
                        field.config && field.config.min
                            ? field.config.min
                            : null
                    }
                    max={
                        field.config && field.config.max
                            ? field.config.max
                            : null
                    }
                    placeholder={
                        field.config && field.config.placeholder
                            ? field.config.placeholder
                            : null
                    }
                    onChange={e => {
                        if (shouldFixNumbers() && field.type === 'number') {
                            onFieldChanged(
                                field,
                                e.target.value.replace(',', '.')
                            );
                        } else {
                            onFieldChanged(field, e.target.value);
                        }
                    }}
                />
            );

        case 'text':
            return (
                <textarea
                    className={`form-control ${error ? 'is-invalid' : ''}`}
                    id={field.name}
                    rows={7}
                    name={field.name}
                    value={value || ''}
                    onChange={e => onFieldChanged(field, e.target.value)}
                />
            );

        case 'checkbox':
            return field.options && field.options.length > 0 ? (
                <div
                    className={`form-check-group ${error ? 'is-invalid' : ''}`}>
                    {field.options.map(option => (
                        <div className="form-check" key={`${option.name}`}>
                            <label
                                className="form-check-label"
                                htmlFor={`${option.name}`}>
                                <input
                                    className="form-check-input"
                                    type="checkbox"
                                    name={`${option.name}`}
                                    id={`${option.name}`}
                                    value={option.value}
                                    checked={
                                        field.isArray
                                            ? value.find(
                                                  val => val === option.value
                                              ) !== undefined
                                            : value === option.value
                                    }
                                    onChange={e =>
                                        onFieldChanged(
                                            field,
                                            e.target.value,
                                            e.target.checked
                                        )
                                    }
                                />
                                {option.name}
                            </label>
                        </div>
                    ))}
                </div>
            ) : (
                <div className="form-check">
                    <input
                        className="form-check-input"
                        type="checkbox"
                        name={field.name}
                        id={`${field.name}`}
                        checked={value || false}
                        onChange={e =>
                            onFieldChanged(
                                field,
                                e.target.value,
                                e.target.checked
                            )
                        }
                    />
                    <label
                        className="form-check-label"
                        htmlFor={`${field.name}`}>
                        {field.label || field.name}
                    </label>
                </div>
            );

        case 'categories':
            return (
                <div
                    className={`form-check-group ${error ? 'is-invalid' : ''}`}>
                    {field.options.length > 0 ? (
                        field.options
                            .filter(option => !option.disabled)
                            .map(option => (
                                <div
                                    className="form-check"
                                    key={`${option.id}`}>
                                    <label
                                        className="form-check-label"
                                        htmlFor={`cat_${option.id}`}>
                                        <input
                                            className="form-check-input"
                                            disabled={option.disabled}
                                            type="checkbox"
                                            name={`${option.id}`}
                                            id={`cat_${option.id}`}
                                            value={option.id}
                                            checked={
                                                field.isArray
                                                    ? value.find(
                                                          id => id === option.id
                                                      ) !== undefined
                                                    : value === option.id
                                            }
                                            onChange={e => {
                                                onFieldChanged(
                                                    field,
                                                    e.target.value,
                                                    e.target.checked
                                                );
                                            }}
                                        />
                                        {option.name}
                                    </label>
                                </div>
                            ))
                    ) : (
                        <EmptyView
                            className="m-0"
                            label={t('general.forms.error.categories.empty')}
                        />
                    )}
                </div>
            );

        case 'tags':
            return (
                <div
                    className={`form-check-group ${error ? 'is-invalid' : ''}`}>
                    {field.options.length > 0 ? (
                        field.options
                            .filter(option => !option.disabled)
                            .map(option => (
                                <div
                                    className="form-check"
                                    key={`${option.id}`}>
                                    <label
                                        className="form-check-label"
                                        htmlFor={`tag_${option.id}`}>
                                        <input
                                            className="form-check-input"
                                            disabled={option.disabled}
                                            type="checkbox"
                                            name={`${option.id}`}
                                            id={`tag_${option.id}`}
                                            value={option.id}
                                            checked={
                                                field.isArray
                                                    ? value.find(
                                                          id => id === option.id
                                                      ) !== undefined
                                                    : value === option.id
                                            }
                                            onChange={e => {
                                                onFieldChanged(
                                                    field,
                                                    e.target.value,
                                                    e.target.checked
                                                );
                                            }}
                                        />
                                        {option.name}
                                    </label>
                                </div>
                            ))
                    ) : (
                        <EmptyView
                            className="m-0"
                            label={t('general.forms.error.tags.empty')}
                        />
                    )}
                </div>
            );

        case 'file':
            return (
                <div className="full-width d-flex justify-content-start">
                    <div className="form-group upload-results d-flex flex-row align-items-center m-0">
                        {value ? (
                            value.versions && value.type === FileType.IMAGE ? (
                                <Fragment>
                                    <img
                                        className="mt-2 img-preview"
                                        src={value.versions['thumb-small']}
                                        alt={t(
                                            'digitalcoaching.list.image.default_alt_message'
                                        )}
                                    />
                                    <button
                                        className="btn btn-icon btn-alert ml-2"
                                        onClick={() =>
                                            onFieldChanged(field, null)
                                        }>
                                        <Icon name="delete" />
                                    </button>
                                </Fragment>
                            ) : (
                                <Fragment>
                                    <p className="m-0">{value.name}</p>

                                    <button
                                        className="btn btn-icon btn-alert ml-2"
                                        onClick={() =>
                                            onFieldChanged(field, null)
                                        }>
                                        <Icon name="delete" />
                                    </button>
                                </Fragment>
                            )
                        ) : (
                            <label
                                onClick={openDialog}
                                className="btn btn-tertiary"
                                htmlFor={field.id}>
                                {t(
                                    'general.form.' +
                                        (field.accept === 'image/*'
                                            ? 'image'
                                            : 'upload')
                                )}
                            </label>
                        )}
                    </div>
                    <input
                        key={value}
                        onChange={e => {
                            onFieldChanged(field, e.target.files[0] || null);
                        }}
                        id={field.id}
                        type="file"
                        accept={field.accept || '*'}
                        hidden
                        ref={inputField}
                    />
                </div>
            );

        default:
            return null;
    }
};
