import { shouldRefreshStateData } from '../../../core/utils/data';

export const DashboardActions = {
    GET_DASHBOARD_CARD: '/dashboard/card/LOAD',
    GET_DASHBOARD_CARD_SUCCESS: '/dashboard/card/LOAD_SUCCESS',
    GET_DASHBOARD_CARD_FAIL: '/dashboard/card/LOAD_FAIL',

    CLEAR_DASHBOARD: '/dashboard/card/CLEAR'
};

const DASHBOARD_CACHE_INTERVAL = 1000 * 60 * 5;

export const getDashboardCard = (cardName, range) => (dispatch, getState) => {
    const cardData = getState().dashboard.list[cardName];
    if (shouldRefreshStateData(cardData, DASHBOARD_CACHE_INTERVAL)) {
        return dispatch(fetchDashboardCard(cardName, range));
    }
};

export const fetchDashboardCard = (name, range = 'month') => {
    return {
        type: DashboardActions.GET_DASHBOARD_CARD,
        payload: {
            request: {
                url: `/dashboard/${name}?range=${range}`,
                method: 'GET',
                type: name,
                range: range
            }
        }
    };
};
