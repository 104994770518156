import React, { Component, Fragment } from 'react';
import {
    clearCoachings,
    getClientCoachings,
    setClientFilters
} from '../../../../../core/actions/coaching';

import DigitalCoachingOverviewContainer from '../../../digital-coaching/overview/DigitalCoachingOverviewContainer';
import PropTypes from 'prop-types';
import RoundButton from '../../../shared/buttons/RoundButton';
import { connect } from 'react-redux';
import { isCoachable } from '../../../../platform/model/client';
import { isLoadingData } from '../../../../../core/utils/data';
import { t } from '@dive/localization-js';

const mapStateToProps = state => {
    const { data, coaching } = state.clients.detail;
    const coachings = coaching ? coaching.data : null;

    return {
        shouldLoadData:
            !coaching ||
            (!coaching.isLoading && !coaching.data && !coaching.error),
        isLoading: isLoadingData(coaching),
        client: data,
        coachings: coachings ? coachings.coaching : null,
        tags: state.tags.data,
        filters: coaching.filters,
        pagination: {
            currentPage: coachings ? coachings.page : null,
            pageSize: coachings ? coachings.page_size : null,
            count: coachings ? coachings.count : null
        }
    };
};

const mapDispatchToProps = dispatch => {
    return {
        getCoachings: (id, page) => {
            dispatch(getClientCoachings(id, page));
        },
        clearCoachings: () => {
            dispatch(clearCoachings());
        },
        setFilters: filters => dispatch(setClientFilters(filters))
    };
};

class ClientDigitalCoachingTab extends Component {
    overviewContainer = null;

    addCoaching = () => {
        // add coaching for this client id
        this.overviewContainer.addCoaching();
    };

    componentDidMount() {
        if (this.props.shouldLoadData) {
            this.fetchData();
        }
    }

    componentDidUpdate(prevProps) {
        // check if we need to reload coachings
        if (!prevProps.shouldLoadData && this.props.shouldLoadData) {
            this.fetchData();
        }
    }

    onFilterChanged = (filter, e) => {
        // set filter
        const filters = Object.assign({}, this.props.filters, filter);
        // blur input
        if (e) {
            e.target.blur();
        }

        this.props.setFilters(filters);
    };

    hasFilter = () => {
        return !!this.props.filters.tag;
    };

    fetchData = page => {
        // clear normal coaching list
        this.props.clearCoachings();
        // refetch the list for this client
        this.props.getCoachings(this.props.client.id, page);
    };

    handleRef = ref => {
        this.overviewContainer = ref ? ref : null;
    };

    render() {
        const {
            client,
            coachings,
            isLoading,
            pagination,
            readOnly
        } = this.props;
        const coachable = isCoachable(client);

        return (
            <Fragment>
                <div className="d-flex align-items-center mt-2 mb-3 justify-content-between">
                    <div className="d-flex align-items-center">
                        <p className="m-0 p-0">{`${t('general.filter')}:`}</p>
                        <form className="form-filter ml-md-2">
                            {this.props.tags && this.props.tags.length > 0 && (
                                <select
                                    className="form-control"
                                    value={this.props.filters.tag || ''}
                                    onChange={e =>
                                        this.onFilterChanged(
                                            { tag: e.target.value },
                                            e
                                        )
                                    }>
                                    <option>
                                        {t('digitalcoaching.filter.tag')}
                                    </option>
                                    {this.props.tags.map(tag => (
                                        <option key={tag.id} value={tag.id}>
                                            {tag.name}
                                        </option>
                                    ))}
                                </select>
                            )}
                        </form>
                    </div>

                    <div className="d-flex align-items-center">
                        {!readOnly && coachable && (
                            <RoundButton
                                icon="add"
                                className="btn-round ml-auto"
                                onClick={this.addCoaching}
                                label={t('digitalcoaching.add')}
                            />
                        )}
                    </div>
                </div>

                <DigitalCoachingOverviewContainer
                    client={client}
                    readOnly={readOnly || !coachable}
                    isLoading={isLoading}
                    coachings={coachings}
                    showReceiver={false}
                    hasFilter={this.hasFilter()}
                    pagination={pagination}
                    onFetch={this.fetchData}
                    receiver={{ client_id: client.id }}
                    ref={this.handleRef}
                    addCoachingRef={fnc => {
                        this.addCoachingRef = fnc;
                    }}
                />
            </Fragment>
        );
    }
}

ClientDigitalCoachingTab.propTypes = {
    client: PropTypes.object.isRequired
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(ClientDigitalCoachingTab);
