import { isEmptyText, isVoid } from '@dive/utils-js';

import React from 'react';

const Select = ({
    className,
    disabled,
    name,
    onChange,
    options,
    placeholder,
    value,
    style
}) => {
    let classes = 'form-control';

    if (!isVoid(className)) {
        classes += ` ${className}`;
    }

    return (
        <select
            style={style}
            disabled={disabled}
            id={name}
            name={name}
            value={value}
            onChange={e => onChange(e.currentTarget.value, name)}
            className={classes}>
            {!isEmptyText(placeholder) && (
                <option value="">- {placeholder}</option>
            )}

            {options.map(option => (
                <option key={option.value} value={option.value}>
                    {option.display}
                </option>
            ))}
        </select>
    );
};

export default Select;
