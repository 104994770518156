import { request } from '@dive/react-redux-networking';
import { t } from '@dive/localization-js';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import moment from 'moment';

import DynamicForm from '../../shared/forms/DynamicForm';
import { isVoid } from '@dive/utils-js';
import DialogModal from '../../shared/modal/DialogModal';
import {
    selectLibraryEntries,
    selectLibraryData,
    selectLibraryError,
    hasSetFilters,
    selectLibraryFilters
} from '../../../../core/models/library';
import {
    selectPage,
    selectPagination,
    selectSort
} from '../../../../core/models/app';
import { isLoadingData } from '../../../../core/utils/data';
import ErrorView from '../../shared/view/ErrorView';
import {
    createLibraryEntry,
    fetchLibrary,
    getLibrary,
    resetFilters,
    setFilters
} from '../../../../core/actions/library';
import LibraryTable from '../table/LibraryTable';
import LibraryUploadForm from './LibraryUploadForm';
import PageFilter from '../../shared/filter/PageFilter';
import SearchField from '../../shared/forms/SearchField';

class LibraryLinkForm extends Component {
    constructor(props) {
        super(props);

        const link = {
            publish_type: isVoid(props.receiver)
                ? 'instant'
                : isVoid(props.receiver.published_at)
                ? isVoid(props.receiver.delay_days)
                    ? 'never'
                    : 'delay'
                : 'date',
            published_at: isVoid(props.receiver)
                ? moment.utc().format()
                : isVoid(props.receiver.published_at)
                ? moment.utc().format()
                : moment.utc(props.receiver.published_at).format(),
            delay_days: isVoid(props.receiver)
                ? null
                : props.receiver.delay_days
        };

        if (isVoid(props.receiver)) {
            link.category_ids = [];
        }

        this.state = {
            isLoading: false,
            errors: {},
            selected: null,
            searchKey: 100,
            upload: false,
            link
        };
    }

    // ///////////////////////////////////////////////////////////////////////
    // LIFECYCLE METHODS
    // ///////////////////////////////////////////////////////////////////////

    componentDidMount() {
        const { coaching_starts_at } = Object.assign({}, this.props.client);
        const link = Object.assign({}, this.props.link);
        const dateOnly =
            !isVoid(coaching_starts_at) &&
            moment(coaching_starts_at).isAfter(moment());

        if (!isVoid(this.props.client) && isVoid(this.props.receiver)) {
            this.props.resetFilters();
            this.props.getLibrary();
        }

        if (dateOnly) {
            link.publish_type = 'date';
            link.published_at = null;
        } else if (!isVoid(link.delay_days)) {
            link.schedule_type = 'delay';
            link.scheduled = moment.utc().format();
        } else {
            link.publish_type = 'instant';
            link.published_at = moment.utc().format();
        }

        this.setState({
            link,
            dateOnly
        });
    }

    componentDidUpdate() {
        this.props.getLibrary();
    }

    componentWillUnmount() {
        this.props.resetFilters();
    }

    // ///////////////////////////////////////////////////////////////////////
    // EVENT HANDLERS
    // ///////////////////////////////////////////////////////////////////////

    handleClear = () => {
        this.props.resetFilters();

        this.setState(prevState => ({ searchKey: prevState.searchKey + 1 }));
    };

    handleFilterChange = (value, name) => {
        const filters = { ...this.props.filters, [name]: value };

        this.props.setFilters(filters);
    };

    handleBackClick = () => {
        this.setState({ selected: null });
    };

    handleDismiss = () => {
        this.setState({ upload: false });
    };

    handleFinished = entry => {
        this.setState({ selected: entry, upload: false });
    };

    handleSort = (column, order) => {
        const sort = isVoid(column) ? null : { column, order };

        this.props.fetchLibrary(this.props.page, sort);
    };

    handlePageChanged = page => {
        this.props.fetchLibrary(page, this.props.sort);
    };

    handleSelect = entry => {
        this.setState({ selected: entry });
    };

    handleSubmit = model => {
        const data = this.prepareFormData(model);

        return this.props.onCreate(data);
    };

    handleUpload = () => {
        this.setState({ upload: true });
    };

    // ///////////////////////////////////////////////////////////////////////
    // OTHERS
    // ///////////////////////////////////////////////////////////////////////

    getFields() {
        const options = [
            {
                value: 'instant',
                name: t('library.form.publish.now')
            },
            {
                value: 'date',
                name: t('library.form.publish.date')
            }
        ];

        if (
            !isVoid(this.props.client) ||
            (!isVoid(this.props.receiver) &&
                this.props.receiver.type === 'clients')
        ) {
            options.push({
                value: 'never',
                name: t('library.form.publish.never')
            });
        } else {
            options.push({
                value: 'delay',
                name: t('digitalcoaching.form.schedule.delay')
            });
        }

        const fields = [
            {
                name: 'publish_type',
                label: t('library.form.publish'),
                type: 'radiobutton',
                size: '12',
                required: true,
                options: options,
                checkVisibility: this.isInputFieldVisible
            },
            {
                name: 'published_at',
                label: t('library.form.publish.date'),
                type: 'datetime',
                required: true,
                size: '8',
                config: {
                    fromMonth: new Date(),
                    disabledDays: {
                        before: new Date()
                    }
                },
                checkVisibility: this.isInputFieldVisible
            },
            {
                name: 'delay_days',
                label: t('digitalcoaching.list.delay'),
                type: 'number',
                config: {
                    min: '0',
                    max: 60,
                    placeholder: '1'
                },
                checkVisibility: this.isInputFieldVisible
            },
            {
                name: 'category_ids',
                label: t('library.form.publish.to'),
                type: 'categories',
                required:
                    isVoid(this.props.client) && isVoid(this.props.receiver),
                isArray: true,
                size: '12',
                checkVisibility: this.isInputFieldVisible,
                disable: this.props.categories
            }
        ];

        if (this.state.dateOnly) {
            fields[0].options.splice(0, 1);
        }

        if (
            !isVoid(this.props.client) &&
            !isVoid(this.props.client.coaching_starts_at)
        ) {
            fields[1].config.disabledDays.before = moment
                .max(moment(), moment(this.props.client.coaching_starts_at))
                .toDate();

            if (!isVoid(this.props.client.coaching_ends_at)) {
                fields[1].config.disabledDays.after = moment(
                    this.props.client.coaching_ends_at
                )
                    .subtract(2, 'hours')
                    .toDate();
            }
        }

        return fields;
    }

    isInputFieldVisible = (field, model) => {
        if (field.name === 'published_at') {
            return model.publish_type === 'date';
        } else if (field.name === 'delay_days') {
            return model.publish_type === 'delay';
        }

        if (field.name === 'category_ids') {
            return isVoid(this.props.client) && isVoid(this.props.receiver);
        }

        return true;
    };

    prepareFormData(model) {
        const data = {};

        if (
            model.publish_type === 'instant' ||
            (model.publish_type === 'date' &&
                moment.utc(model.published_at).isBefore(moment.utc()))
        ) {
            model.published_at = moment.utc().format();
            model.delay_days = null;
        } else if (model.publish_type === 'never') {
            model.published_at = null;
            model.delay_days = null;
        } else if (model.publish_type === 'delay') {
            model.published_at = null;
            if (isVoid(model.delay_days)) {
                model.delay_days = 1;
            }
        }

        // set categories if we're not linking for client
        if (!isVoid(model.category_ids)) {
            data.categories = model.category_ids.map(id => ({
                id,
                published_at: model.published_at,
                delay_days: model.delay_days
            }));
        }

        if (!isVoid(this.props.receiver)) {
            data[this.props.receiver.type] = [
                {
                    id: isVoid(this.props.client)
                        ? this.props.receiver.id
                        : this.props.client.id,
                    published_at: model.published_at,
                    delay_days: model.delay_days
                }
            ];

            if (!isVoid(this.props.client)) {
                data.id = this.props.receiver.entry;
            }
        } else if (!isVoid(this.props.client)) {
            data.clients = [
                {
                    id: this.props.client.id,
                    published_at: model.published_at,
                    delay_days: model.delay_days
                }
            ];
            data.id = this.state.selected.id;
        }

        return data;
    }

    render() {
        const { link, selected, upload, searchKey } = this.state;
        const {
            client,
            createLibraryEntry,
            error,
            isLoading,
            library,
            filters,
            hasFilters,
            onDismiss,
            onFinished,
            page,
            pagination,
            receiver,
            sort
        } = this.props;

        if (isVoid(link)) {
            return null;
        }

        if (upload) {
            return (
                <LibraryUploadForm
                    entry={{}}
                    onCreate={createLibraryEntry}
                    onDismissClicked={this.handleDismiss}
                    onFinished={this.handleFinished}
                />
            );
        }

        let title;

        if (!isVoid(selected)) {
            title = t('library.form.link.title') + ` (${selected.file.name})`;
        } else if (!isVoid(client) && isVoid(receiver)) {
            title = t('library.form.choose.title');
        } else if (!isVoid(receiver)) {
            title = t('library.form.link.edit.title');
        } else {
            title = t('library.form.link.title');
        }

        return (
            <DialogModal onDismissClicked={onDismiss} title={title}>
                {!isVoid(client) && isVoid(receiver) && isVoid(selected) ? (
                    <>
                        <ErrorView error={error} />

                        <div className="d-flex align-items-center mb-2">
                            <PageFilter
                                onClearClick={this.handleClear}
                                showClear={hasFilters}>
                                <SearchField
                                    key={searchKey}
                                    name="search"
                                    placeholder={
                                        t('library.filter.search') + '...'
                                    }
                                    onChange={this.handleFilterChange}
                                    initialValue={filters.search}
                                    className="mt-2 mt-md-0 ml-xl-auto"
                                />
                            </PageFilter>
                            <button
                                onClick={this.handleUpload}
                                className="btn btn-tertiary d-block mb-2 ml-auto">
                                {t('library.form.client.upload')}
                            </button>
                        </div>

                        <div
                            className="overflow-auto mb-2"
                            style={{ maxHeight: '50vh' }}>
                            <LibraryTable
                                hideActions
                                library={library}
                                isLoading={isLoading}
                                page={page}
                                pagination={pagination}
                                sort={sort}
                                onSelect={this.handleSelect}
                                onSort={this.handleSort}
                                onPageChanged={this.handlePageChanged}
                            />
                        </div>
                    </>
                ) : (
                    <DynamicForm
                        model={link}
                        fields={this.getFields()}
                        onFinished={onFinished}
                        onSaveForm={this.handleSubmit}
                    />
                )}

                {!isVoid(selected) && (
                    <button
                        onClick={this.handleBackClick}
                        className="btn btn-to btn-back mb-3 p-0">
                        &laquo;&nbsp;
                        {t('general.prev')}
                    </button>
                )}
            </DialogModal>
        );
    }
}

const mapStateToProps = state => ({
    error: selectLibraryError(state),
    filters: selectLibraryFilters(state),
    hasFilters: hasSetFilters(state),
    isLoading: isLoadingData(state.library),
    library: selectLibraryEntries(state),
    page: selectPage(selectLibraryData(state)),
    pagination: selectPagination(selectLibraryData(state)),
    sort: selectSort(selectLibraryData(state))
});

const mapDispatchToProps = dispatch => ({
    createLibraryEntry: file => request(dispatch(createLibraryEntry(file))),
    fetchLibrary: page => dispatch(fetchLibrary(page)),
    getLibrary: () => dispatch(getLibrary()),
    resetFilters: () => dispatch(resetFilters()),
    setFilters: filters => dispatch(setFilters(filters))
});

export default connect(mapStateToProps, mapDispatchToProps)(LibraryLinkForm);
