import { getError, AuthActions } from '@dive/react-redux-networking';
import { CategoryActions } from '../actions/categories';
import { sortCategoriesFilter } from '../model/category';

const DEFAULT = { isLoading: false };

const categories = (state = Object.assign({}, DEFAULT), action) => {
    switch (action.type) {
        /*
         * CATEGORIES
         */
        case CategoryActions.GET_CATEGORIES:
            return Object.assign({}, state, { isLoading: true, error: null });
        case CategoryActions.GET_CATEGORIES_SUCCESS:
            return Object.assign({}, state, {
                data: action.payload.data.sort(sortCategoriesFilter),
                isLoading: false,
                timestamp: new Date().getTime()
            });
        case CategoryActions.GET_CATEGORIES_FAIL:
            return Object.assign({}, state, {
                isLoading: false,
                error: getError(action).getMessage()
            });

        case CategoryActions.CLEAR_CATEGORIES:
            return Object.assign({}, DEFAULT);

        /* LOGOUT */
        case AuthActions.CLEAR_AUTH:
            // remove list
            return Object.assign({}, DEFAULT);

        default:
            return state;
    }
};

export default categories;
