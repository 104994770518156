import { shouldRefreshStateData } from '../utils/data';

export const ConsultationsActions = {
    GET_CONSULTATIONS: '/consultations/LOAD',
    GET_CONSULTATIONS_SUCCESS: '/consultations/LOAD_SUCCESS',
    GET_CONSULTATIONS_FAIL: '/consultations/LOAD_FAIL',

    CLEAR_CONSULTATIONS: '/consultations/CLEAR',

    GET_CLIENT_CONSULTATIONS: '/client/consultations/LOAD',
    GET_CLIENT_CONSULTATIONS_SUCCESS: '/client/consultations/LOAD_SUCCESS',
    GET_CLIENT_CONSULTATIONS_FAIL: '/client/consultations/LOAD_FAIL',

    CLEAR_CLIENT_CONSULTATIONS: '/client/consultations/CLEAR',

    GET_CONSULTATION: '/consultation/LOAD',
    GET_CONSULTATION_SUCCESS: '/consultation/LOAD_SUCCESS',
    GET_CONSULTATION_FAIL: '/consultation/LOAD_FAIL',

    CLEAR_CONSULTATION: '/consultation/CLEAR',

    GET_UPCOMING_CONSULTATIONS: '/consultations/upcoming/LOAD',
    GET_UPCOMING_CONSULTATIONS_SUCCESS: '/consultations/upcoming/LOAD_SUCCESS',
    GET_UPCOMING_CONSULTATIONS_FAIL: '/consultations/upcoming/LOAD_FAIL',

    CLEAR_UPCOMING_CONSULTATIONS: '/consultations/upcoming/CLEAR',

    CREATE_CONSULTATION: '/consultation/CREATE',
    CREATE_CONSULTATION_SUCCESS: '/consultation/CREATE_SUCCESS',
    CREATE_CONSULTATION_FAIL: '/consultation/CREATE_FAIL',

    UPDATE_CONSULTATION: '/consultation/UPDATE',
    UPDATE_CONSULTATION_SUCCESS: '/consultation/UPDATE_SUCCESS',
    UPDATE_CONSULTATION_FAIL: '/consultation/UPDATE_FAIL',

    REMOVE_CONSULTATION: '/consultation/REMOVE',
    REMOVE_CONSULTATION_SUCCESS: '/consultation/REMOVE_SUCCESS',
    REMOVE_CONSULTATION_FAIL: '/consultation/REMOVE_FAIL'
};

const CONSULTATIONS_CACHE_INTERVAL = 1000 * 60 * 5;

export const clearConsultations = () => ({
    type: ConsultationsActions.CLEAR_CONSULTATIONS
});

export const getConsultations = () => (dispatch, getState) => {
    if (
        shouldRefreshStateData(
            getState().consultations,
            CONSULTATIONS_CACHE_INTERVAL
        )
    ) {
        // first clear previous consultations
        dispatch(clearConsultations());

        // fetch new consultations
        return dispatch(
            fetchConsultations(
                getState().consultations.data
                    ? getState().consultations.data.page
                    : 1
            )
        );
    }
};

export const fetchConsultations = (page = 1) => ({
    type: ConsultationsActions.GET_CONSULTATIONS,
    payload: {
        request: {
            url: `/consultations?page=${page}`,
            method: 'GET'
        }
    }
});

export const fetchClientConsultations = (id, page = 1, sortColumn, order) => {
    const sortQuery = sortColumn ? `&sort=${sortColumn}:${order}` : '';

    return {
        type: ConsultationsActions.GET_CLIENT_CONSULTATIONS,
        payload: {
            request: {
                url: `/clients/${id}/consultations?page=${page}${sortQuery}`,
                method: 'GET'
            }
        }
    };
};

export const getConsultationDetail = id => ({
    type: ConsultationsActions.GET_CONSULTATION,
    payload: {
        request: {
            url: `/consultations/${id}`,
            method: 'GET',
            id
        }
    }
});

export const getUpcomingConsultations = () => (dispatch, getState) => {
    if (
        shouldRefreshStateData(
            getState().consultations.upcoming,
            CONSULTATIONS_CACHE_INTERVAL
        )
    ) {
        return dispatch(fetchUpcomingConsultations());
    }
};

export const fetchUpcomingConsultations = () => ({
    type: ConsultationsActions.GET_UPCOMING_CONSULTATIONS,
    payload: {
        request: {
            url: `/consultations/upcoming`,
            method: 'GET'
        }
    }
});

export const updateConsultation = consultation => ({
    type: consultation.id
        ? ConsultationsActions.UPDATE_CONSULTATION
        : ConsultationsActions.CREATE_CONSULTATION,
    payload: {
        request: {
            url: consultation.id
                ? `/consultations/${consultation.id}`
                : '/consultations',
            method: consultation.id ? 'PUT' : 'POST',
            data: consultation,
            id: consultation.id
        }
    }
});

export const deleteConsultation = id => ({
    type: ConsultationsActions.REMOVE_CONSULTATION,
    payload: {
        request: {
            url: `/consultations/${id}`,
            method: 'DELETE',
            id
        }
    }
});
