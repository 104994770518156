import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { t } from '@dive/localization-js';
import Card from '../../../../shared/card/Card';
import GoalForm from './form/GoalForm';
import RoundButton from '../../../../shared/buttons/RoundButton';
import DeleteAlertModal from '../../../../shared/modal/DeleteAlertModal';
import EmptyView from '../../../../shared/view/EmptyView';
import ErrorView from '../../../../shared/view/ErrorView';
import GoalItemView from './item/GoalItemView';
import LoadingIcon from '../../../../shared/loading/LoadingIcon';

const GoalsView = ({
    readOnly,
    client,
    goals,
    error,
    isLoading,
    updateGoalInfo,
    deleteGoalInfo,
    addGoal,
    editGoal,
    deleteGoal,
    changeStatus,
    deleteClientGoals,
    onFinishAction
}) => {
    return (
        <Fragment>
            <div className="d-flex flex-wrap justify-content-between align-items-end mb-2">
                <h2 className="mr-5 mb-0">{t('treatment.goals')}</h2>
                {!readOnly && (
                    <RoundButton
                        icon="add"
                        className="btn-round mb-auto"
                        onClick={() => addGoal()}
                        label={t('treatment.goals.add')}
                    />
                )}
            </div>
            {isLoading ? (
                <Card className="mb-3">
                    <LoadingIcon />
                </Card>
            ) : (
                <Fragment>
                    <Card>
                        {goals && goals.length > 0 ? (
                            <ul className="bordered-list m-0 p-0">
                                {goals.map(goal => (
                                    <GoalItemView
                                        goal={goal}
                                        key={goal.id}
                                        editGoal={editGoal}
                                        deleteGoal={deleteGoal}
                                        changeStatus={changeStatus}
                                    />
                                ))}
                            </ul>
                        ) : error ? (
                            <ErrorView error={error} />
                        ) : (
                            <EmptyView label={t('treatment.goals.empty')} />
                        )}
                    </Card>
                    {updateGoalInfo && (
                        <GoalForm
                            client={client}
                            goal={updateGoalInfo}
                            onDismissClicked={() => editGoal(null)}
                            onFinished={() => onFinishAction()}
                        />
                    )}
                    {deleteGoalInfo && (
                        <DeleteAlertModal
                            title={t('treatment.goals.delete.title')}
                            description={t(
                                'treatment.goals.delete.description'
                            )}
                            onDelete={() =>
                                deleteClientGoals(client.id, deleteGoalInfo.id)
                            }
                            onDismissClicked={() => deleteGoal(null)}
                            onFinished={() => onFinishAction()}
                        />
                    )}
                </Fragment>
            )}
        </Fragment>
    );
};

GoalsView.propTypes = {
    readOnly: PropTypes.bool,
    client: PropTypes.object,
    goals: PropTypes.array,
    error: PropTypes.string,
    isLoading: PropTypes.bool,
    updateGoalInfo: PropTypes.object,
    deleteGoalInfo: PropTypes.object,
    addGoal: PropTypes.func,
    editGoal: PropTypes.func,
    changeStatus: PropTypes.func,
    deleteGoal: PropTypes.func,
    deleteClientGoals: PropTypes.func,
    onFinishAction: PropTypes.func
};

export default GoalsView;
