import { getError, AuthActions } from '@dive/react-redux-networking';
import { TagActions } from '../actions/tags';
import { sortTagsFilter } from '../model/tag';

const DEFAULT = { isLoading: false };

const tags = (state = Object.assign({}, DEFAULT), action) => {
    switch (action.type) {
        /*
         * TAGS
         */
        case TagActions.GET_TAGS:
            return Object.assign({}, state, { isLoading: true, error: null });
        case TagActions.GET_TAGS_SUCCESS:
            return Object.assign({}, state, {
                data: action.payload.data.sort(sortTagsFilter),
                isLoading: false,
                timestamp: new Date().getTime()
            });
        case TagActions.GET_TAGS_FAIL:
            return Object.assign({}, state, {
                isLoading: false,
                error: getError(action).getMessage()
            });

        case TagActions.CLEAR_TAGS:
            return Object.assign({}, DEFAULT);

        /* LOGOUT */
        case AuthActions.CLEAR_AUTH:
            // remove list
            return Object.assign({}, DEFAULT);

        default:
            return state;
    }
};

export default tags;
