import { isVoid } from '@dive/utils-js';
import { t } from '@dive/localization-js';
import { request } from '@dive/react-redux-networking';
import React, { Component } from 'react';
import { connect } from 'react-redux';

import BackButton from '../../shared/buttons/BackButton';
import { ROUTE_LIBRARY } from '../../../platform/routing';
import ContentHeader from '../../shared/content/ContentHeader';
import PageHeader from '../../shared/header/PageHeader';
import {
    deleteEntryReceivers,
    fetchLibraryEntry,
    updateEntryReceivers
} from '../../../../core/actions/library';
import ContentBody from '../../shared/content/ContentBody';
import ErrorView from '../../shared/view/ErrorView';
import LoadingIcon from '../../shared/loading/LoadingIcon';
import LibraryDetail from './LibraryDetail';
import RoundButton from '../../shared/buttons/RoundButton';
import { FileType } from '../../../../core/models/library';
import Lightbox from '../../shared/lightbox/Lightbox';
import LibraryLinkForm from '../form/LibraryLinkForm';
import { selectCategories } from '../../../platform/model/category';

class LibraryDetailContainer extends Component {
    state = {
        displayForm: false
    };

    constructor(props) {
        super(props);

        this.id = this.props.match.params.id;
    }

    // ///////////////////////////////////////////////////////////////////////
    // LIFECYCLE METHODS
    // ///////////////////////////////////////////////////////////////////////

    componentDidMount() {
        this.fetchEntry();
    }

    // ///////////////////////////////////////////////////////////////////////
    // EVENT HANDLERS
    // ///////////////////////////////////////////////////////////////////////

    handleAdd = () => {
        this.setState({ displayForm: true });
    };

    handleClose = () => {
        this.setState({ image: null });
    };

    handleCreate = data => {
        return this.props.updateEntryReceivers(this.id, data);
    };

    handleDelete = receiver => {
        const data = { [receiver.type]: [{ id: receiver.id }] };

        return this.props
            .deleteEntryReceivers(this.id, data)
            .then(() => this.removeReceiver(receiver));
    };

    handleDismiss = () => {
        this.setState({ displayForm: false, receiver: null });
    };

    handleEdit = receiver => {
        this.setState({ displayForm: true, receiver });
    };

    handleFinished = () => {
        this.fetchEntry();
        this.setState({ displayForm: false, receiver: null });
    };

    handlePreview = () => {
        const { file } = this.state.entry;

        if (file.type !== FileType.IMAGE) {
            window.open(file.versions.original);
            return;
        }

        this.setState({ image: file.versions.large || file.versions.original });
    };

    // ///////////////////////////////////////////////////////////////////////
    // OTHERS
    // ///////////////////////////////////////////////////////////////////////

    fetchEntry() {
        this.props
            .fetchLibraryEntry(this.id)
            .then(entry => {
                this.setState({ entry });
            })
            .catch(err => {
                this.setState({
                    error: err.getMessage()
                });
            });
    }

    removeReceiver(receiver) {
        const entry = { ...this.state.entry };

        entry.receivers[receiver.type] = entry.receivers[receiver.type].filter(
            r => r.id !== receiver.id
        );

        this.setState({ entry });
    }

    render() {
        const { displayForm, receiver, entry, error, image } = this.state;

        if (!isVoid(error)) {
            return (
                <ContentBody>
                    <ErrorView error={error} />
                </ContentBody>
            );
        }

        if (isVoid(entry)) {
            return <LoadingIcon />;
        }

        return (
            <>
                <ContentHeader>
                    <PageHeader before title={entry.file.name}>
                        <div className="d-flex justify-content-between">
                            <BackButton
                                className="align-self-start mb-3"
                                to={ROUTE_LIBRARY}
                                label={t('general.detail.back')}
                            />

                            {entry.receivers.categories.length !==
                                this.props.categories.length && (
                                <RoundButton
                                    className="mt-0"
                                    icon="link"
                                    label={t('library.detail.add')}
                                    onClick={this.handleAdd}
                                />
                            )}
                        </div>
                    </PageHeader>
                </ContentHeader>

                <ContentBody>
                    <LibraryDetail
                        onDelete={this.handleDelete}
                        onEdit={this.handleEdit}
                        onPreview={this.handlePreview}
                        entry={entry}
                    />
                </ContentBody>

                {!isVoid(image) && (
                    <Lightbox url={image} onClose={this.handleClose} />
                )}

                {displayForm && (
                    <LibraryLinkForm
                        categories={entry.receivers.categories}
                        onCreate={this.handleCreate}
                        onDismiss={this.handleDismiss}
                        onFinished={this.handleFinished}
                        receiver={receiver}
                    />
                )}
            </>
        );
    }
}

const mapStateToProps = state => ({
    categories: selectCategories(state)
});

const mapDispatchToProps = dispatch => ({
    deleteEntryReceivers: (id, data) =>
        request(dispatch(deleteEntryReceivers(id, data))),
    fetchLibraryEntry: id => request(dispatch(fetchLibraryEntry(id))),
    updateEntryReceivers: (id, data) =>
        request(dispatch(updateEntryReceivers(id, data)))
});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(LibraryDetailContainer);
