import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { t } from '@dive/localization-js';
import { request } from '@dive/react-redux-networking';

import DynamicFormDialog from '../../../../shared/forms/DynamicFormDialog';
import { updateFeedback } from '../../../../../../core/actions/diary';
import Icon from '../../../../shared/icon/Icon';

const mapDispatchToProps = dispatch => {
    return {
        saveFeedback: (type, feedback) => {
            return request(dispatch(updateFeedback(type, feedback)));
        }
    };
};

class FeedbackForm extends Component {
    constructor(props) {
        super(props);

        this.setFields();

        this.state = {
            isLoading: false,
            errors: {}
        };
    }

    setFields() {
        this.fields = [
            {
                name: 'score',
                label: t('diary.feedback.score'),
                type: 'radiobutton',
                size: '12',
                options: [
                    {
                        name: <Icon name="feedback" className="icon-light" />,
                        value: '0'
                    },
                    {
                        name: (
                            <Icon name="thumbs-up" className="icon-success" />
                        ),
                        value: '1'
                    },
                    {
                        name: (
                            <Icon name="thumbs-down" className="icon-alert" />
                        ),
                        value: '-1'
                    }
                ]
            },
            {
                name: 'note',
                label: t('diary.feedback.note'),
                type: 'text',
                size: '12'
            }
        ];
    }

    componentDidMount() {
        this.setState({
            feedback: Object.assign({}, this.props.feedback, {
                note: this.props.feedback.note || null,
                score: `${this.props.feedback.score || 0}`
            })
        });
    }

    onSubmit = model => {
        return this.props.saveFeedback(this.props.type, model);
    };

    render() {
        const { feedback } = this.state;
        return feedback ? (
            <DynamicFormDialog
                title={t('diary.feedback.title')}
                model={feedback}
                fields={this.fields}
                submitLabel={t('general.form.update')}
                onDismissClicked={this.props.onDismissClicked}
                onFinished={this.props.onFinished}
                onSaveForm={this.onSubmit}
            />
        ) : null;
    }
}

FeedbackForm.propTypes = {
    feedback: PropTypes.object.isRequired,
    type: PropTypes.string.isRequired,
    onDismissClicked: PropTypes.func.isRequired,
    onFinished: PropTypes.func.isRequired
};

export default connect(
    undefined,
    mapDispatchToProps
)(FeedbackForm);
