import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import Icon from 'app/components/shared/icon/Icon';

const MoreButton = ({ label, to }) => {
    return (
        <Link className={'btn-to'} to={to}>
            {label} <Icon name="arrow-right" />
        </Link>
    );
};

MoreButton.propTypes = {
    label: PropTypes.string.isRequired,
    to: PropTypes.string.isRequired
};

export default MoreButton;
