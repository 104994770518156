import { t } from '@dive/localization-js';
import React from 'react';
import moment from 'moment';
import { isEmptyText, isVoid } from '@dive/utils-js';

const PublishStatus = ({ className, children }) => {
    let classes = 'm-0';
    let li = 'mr-3 px-2 rounded-circle bg-';

    const published = isEmptyText(children)
        ? 'never'
        : moment().diff(moment(children)) > 0
        ? 'published'
        : 'pending';

    if (published === 'never') {
        li += 'dark';
    } else if (published === 'published') {
        li += 'success';
    } else {
        li += 'warning';
    }

    if (!isVoid(className)) {
        classes += ` ${className}`;
    }

    return (
        <span className={classes}>
            <i className={li}>&nbsp;</i>

            {t('library.status.' + published)}
        </span>
    );
};

export default PublishStatus;
