import { AuthActions, getError } from '@dive/react-redux-networking';

import { CategoryActions } from '../actions/categories';
import { ClientsActions } from '../actions/clients';
import { ConsultationsActions } from '../../../core/actions/consultations';
import { DashboardActions } from '../actions/dashboard';

const DEFAULT = {
    list: {}
};

const dashboard = (state = Object.assign({}, DEFAULT), action) => {
    switch (action.type) {
        // GET
        case DashboardActions.GET_DASHBOARD_CARD:
            return {
                ...state,
                list: {
                    ...state.list,
                    [action.payload.request.type]: {
                        isLoading: true
                    }
                }
            };
        case DashboardActions.GET_DASHBOARD_CARD_SUCCESS:
            return {
                ...state,
                list: {
                    ...state.list,
                    [action.payload.data.id]: {
                        ...action.payload.data,
                        isLoading: false,
                        range: action.meta.previousAction.payload.request.range
                    }
                },
                timestamp: new Date().getTime()
            };
        case DashboardActions.GET_DASHBOARD_CARD_FAIL:
            return {
                ...state,
                list: {
                    ...state.list,
                    [action.meta.previousAction.payload.request.type]: {
                        error: getError(action).getMessage(),
                        isLoading: false
                    }
                }
            };

        // REFRESH DASHBOARD WHEN ..
        case DashboardActions.CLEAR_DASHBOARD:
        case ConsultationsActions.CREATE_CONSULTATION_SUCCESS:
        case ConsultationsActions.UPDATE_CONSULTATION_SUCCESS:
        case ConsultationsActions.REMOVE_CONSULTATION_SUCCESS:
        case CategoryActions.CREATE_CATEGORY_SUCCESS:
        case CategoryActions.UPDATE_CATEGORY_SUCCESS:
        case CategoryActions.DELETE_CATEGORY_SUCCESS:
        case ClientsActions.CREATE_CLIENT_SUCCESS:
        case ClientsActions.UPDATE_CLIENT_SUCCESS:
        case ClientsActions.DELETE_CLIENT_SUCCESS:
            return Object.assign({}, DEFAULT);

        /* LOGOUT */
        case AuthActions.CLEAR_AUTH:
            // remove list
            return Object.assign({}, DEFAULT);

        default:
            return state;
    }
};

export default dashboard;
