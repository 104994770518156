import { shouldRefreshStateData } from '../../../core/utils/data';

export const CategoryActions = {
    GET_CATEGORIES: '/categories/LOAD',
    GET_CATEGORIES_SUCCESS: '/categories/LOAD_SUCCESS',
    GET_CATEGORIES_FAIL: '/categories/LOAD_FAIL',

    CLEAR_CATEGORIES: '/categories/CLEAR',

    CREATE_CATEGORY: '/categories/CREATE',
    CREATE_CATEGORY_SUCCESS: '/categories/CREATE_SUCCESS',
    CREATE_CATEGORY_FAIL: '/categories/CREATE_FAIL',

    UPDATE_CATEGORY: '/categories/UPDATE',
    UPDATE_CATEGORY_SUCCESS: '/categories/UPDATE_SUCCESS',
    UPDATE_CATEGORY_FAIL: '/categories/UPDATE_FAIL',

    DELETE_CATEGORY: '/categories/REMOVE',
    DELETE_CATEGORY_SUCCESS: '/categories/REMOVE_SUCCESS',
    DELETE_CATEGORY_FAIL: '/categories/REMOVE_FAIL'
};

const CATEGORIES_CACHE_INTERVAL = 1000 * 60 * 2;

export const getCategories = () => (dispatch, getState) => {
    const { categories } = getState();

    if (shouldRefreshStateData(categories, CATEGORIES_CACHE_INTERVAL)) {
        return dispatch(fetchCategories());
    }
};

export const fetchCategories = () => ({
    type: CategoryActions.GET_CATEGORIES,
    payload: {
        request: {
            url: `/categories`,
            method: 'GET'
        }
    }
});

export const updateCategory = category => ({
    type: category.id
        ? CategoryActions.UPDATE_CATEGORY
        : CategoryActions.CREATE_CATEGORY,
    payload: {
        request: {
            url: category.id ? `/categories/${category.id}` : '/categories',
            method: category.id ? 'PUT' : 'POST',
            data: category,
            id: category.id
        }
    }
});

export const deleteCategory = id => ({
    type: CategoryActions.DELETE_CATEGORY,
    payload: {
        request: {
            url: `/categories/${id}`,
            method: 'DELETE',
            id
        }
    }
});
