import ClientsSearchForm from '../../clients/search/ClientsSearchForm';
import DropDownMenu from '../dropdown/DropDownMenu';
import DropDownToggle from '../dropdown/DropDownToggle';
import Dropdown from '../dropdown/DropDown';
import Icon from 'app/components/shared/icon/Icon';
import { Link } from 'react-router-dom';
import LogoSmall from '../../../assets/logo-small.png';
import PropTypes from 'prop-types';
import { ROUTE_PROFILE } from '../../../platform/routing';
import React from 'react';
import { t } from '@dive/localization-js';

const Header = ({
    email,
    name,
    showSearch = true,
    showFull = true,
    onLogoutClicked
}) => {
    return (
        <nav className="navbar navbar-expand-lg fixed-top navbar-dark navbar-main">
            <Link to={'/'} className="navbar-brand">
                <img
                    className="logo"
                    alt="My Food Coach and Me"
                    src={LogoSmall}
                />
            </Link>
            {showFull && showSearch && <ClientsSearchForm />}
            <ul
                className={`navbar-nav ${
                    showFull && showSearch ? 'ml-3 ml-xl-0' : 'ml-auto'
                }`}>
                <li className="nav-item nav-item-user">
                    <Dropdown>
                        <DropDownToggle className="nav-link dropdown-toggle">
                            <Icon name="user" />
                            <span>{email}</span>
                        </DropDownToggle>
                        <DropDownMenu>
                            {showFull && (
                                <Link
                                    className="dropdown-item"
                                    to={ROUTE_PROFILE}>
                                    <Icon name="user" />{' '}
                                    {t('header.my_profile')}
                                </Link>
                            )}
                            {showFull && <div className="dropdown-divider" />}
                            <button
                                className="dropdown-item btn-alert"
                                onClick={onLogoutClicked}>
                                <Icon name="logout" /> {t('header.logout')}
                            </button>
                        </DropDownMenu>
                    </Dropdown>
                </li>
            </ul>
        </nav>
    );
};

Header.propTypes = {
    email: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    onLogoutClicked: PropTypes.func.isRequired,
    showFull: PropTypes.bool
};

export default Header;
