import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import {
    getFormattedPrice,
    getName,
    getPrice,
    getPriceWithCurrency,
    getPriceWithTax,
    isTrial
} from '../../../../platform/model/subscription';
import { getFormattedNumber } from '../../../../../core/utils/data';
import Card from '../../../shared/card/Card';
import { t } from '@dive/localization-js';
import CouponButton from './CouponButton';

const ShoppingCart = ({
    plan,
    tax,
    currentPlan,
    payment: { proration_amount, coupon },
    onCouponSelect
}) => {
    return (
        <Card className="card-bordered mb-4 mt-4">
            <p className="d-flex">
                <span className="font-weight-bold">{getName(plan)}</span>
                <span
                    className="text-right ml-auto"
                    style={{ minWidth: '20%' }}>
                    {getFormattedPrice(plan)} / {t('subscriptions.plans.month')}
                </span>
            </p>
            <p className="d-flex justify-content-end">
                <span className="text-right" style={{ minWidth: '20%' }}>
                    {t('subscriptions.total_vat_excl')}
                </span>
                <span className="text-right" style={{ minWidth: '20%' }}>
                    {getPriceWithCurrency(
                        plan,
                        getFormattedNumber(getPrice(plan) / 100, 'nl')
                    )}
                </span>
            </p>
            {coupon.discount > 0 && (
                <Fragment>
                    <p className="d-flex justify-content-end font-weight-bold">
                        <span
                            className="text-right"
                            style={{ minWidth: '20%' }}>
                            {t('subscriptions.total_coupon', {
                                code: coupon.code
                            })}
                        </span>
                        <span
                            className="text-right"
                            style={{ minWidth: '20%' }}>
                            -
                            {coupon.discount
                                ? getPriceWithCurrency(
                                      plan,
                                      getFormattedNumber(
                                          coupon.discount / 100,
                                          'nl'
                                      )
                                  )
                                : `${coupon.discountPercent}%`}
                        </span>
                    </p>
                    <p className="d-flex justify-content-end">
                        <span
                            className="text-right"
                            style={{ minWidth: '20%' }}>
                            {t('subscriptions.total_vat_excl')}
                        </span>
                        <span
                            className="text-right"
                            style={{ minWidth: '20%' }}>
                            {getPriceWithCurrency(
                                plan,
                                getFormattedNumber(
                                    getPrice(
                                        plan,
                                        coupon ? coupon.discount : null,
                                        coupon ? coupon.discountPercent : null
                                    ) / 100,
                                    'nl'
                                )
                            )}
                        </span>
                    </p>
                </Fragment>
            )}
            {proration_amount > 0 && (
                <Fragment>
                    <p className="d-flex justify-content-end">
                        <span
                            className="text-right"
                            style={{ minWidth: '20%' }}>
                            {t('subscriptions.pro_ration_total')}
                        </span>
                        <span
                            className="text-right"
                            style={{ minWidth: '20%' }}>
                            -
                            {getPriceWithCurrency(
                                plan,
                                getFormattedNumber(proration_amount / 100, 'nl')
                            )}
                        </span>
                    </p>
                    <p className="d-flex justify-content-end">
                        <span
                            className="text-right"
                            style={{ minWidth: '20%' }}>
                            {t('subscriptions.total_vat_excl')}
                        </span>
                        <span
                            className="text-right"
                            style={{ minWidth: '20%' }}>
                            {getPriceWithCurrency(
                                plan,
                                getFormattedNumber(
                                    getPrice(plan, proration_amount) / 100,
                                    'nl'
                                )
                            )}
                        </span>
                    </p>
                </Fragment>
            )}
            <p className="d-flex justify-content-end font-weight-bold">
                <span className="text-right" style={{ minWidth: '20%' }}>
                    {t('subscriptions.total_vat_incl', { vat: tax })}
                </span>
                <span className="text-right" style={{ minWidth: '20%' }}>
                    {getPriceWithCurrency(
                        plan,
                        getFormattedNumber(
                            getPriceWithTax(
                                plan,
                                tax,
                                coupon && coupon.discount > 0
                                    ? coupon.discount
                                    : proration_amount > 0
                                    ? proration_amount
                                    : null,
                                coupon ? coupon.discountPercent : null
                            ) / 100,
                            'nl'
                        )
                    )}
                </span>
            </p>
            {!coupon.code && proration_amount === 0 && isTrial(currentPlan) && (
                <div className="d-flex justify-content-end mt-4">
                    <div style={{ maxWidth: '500px' }}>
                        <CouponButton onCouponSelect={onCouponSelect} />
                    </div>
                </div>
            )}
        </Card>
    );
};

ShoppingCart.propTypes = {
    plan: PropTypes.object.isRequired,
    currentPlan: PropTypes.object,
    tax: PropTypes.number.isRequired,
    payment: PropTypes.object,
    coupon: PropTypes.object,
    onCouponSelect: PropTypes.func
};

export default ShoppingCart;
