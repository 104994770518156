import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { t } from '@dive/localization-js';
import moment from 'moment';

import ContentHeader from 'app/components/shared/content/ContentHeader';
import ContentBody from 'app/components/shared/content/ContentBody';
import Card from '../shared/card/Card';
import { Link } from 'react-router-dom';
import {
    ROUTE_SUBSCRIPTION_CHOOSE,
    ROUTE_SUBSCRIPTION_EXTEND
} from '../../platform/routing';
import ProfileForm from './form/ProfileForm';
import { isAdmin } from '../../../core/models/user';
import {
    getClientLimit,
    getEndDate,
    getStartDate,
    getName,
    isTrial,
    isUnlimited
} from '../../platform/model/subscription';
import { showMessage } from '../../platform/actions/messages';

const mapStateToProps = state => {
    return {
        profile: state.user.data,
        showSubscription: !isAdmin(state.user.data)
    };
};

const mapDispatchToProps = dispatch => {
    return {
        showMessage: message => {
            return dispatch(showMessage(message));
        }
    };
};

class ProfileContainer extends Component {
    onProfileChanged = () => {
        this.props.showMessage(t('profile.edit.success'));
    };

    render() {
        const { profile } = this.props;

        return (
            <Fragment>
                <ContentHeader>
                    <h1>{t('profile')}</h1>
                </ContentHeader>
                <ContentBody>
                    <div className="row">
                        <div
                            className={
                                this.props.showSubscription
                                    ? 'col-lg-6'
                                    : 'col-lg-12'
                            }>
                            <h2>{t('profile.profile')}</h2>
                            <Card className="mb-4" padding={true}>
                                <ProfileForm
                                    profile={profile}
                                    mode={'profile'}
                                    onFinished={() => this.onProfileChanged()}
                                />
                            </Card>
                        </div>
                        {this.props.showSubscription && (
                            <div className={'col-lg-6'}>
                                <h2>{t('profile.plan')}</h2>
                                <Card className="mb-4" padding={true}>
                                    <h3>{getName(profile.current_plan)}</h3>
                                    <div className="row mt-3">
                                        <Fragment>
                                            <dt className="col-sm-4">
                                                {t('profile.plan.type')}
                                            </dt>
                                            <dd className="col-sm-8">
                                                {getName(profile.current_plan)}
                                                {isUnlimited(
                                                    profile.current_plan
                                                ) ? (
                                                    <p className="label mb-0">
                                                        {t(
                                                            'subscriptions.client_limit.unlimited'
                                                        )}
                                                    </p>
                                                ) : (
                                                    <p className="label mb-0">
                                                        {t(
                                                            'subscriptions.client_limit.amount',
                                                            {
                                                                limit: getClientLimit(
                                                                    profile.current_plan
                                                                )
                                                            }
                                                        )}
                                                    </p>
                                                )}
                                                <div className="mt-0">
                                                    <Link
                                                        className="d-block"
                                                        to={
                                                            ROUTE_SUBSCRIPTION_CHOOSE
                                                        }>
                                                        {t('subscription.edit')}
                                                    </Link>
                                                </div>
                                            </dd>
                                        </Fragment>
                                        <Fragment>
                                            <dt className="col-sm-4">
                                                {t('profile.plan.expires')}
                                            </dt>
                                            <dd className="col-sm-8">
                                                {getEndDate(
                                                    profile.current_plan
                                                ) !== null &&
                                                getEndDate(
                                                    profile.current_plan
                                                ) !== undefined
                                                    ? moment(
                                                          getEndDate(
                                                              profile.current_plan
                                                          )
                                                      ).format(
                                                          `DD/MM/YYYY [-] HH[${t(
                                                              'general.units.hour'
                                                          )}]mm`
                                                      )
                                                    : '-'}
                                                {!isTrial(
                                                    profile.current_plan
                                                ) &&
                                                    moment().diff(
                                                        getStartDate(
                                                            profile.current_plan
                                                        )
                                                    ) > 0 && (
                                                        <Link
                                                            className="d-block"
                                                            to={
                                                                ROUTE_SUBSCRIPTION_EXTEND
                                                            }>
                                                            {t(
                                                                'subscription.extend'
                                                            )}
                                                        </Link>
                                                    )}
                                            </dd>
                                        </Fragment>
                                    </div>
                                </Card>
                            </div>
                        )}
                        <div className="col-lg-6">
                            <h2>{t('profile.links')}</h2>
                            <Card padding={true}>
                                <ul
                                    className="p-0 m-0"
                                    style={{ listStyleType: 'none' }}>
                                    <li>
                                        <a
                                            target="_blank"
                                            rel="noopener noreferrer"
                                            href="https://myfoodcoachandme.com/wp-content/uploads/My-Food-Coach-and-Me-PRIVACY-POLICY.pdf">
                                            {t(
                                                'register.form.terms.privacy_policy'
                                            )}
                                        </a>
                                    </li>
                                    <li className="mt-1">
                                        <a
                                            target="_blank"
                                            rel="noopener noreferrer"
                                            href="https://myfoodcoachandme.com/wp-content/uploads/My-Food-Coach-and-Me-LICENTIEVOORWAARDEN.pdf">
                                            {t('register.form.terms.licenses')}
                                        </a>
                                    </li>
                                    <li className="mt-1">
                                        <a
                                            target="_blank"
                                            rel="noopener noreferrer"
                                            href="https://myfoodcoachandme.com/wp-content/uploads/My-Food-Coach-and-Me-VERWERKINGSVOORWAARDEN.pdf">
                                            {t(
                                                'register.form.terms.data_protection'
                                            )}
                                        </a>
                                    </li>
                                </ul>
                            </Card>
                            <p className="d-block mt-2 text-center w-100 text-body-light font-size-extra-small">
                                {t('profile.build.version', {
                                    version: process.env.REACT_APP_VERSION
                                })}
                                <br />
                                {t('profile.build.number', {
                                    build: process.env.REACT_APP_CURRENT_GIT_SHA
                                })}
                            </p>
                        </div>
                    </div>
                </ContentBody>
            </Fragment>
        );
    }
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(ProfileContainer);
