import { fetchCategories } from './categories';
import { fetchSettings } from '../../../core/actions/settings';
import { fetchUserFields } from './fields';
import { fetchTags } from './tags';

export const fetchInitialData = () => dispatch => {
    dispatch(fetchCategories());
    dispatch(fetchTags());

    dispatch(fetchUserFields('consultation'));

    return dispatch(fetchSettings());
};
