export default {
    'general.form.create': 'Aanmaken',
    'general.form.update': 'Opslaan',
    'general.form.send': 'Verzenden',
    'general.form.confirm': 'Bevestigen',
    'general.form.upload': 'Kies bestand',
    'general.form.image': 'Kies afbeelding',
    'general.form.errors.required': 'Dit veld is verplicht',
    'general.form.errors.number': 'Dit is geen geldig getal',
    'general.form.errors.email': 'Dit is geen geldig e-mailadres',
    'general.form.errors.equals': 'Dit moet gelijk zijn aan {label}',
    'general.form.errors.length':
        'Dit veld moet {equality} {characters} tekens hebben.',
    'general.form.errors.min': 'minstens',
    'general.form.errors.max': 'maximaal',
    'general.forms.error.categories.empty':
        "Je hebt nog geen categorieën. Voeg deze toe onder 'Instellingen'",
    'general.forms.error.tags.empty':
        "Je hebt nog geen tags. Voeg deze toe onder 'Instellingen'",

    'general.form.errors.max_size':
        'Het geselecteerde bestand is te groot (maximaal 40MB)',
    'general.form.errors.image/*': 'Gelieve een afbeelding te selecteren',

    'general.detail.back': 'Terug naar overzicht',

    'general.units.kg': 'kg',
    'general.units.g': 'g',
    'general.units.ml': 'ml',
    'general.units.portion': 'portie(s)',
    'general.units.handfull': 'handje(s)',
    'general.units.slice': 'sneetje(s)',
    'general.units.glass': 'glas(glazen)',
    'general.units.large_glass': 'groot glas(glazen)',
    'general.units.cup': 'tas(sen)',
    'general.units.large_cup': 'grote tas(sen)',
    'general.units.tablespoon': 'eetlepel(s)',
    'general.units.teaspoon': 'koffielepel(s)',
    'general.units.jar': 'potje(s)',
    'general.units.pinch': 'mespunt(en)',
    'general.units.cube': 'klontje(s)',
    'general.units.fat': 'vet',
    'general.units.pieces': 'stuk(s)',
    'general.units.cm': 'cm',
    'general.units.hour': 'u',
    'general.units.minutes': 'min',
    'general.units.kcal': 'kcal',
    'general.units.year': 'j',
    'general.units.glasses': 'Glazen',
    'general.units.cups': 'Tassen',

    'general.trial_period': 'Trial: {days} dag(en) resterend',
    'general.plan_expires': 'Opgelet: Abonnement vervalt binnen {days} dag(en)',

    'general.filter.start_date': 'Van',
    'general.filter.end_date': 'Tot',

    'general.cancel': 'Annuleren',
    'general.close': 'Sluiten',
    'general.remove': 'Verwijderen',
    'general.duplicate': 'Dupliceren',
    'general.confirm': 'Bevestigen',
    'general.edit': 'Wijzigen',
    'general.yes': 'Ja',
    'general.no': 'Nee',
    'general.actions': 'Acties',
    'general.filter': 'Filteren',
    'general.next': 'Volgende',
    'general.prev': 'Vorige',
    'general.file.open': 'Bestand openen',
    'general.upload': 'Uploaden',
    'general.save': 'Opslaan',
    'general.from': 'vanaf',
    'general.until': 't.e.m.',

    'general.impersonation.warning': 'Je doet je momenteel voor als {user}',
    'general.impersonation.stop': 'Stoppen',

    'general.unread': 'Ongelezen',

    'mobile_app.title': 'Mobiele app',
    'mobile_app.description':
        'Om gebruik te maken van My Food Coach and Me, download de mobiele app voor Android of iOS:',

    'errors.something_went_wrong': 'Er ging iets mis',

    'header.my_profile': 'Mijn profiel',
    'header.logout': 'Uitloggen',

    'login.login.title': 'Inloggen',
    'login.login.description': 'Log in met je My Food Coach and Me account',
    'login.login.form.email.label': 'E-mail',
    'login.login.form.password.label': 'Wachtwoord',
    'login.login.form.submit': 'Inloggen',
    'login.login.form.errors.auth':
        'Combinatie e-mail en wachtwoord is niet correct',
    'login.register.title': 'Nog geen account?',
    'login.register.description':
        'Maak een account aan en start met ons gratis FREE abonnement',
    'login.register.button': 'Uitproberen',

    'register.title': 'Account aanmaken als food coach',
    'register.description':
        'Maak een account aan en start met ons gratis FREE abonnement.',
    'register.title.success': 'Account aangemaakt',
    'register.description.success':
        'Je ontvangt dadelijk een mail om je account te activeren.',
    'register.form.email': 'E-mail',
    'register.form.password': 'Wachtwoord',
    'register.form.password_confirm': 'Wachtwoord bevestigen',
    'register.form.first_name': 'Voornaam',
    'register.form.name': 'Naam',
    'register.form.country': 'Land',
    'register.form.terms': 'Ik ga akkoord met de ',
    'register.form.terms.privacy_policy': 'Privacy policy',
    'register.form.terms.licenses': 'Licentievoorwaarden',
    'register.form.terms.data_protection': 'Verwerkingsvoorwaarden',

    'verify.email.success': 'Account geactiveerd. Log opnieuw in',

    'reset.password.link': 'Wachtwoord vergeten?',
    'reset.password.title': 'Wachtwoord aanpassen',
    'reset.password.title.success': 'Mail succesvol verzonden',
    'reset.password.description':
        'Vul je e-mail in en ontvang zo dadelijk een mail om je wachtwoord aan te passen.',
    'reset.password.description.success':
        'Je ontvangt zo dadelijk een mail om je wachtwoord te wijzigen.',
    'reset.password.email.label': 'E-mail',
    'reset.password.new': 'Nieuw wachtwoord',
    'reset.password.confirm': 'Wachtwoord bevestigen',
    'reset.password.back': 'Terug',

    'reset.new_password.title': 'Nieuw wachtwoord',
    'reset.new_password.description': 'Kies een nieuw wachtwoord.',
    'reset.new_password.label': 'Nieuw wachtwoord',
    'reset.new_password.confirmation_label': 'Wachtwoord bevestigen',
    'reset.new_password.success':
        'Je wachtwoord werd succesvol gewijzigd. Meld je opnieuw aan.',

    'reset.new_password.mobile_success.title': 'Wachtwoord gewijzigd',
    'reset.new_password.mobile_success.description':
        'Je kan nu inloggen met je nieuwe wachtwoord in de mobiele app',

    'reset.new_password.invitation.title': 'Account activeren',
    'reset.new_password.invitation.description': 'Kies een wachtwoord.',
    'reset.new_password.invitation.success':
        'Je account werd succesvol geactiveerd.',

    'search.placeholder': 'Zoek klant',

    'lightbox.error':
        'Er is iets misgelopen tijdens het inladen van de afbeelding.',

    'dashboard.title': 'Dashboard',
    'dashboard.settings': 'Weergave',
    'dashboard.filters.change': 'Weergave van het dashboard wijzigen',
    'dashboard.filters.change.label': 'Weergegeven velden',

    'dashboard.filters.number-of-active-clients': 'Actieve klanten',
    'dashboard.filters.consultations': 'Eerstvolgende consult',
    'dashboard.filters.number-of-consultations': 'Consulten',
    'dashboard.filters.number-of-new-clients': 'Nieuwe klanten',
    'dashboard.filters.clients-gender': 'Geslacht klanten',
    'dashboard.filters.clients-age': 'Leeftijd klanten',
    'dashboard.filters.clients-categories': 'Categorieën',
    'dashboard.filters.inactive-mobile-clients':
        'Mobiele app nog niet geactiveerd',
    'dashboard.filters.clients-unread-entries': 'Ongelezen dagboeken',
    'dashboard.filters.coaching-unreceived':
        'Geen e-coaching gestuurd in laatste x dagen',

    'dashboard.filters.week': 'Afgelopen week',
    'dashboard.filters.month': 'Afgelopen maand',

    'dashboard.empty.number-of-active-clients': 'Er zijn geen actieve klanten',
    'dashboard.empty.consultations': 'Er zijn geen eerstvolgende consulten',
    'dashboard.empty.number-of-consultations': 'Er zijn geen consulten',
    'dashboard.empty.number-of-new-clients': 'Er zijn geen nieuwe klanten',
    'dashboard.empty.clients-gender': 'Er is geen geslachtsinformatie',
    'dashboard.empty.clients-age': 'Er is geen leeftijdsinformatie',
    'dashboard.empty.clients-categories': 'Er zijn geen categorieën',
    'dashboard.empty.inactive-mobile-clients':
        'Er zijn geen klanten die de app nog niet hebben geactiveerd',
    'dashboard.empty.clients-unread-entries':
        'Er zijn geen klanten met ongelezen dagboeken',
    'dashboard.empty.coaching-unreceived':
        'Er zijn klanten die geen e-coaching hebben gestuurd',

    clients: 'Klanten',
    'clients.add': 'Klant toevoegen',
    'clients.add.success': 'Klant succesvol toegevoegd',
    'clients.add.failed': 'Klant niet toegevoegd',
    'clients.edit': 'Klantgegevens wijzigen',
    'clients.edit.success': 'De klantgegevens zijn succesvol gewijzigd',
    'clients.edit.general': 'Anamnese aanpassen',
    'clients.edit.food': 'Voedingsanamnese aanpassen',
    'clients.delete.success': 'Klant succesvol verwijderd',

    'clients.list.empty':
        'Hier verschijnt de lijst van je klanten. Begin met je eerste klant aan te maken.',
    'clients.list.filter.empty':
        'Er zijn geen klanten onder de gekozen filter.',
    'clients.list.name': 'Naam',
    'clients.list.email': 'Email',
    'clients.list.bmi': 'BMI ',
    'clients.list.weight': 'Gewicht ',
    'clients.list.ideal_weight': 'Ideaal gewicht (BMI 25)',
    'clients.list.birth_date': 'Geboortedatum',
    'clients.list.length': 'Lengte',
    'clients.list.coaching': 'E-coaching?',
    'clients.list.coaching_starts_at': 'E-coaching startdatum',
    'clients.list.categories': 'Categorieën',
    'clients.list.impersonate': 'Klant nabootsen',

    'clients.detail.tabs.general': 'Algemeen',
    'clients.detail.tabs.treatment': 'Behandelplan',
    'clients.detail.tabs.consultations': 'Consulten',
    'clients.detail.tabs.digitalcoaching': 'E-coaching',
    'clients.detail.tabs.library': 'E-bib',
    'clients.detail.tabs.diary': 'Dagboek',
    'clients.detail.tabs.evolution': 'Voortgang',

    'clients.detail.general.overview': 'Overzicht',
    'clients.detail.general.name': 'Naam',
    'clients.detail.general.first_name': 'Voornaam',
    'clients.detail.general.categories': 'Categorieën',
    'clients.detail.general.address': 'Adres',
    'clients.detail.general.street': 'Straat + Nr.',
    'clients.detail.general.postalcode': 'Postcode',
    'clients.detail.general.country.be': 'België',
    'clients.detail.general.country.nl': 'Nederland',
    'clients.detail.general.city': 'Gemeente',
    'clients.detail.general.email': 'Mail',
    'clients.detail.general.gender': 'Geslacht',
    'clients.detail.general.gender.male': 'Man',
    'clients.detail.general.gender.female': 'Vrouw',
    'clients.detail.general.birthdate': 'Geboortedatum',
    'clients.detail.general.age': '{age} jaar',
    'clients.detail.general.height': 'Lengte (cm)',
    'clients.detail.general.bmi': 'BMI',
    'clients.detail.general.telephone': 'Tel.',
    'clients.detail.general.evolution': 'Voortgang',
    'clients.detail.general.coaching': 'E-coaching?',
    'clients.detail.general.coaching_starts_at': 'Startdatum e-coaching',
    'clients.detail.general.coaching_ends_at': 'Einddatum e-coaching',

    'clients.detail.general.no_user':
        'Deze klant heeft zijn account nog niet geactiveerd.',
    'clients.detail.general.no_user.resend': 'Opnieuw uitnodigen',
    'clients.detail.general.no_user.sent': 'Verzonden',
    'clients.detail.general.no_user.resend.success':
        'Invitatie mail succesvol verzonden',
    'clients.detail.general.no_user.resend.failed':
        'Er was een probleem bij het verzenden van de invitatie mail',

    'clients.detail.general.diagnose.empty':
        'Nog geen gegevens ingevuld. Voeg nu gegevens toe.',
    'clients.detail.general.diagnose.add': 'Aanvullen',
    'clients.detail.general.diagnose.general': 'Anamnese',
    'clients.detail.general.diagnose.food': 'Voedingsanamnese',
    'clients.detail.general.diagnose.form.drinks': 'Dranken',
    'clients.detail.general.diagnose.form.meal': 'Voeding',

    'clients.detail.general.activate': 'Klant activeren',
    'clients.detail.general.deactivate': 'Klant deactiveren',
    'clients.detail.general.active': 'Klant heractiveren',
    'clients.detail.general.delete': 'Klant verwijderen',

    'clients.evolution.view': 'Volledige voortgang',
    'clients.evolution.empty': 'Nog geen gegevens',
    'clients.evolution.filter.two_months': 'Twee maanden',
    'clients.evolution.filter.six_months': 'Zes maanden',
    'clients.evolution.filter.year': 'Jaar',
    'clients.evolution.filter.year.info':
        'voor de laatst geregistreerde consult',

    'clients.deactivate.title': 'Klant deactiveren?',
    'clients.deactivate.description':
        'Bent u zeker dat u deze klant wil deactiveren? U zal de klant zijn gegevens niet meer kunnen aanpassen en hij zal geen opvolging meer krijgen via de app',

    'clients.activate.title': 'Klant activeren',
    'clients.activate.description':
        'Bevestig dat u deze klant terug wil activeren. Let op: door deze klant te activeren telt hij mee binnen de limiet van je plan',

    'clients.delete.title': 'Klant verwijderen?',
    'clients.delete.description':
        'Bent u zeker dat u deze klant wil verwijderen? Opgelet, dit kan niet ongedaan gemaakt worden.',

    'clients.upcoming.title': 'Eerstvolgende consult',
    'clients.upcoming.empty': 'Er zijn geen consulten gepland',

    'clients.inactive': 'Inactieve klanten',
    'clients.inactive.navigate': 'Inactieve klanten',
    'clients.inactive.list.empty': 'Er zijn geen inactieve klanten',

    'clients.filter.state.active': 'Actieve klanten',
    'clients.filter.state.deactive': 'Inactieve klanten',

    'clients.filter.categories.select': 'Selecteer categorie',

    consultations: 'Consulten',
    'consultations.add': 'Consult toevoegen',
    'consultations.add.success': 'Consult succesvol toegevoegd',
    'consultations.edit': 'Consult aanpassen',
    'consultations.edit.success': 'Consult succesvol gewijzigd',
    'consultations.notes.edit': 'Notities aanpassen',
    'consultations.delete.success': 'Consult succesvol verwijderd',
    'consultations.list.empty': 'Nog geen consulten',
    'consultations.list.date': 'Datum',
    'consultations.list.note': 'Notitie',
    'consultations.list.note_food': 'Nota voeding',
    'consultations.list.note_movement': 'Nota beweging',
    'consultations.list.extra': 'Extra',
    'consultations.list.bmi': 'BMI',
    'consultations.list.bmr': 'BMR',
    'consultations.list.weight': 'Gewicht',
    'consultations.list.fat': 'Vetmassa',
    'consultations.list.lean': 'Vetvrije massa',
    'consultations.list.visceral': 'Visceraal vet',
    'consultations.list.muscle': 'Spiermassa',
    'consultations.list.next_goal': 'Voornemen tegen volgende consult',
    'consultations.list.clothing_size': 'Kledingmaat',
    'consultations.list.blood_pressure': 'Bloeddruk',
    'consultations.list.notes': "Nota's",
    'consultations.list.attention_points': 'Aandachtspunten',
    'consultations.detail.back': 'Terug naar consulten overzicht',
    'consultations.detail.info': 'Consult info',
    'consultations.detail.info.hour': 'Consult start:',
    'consultations.detail.measurements': 'Notities',
    'consultations.detail.measurements.empty': 'Nog geen metingen',
    'consultations.detail.measurements.add': 'Metingen aanvullen',
    'consultations.detail.measurements.edit': 'Metingen aanpassen',
    'consultations.detail.measurements.weight': 'Gewicht',
    'consultations.detail.measurements.fat': 'Vetmassa',
    'consultations.detail.measurements.lean': 'Vetvrije massa',
    'consultations.detail.measurements.muscle': 'Spiermassa',
    'consultations.detail.measurements.visceral': 'Visceraal vet',
    'consultations.detail.measurements.bmi': 'BMI',
    'consultations.detail.measurements.bmr': 'BMR',
    'consultations.detail.measurements.upper_arms': 'Bovenarmen',
    'consultations.detail.measurements.upper_legs': 'Bovenbenen',
    'consultations.detail.measurements.waist': 'Taille',
    'consultations.detail.measurements.hips': 'Heupen',
    'consultations.detail.measurements.clothing_size': 'Kledingmaat',
    'consultations.detail.measurements.blood_pressure': 'Bloeddruk',
    'consultations.detail.delete': 'Consult verwijderen',
    'consultations.delete.title': 'Consult verwijderen?',
    'consultations.delete.description':
        'Bent u zeker dat u deze consult wil verwijderen? Opgelet, dit kan niet ongedaan gemaakt worden.',
    'consultations.form.errors.impedance':
        'Gewicht of vetmassa, vetvrije masse en spiermassa zijn verplicht',
    'consultations.form.errors.calc':
        'Vetmassa + vetvrije massa moet gelijk zijn aan gewicht',
    'consultations.form.impedance': 'Impedantie',
    'consultations.form.circumference': 'Omtrekmetingen',
    'consultations.form.other': 'Overige metingen',

    digitalcoaching: 'E-Coaching Agenda',
    'digitalcoaching.add': 'E-Coaching toevoegen',
    'digitalcoaching.add.success': 'E-coaching succesvol toegevoegd',
    'digitalcoaching.edit.success': 'E-coaching succesvol gewijzigd',
    'digitalcoaching.delete.success': 'E-coaching succesvol verwijderd',
    'digitalcoaching.edit': 'E-Coaching aanpassen',
    'digitalcoaching.list.empty':
        'Hier komt het overzicht van e-coaching berichten. Maak nu je eerste bericht aan.',
    'digitalcoaching.list.empty.filter':
        'Er zijn geen e-coaching berichten voor deze filter',
    'digitalcoaching.list.to': 'Aan',
    'digitalcoaching.list.tags': 'Tags',
    'digitalcoaching.list.delay': 'Aantal dagen na koppeling categorie',
    'digitalcoaching.list.date': 'Verzenddatum',
    'digitalcoaching.list.motivation': 'Motivatiebericht',
    'digitalcoaching.list.motivation_image': 'Motivatieafbeelding',
    'digitalcoaching.list.food': 'Voedingsbericht',
    'digitalcoaching.list.food_image': 'Voedingsafbeelding',
    'digitalcoaching.list.movement': 'Bewegingsbericht',
    'digitalcoaching.list.movement_image': 'Bewegingsafbeelding',
    'digitalcoaching.list.scheduled': 'Gepland',
    'digitalcoaching.list.sent': 'Verzonden',
    'digitalcoaching.list.emotion': 'Emotie',
    'digitalcoaching.delete.title': 'E-Coaching verwijderen?',
    'digitalcoaching.delete.description':
        'Bent u zeker dat u deze e-coaching wil verwijderen? Opgelet, dit kan niet ongedaan gemaakt worden.',
    'digitalcoaching.form.image.upload': 'Afbeelding uploaden',
    'digitalcoaching.form.emotion.none': 'Geen',
    'digitalcoaching.form.schedule': 'Wanneer verzenden?',
    'digitalcoaching.form.schedule.now': 'Nu',
    'digitalcoaching.form.schedule.date': 'Op datum',
    'digitalcoaching.form.schedule.delay': 'Na koppeling categorie',
    'digitalcoaching.form.errors.image.upload':
        'Gelieve een afbeelding te selecteren',
    'digitalcoaching.form.errors.notes':
        'Minstens één bericht, afbeelding of emoticon verplicht',
    'digitalcoaching.filter.category': 'Filter op categorie',
    'digitalcoaching.filter.tag': 'Filter op tag',

    diary: 'Dagboek',
    'diary.tabs.general': 'Algemeen',
    'diary.tabs.food': 'Voeding',
    'diary.tabs.movement': 'Beweging',

    'diary.general.list.empty': 'Klant heeft nog geen algemene dagboek logs',
    'diary.general.list.date': 'Datum',
    'diary.general.list.sleep': 'Slaap',
    'diary.general.list.stress': 'Stress',
    'diary.general.list.note': 'Notitie',

    'diary.food.list.empty': 'Klant heeft nog geen voeding ingegeven',
    'diary.food.list.date': 'Datum',
    'diary.food.list.kcal': 'Kcal',
    'diary.food.list.fat': 'Vet (g)',
    'diary.food.list.protein': 'Eiwitten (g)',
    'diary.food.list.carbs': 'Koolhydraten (g)',

    'diary.simple.detail.back': 'Terug naar lijst algemeen dagboek',
    'diary.simple.detail.general': 'Algemeen dagboek',

    'diary.food.detail.back': 'Terug naar lijst voedingsdagboek',
    'diary.food.detail.general': 'Voedingsdagboek',

    'diary.food.detail.kcal': 'kcal',
    'diary.food.detail.fat': 'vet',
    'diary.food.detail.protein': 'eiwit',
    'diary.food.detail.carbs': 'koolh.',

    'diary.food.nutrient.nutritional_value':
        'Per {amount}{unit}: {cal}kcal | eiwit: {protein}g  | vet: {fat}g | koolh.: {carbs}g.',
    'diary.food.nutrient.nutritional_value.calculated':
        '{cal}kcal | vet: {fat}g | eiwit: {protein}g | koolh.: {carbs}g.',

    'diary.food.meal.breakfast': 'Ontbijt',
    'diary.food.meal.lunch': 'Lunch',
    'diary.food.meal.dinner': 'Diner',
    'diary.food.meal.snacks': 'Snacks',

    'diary.movement.list.empty':
        'Klant heeft nog geen bewegingsmomenten gelogd',
    'diary.movement.list.week': 'Week',
    'diary.movement.list.week.value': 'Week {week}',
    'diary.movement.list.amount': 'Aantal bewegingsmomenten',
    'diary.movement.list.duration': 'Totale duur',

    'diary.movement.activities.aerobics': 'Aerobics',
    'diary.movement.activities.aquajogging': 'Aquajogging',
    'diary.movement.activities.basketball': 'Basketbal',
    'diary.movement.activities.badminton': 'Badminton',
    'diary.movement.activities.conditiontraining': 'Conditietraining',
    'diary.movement.activities.dancing': 'Dansen',
    'diary.movement.activities.hiit': 'HIIT',
    'diary.movement.activities.cycling': 'Fietsen',
    'diary.movement.activities.fitness': 'Fitness',
    'diary.movement.activities.powertraining': 'Krachttraining',
    'diary.movement.activities.running': 'Lopen',
    'diary.movement.activities.climbing': 'Muurklimmen',
    'diary.movement.activities.pilates': 'Pilates',
    'diary.movement.activities.spinning': 'Spinning',
    'diary.movement.activities.tennis': 'Tennis',
    'diary.movement.activities.gardening': 'Tuinieren',
    'diary.movement.activities.gymnastics': 'Turnen',
    'diary.movement.activities.martialarts': 'Gevechtssport',
    'diary.movement.activities.soccer': 'Voetbal',
    'diary.movement.activities.volleyball': 'Volleybal',
    'diary.movement.activities.walking': 'Wandelen',
    'diary.movement.activities.yoga': 'Yoga',
    'diary.movement.activities.zumba': 'Zumba',
    'diary.movement.activities.swimming': 'Zwemmen',
    'diary.movement.activities.other': 'Overige',

    'diary.movement.detail.back': 'Terug naar lijst bewegingsdagboek',
    'diary.movement.detail.general': 'Bewegingsdagboek',
    'diary.movement.detail.overview': 'Overzicht',

    'diary.movement.detail.duration': 'Aangeraden duur',
    'diary.movement.detail.duration.value': '{minutes}min / beweging',
    'diary.movement.detail.type': 'Bij voorkeur',
    'diary.movement.detail.empty': 'Geen bewegingsmomenten',

    'diary.feedback.title': 'Feedback',
    'diary.feedback.score': 'Gevoel',
    'diary.feedback.score.negative': 'Negatief',
    'diary.feedback.score.neutral': 'Neutraal',
    'diary.feedback.score.positive': 'Positief',
    'diary.feedback.note': 'Feedback',

    library: 'E-Bib',
    'library.add': 'Bestand uploaden',
    'library.empty': 'Er zijn geen bestanden',
    'library.no_file': 'Geen',
    'library.edit': 'Wijzigen',
    'library.unlink': 'Ontkoppelen',

    'library.add.success': 'Bestand toegevoegd',
    'library.add.link.success': 'Bestand gekoppeld',
    'library.edit.success': 'Bestand gewijzigd',
    'library.delete.success': 'Bestand verwijderd',
    'library.delete.link.success': 'Bestand ontkoppeld',

    'library.delete.title': 'Bestand verwijderen?',
    'library.delete.description':
        'Bent u zeker dat u dit bestand wil verwijderen? Opgelet, dit kan niet ongedaan gemaakt worden.',

    'library.client.link': 'Bestand koppelen',

    'library.detail.add': 'Koppeling toevoegen',
    'library.detail.categories': 'Categorieën',
    'library.detail.clients': 'Klanten',
    'library.detail.empty':
        'Dit bestand zal voor niemand zichtbaar zijn zolang er geen koppelingen zijn',
    'library.detail.links': 'Koppelingen',
    'library.detail.file': 'Bestand',
    'library.detail.file.type': 'Soort',
    'library.detail.file.size': 'Grootte',

    'library.link.delete.title': 'Koppeling verwijderen?',
    'library.link.delete.description':
        'Bent u zeker dat u dit bestand wilt ontkoppelen? Opgelet, dit kan niet ongedaan gemaakt worden.',

    'library.form.add.title': 'Bestand uploaden',
    'library.form.choose.title': 'Bestand kiezen',
    'library.form.edit.title': 'Bestand wijzigen',
    'library.form.link.title': 'Koppeling toevoegen',
    'library.form.link.edit.title': 'Koppeling wijzigen',
    'library.form.publish': 'Wanneer publiceren?',
    'library.form.publish.to': 'Aan',
    'library.form.publish.now': 'Nu',
    'library.form.publish.date': 'Op datum',
    'library.form.publish.never': 'Nooit',
    'library.form.client.upload': 'Nieuw bestand uploaden?',

    'library.filter.category': 'Selecteer categorie',
    'library.filter.file_type': 'Selecteer type',
    'library.filter.publication': 'Publicatie',
    'library.filter.search': 'Zoeken',

    'library.type.image': 'Afbeelding',
    'library.type.video': 'Video',
    'library.type.pdf': 'PDF',
    'library.type.document': 'Document',

    'library.status.pending': 'Ingepland',
    'library.status.published': 'Gepubliceerd',
    'library.status.never': 'Niet gepubliceerd',

    'library.table.file': 'Bestand',
    'library.table.recipient': 'Ontvanger',
    'library.table.publish_date': 'Publicatiedatum',
    'library.table.status': 'Status',
    'library.table.created_at': 'Aangemaakt op',

    profile: 'Mijn profiel',
    'profile.profile': 'Gegevens',
    'profile.name': 'Naam',
    'profile.email': 'E-mail',
    'profile.company': 'Bedrijfsnaam',
    'profile.vat': 'BTW nummer',
    'profile.address': 'Adres',
    'profile.street': 'Straat + Nr.',
    'profile.postal_code': 'Postcode',
    'profile.city': 'Gemeente',
    'profile.plan': 'Abonnement',
    'profile.plan.type': 'Type abonnement',
    'profile.plan.expires': 'Geldig tot',
    'profile.edit': 'Profiel aanpassen',
    'profile.country': 'Land',
    'profile.form.new_password': 'Nieuw wachtwoord',
    'profile.links': 'Disclaimer',
    'profile.edit.success': 'Profielgegevens opgeslagen',
    'profile.build.version': 'Versie: {version}',
    'profile.build.number': 'Build: {build}',

    'graphs.empty': 'Er zijn nog geen gegevens',
    'graphs.title.weight': 'Gewicht',
    'graphs.title.mass': 'Massa',
    'graphs.title.contour': 'Omtrek',
    'graphs.filter.title': 'Filter',
    'graphs.filter.options.two_months': 'Afgelopen 2 maand',
    'graphs.filter.options.six_months': 'Afgelopen 6 maand',
    'graphs.filter.options.year': 'Afgelopen jaar',

    settings: 'Instellingen',
    'settings.edit.success': 'Instelling opgeslagen',
    'settings.edit.failed': 'Instelling wijzigen mislukt',
    'settings.tabs.general': 'Algemeen',
    'settings.tabs.categories': 'Categorieën',
    'settings.tabs.tags': 'Tags',
    'settings.tabs.fields': 'Velden',

    'settings.general': 'Algemene instellingen',
    'settings.general.max_coaching_interval':
        'Aantal dagen geen coaching vooraleer melding',
    'settings.general.client_overview_columns': 'Tonen in lijst klanten',
    'settings.general.consultation_overview_columns':
        'Tonen in lijst consulten',
    'settings.general.dashboard': 'Tonen in dashboard',

    'settings.user_fields': 'Velden {label}',
    'settings.user_fields.empty': 'Nog geen eigen {label} velden',
    'settings.user_fields.add': 'Veld toevoegen',
    'settings.user_fields.add.success': 'Veld succesvol toegevoegd',
    'settings.user_fields.edit': 'Veld aanpassen',
    'settings.user_fields.edit.success': 'Veld succesvol gewijzigd',
    'settings.user_fields.delete.success': 'Veld succesvol verwijderd',
    'settings.user_fields.name': 'Label',
    'settings.user_fields.type': 'Soort veld',
    'settings.user_fields.group': 'Type',
    'settings.user_fields.actions': 'Acties',
    'settings.user_fields.delete.title': 'Veld verwijderen?',
    'settings.user_fields.delete.description':
        'Bent u zeker dat u dit veld wil verwijderen? Opgelet, dit kan niet ongedaan gemaakt worden en de data hieraan gekoppeld zal verdwijnen.',

    'settings.user_fields.type.number': 'Nummer',
    'settings.user_fields.type.string': 'Korte tekst',
    'settings.user_fields.type.text': 'Lange tekst',
    'settings.user_fields.type.checkbox': 'Checkbox',

    'settings.categories.add': 'Categorie toevoegen',
    'settings.categories.add.success': 'Categorie succesvol toegevoegd',
    'settings.categories.edit': 'Categorie aanpassen',
    'settings.categories.edit.success': 'Categorie succesvol aangepast',
    'settings.categories.delete.success': 'Categorie succesvol verwijderd',
    'settings.categories.list.empty':
        'Categorieën kunnen gebruikt worden om klanten in onder te verdelen. Zo kan je gemakkelijk klanten contacteren per categorie.',
    'settings.categories.list.name': 'Naam',
    'settings.categories.list.clients': '{count} gekoppelde klanten',
    'settings.categories.list.actions': 'Acties',
    'settings.categories.delete.title': 'Categorie verwijderen?',
    'settings.categories.delete.description':
        'Bent u zeker dat u deze categorie wil verwijderen? Opgelet, dit kan niet ongedaan gemaakt worden.',

    'settings.tags.add': 'Tag toevoegen',
    'settings.tags.add.success': 'Tag succesvol toegevoegd',
    'settings.tags.edit': 'Tag aanpassen',
    'settings.tags.edit.success': 'Tag succesvol aangepast',
    'settings.tags.delete.success': 'Tag succesvol verwijderd',
    'settings.tags.list.empty':
        'Tags kunnen gebruikt worden om e-coaching berichten & e-bib bestanden in onder te verdelen.',
    'settings.tags.list.name': 'Naam',
    'settings.tags.list.actions': 'Acties',
    'settings.tags.delete.title': 'Tag verwijderen?',
    'settings.tags.delete.description':
        'Bent u zeker dat u deze tag wil verwijderen? Opgelet, dit kan niet ongedaan gemaakt worden.',

    subscribers: 'Gebruikers',
    'subscribers.list.name': 'Naam',
    'subscribers.list.email': 'E-mail',
    'subscribers.list.plan': 'Huidig plan',
    'subscribers.list.end_date': 'Einddatum',
    'subscribers.list.empty': 'Er zijn momenteel geen gebruikers',

    'subscriptions.plans.trial': 'Free',
    'subscriptions.plans.small_quarterly': 'Small - Per kwartaal',
    'subscriptions.plans.medium_quarterly': 'Medium - Per kwartaal',
    'subscriptions.plans.unlimited_quarterly': 'Unlimited - Per kwartaal',
    'subscriptions.plans.small_yearly': 'Small - Jaarlijks',
    'subscriptions.plans.medium_yearly': 'Medium - Jaarlijks',
    'subscriptions.plans.unlimited_yearly': 'Unlimited - Jaarlijks',
    'subscriptions.plans.year': 'jaar',
    'subscriptions.plans.month': 'maand',
    'subscriptions.plans.yearly': 'Jaarlijks',
    'subscriptions.plans.quarterly': 'Per kwartaal',
    'subscriptions.plans.current': 'Huidig plan',

    'subscriptions.trial.title': 'Je abonnement is verlopen',
    'subscriptions.trial.description':
        'Ben je tevreden van ons product? Je kan hieronder een abonnement nemen en direct verder gaan met je data!',
    'subscriptions.expired.title': 'Je abonnement is verlopen',
    'subscriptions.expired.description':
        'Wil je My Food Coach and Me blijven gebruiken? Geen nood, je kan hieronder je abonnement verlengen.',
    'subscriptions.choose_plan.title': 'Plan wijzigen',
    'subscriptions.choose_plan.description':
        'Je kan hieronder je plan wijzigen. Het is enkel mogelijk om je abonnement te vergroten. Het resterende krediet van je huidige abonnement zal in rekening gebracht worden na het kiezen van een nieuw plan. Indien je je abonnement wil verkleinen, neem dan contact op.',
    'subscriptions.extend_plan.title': 'Abonnement verlengen',
    'subscriptions.extend_plan.description':
        'Je zal onderstaand abonnement verlengen. De nieuwe periode zal ingaan vanaf je huidige abonnement is verlopen.',
    'subscriptions.choose_plan': 'Kies een plan',
    'subscriptions.client_limit.amount': 'tot {limit} actieve klanten',
    'subscriptions.client_limit.unlimited':
        'ongelimiteerd aantal actieve klanten',
    'subscriptions.client_limit.exceeded':
        'Opgelet, u gebruikt het maximaal aantal actieve klanten binnen uw huidig abonnement.',
    'subscriptions.client_limit.exceeded.link': 'Abonnement wijzigen',
    'subscriptions.current_plan.end_date': 'Geldig tot {date}',
    'subscriptions.price_without_vat': 'prijzen exclusief BTW',
    'subscriptions.next_to_payment': 'Naar betaling',

    'subscriptions.total_vat_excl': 'Totaal (excl. BTW)',
    'subscriptions.pro_ration_total': 'Resterend bedrag huidig abonnement',
    'subscriptions.total_vat_incl': 'Totaal (incl. {vat}% BTW)',
    'subscriptions.total_coupon': 'Kortingscode "{code}"',

    'subscriptions.coupon.code': 'Kortingscode',
    'subscriptions.coupon.submit': 'Valideren',
    'subscriptions.coupon.success': 'Kortingscode toegepast',
    'subscriptions.coupon.errors.not_valid': 'Deze code is niet geldig',

    'subscriptions.errors.payment_failed': 'Betaling mislukt',

    'subscription.edit': 'Abonnement wijzigen',
    'subscription.edit.success': 'Abonnement succesvol gewijzigd',
    'subscription.extend': 'Abonnement verlengen',

    'subscriptions.success': 'Plan succesvol gekozen',

    'treatment.goals': 'Doelen',
    'treatment.goals.add': 'Doel toevoegen',
    'treatment.goals.add.success': 'Doel succesvol toegevoegd',
    'treatment.goals.edit': 'Doel aanpassen',
    'treatment.goals.edit.success': 'Doel succesvol gewijzigd',
    'treatment.goals.delete.success': 'Doel succesvol verwijderd',
    'treatment.goals.name': 'Doel {number}',
    'treatment.goals.last': 'Eindoel',
    'treatment.goals.empty': 'Er zijn geen doelen vastgelegd',
    'treatment.goals.delete.title': 'Doel verwijderen?',
    'treatment.goals.delete.description':
        'Bent u zeker dat u dit doel wilt verwijderen? Opgelet, dit kan niet ongedaan gemaakt worden.',
    'treatment.goals.form.fat': 'Vetmassa',
    'treatment.goals.form.weight': 'Doelgewicht',
    'treatment.goals.form.note': 'Notitie',
    'treatment.goals.form.date': 'Doeldatum',
    'treatment.goals.form.state': 'Status',
    'treatment.goals.form.state.null': '/',
    'treatment.goals.form.state.failed': 'Niet gehaald',
    'treatment.goals.form.state.completed': 'Gehaald',
    'treatment.goals.form.errors.weight':
        'Gewicht, vetmassa of notitie is verplicht',

    'treatment.plan': 'Behandelplan',
    'treatment.plan.empty': 'Nog geen behandelplan opgesteld',
    'treatment.plan.add': 'Behandelplan aanmaken',
    'treatment.plan.edit': 'Behandelplan wijzigen',
    'treatment.plan.edit.success': 'Behandelplan succesvol gewijzigd',
    'treatment.plan.treatment_duration': 'Duur behandeling (maanden)',
    'treatment.plan.consultation_frequency': 'Frequentie live consulten',
    'treatment.plan.is_intensive': 'Intensieve motivering',
    'treatment.plan.kcal': 'Aantal op te nemen kcal',
    'treatment.plan.kcal_total': 'Totaal',
    'treatment.plan.kcal_breakfast': 'Ontbijt',
    'treatment.plan.kcal_lunch': 'Lunch',
    'treatment.plan.kcal_dinner': 'Diner',
    'treatment.plan.kcal_snacks': 'Tussendoortjes',
    'treatment.plan.nutrients': 'Verdeling in voedingsstoffen',
    'treatment.plan.carbs': 'Koolhydraten',
    'treatment.plan.protein': 'Eiwitten',
    'treatment.plan.fat': 'Vet',
    'treatment.plan.movement': 'Bewegingsplan',
    'treatment.plan.movement_count': 'Aantal bewegingsmomenten/week',
    'treatment.plan.movement_type': 'Bewegingstype',
    'treatment.plan.movement_duration': 'Duur bewegingsmoment (min)',
    'treatment.plan.meals_file': 'Maaltijdenplan',
    'treatment.plan.note': 'Extra notitie',
    'treatment.plan.app': 'Mobiele app integratie',
    'treatment.plan.diary_type': 'Type dagboek',
    'treatment.plan.diary_simple': 'Eenvoudig dagboek',
    'treatment.plan.diary_default': 'Voedings- en bewegingsdagboek',
    'treatment.plan.diary_simple_configuration': 'Bij te houden door klant:',
    'treatment.plan.diary_default_configuration': 'Instellingen',
    'treatment.plan.diary_custom_fields': 'Aangepaste velden weergeven',
    'treatment.plan.show_nutrient_values':
        'Toon voedingswaarden (kcal, vet, ...)',

    'treatment.plan.simple_question_sleep': 'Uren slaap',
    'treatment.plan.simple_question_stress': 'Stresslevel',
    'treatment.plan.simple_question_note': 'Notitie',
    'treatment.plan.simple_question_alcohol': 'Glazen alcohol',
    'treatment.plan.simple_question_coffee': 'Tassen koffie',
    'treatment.plan.simple_question_water': 'Glazen water',
    'treatment.plan.simple_question_bool_sugar': 'Suiker: ja/nee',
    'treatment.plan.simple_question_bool_steps_5000': '5000 stappen',
    'treatment.plan.simple_question_bool_steps_10000': '10000 stappen',
    'treatment.plan.simple_question_bool_yoga': 'Yoga',
    'treatment.plan.simple_question_bool_movement': 'Bewegingsmoment',
    'treatment.plan.simple_question_bool_red_meat': 'Rood vlees',
    'treatment.plan.simple_question_quantity_vegetables': 'Groenten',
    'treatment.plan.simple_question_quantity_bread': 'Sneden brood',
    'treatment.plan.simple_question_quantity_potatoes':
        'Aantal stukken aardappelen',
    'treatment.plan.simple_question_bool_restaurant': 'Restaurant: ja/nee',

    'treatment.plan.form.errors.kcal':
        'Totaal moet gelijk zijn aan ontbijt, lunch, diner en tussendoortjes samen',
    'treatment.plan.form.errors.nutrients': 'Totaal percentage moet 100% zijn',

    'treatment.mealplan': 'Maaltijdenplan',
    'treatment.mealplan.form.upload.empty':
        'Er is nog geen maaltijdenplan opgeladen',
    'treatment.mealplan.errors.file_type':
        'Gelieve een PDF bestand te selecteren',
    'treatment.mealplan.delete.title': 'Maaltijdenplan verwijderen?',
    'treatment.mealplan.delete.description':
        'Bent u zeker dat u dit maaltijdenplan voor deze klant wil verwijderen?',
    'treatment.mealplan.add.success': 'Maaltijdenplan succesvol opgeladen',
    'treatment.mealplan.delete.success': 'Maaltijdenplan verwijderd',

    help: 'Help'
};
