import { AuthActions, getError } from '@dive/react-redux-networking';

import { ClientsActions } from '../actions/clients';
import { CoachingActions } from '../../../core/actions/coaching';
import { ConsultationsActions } from '../../../core/actions/consultations';
import { DiaryActions } from '../../../core/actions/diary';
import { GoalsActions } from '../../../core/actions/goals';
import { LibraryTypes } from '../../../core/actions/library';
import { TreatmentActions } from '../actions/treatment';
import { UserFieldsActions } from '../actions/fields';

const DEFAULT_ITEM = {
    isLoading: false,
    data: null,
    error: null
};

const DEFAULT_DIARY_ITEM = {
    diary: Object.assign({}, DEFAULT_ITEM),
    diaryDetail: Object.assign({}, DEFAULT_ITEM),
    food: Object.assign({}, DEFAULT_ITEM),
    foodDetail: Object.assign({}, DEFAULT_ITEM),
    movements: Object.assign({}, DEFAULT_ITEM),
    movementDetail: Object.assign({}, DEFAULT_ITEM)
};

const DEFAULT_COACHING_ITEM = Object.assign({}, DEFAULT_ITEM, {
    filters: {}
});

const DEFAULT_LIBRARY_ITEM = Object.assign({}, DEFAULT_ITEM, {
    filters: {
        search: ''
    }
});

const DEFAULT_DETAIL = {
    id: null,
    isLoading: false,
    consultations: Object.assign({}, DEFAULT_ITEM),
    consultationDetail: Object.assign({}, DEFAULT_ITEM),
    treatment: Object.assign({}, DEFAULT_ITEM),
    coaching: Object.assign({}, DEFAULT_COACHING_ITEM),
    library: Object.assign({}, DEFAULT_LIBRARY_ITEM),
    diary: Object.assign({}, DEFAULT_DIARY_ITEM),
    goals: Object.assign({}, DEFAULT_ITEM)
};

const DEFAULT = {
    list: Object.assign({}, DEFAULT_ITEM),
    filter: 'active',
    inactive: Object.assign({}, DEFAULT_ITEM),
    detail: Object.assign({}, DEFAULT_DETAIL)
};

const updateItem = (state, name, data) => {
    return Object.assign({}, state, {
        [name]: Object.assign({}, state[name], data)
    });
};

const clients = (state = DEFAULT, action) => {
    switch (action.type) {
        /*
         * CLIENTS
         */
        case ClientsActions.GET_CLIENTS:
            return Object.assign({}, state, {
                list: Object.assign({}, state.list, {
                    isLoading: true,
                    error: null
                })
            });
        case ClientsActions.GET_CLIENTS_SUCCESS:
            // we also set the detail here to null
            return Object.assign({}, state, {
                list: {
                    data: action.payload.data,
                    isLoading: false,
                    timestamp: new Date().getTime()
                },
                detail: Object.assign({}, DEFAULT_DETAIL)
            });
        case ClientsActions.GET_CLIENTS_FAIL:
            return Object.assign({}, state, {
                list: {
                    isLoading: false,
                    error: getError(action).getMessage()
                }
            });
        case ClientsActions.CLEAR_CLIENTS:
            return Object.assign({}, state, {
                list: Object.assign({}, DEFAULT_ITEM)
            });

        /*
         * CLIENTS
         */
        case ClientsActions.GET_INACTIVE_CLIENTS:
            return updateItem(state, 'inactive', {
                isLoading: true,
                error: null
            });
        case ClientsActions.GET_INACTIVE_CLIENTS_SUCCESS:
            // we also set the detail here to null
            return Object.assign({}, state, {
                inactive: {
                    data: action.payload.data,
                    isLoading: false,
                    timestamp: new Date().getTime()
                },
                detail: Object.assign({}, DEFAULT_DETAIL)
            });
        case ClientsActions.GET_INACTIVE_CLIENTS_ERROR:
            return Object.assign({}, state, {
                inactive: {
                    isLoading: false,
                    error: getError(action).getMessage()
                }
            });
        case ClientsActions.CLEAR_INACTIVE_CLIENTS:
            return Object.assign({}, state, { inactive: { isLoading: false } });

        case ConsultationsActions.UPDATE_CONSULTATION_SUCCESS:
        case ConsultationsActions.CREATE_CONSULTATION_SUCCESS:
        case ConsultationsActions.REMOVE_CONSULTATION_SUCCESS:
            return Object.assign({}, state, {
                list: Object.assign({}, DEFAULT_ITEM),
                detail: Object.assign({}, state.detail, {
                    data: null,
                    consultations: { id: null, isLoading: false }
                })
            });

        /*
         * DETAIL
         */
        case ClientsActions.GET_CLIENT:
            // check if client exists in list of clients
            const client =
                state.list.data != null
                    ? state.list.data.clients.find(
                          client =>
                              client.id ===
                              parseInt(action.payload.request.id, 10)
                      )
                    : null;
            return Object.assign({}, state, {
                detail: Object.assign({}, DEFAULT_DETAIL, {
                    id: action.payload.request.id,
                    data: client,
                    isLoading: true
                })
            });
        case ClientsActions.GET_CLIENT_SUCCESS:
            return Object.assign({}, state, {
                detail: Object.assign({}, DEFAULT_DETAIL, {
                    id: action.payload.data.id,
                    data: action.payload.data,
                    isLoading: false,
                    timestamp: new Date().getTime()
                })
            });
        case ClientsActions.GET_CLIENT_FAIL:
            return Object.assign({}, state, {
                detail: Object.assign({}, DEFAULT_DETAIL, {
                    id: state.detail.id,
                    error: getError(action).getMessage(),
                    isLoading: false
                })
            });

        /*
         * CREATE / UPDATE
         */
        case ClientsActions.UPDATE_CLIENT_SUCCESS:
        case ClientsActions.CREATE_CLIENT_SUCCESS:
            // remove list
            return Object.assign({}, state, {
                list: { isLoading: false },
                inactive: { isLoading: false },
                detail: Object.assign({}, DEFAULT_DETAIL, {
                    id: action.payload.data.id,
                    data: action.payload.data,
                    isLoading: false
                })
            });

        /*
         * DELETE
         */
        case ClientsActions.REMOVE_CLIENT_SUCCESS:
            // remove list
            return Object.assign({}, state, {
                list: { isLoading: false },
                inactive: { isLoading: false }
            });

        /*
         * SET FILTER FOR ACTIVE/INACTIVE CLIENTS
         */
        case ClientsActions.SET_CLIENT_FILTER:
            return Object.assign({}, state, { filter: action.filter });

        case ClientsActions.SET_CLIENT_CATEGORY_FILTER:
            return Object.assign({}, state, { category: action.payload });

        /* LOGOUT */
        case AuthActions.CLEAR_AUTH:
            // remove list
            return Object.assign({}, DEFAULT);

        /*
         * DETAIL CONSULTATIONS
         */
        case ConsultationsActions.GET_CLIENT_CONSULTATIONS:
            return updateItem(state, 'detail', {
                consultations: Object.assign({}, state.detail.consultations, {
                    isLoading: true,
                    error: null
                })
            });
        case ConsultationsActions.GET_CLIENT_CONSULTATIONS_SUCCESS:
            return updateItem(state, 'detail', {
                consultations: {
                    data: action.payload.data,
                    isLoading: false
                }
            });
        case ConsultationsActions.GET_CLIENT_CONSULTATIONS_FAIL:
            return updateItem(state, 'detail', {
                consultations: {
                    isLoading: false,
                    error: getError(action).getMessage()
                }
            });

        case ConsultationsActions.GET_CONSULTATION:
            return updateItem(state, 'detail', {
                consultationDetail: Object.assign(
                    {},
                    state.detail.consultationDetail,
                    {
                        id: action.payload.request.id,
                        isLoading: true,
                        error: null,
                        data: null
                    }
                )
            });

        case ConsultationsActions.GET_CONSULTATION_SUCCESS:
            return updateItem(state, 'detail', {
                consultationDetail: {
                    id: action.payload.data.id,
                    data: action.payload.data,
                    isLoading: false
                }
            });
        case ConsultationsActions.GET_CONSULTATION_FAIL:
            return updateItem(state, 'detail', {
                consultationDetail: {
                    id: state.detail.consultationDetail.id,
                    isLoading: false,
                    error: getError(action).getMessage()
                }
            });

        /*
         * DETAIL COACHINGS
         */
        case CoachingActions.GET_CLIENT_COACHING:
            return updateItem(state, 'detail', {
                coaching: Object.assign({}, state.detail.coaching, {
                    isLoading: true,
                    error: null
                })
            });
        case CoachingActions.GET_CLIENT_COACHING_SUCCESS:
            return updateItem(state, 'detail', {
                coaching: Object.assign({}, state.detail.coaching, {
                    data: action.payload.data,
                    isLoading: false
                })
            });
        case CoachingActions.GET_CLIENT_COACHING_FAIL:
            return updateItem(state, 'detail', {
                coaching: Object.assign({}, state.detail.coaching, {
                    isLoading: false,
                    error: getError(action).getMessage()
                })
            });

        case CoachingActions.SET_CLIENT_FILTERS:
            return updateItem(state, 'detail', {
                coaching: Object.assign({}, state.detail.coaching, {
                    data: null,
                    filters: action.filters
                })
            });

        /*
         * LIBRARY
         */
        case LibraryTypes.GET_CLIENT_LIBRARY:
            return updateItem(state, 'detail', {
                library: Object.assign({}, state.detail.library, {
                    isLoading: true,
                    error: null
                })
            });

        case LibraryTypes.GET_CLIENT_LIBRARY_SUCCESS:
            return updateItem(state, 'detail', {
                library: Object.assign({}, state.detail.library, {
                    data: action.payload.data,
                    isLoading: false
                })
            });

        case LibraryTypes.GET_CLIENT_LIBRARY_FAIL:
            return updateItem(state, 'detail', {
                library: {
                    isLoading: false,
                    error: getError(action).getMessage()
                }
            });

        case LibraryTypes.SET_CLIENT_FILTERS:
            return updateItem(state, 'detail', {
                library: Object.assign({}, state.detail.library, {
                    data: null,
                    filters: action.payload
                })
            });

        case LibraryTypes.CREATE_ENTRY_SUCCESS:
        case LibraryTypes.UPDATE_ENTRY_SUCCESS:
        case LibraryTypes.REMOVE_ENTRY_SUCCESS:
        case LibraryTypes.UPDATE_ENTRY_RECEIVERS_SUCCESS:
        case LibraryTypes.REMOVE_ENTRY_RECEIVERS_SUCCESS:
        case LibraryTypes.RESET_CLIENT_FILTERS:
            return updateItem(state, 'detail', {
                library: Object.assign({}, DEFAULT_LIBRARY_ITEM)
            });

        /*
         * DIARY
         */
        case DiaryActions.GET_CLIENT_DIARY:
            return updateItem(state, 'detail', {
                diary: updateItem(state.detail.diary, 'diary', {
                    isLoading: true,
                    error: null
                })
            });
        case DiaryActions.GET_CLIENT_DIARY_SUCCESS:
            return updateItem(state, 'detail', {
                diary: updateItem(state.detail.diary, 'diary', {
                    data: action.payload.data,
                    isLoading: false,
                    timestamp: new Date().getTime()
                })
            });
        case DiaryActions.GET_CLIENT_DIARY_FAIL:
            return updateItem(state, 'detail', {
                diary: updateItem(state.detail.diary, 'diary', {
                    isLoading: false,
                    error: getError(action).getMessage()
                })
            });

        case DiaryActions.GET_CLIENT_FOOD_DIARY:
            return updateItem(state, 'detail', {
                diary: updateItem(state.detail.diary, 'food', {
                    isLoading: true,
                    error: null
                })
            });

        case DiaryActions.GET_CLIENT_FOOD_DIARY_SUCCESS:
            return updateItem(state, 'detail', {
                diary: updateItem(state.detail.diary, 'food', {
                    data: action.payload.data,
                    isLoading: false,
                    timestamp: new Date().getTime()
                })
            });
        case DiaryActions.GET_CLIENT_FOOD_DIARY_FAIL:
            return updateItem(state, 'detail', {
                diary: updateItem(state.detail.diary, 'food', {
                    isLoading: false,
                    error: getError(action).getMessage()
                })
            });

        case DiaryActions.GET_CLIENT_MOVEMENTS:
            return updateItem(state, 'detail', {
                diary: updateItem(state.detail.diary, 'movements', {
                    isLoading: true,
                    error: null
                })
            });
        case DiaryActions.GET_CLIENT_MOVEMENTS_SUCCESS:
            return updateItem(state, 'detail', {
                diary: updateItem(state.detail.diary, 'movements', {
                    data: action.payload.data,
                    isLoading: false,
                    timestamp: new Date().getTime()
                })
            });
        case DiaryActions.GET_CLIENT_MOVEMENTS_FAIL:
            return updateItem(state, 'detail', {
                diary: updateItem(state.detail.diary, 'movements', {
                    isLoading: false,
                    error: getError(action).getMessage()
                })
            });

        case DiaryActions.GET_CLIENT_GENERAL_DIARY_DETAIL:
            return updateItem(state, 'detail', {
                diary: updateItem(state.detail.diary, 'diaryDetail', {
                    date: action.payload.request.date,
                    isLoading: true,
                    error: null
                })
            });
        case DiaryActions.GET_CLIENT_GENERAL_DIARY_DETAIL_SUCCESS:
            // TODO: Only clear this when the item was unread
            state.detail.diary.diary = {
                ...state.detail.diary.diary,
                data: null // Clear data to refresh list.
            };
            return updateItem(state, 'detail', {
                diary: updateItem(state.detail.diary, 'diaryDetail', {
                    data: action.payload.data,
                    isLoading: false,
                    timestamp: new Date().getTime()
                })
            });
        case DiaryActions.GET_CLIENT_GENERAL_DIARY_DETAIL_FAIL:
            return updateItem(state, 'detail', {
                diary: updateItem(state.detail.diary, 'diaryDetail', {
                    isLoading: false,
                    error: getError(action).getMessage()
                })
            });
        case DiaryActions.CLEAR_CLIENT_GENERAL_DIARY_DETAIL:
            return updateItem(state, 'detail', {
                diary: updateItem(state.detail.diary, 'diaryDetail', {
                    isLoading: false,
                    error: null,
                    data: null
                })
            });

        case DiaryActions.GET_CLIENT_MOVEMENT_DETAIL:
            return updateItem(state, 'detail', {
                diary: updateItem(state.detail.diary, 'movementDetail', {
                    date: action.payload.request.date,
                    isLoading: true,
                    error: null
                })
            });
        case DiaryActions.GET_CLIENT_MOVEMENT_DETAIL_SUCCESS:
            // TODO: Only clear this when the item was unread
            state.detail.diary.movements = {
                ...state.detail.diary.movements,
                data: null // Clear data to refresh list.
            };
            return updateItem(state, 'detail', {
                diary: updateItem(state.detail.diary, 'movementDetail', {
                    data: action.payload.data,
                    isLoading: false,
                    timestamp: new Date().getTime()
                })
            });
        case DiaryActions.GET_CLIENT_MOVEMENT_DETAIL_FAIL:
            return updateItem(state, 'detail', {
                diary: updateItem(state.detail.diary, 'movementDetail', {
                    isLoading: false,
                    error: getError(action).getMessage()
                })
            });
        case DiaryActions.CLEAR_CLIENT_MOVEMENT_DETAIL:
            return updateItem(state, 'detail', {
                diary: updateItem(state.detail.diary, 'movementDetail', {
                    isLoading: false,
                    error: null,
                    data: null
                })
            });
        case DiaryActions.GET_CLIENT_FOOD_DIARY_DETAIL:
            return updateItem(state, 'detail', {
                diary: updateItem(state.detail.diary, 'foodDetail', {
                    date: action.payload.request.date,
                    isLoading: true,
                    error: null
                })
            });
        case DiaryActions.GET_CLIENT_FOOD_DIARY_DETAIL_SUCCESS:
            // TODO: Only clear this when the item was unread
            state.detail.diary.food = {
                ...state.detail.diary.food,
                data: null // Clear data to refresh list.
            };
            return updateItem(state, 'detail', {
                diary: updateItem(state.detail.diary, 'foodDetail', {
                    data: action.payload.data,
                    isLoading: false,
                    timestamp: new Date().getTime()
                })
            });
        case DiaryActions.GET_CLIENT_FOOD_DIARY_DETAIL_FAIL:
            return updateItem(state, 'detail', {
                diary: updateItem(state.detail.diary, 'foodDetail', {
                    isLoading: false,
                    error: getError(action).getMessage()
                })
            });
        case DiaryActions.CLEAR_CLIENT_FOOD_DIARY_DETAIL:
            return updateItem(state, 'detail', {
                diary: updateItem(state.detail.diary, 'foodDetail', {
                    isLoading: false,
                    error: null,
                    data: null
                })
            });

        /*
         * DETAIL GOALS
         */
        case GoalsActions.GET_CLIENT_GOALS:
            return updateItem(state, 'detail', {
                goals: Object.assign({}, state.detail.goals, {
                    isLoading: true,
                    error: null
                })
            });
        case GoalsActions.GET_CLIENT_GOALS_SUCCESS:
            return updateItem(state, 'detail', {
                goals: { data: action.payload.data, isLoading: false }
            });
        case GoalsActions.GET_CLIENT_GOALS_FAIL:
            return updateItem(state, 'detail', {
                goals: {
                    isLoading: false,
                    error: getError(action).getMessage()
                }
            });

        /*
         * DETAIL TREATMENT
         */
        case TreatmentActions.GET_CLIENT_TREATMENT:
            return updateItem(state, 'detail', {
                treatment: Object.assign({}, state.detail.treatment, {
                    isLoading: true,
                    error: null
                })
            });
        case TreatmentActions.GET_CLIENT_TREATMENT_SUCCESS:
            return updateItem(state, 'detail', {
                treatment: { data: action.payload.data, isLoading: false }
            });
        case TreatmentActions.GET_CLIENT_TREATMENT_FAIL:
            return updateItem(state, 'detail', {
                treatment: {
                    isLoading: false,
                    error: getError(action).getMessage()
                }
            });
        case TreatmentActions.CREATE_TREATMENT_SUCCESS:
            // remove diary (which has connection to treatment)
            return updateItem(state, 'detail', {
                diary: Object.assign({}, DEFAULT_DIARY_ITEM)
            });

        /*
         * USER FIELDS
         */
        case UserFieldsActions.UPDATE_USER_FIELD_SUCCESS:
        case UserFieldsActions.CREATE_USER_FIELD_SUCCESS:
        case UserFieldsActions.REMOVE_USER_FIELD_SUCCESS:
            return Object.assign({}, state, {
                detail: Object.assign({}, DEFAULT_DETAIL)
            });

        default:
            return state;
    }
};

export default clients;
