import { getError, AuthActions } from '@dive/react-redux-networking';

import { ConsultationsActions } from '../actions/consultations';

const DEFAULT = {
    isLoading: false,
    upcoming: {
        isLoading: false
    }
};

const consultations = (state = Object.assign({}, DEFAULT), action) => {
    switch (action.type) {
        case ConsultationsActions.GET_CONSULTATIONS:
            return Object.assign({}, state, { isLoading: true, error: null });
        case ConsultationsActions.GET_CONSULTATIONS_SUCCESS:
            // mobile pagination
            return Object.assign({}, state, {
                data:
                    action.payload.data.page > 1
                        ? Object.assign({}, action.payload.data, {
                              consultations: [
                                  ...state.data.consultations,
                                  ...action.payload.data.consultations
                              ]
                          })
                        : action.payload.data,
                isLoading: false,
                timestamp: new Date().getTime()
            });
        case ConsultationsActions.GET_CONSULTATIONS_FAIL:
            return Object.assign({}, state, {
                isLoading: false,
                error: getError(action).getMessage()
            });
        case ConsultationsActions.CLEAR_CONSULTATIONS:
            return Object.assign({}, DEFAULT);

        case ConsultationsActions.GET_UPCOMING_CONSULTATIONS:
            return Object.assign({}, state, {
                upcoming: Object.assign({}, state.upcoming, { isLoading: true })
            });
        case ConsultationsActions.GET_UPCOMING_CONSULTATIONS_SUCCESS:
            return Object.assign({}, state, {
                upcoming: Object.assign({}, state.upcoming, {
                    isLoading: false,
                    data: action.payload.data,
                    timestamp: new Date().getTime()
                })
            });
        case ConsultationsActions.GET_UPCOMING_CONSULTATIONS_FAIL:
            return Object.assign({}, state, {
                upcoming: Object.assign({}, state.upcoming, {
                    isLoading: false,
                    error: getError(action).getMessage()
                })
            });

        case ConsultationsActions.CREATE_CONSULTATION_SUCCESS:
        case ConsultationsActions.UPDATE_CONSULTATION_SUCCESS:
        case ConsultationsActions.REMOVE_CONSULTATION_SUCCESS:
            return Object.assign({}, state, {
                upcoming: {
                    isLoading: false
                }
            });

        /* LOGOUT */
        case AuthActions.CLEAR_AUTH:
            // remove list
            return Object.assign({}, DEFAULT);

        default:
            return state;
    }
};

export default consultations;
