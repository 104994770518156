import React from 'react';
import PropTypes from 'prop-types';

const getColor = (percentage, props) => {
    if (percentage < 100) {
        return props.defaultColor || 'primary';
    } else {
        return props.completeColor || props.defaultColor || 'primary';
    }
};

const ProgressBar = props => {
    return (
        <div
            className={`progressbar ${props.className || ''}`}
            style={props.style || {}}>
            <div
                className={`inner bg-${getColor(
                    (props.current / props.total) * 100,
                    props
                )}`}
                style={{
                    width: `${Math.min(
                        100,
                        (props.current / props.total) * 100
                    )}%`
                }}
            />
        </div>
    );
};

ProgressBar.propTypes = {
    current: PropTypes.number.isRequired,
    total: PropTypes.number.isRequired,
    defaultColor: PropTypes.string,
    completeColor: PropTypes.string
};

export default ProgressBar;
