import React from 'react';
import PropTypes from 'prop-types';
import { t } from '@dive/localization-js';
import Card from 'app/components/shared/card/Card';
import IconButton from '../../../shared/buttons/IconButton';
import moment from 'moment';

const ClientConsultationInfo = ({ readOnly, consultation, onEditClicked }) => {
    return (
        <Card>
            <div>
                <h3 className="mt-1 mb-0 d-flex">
                    {moment(consultation.scheduled).format('DD/MM/YYYY')}
                </h3>
                <p className="text-body-light">
                    {t('consultations.detail.info.hour')}{' '}
                    {moment(consultation.scheduled).format(
                        `HH[${t('general.units.hour')}]mm`
                    )}
                </p>
                {consultation.note && (
                    <p className="m-0">{consultation.note}</p>
                )}
                {!readOnly && (
                    <div className="card-buttons">
                        <IconButton
                            icon="edit"
                            onClick={() => onEditClicked()}
                        />
                    </div>
                )}
            </div>
        </Card>
    );
};

ClientConsultationInfo.propTypes = {
    consultation: PropTypes.object.isRequired,
    onEditClicked: PropTypes.func.isRequired
};

export default ClientConsultationInfo;
