import { t } from '@dive/localization-js';
import { request } from '@dive/react-redux-networking';
import React from 'react';
import { connect } from 'react-redux';

import { searchClients } from '../../../platform/actions/clients';
import { history, ROUTE_CLIENTS_DETAIL } from '../../../platform/routing';
import Icon from '../../shared/icon/Icon';
import { getFullName } from '../../../../core/models/client';

const mapStateToProps = state => {
    return {
        user: state.user.data
    };
};

const mapDispatchToProps = dispatch => {
    return {
        searchClients: keyword => {
            return request(dispatch(searchClients(keyword)));
        }
    };
};

class ClientsSearchForm extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            isOpen: null,
            query: '',
            clients: null,
            error: null,
            focusChild: null
        };
    }

    onFocus() {
        this.setState({
            isOpen: true
        });
    }

    onBlur() {
        // we need the timeout to make sure the click event works
        setTimeout(() => {
            this.setState({
                isOpen: false,
                query: '',
                clients: null,
                error: null,
                focusChild: null
            });
        }, 200);
    }

    focusElement = (selected, keyPress) => {
        let next;
        if (keyPress === 'keyDown') {
            next = selected + 1;
        }
        if (keyPress === 'keyUp') {
            next = selected - 1;
        }
        if (selected === null || this.state.clients[next] === undefined) {
            if (keyPress === 'keyDown') {
                this.setState({ focusChild: 0 });
            }
            if (keyPress === 'keyUp') {
                this.setState({ focusChild: this.state.clients.length - 1 });
            }
        } else {
            this.setState({ focusChild: next });
        }
    };

    onKeyPressed(e) {
        switch (e.keyCode) {
            case 40: // Key down
                if (this.state.clients) {
                    this.focusElement(this.state.focusChild, 'keyDown');
                }
                break;
            case 38: // Key up
                if (this.state.clients) {
                    this.focusElement(this.state.focusChild, 'keyUp');
                }
                break;
            case 27: // Escape
                this.onBlur();
                break;

            default:
                break;
        }
    }

    onInputChanged(e) {
        e.preventDefault();
        this.setState({
            query: e.target.value,
            isOpen: true,
            clients: null,
            focusChild: null
        });
        this.searchClients();
    }

    searchClients() {
        // wait 300 seconds
        if (this.timeout) {
            clearTimeout(this.timeout);
            this.timeout = null;
        }
        this.timeout = setTimeout(() => {
            if (this.state.query.length > 2) {
                this.doSearch();
            }
        }, 300);
    }

    doSearch() {
        this.props
            .searchClients(this.state.query)
            .then(response => {
                this.setState({
                    clients: response.clients,
                    error: null
                });
            })
            .catch(err => {
                this.setState({
                    clients: null,
                    error: err.getMessage()
                });
            });
    }

    submitForm = e => {
        e.preventDefault();
        if (this.state.clients && this.state.focusChild !== null) {
            this.goToClient(this.state.clients[this.state.focusChild].id);
            this.onBlur();
        }
    };

    goToClient(id) {
        history.push(ROUTE_CLIENTS_DETAIL.replace(':id', id));
    }

    render() {
        return (
            <form
                onSubmit={e => {
                    this.submitForm(e);
                }}
                className={`form-inline form-search ml-auto dropdown ${
                    this.state.clients && this.state.clients.length > 0
                        ? 'show'
                        : ''
                }`}>
                <div className="input-group">
                    <span className="input-group-addon">
                        <Icon name="search" />
                    </span>
                    <input
                        className={`form-control ${
                            this.state.isOpen ? 'active' : ''
                        }`}
                        onKeyDown={e => this.onKeyPressed(e)}
                        autoComplete="false"
                        type="search"
                        placeholder={t('search.placeholder')}
                        value={this.state.query}
                        onChange={e => this.onInputChanged(e)}
                        onFocus={() => this.onFocus()}
                        onBlur={() => this.onBlur()}
                        aria-label="Search"
                    />
                </div>
                {this.state.clients && this.state.clients.length > 0 && (
                    <div className="dropdown-menu show">
                        {this.state.clients.map(client => (
                            <button
                                key={client.id}
                                className={`dropdown-item ${
                                    this.state.focusChild !== null
                                        ? client.id ===
                                          this.state.clients[
                                              this.state.focusChild
                                          ].id
                                            ? 'active text-dark'
                                            : ''
                                        : ''
                                }`}
                                type="button"
                                onClick={() => this.goToClient(client.id)}>
                                {getFullName(client)}
                            </button>
                        ))}
                    </div>
                )}
            </form>
        );
    }
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(ClientsSearchForm);
