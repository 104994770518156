import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { t } from '@dive/localization-js';
import { ROUTE_CLIENTS_DETAIL } from 'app/platform/routing';
import Card from '../../shared/card/Card';
import LoadingIcon from '../../shared/loading/LoadingIcon';
import {
    fetchInactiveClients,
    getInactiveClients
} from '../../../platform/actions/clients';
import SortableTable from '../../shared/table/SortableTable';
import Pagination from '../../shared/pagination/Pagination';
import { history } from '../../../platform/routing';
import ErrorView from '../../shared/view/ErrorView';
import EmptyView from '../../shared/view/EmptyView';
import { isLoadingData } from '../../../../core/utils/data';
import ContentBody from '../../shared/content/ContentBody';

const mapStateToProps = state => {
    const { data, error } = state.clients.inactive;
    return {
        isLoading: isLoadingData(state.clients.inactive),
        clients: data ? data.clients : null,
        pagination: {
            currentPage: data ? data.page : null,
            pageSize: data ? data.page_size : null,
            count: data ? data.count : null
        },
        error: error,
        sort: {
            column: data && data.sort ? data.sort.column : null,
            order: data && data.sort ? data.sort.order : null
        }
    };
};

const mapDispatchToProps = dispatch => {
    return {
        getInactiveClients: () => {
            dispatch(getInactiveClients());
        },
        fetchInactiveClients: (page, sortColumn, order) => {
            dispatch(fetchInactiveClients(page, sortColumn, order));
        }
    };
};

class InactiveClientsOverviewContainer extends Component {
    constructor(props) {
        super(props);
        this.columns = [
            {
                title: t('clients.list.name'),
                key: 'name',
                sortable: true
            },
            {
                title: t('clients.list.email'),
                key: 'email',
                sortable: true
            }
        ];
    }

    componentDidMount() {
        this.props.getInactiveClients();
    }

    componentDidUpdate(prevProps) {
        // check if we need to reload clients
        this.props.getInactiveClients();
    }

    fetchSort = (column, sort) => {
        this.props.fetchInactiveClients(
            this.props.pagination.currentPage,
            column,
            sort
        );
    };

    fetchPage = page => {
        this.props.fetchInactiveClients(
            page,
            this.props.sort.column,
            this.props.sort.order
        );
    };

    showClientDetail = id => {
        history.push(ROUTE_CLIENTS_DETAIL.replace(':id', id));
    };

    render() {
        return (
            <Fragment>
                <ContentBody className="m-0 mt-3">
                    {this.props.isLoading ? (
                        <LoadingIcon />
                    ) : this.props.error ? (
                        <ErrorView error={this.props.error} />
                    ) : (
                        <div className="row d-flex flex-row">
                            <div className="col-sm-12">
                                <Card padding={this.props.clients.length === 0}>
                                    {this.props.clients.length > 0 ? (
                                        <Fragment>
                                            <SortableTable
                                                columns={this.columns}
                                                sortColumn={
                                                    this.props.sort.column
                                                }
                                                order={this.props.sort.order}
                                                isLoading={this.props.isLoading}
                                                onFetch={this.fetchSort}>
                                                {this.props.clients.map(
                                                    client => (
                                                        <tr
                                                            className="tr-selectable"
                                                            key={client.id}
                                                            onClick={() =>
                                                                this.showClientDetail(
                                                                    client.id
                                                                )
                                                            }>
                                                            <td className="font-weight-semi-bold">{`${
                                                                client.name
                                                            } ${
                                                                client.first_name
                                                            }`}</td>
                                                            <td>
                                                                {client.email}
                                                            </td>
                                                        </tr>
                                                    )
                                                )}
                                            </SortableTable>
                                            <Pagination
                                                currentPage={
                                                    this.props.pagination
                                                        .currentPage
                                                }
                                                count={
                                                    this.props.pagination.count
                                                }
                                                pageSize={
                                                    this.props.pagination
                                                        .pageSize
                                                }
                                                onFetch={this.fetchPage}
                                            />
                                        </Fragment>
                                    ) : (
                                        <div className="d-flex flex-column align-items-center">
                                            {this.props.error ? (
                                                <ErrorView
                                                    error={this.props.error}
                                                />
                                            ) : (
                                                <EmptyView
                                                    label={t(
                                                        'clients.inactive.list.empty'
                                                    )}
                                                />
                                            )}
                                        </div>
                                    )}
                                </Card>
                            </div>
                        </div>
                    )}
                </ContentBody>
            </Fragment>
        );
    }
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(InactiveClientsOverviewContainer);
