import { isVoid } from '@dive/utils-js';
import React from 'react';

import { sortCategoriesFilter } from '../../../platform/model/category';
import Receiver from '../receiver/Receiver';

const ReceiverList = ({ children, className }) => (
    <div className={className}>
        {isVoid(children.client) &&
        children.categories.length === 0 ? null : isVoid(children.client) ? (
            children.categories
                .sort(sortCategoriesFilter)
                .map(category => (
                    <Receiver key={category.id}>{category}</Receiver>
                ))
        ) : (
            <Receiver>{children.client}</Receiver>
        )}
    </div>
);

export default ReceiverList;
