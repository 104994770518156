import React, { Component, Fragment } from 'react';
import {
    getFormattedNumber,
    isLoadingData
} from '../../../../../../core/utils/data';

import Card from '../../../../shared/card/Card';
import EmptyView from '../../../../shared/view/EmptyView';
import ErrorView from '../../../../shared/view/ErrorView';
import LoadingIcon from '../../../../shared/loading/LoadingIcon';
import ReadNotification from '../../../../shared/read/ReadNotification';
import SortableTable from '../../../../shared/table/SortableTable';
import { connect } from 'react-redux';
import { getClientMovements } from '../../../../../../core/actions/diary';
import { getDuration } from '../../../../../../core/models/diary';
import { history } from '../../../../../platform/routing';
import moment from 'moment/moment';
import { t } from '@dive/localization-js';

const mapStateToProps = state => {
    const { movements } = state.clients.detail.diary;
    const data = movements ? movements.data : null;
    return {
        isLoading: isLoadingData(movements),
        diary: data ? data.movements : null,
        treatment: data ? data.treatment : null,
        error: movements.error
    };
};

const mapDispatchToProps = dispatch => {
    return {
        getClientMovements: () => {
            dispatch(getClientMovements());
        }
    };
};

class ClientMovementsOverview extends Component {
    constructor(props) {
        super(props);
        this.state = {
            errors: {}
        };
        this.columns = [
            {
                key: 'movements-read',
                sortable: false,
                className: 'read-th'
            },
            {
                title: t('diary.movement.list.week'),
                key: 'date',
                sortable: false
            },
            {
                title: t('diary.movement.list.amount'),
                key: 'amount',
                sortable: false
            },
            {
                title: t('diary.movement.list.duration'),
                key: 'amount',
                sortable: false
            }
        ];
    }

    componentDidMount() {
        this.props.getClientMovements();
    }

    componentDidUpdate(prevProps) {
        if (prevProps !== this.props) {
            this.props.getClientMovements();
        }
    }

    showMovementDetail(date) {
        history.push(
            `${this.props.location.pathname}/${moment(date).format('DDMMYYYY')}`
        );
    }

    getValueColumn = (log, count, total) => {
        return (
            <td>
                <span
                    className={
                        this.props.treatment &&
                        this.props.treatment[total] &&
                        this.props.treatment[total] > 0 &&
                        log[count] < this.props.treatment[total]
                            ? 'color-secondary'
                            : ''
                    }>
                    {getFormattedNumber(log[count], 'nl')}
                </span>
                {this.props.treatment &&
                this.props.treatment[total] &&
                this.props.treatment[total] > 0
                    ? ` / ${getFormattedNumber(
                          this.props.treatment[total],
                          'nl'
                      )}`
                    : ''}
            </td>
        );
    };

    render() {
        return this.props.isLoading ? (
            <LoadingIcon />
        ) : (
            <Fragment>
                <Card padding={false} className="mt-3">
                    {this.props.diary && this.props.diary.length > 0 ? (
                        <Fragment>
                            <SortableTable
                                columns={this.columns}
                                sortColumn={''}
                                order={''}
                                isLoading={this.props.isLoading}
                                onFetch={() => {}}>
                                {this.props.diary.map(log => {
                                    return (
                                        <tr
                                            className="tr-selectable"
                                            onClick={() =>
                                                this.showMovementDetail(
                                                    log.start_of_week
                                                )
                                            }
                                            key={log.start_of_week}>
                                            <td className="read-td">
                                                <ReadNotification
                                                    read={log.read}
                                                />
                                            </td>
                                            <td>
                                                <span className="font-weight-semi-bold">
                                                    {t(
                                                        'diary.movement.list.week.value',
                                                        { week: log.week }
                                                    )}
                                                </span>
                                                {` (${moment(
                                                    log.start_of_week
                                                ).format(`dd`)} ${moment(
                                                    log.start_of_week
                                                ).format(
                                                    'DD/MM/YY'
                                                )} - ${moment(
                                                    log.end_of_week
                                                ).format(`dd`)} ${moment(
                                                    log.end_of_week
                                                ).format('DD/MM/YY')})`}
                                            </td>
                                            {this.getValueColumn(
                                                log,
                                                'movement_count',
                                                'movement_total'
                                            )}
                                            <td>
                                                {getDuration(
                                                    log.movement_duration_count
                                                )}
                                            </td>
                                        </tr>
                                    );
                                })}
                            </SortableTable>
                        </Fragment>
                    ) : this.props.error ? (
                        <ErrorView error={this.props.error} />
                    ) : (
                        <EmptyView label={t('diary.movement.list.empty')} />
                    )}
                </Card>
            </Fragment>
        );
    }
}

ClientMovementsOverview.propTypes = {};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(ClientMovementsOverview);
