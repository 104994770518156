import React, { Fragment } from 'react';
import { hasCoachingEnd, hasCoachingStart } from 'app/platform/model/client';

import Avatar from '../../../../assets/avatar.svg';
import Card from 'app/components/shared/card/Card';
import Icon from 'app/components/shared/icon/Icon';
import IconButton from '../../../shared/buttons/IconButton';
import PropTypes from 'prop-types';
import { getFormattedNumber } from '../../../../../core/utils/data';
import { getFullName } from '../../../../../core/models/client';
import moment from 'moment';
import { sortCategoriesFilter } from '../../../../platform/model/category';
import { t } from '@dive/localization-js';

const ClientContactInfo = ({ readOnly, client, onEditClick }) => {
    const end = hasCoachingEnd(client);
    const start = hasCoachingStart(client);

    return (
        <Card>
            <div className="d-flex">
                <div className="mr-3">
                    <img src={Avatar} className="avatar" alt="" />
                </div>
                <div>
                    <h3 className="mt-1 mb-0 d-flex align-items-center">
                        {getFullName(client)}
                        {client.gender && (
                            <Icon
                                className="icon-gender ml-1"
                                name={client.gender === 'M' ? 'male' : 'female'}
                            />
                        )}
                    </h3>
                    {client.categories.length > 0 && (
                        <div className="badge-list mt-2">
                            {client.categories
                                .sort(sortCategoriesFilter)
                                .map((category) => (
                                    <span
                                        className="badge badge-light"
                                        key={category.name}>
                                        {category.name}
                                    </span>
                                ))}
                        </div>
                    )}
                </div>
            </div>
            <div className="row mt-4">
                <div className="col-sm-12">
                    <dl className="row">
                        {client.address_street && (
                            <Fragment>
                                <dt className="col-sm-3">
                                    {t('clients.detail.general.address')}
                                </dt>
                                <dd className="col-sm-9">
                                    {client.address_street}
                                    <br />
                                    {`${client.address_postal_code || ''} ${
                                        client.address_city || ''
                                    }`}
                                </dd>
                            </Fragment>
                        )}
                        {client.telephone_number && (
                            <Fragment>
                                <dt className="col-sm-3">
                                    {t('clients.detail.general.telephone')}
                                </dt>
                                <dd className="col-sm-9">
                                    {client.telephone_number}
                                </dd>
                            </Fragment>
                        )}
                        {client.email && (
                            <Fragment>
                                <dt className="col-sm-3">
                                    {t('clients.detail.general.email')}
                                </dt>
                                <dd className="col-sm-9">
                                    <a
                                        href={`mailto:${client.email}`}
                                        title="Mail">
                                        {client.email}
                                    </a>
                                </dd>
                            </Fragment>
                        )}
                        {client.birth_date && (
                            <Fragment>
                                <dt className="col-sm-3">
                                    {t('clients.detail.general.birthdate')}
                                </dt>
                                <dd className="col-sm-9">
                                    {moment
                                        .utc(client.birth_date)
                                        .format('DD/MM/YYYY')}
                                    <span className="text-body-light">{` | ${t(
                                        'clients.detail.general.age',
                                        {
                                            age: moment().diff(
                                                client.birth_date,
                                                'years',
                                                false
                                            ),
                                        }
                                    )}`}</span>
                                </dd>
                            </Fragment>
                        )}
                        {client.height !== null && client.height > 0 && (
                            <Fragment>
                                <dt className="col-sm-3">
                                    {t('clients.detail.general.height')}
                                </dt>
                                <dd className="col-sm-9">{`${client.height} ${t(
                                    'general.units.cm'
                                )}`}</dd>
                            </Fragment>
                        )}
                        {client.bmi > 0 && (
                            <Fragment>
                                <dt className="col-sm-3">
                                    {t('clients.detail.general.bmi')}
                                </dt>
                                <dd className="col-sm-9">
                                    {getFormattedNumber(client.bmi, 'nl')}
                                </dd>
                            </Fragment>
                        )}
                        <dt className="col-sm-3">
                            {t('clients.detail.general.coaching')}
                        </dt>

                        <dd className="col-sm-9">
                            {t(client.coaching ? 'general.yes' : 'general.no')}

                            {start && (
                                <span className="ml-2">
                                    ({end ? '' : t('general.from') + ' '}
                                    {moment
                                        .utc(client.coaching_starts_at)
                                        .format('DD/MM/YYYY')}
                                    {end && ' ' + t('general.until') + ' '}
                                    {end &&
                                        moment
                                            .utc(client.coaching_ends_at)
                                            .format('DD/MM/YYYY')}
                                    )
                                </span>
                            )}
                        </dd>
                    </dl>
                </div>
            </div>
            {!readOnly && (
                <div className="card-buttons">
                    <IconButton icon="edit" onClick={() => onEditClick()} />
                </div>
            )}
        </Card>
    );
};

ClientContactInfo.propTypes = {
    client: PropTypes.object.isRequired,
    onEditClick: PropTypes.func.isRequired,
};

export default ClientContactInfo;
