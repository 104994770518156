import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { t } from '@dive/localization-js';

import AlertModal from './AlertModal';

class DeleteAlertModal extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isLoading: false,
            error: null
        };
    }

    deleteModel = () => {
        // set loading
        this.setState({
            isLoading: true
        });
        // do call
        this.props
            .onDelete()
            .then(() => {
                // finished
                this.props.onFinished();
            })
            .catch(err => {
                // set error
                this.setState({
                    isLoading: false,
                    error: t('errors.something_went_wrong')
                });
            });
    };

    render() {
        return (
            <AlertModal
                title={this.props.title}
                description={this.props.description}
                alertType="danger"
                confirmButtonLabel={
                    this.props.confirmLabel || t('general.remove')
                }
                onDismissClicked={this.props.onDismissClicked}
                onConfirmClicked={this.deleteModel}
                isLoading={this.state.isLoading}
                error={this.state.error}
            />
        );
    }
}

DeleteAlertModal.propTypes = {
    title: PropTypes.string.isRequired,
    description: PropTypes.string.isRequired,
    onDismissClicked: PropTypes.func.isRequired,
    onFinished: PropTypes.func.isRequired,
    onDelete: PropTypes.func.isRequired,
    confirmLabel: PropTypes.string
};

export default DeleteAlertModal;
