export const MessagesActions = {
    SET_MESSAGE: '/messages/SET',
    CLEAR_MESSAGE: '/messages/CLEAR'
};

export const showMessage = message => ({
    type: MessagesActions.SET_MESSAGE,
    data: { key: 'general', message: message }
});

export const clearMessage = key => ({
    type: MessagesActions.CLEAR_MESSAGE,
    key
});
