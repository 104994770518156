export const TreatmentActions = {
    GET_CLIENT_TREATMENT: '/treatment/LOAD',
    GET_CLIENT_TREATMENT_SUCCESS: '/treatment/LOAD_SUCCESS',
    GET_CLIENT_TREATMENT_FAIL: '/treatment/LOAD_FAIL',

    CLEAR_CLIENT_TREATMENT: '/treatment/CLEAR',

    CREATE_TREATMENT: '/treatment/CREATE',
    CREATE_TREATMENT_SUCCESS: '/treatment/CREATE_SUCCESS',
    CREATE_TREATMENT_FAIL: '/treatment/CREATE_FAIL',

    UPLOAD_TREATMENT_FILE: '/treatment/UPLOAD',
    UPLOAD_TREATMENT_FILE_SUCCESS: '/treatment/UPLOAD_SUCCESS',
    UPLOAD_TREATMENT_FILE_FAIL: '/treatment/UPLOAD_FAIL',

    REMOVE_TREATMENT: '/treatment/REMOVE',
    REMOVE_TREATMENT_SUCCESS: '/treatment/REMOVE_SUCCESS',
    REMOVE_TREATMENT_FAIL: '/treatment/REMOVE_FAIL'
};

export const getClientTreatment = id => ({
    type: TreatmentActions.GET_CLIENT_TREATMENT,
    payload: {
        request: {
            url: `/clients/${id}/treatment`,
            method: 'GET'
        }
    }
});

export const updateClientTreatment = (id, treatment) => ({
    type: TreatmentActions.CREATE_TREATMENT,
    payload: {
        request: {
            url: `/clients/${id}/treatment`,
            method: 'PUT',
            data: treatment
        }
    }
});

export const uploadMealPlan = (id, mealplan) => {
    const file = new FormData();

    file.append('file', mealplan);

    return {
        type: TreatmentActions.UPLOAD_TREATMENT_FILE,
        payload: {
            request: {
                url: `/clients/${id}/treatment/plan`,
                method: 'POST',
                data: file,
                headers: { 'Content-Type': 'multipart/form-data' },
                fileName: mealplan.name
            }
        }
    };
};

export const deleteMealPlan = id => ({
    type: TreatmentActions.REMOVE_TREATMENT,
    payload: {
        request: {
            url: `/clients/${id}/treatment/plan`,
            method: 'DELETE'
        }
    }
});
