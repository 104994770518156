import { t } from '@dive/localization-js';
import { request } from '@dive/react-redux-networking';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import { requestInvite } from '../../../../../../core/actions/password';
import { isActivated } from '../../../../../../core/models/client';
import LoadingIcon from '../../../../shared/loading/LoadingIcon';

class ClientActivationStatus extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isLoading: false,
            isSent: false
        };
    }

    onResendInvitation = () => {
        this.setState({
            isLoading: true
        });
        this.props
            .resendInvitation(this.props.client.id)
            .then(() => {
                this.setState({
                    isLoading: false,
                    isSent: true
                });
            })
            .catch(() => {
                this.setState({
                    isLoading: false,
                    isSent: false
                });
            });
    };

    render() {
        return (
            !isActivated(this.props.client) && (
                <div className="alert alert-warning d-flex align-items-center flex-wrap pt-1 pb-1 pl-3 pr-3 mb-4">
                    <p className="flex-grow-1 mb-0 mr-3">
                        {t('clients.detail.general.no_user')}
                    </p>
                    <div>
                        {this.state.isLoading && (
                            <LoadingIcon className="mt-0 mb-0 ml-1 mr-1" />
                        )}
                        {!this.state.isSent && !this.state.isLoading && (
                            <button
                                className="btn btn-link pl-0"
                                onClick={this.onResendInvitation}>
                                {t('clients.detail.general.no_user.resend')}
                            </button>
                        )}
                        {this.state.isSent && !this.state.isLoading && (
                            <p className="mb-0">
                                {t('clients.detail.general.no_user.sent')}
                            </p>
                        )}
                    </div>
                </div>
            )
        );
    }
}

ClientActivationStatus.propTypes = {
    client: PropTypes.object.isRequired
};

const mapDispatchToProps = dispatch => ({
    resendInvitation: clientId => request(dispatch(requestInvite(clientId)))
});

export default connect(
    undefined,
    mapDispatchToProps
)(ClientActivationStatus);
