import React from 'react';
import PropTypes from 'prop-types';

class DropDownMenu extends React.Component {
    constructor() {
        super();
        this.dropdown = React.createRef();
        this.state = {
            viewPortClass: null
        };
    }
    componentDidUpdate(prevProps) {
        if (this.props.isOpen && !prevProps.isOpen) {
            this.getDropdownInfo();
        } else if (prevProps.isOpen && !this.props.isOpen) {
            if (this.state.viewPortClass) {
                this.setState({ viewPortClass: null });
            }
        }
    }

    getDropdownInfo = () => {
        if (!this.state.viewPortClass) {
            this.setState({
                viewPortClass: this.checkViewport(this.dropdown.current)
            });
        }
    };

    checkViewport = elem => {
        // Info about the element
        let bounding = elem.getBoundingClientRect();

        const vertical = this.checkVertical(bounding);
        const horizontal = this.checkHorizontal(bounding);

        return `${vertical} ${horizontal}`;
    };

    checkVertical(bounding) {
        if (
            bounding.bottom >
            (window.innerHeight || document.documentElement.clientHeight)
        ) {
            return 'bottom';
        } else if (bounding.top < 0) {
            return 'top';
        } else {
            return '';
        }
    }

    checkHorizontal(bounding) {
        if (bounding.left < 0) {
            return 'left';
        } else if (
            bounding.right >
            (window.innerWidth || document.documentElement.clientWidth)
        ) {
            return 'right';
        } else {
            return '';
        }
    }

    render() {
        return (
            <div
                ref={this.props.isOpen && this.dropdown}
                className={`dropdown-menu ${this.state.viewPortClass || ''} ${
                    this.props.isOpen ? 'show' : ''
                }`}>
                {this.props.children}
            </div>
        );
    }
}

DropDownMenu.propTypes = {
    isOpen: PropTypes.bool
};

export default DropDownMenu;
