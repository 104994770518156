export const PasswordActions = {
    REQUEST_PASSWORD_RESET: '/password/request/LOAD',
    REQUEST_PASSWORD_RESET_SUCCESS: '/password/request/LOAD_SUCCESS',
    REQUEST_PASSWORD_RESET_FAIL: '/password/request/LOAD_FAIL',

    RESET_PASSWORD: '/password/reset/LOAD',
    RESET_PASSWORD_SUCCESS: '/password/reset/LOAD_SUCCESS',
    RESET_PASSWORD_FAIL: '/password/reset/LOAD_FAIL',

    REQUEST_INVITATION: '/invitation/request/LOAD',
    REQUEST_INVITATION_SUCCESS: '/invitation/request/LOAD_SUCCESS',
    REQUEST_INVITATION_FAIL: '/invitation/request/LOAD_FAIL',

    ACCEPT_INVITATION: '/invitation/accept/LOAD',
    ACCEPT_INVITATION_SUCCESS: '/invitation/accept/LOAD_SUCCESS',
    ACCEPT_INVITATION_FAIL: '/invitation/accept/LOAD_FAIL'
};

export const resetPasswordRequest = email => ({
    type: PasswordActions.REQUEST_PASSWORD_RESET,
    payload: {
        request: {
            url: '/user/password/email',
            method: 'POST',
            data: { email } // this is necessary for React Native fetch
        }
    }
});

export const savePassword = data => ({
    type: PasswordActions.RESET_PASSWORD,
    payload: {
        request: {
            url: '/user/password/reset',
            method: 'POST',
            data
        }
    }
});

export const requestInvite = clientId => ({
    type: PasswordActions.REQUEST_INVITATION,
    payload: {
        request: {
            url: '/user/invite',
            method: 'POST',
            data: { client_id: clientId }
        }
    }
});

export const acceptInvitation = data => ({
    type: PasswordActions.ACCEPT_INVITATION,
    payload: {
        request: {
            url: '/user/invite/accept',
            method: 'POST',
            data
        }
    }
});
