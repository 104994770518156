import React from 'react';
import PropTypes from 'prop-types';
import { t } from '@dive/localization-js';

import {
    getClientLimit,
    getId,
    getInterval,
    isTrial,
    isValid
} from '../../../../platform/model/subscription';
import Card from '../../../shared/card/Card';
import EmptyView from '../../../shared/view/EmptyView';
import SubmitButton from '../../../shared/buttons/SubmitButton';
import ButtonToggleGroup from '../../../shared/buttons/ButtonToggleGroup';
import PlanItem from './PlanItem';

class PlanPicker extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            selectedInterval: 'yearly'
        };
    }

    componentDidMount() {
        if (this.props.selectedPlan || this.props.currentPlan) {
            this.setInterval();
        }
    }

    componentDidUpdate(prevProps) {
        if (prevProps.selectedPlan !== this.props.selectedPlan) {
            this.setInterval();
        }
    }

    setInterval = () => {
        this.setState({
            selectedInterval: this.props.selectedPlan
                ? getInterval(this.props.selectedPlan)
                : this.props.currentPlan && !isTrial(this.props.currentPlan)
                ? getInterval(this.props.currentPlan)
                : 'yearly'
        });
    };

    onIntervalChanged = interval => {
        this.setState({
            selectedInterval: interval
        });
    };

    render() {
        const { selectedInterval } = this.state;
        const { currentPlan, selectedPlan } = this.props;

        return (
            <Card className="card-bordered">
                {currentPlan && getInterval(currentPlan) === 'yearly' ? null : (
                    <ButtonToggleGroup
                        className="mt-3"
                        buttons={[
                            {
                                label: t('subscriptions.plans.quarterly'),
                                value: 'quarterly'
                            },
                            {
                                label: t('subscriptions.plans.yearly'),
                                value: 'yearly'
                            }
                        ]}
                        value={selectedInterval}
                        onChange={value => this.onIntervalChanged(value)}
                    />
                )}
                <div className="row mt-5">
                    {this.props.subscriptions.map(plan =>
                        getId(plan).indexOf(selectedInterval) >= 0 ? (
                            <PlanItem
                                plan={plan}
                                key={getId(plan)}
                                disabled={
                                    currentPlan &&
                                    // if current plan is invalid, user is allowed to take previous plan
                                    ((isValid(currentPlan) &&
                                        !isTrial(currentPlan) &&
                                        !(
                                            getClientLimit(plan) >
                                                getClientLimit(currentPlan) ||
                                            (getClientLimit(plan) ===
                                                getClientLimit(currentPlan) &&
                                                getInterval(plan) ===
                                                    'yearly' &&
                                                getInterval(currentPlan) !==
                                                    getInterval(plan))
                                        )) ||
                                        (!isValid(currentPlan) &&
                                            !isTrial(currentPlan) &&
                                            getClientLimit(plan) <
                                                getClientLimit(currentPlan)))
                                }
                                isActive={
                                    selectedPlan &&
                                    getId(selectedPlan) === getId(plan)
                                }
                                isCurrent={
                                    currentPlan &&
                                    getId(currentPlan) === getId(plan)
                                }
                                onClick={() => this.props.onPlanChange(plan)}
                            />
                        ) : null
                    )}
                    <EmptyView
                        className="col-sm-12"
                        label={'* ' + t('subscriptions.price_without_vat')}
                    />
                </div>
                {selectedPlan && (
                    <div className="d-flex justify-content-end">
                        <SubmitButton
                            label={t('general.next')}
                            onClick={this.props.onSubmit}
                        />
                    </div>
                )}
            </Card>
        );
    }
}

PlanPicker.propTypes = {
    subscriptions: PropTypes.arrayOf(PropTypes.object),
    currentPlan: PropTypes.object,
    selectedPlan: PropTypes.object,
    onSubmit: PropTypes.func.isRequired,
    onPlanChange: PropTypes.func.isRequired
};

export default PlanPicker;
