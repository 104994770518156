import { isVoid } from '@dive/utils-js';
import { t } from '@dive/localization-js';
import React, { Component } from 'react';
import { createPortal } from 'react-dom';

import ErrorView from '../view/ErrorView';
import Icon from '../icon/Icon';

class Lightbox extends Component {
    static defaultProps = {
        portal: 'overlay'
    };

    state = {
        error: null,
        isSlow: false,
        src: null
    };
    timeout = null;
    img = null;

    // ///////////////////////////////////////////////////////////////////////
    // LIFECYCLE METHODS
    // ///////////////////////////////////////////////////////////////////////

    componentDidMount() {
        document.addEventListener('keyup', this.handleKeyUp);

        const img = new Image();

        img.addEventListener('load', this.handleLoad);
        img.addEventListener('error', this.handleError);

        img.src = this.props.url;

        this.timeout = window.setTimeout(() => {
            if (isVoid(this.state.src)) {
                this.setState({
                    isSlow: true
                });
            }
        }, 250);

        this.img = img;
    }

    componentWillUnmount() {
        document.removeEventListener('keyup', this.handleKeyUp);

        this.img.removeEventListener('load', this.handleLoad);
        this.img.removeEventListener('error', this.handleError);

        if (!isVoid(this.timeout)) {
            window.clearTimeout(this.timeout);
        }
    }

    // ///////////////////////////////////////////////////////////////////////
    // EVENT HANDLERS
    // ///////////////////////////////////////////////////////////////////////

    handleError = () => {
        this.setState({
            error: t('lightbox.error')
        });
    };

    handleKeyUp = e => {
        if (e.keyCode === 27) this.props.onClose();
    };

    handleLoad = e => {
        this.setState({
            src: e.currentTarget.src
        });
    };

    // ///////////////////////////////////////////////////////////////////////
    // OTHERS
    // ///////////////////////////////////////////////////////////////////////

    render() {
        const { onClose, portal } = this.props;
        const { error, isSlow, src } = this.state;

        let content;

        if (!isVoid(error)) {
            content = <ErrorView error={error} />;
        } else if (!isVoid(src)) {
            content = (
                <img
                    onClick={e => e.stopPropagation()}
                    style={{
                        maxHeight: '50vh',
                        maxWidth: '90vw'
                    }}
                    src={src}
                    alt="Lightbox"
                />
            );
        } else if (isSlow) {
            content = <div className="spinner-border text-light mx-auto" />;
        } else {
            content = null;
        }

        const markup = (
            <>
                <div onClick={onClose} className="modal fade show d-block">
                    <button
                        className="btn btn-icon p-4 p-md-5 position-absolute"
                        style={{ top: 0, right: 0 }}
                        title={t('general.close')}>
                        <Icon
                            style={{ fill: 'white' }}
                            className="icon-lg"
                            name="close"
                        />
                    </button>

                    <div className="modal-dialog-centered justify-content-center">
                        {content}
                    </div>
                </div>

                <div className="modal-backdrop fade show" />
            </>
        );

        return createPortal(markup, document.getElementById(portal));
    }
}

export default Lightbox;
