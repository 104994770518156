import 'moment/locale/nl-be';

import { Localization } from '@dive/localization-js';
// Languages
import nl from './nl';

// Settings Language
export const initLocalizations = (current, fallback) => {
    // init
    Localization.init({
        dictionary: {
            nl: nl
        },
        currentLocale: current,
        fallbackLocale: fallback
    });
};
