import { t } from '@dive/localization-js';
import React from 'react';
import PropTypes from 'prop-types';

import Card from 'app/components/shared/card/Card';
import IconButton from '../../../../../shared/buttons/IconButton';
import MultilineText from '../../../../../shared/text/MultilineText';
import { getFormattedNumber } from '../../../../../../../core/utils/data';

const getSimpleDiarySettings = treatment =>
    Object.keys(treatment)
        .filter(key => key.match('simple_question') && treatment[key])
        .map(key => t(`treatment.plan.${key}`))
        .concat(
            treatment.fields
                .filter(field => !field.is_hidden)
                .map(field => field.name)
        )
        .join(', ');

const TreatmentInfo = ({ readOnly, treatment, onEditClicked }) => (
    <>
        <h2>{t('treatment.plan')}</h2>

        <Card>
            <div className="row">
                <div className="col-sm-12">
                    <dl className="row">
                        <dt className="col-sm-4">
                            {t('treatment.plan.treatment_duration')}
                        </dt>
                        <dd className="col-sm-8">
                            {treatment.treatment_duration}
                        </dd>

                        <dt className="col-sm-4">
                            {t('treatment.plan.consultation_frequency')}
                        </dt>
                        <dd className="col-sm-8">
                            {treatment.consultation_frequency}
                        </dd>

                        <dt className="col-sm-4">
                            {t('treatment.plan.is_intensive')}
                        </dt>
                        <dd className="col-sm-8">
                            {t(
                                treatment.is_intensive
                                    ? 'general.yes'
                                    : 'general.no'
                            )}
                        </dd>

                        <dt className="col-sm-4">{t('treatment.plan.note')}</dt>
                        <dd className="col-sm-8">
                            <MultilineText>{treatment.note}</MultilineText>
                        </dd>
                    </dl>
                </div>

                <div className="col-sm-4">
                    <p className="font-weight-semi-bold">
                        {t('treatment.plan.kcal')}
                    </p>

                    <dl className="row">
                        <dt className="col-sm-5">
                            {t('treatment.plan.kcal_total')}
                        </dt>
                        <dd className="col-sm-5">
                            {treatment.kcal_total
                                ? `${treatment.kcal_total}${t(
                                      'general.units.kcal'
                                  )}`
                                : ''}
                        </dd>

                        <dt className="col-sm-5">
                            {t('treatment.plan.kcal_breakfast')}
                        </dt>
                        <dd className="col-sm-5">
                            {treatment.kcal_breakfast
                                ? `${treatment.kcal_breakfast}${t(
                                      'general.units.kcal'
                                  )}`
                                : ''}
                        </dd>

                        <dt className="col-sm-5">
                            {t('treatment.plan.kcal_lunch')}
                        </dt>
                        <dd className="col-sm-5">
                            {treatment.kcal_lunch
                                ? `${treatment.kcal_lunch}${t(
                                      'general.units.kcal'
                                  )}`
                                : ''}
                        </dd>

                        <dt className="col-sm-5">
                            {t('treatment.plan.kcal_dinner')}
                        </dt>
                        <dd className="col-sm-5">
                            {treatment.kcal_dinner
                                ? `${treatment.kcal_dinner}${t(
                                      'general.units.kcal'
                                  )}`
                                : ''}
                        </dd>

                        <dt className="col-sm-5">
                            {t('treatment.plan.kcal_snacks')}
                        </dt>
                        <dd className="col-sm-5">
                            {treatment.kcal_snacks
                                ? `${treatment.kcal_snacks}${t(
                                      'general.units.kcal'
                                  )}`
                                : ''}
                        </dd>
                    </dl>
                </div>

                <div className="col-sm-4">
                    <p className="font-weight-semi-bold">
                        {t('treatment.plan.nutrients')}
                    </p>

                    <dl className="row">
                        <dt className="col-sm-4">
                            {t('treatment.plan.carbs')}
                        </dt>
                        <dd className="col-sm-8">
                            {treatment.carbs
                                ? `${getFormattedNumber(
                                      treatment.carbs,
                                      'nl'
                                  )}${t('general.units.g')}`
                                : ''}
                            {treatment.carbs_percentage
                                ? ` (${getFormattedNumber(
                                      treatment.carbs_percentage,
                                      'nl'
                                  )}%)`
                                : ''}
                        </dd>

                        <dt className="col-sm-4">
                            {t('treatment.plan.protein')}
                        </dt>
                        <dd className="col-sm-8">
                            {treatment.protein
                                ? `${getFormattedNumber(
                                      treatment.protein,
                                      'nl'
                                  )}${t('general.units.g')}`
                                : ''}
                            {treatment.protein_percentage
                                ? ` (${getFormattedNumber(
                                      treatment.protein_percentage,
                                      'nl'
                                  )}%)`
                                : ''}
                        </dd>

                        <dt className="col-sm-4">{t('treatment.plan.fat')}</dt>
                        <dd className="col-sm-8">
                            {treatment.fat
                                ? `${getFormattedNumber(
                                      treatment.fat,
                                      'nl'
                                  )}${t('general.units.g')}`
                                : ''}
                            {treatment.fat_percentage
                                ? ` (${getFormattedNumber(
                                      treatment.fat_percentage,
                                      'nl'
                                  )}%)`
                                : ''}
                        </dd>
                    </dl>
                </div>

                <div className="col-sm-4">
                    <p className="font-weight-semi-bold">
                        {t('treatment.plan.movement')}
                    </p>

                    <dl className="row">
                        <dt className="col-sm-10">
                            {t('treatment.plan.movement_count')}
                        </dt>
                        <dd className="col-sm-2">{treatment.movement_total}</dd>

                        <dt className="col-sm-12">
                            {t('treatment.plan.movement_type')}
                        </dt>
                        <dd className="col-sm-12">
                            <MultilineText>
                                {treatment.movement_type}
                            </MultilineText>
                        </dd>

                        <dt className="col-sm-10">
                            {t('treatment.plan.movement_duration')}
                        </dt>
                        <dd className="col-sm-2">
                            {treatment.movement_duration}
                        </dd>
                    </dl>
                </div>

                <div className="col-sm-6">
                    <p className="font-weight-semi-bold">
                        {t('treatment.plan.app')}
                    </p>

                    <dl className="row">
                        <dt className="col-sm-4">
                            {t('treatment.plan.diary_type')}
                        </dt>
                        <dd className="col-sm-8">
                            {t(
                                treatment.use_simplified_diary
                                    ? 'treatment.plan.diary_simple'
                                    : 'treatment.plan.diary_default'
                            )}
                        </dd>

                        {treatment.use_simplified_diary ? (
                            <>
                                <dt className="col-sm-4">
                                    {t(
                                        'treatment.plan.diary_simple_configuration'
                                    )}
                                </dt>
                                <dd className="col-sm-8">
                                    <MultilineText>
                                        {getSimpleDiarySettings(treatment)}
                                    </MultilineText>
                                </dd>
                            </>
                        ) : (
                            <>
                                <dt className="col-sm-4">
                                    {t('treatment.plan.show_nutrient_values')}
                                </dt>
                                <dd className="col-sm-8">
                                    {t(
                                        treatment.show_nutrient_values
                                            ? 'general.yes'
                                            : 'general.no'
                                    )}
                                </dd>
                            </>
                        )}
                    </dl>
                </div>

                {!readOnly && (
                    <div className="card-buttons">
                        <IconButton icon="edit" onClick={onEditClicked} />
                    </div>
                )}
            </div>
        </Card>
    </>
);

TreatmentInfo.propTypes = {
    treatment: PropTypes.object.isRequired,
    onEditClicked: PropTypes.func.isRequired
};

export default TreatmentInfo;
