import React, { Component, Fragment } from 'react';

import EmptyView from '../../../../shared/view/EmptyView';
import { Line } from 'react-chartjs-2';
import LoadingIcon from 'app/components/shared/loading/LoadingIcon';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { getClientMeasurements } from '../../../../../../core/actions/measurements';
import { isLoadingData } from '../../../../../../core/utils/data';
import moment from 'moment/moment';
import { t } from '@dive/localization-js';

const mapStateToProps = (state, props) => {
    const type = state.measurements[props.type];
    const data = type ? type.data : null;
    return {
        isLoading: isLoadingData(state.measurements[props.type]),
        measurements: data ? data : null,
        error: type ? type.error : null
    };
};

const mapDispatchToProps = dispatch => {
    return {
        getMeasurements: type => {
            return dispatch(getClientMeasurements(type));
        }
    };
};

const COLORS = ['#F5A302', '#17B662', '#4B5650'];

const GRAPH_DATA = {
    fill: false,
    lineTension: 0.1,
    borderColor: '',
    borderCapStyle: 'butt',
    borderDash: [],
    borderDashOffset: 0.0,
    borderJoinStyle: 'miter',
    pointBackgroundColor: '#fff',
    pointBorderWidth: 1,
    pointHoverRadius: 5,
    pointHoverBorderColor: 'rgba(220,220,220,1)',
    pointHoverBorderWidth: 2,
    backgroundColor: 'transparent'
};

class MeasurementGraph extends Component {
    constructor(props) {
        super(props);

        this.state = {
            data: { datasets: [] }
        };
    }

    componentDidMount() {
        this.props.getMeasurements(this.props.type);
        if (this.props.measurements) {
            this.setGraphData();
        }
    }

    componentDidUpdate(prevProps) {
        this.props.getMeasurements(this.props.type);

        if (
            prevProps.measurements !== this.props.measurements &&
            this.props.measurements
        ) {
            this.setGraphData();
        }
    }

    setGraphData = () => {
        const datasets = this.props.measurements.graph.map(
            (measurement, key) => {
                return Object.assign({}, GRAPH_DATA, {
                    label: `${measurement.label} (${this.props.measurements.unit})`,
                    unit: this.props.measurements.unit,
                    data: measurement.data,
                    borderColor: COLORS[key],
                    backgroundColor: COLORS[key]
                });
            }
        );
        const graph = { datasets: datasets };
        this.setState({
            data: graph
        });
    };

    render() {
        return this.props.shouldLoadData || this.props.isLoading ? (
            <LoadingIcon className="mb-5 col-xl-6" />
        ) : this.state.data &&
          this.state.data.datasets.length > 0 &&
          this.state.data.datasets[0].data.length > 0 ? (
            <Fragment>
                {this.props.showLastValue &&
                    this.props.measurements.graph.length > 0 && (
                        <div className="d-flex align-items-center mb-3">
                            <h4>{`${parseFloat(
                                this.state.data.datasets[0].data[
                                    this.state.data.datasets[0].data.length - 1
                                ].y
                            ).toLocaleString('nl')}${
                                this.props.measurements.unit
                            }`}</h4>
                            <span className="label ml-auto">
                                {moment(
                                    this.state.data.datasets[0].data[
                                        this.state.data.datasets[0].data
                                            .length - 1
                                    ].x
                                ).format('DD/MM/YYYY')}
                            </span>
                        </div>
                    )}
                <div className="chart-container">
                    <Line
                        data={this.state.data}
                        options={{
                            responsive: true,
                            maintainAspectRatio: false,
                            scales: {
                                xAxes: [
                                    {
                                        type: 'time',
                                        time: {
                                            displayFormats: {
                                                day: 'DD/MM/YY'
                                            }
                                        }
                                    }
                                ]
                            },
                            legend: {
                                display: true,
                                position: 'bottom',
                                labels: {
                                    fontSize: 14,
                                    boxWidth: 20
                                }
                            },
                            tooltips: {
                                backgroundColor: 'rgb(23, 182, 98)',
                                titleSpacing: 5,
                                titleFontSize: 15,
                                bodyFontSize: 13,
                                displayColors: false,
                                bodySpacing: 10,
                                xPadding: 15,
                                yPadding: 20,
                                callbacks: {
                                    title: function(tooltipItem, data) {
                                        return moment(
                                            tooltipItem[0].xLabel
                                        ).format('DD/MM/YY');
                                    },
                                    label: function(tooltipItem, data) {
                                        return `${parseFloat(
                                            tooltipItem.yLabel
                                        ).toLocaleString('nl')}${
                                            data.datasets[0].unit
                                        }`;
                                    }
                                }
                            }
                        }}
                    />
                </div>
            </Fragment>
        ) : (
            <EmptyView label={t('graphs.empty')} />
        );
    }
}

MeasurementGraph.propTypes = {
    type: PropTypes.string.isRequired,
    showLastValue: PropTypes.bool,
    months: PropTypes.string
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(MeasurementGraph);
