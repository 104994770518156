import React from 'react';
import PropTypes from 'prop-types';
import { t } from '@dive/localization-js';
import ProgressBar from '../../../../../../shared/progress/ProgressBar';
import { getFormattedNumber } from '../../../../../../../../core/utils/data';

const MealHeaderListItem = ({ meal }) => {
    return (
        <div className="d-flex w-100 align-items-center pb-2">
            <h2 className="mb-0">{meal.title}</h2>
            <div className="d-flex ml-auto align-items-center">
                <p
                    className="mb-0 mt-0 mr-1 font-size-small text-right"
                    style={{ minWidth: 32 }}>
                    <span className="font-weight-semi-bold">
                        {getFormattedNumber(meal.kcal_count)}
                    </span>
                    <span className="text-body-light">
                        {meal.kcal ? `/${getFormattedNumber(meal.kcal)}` : ''}
                    </span>
                </p>
                {meal.kcal ? (
                    <ProgressBar
                        style={{ width: 40 }}
                        className="ml-1 mr-2"
                        current={meal.kcal_count}
                        total={meal.kcal}
                        completeColor={'alert'}
                        defaultColor={'primary'}
                    />
                ) : null}
                <span className="text-body-light font-size-extra-small mr-3">
                    {t('general.units.kcal').toUpperCase()}
                </span>
                <p
                    className="mb-0 mt-0 mr-3 font-size-small text-right"
                    style={{ minWidth: 72 }}>
                    <span className="font-weight-semi-bold">
                        {getFormattedNumber(meal.fat_count)}
                        {t('general.units.g')}
                    </span>
                    <span className="text-body-light font-size-extra-small">
                        {' '}
                        {t('diary.food.detail.fat').toUpperCase()}
                    </span>
                </p>
                <p
                    className="mb-0 mt-0 mr-3 font-size-small text-right"
                    style={{ minWidth: 72 }}>
                    <span className="font-weight-semi-bold">
                        {getFormattedNumber(meal.protein_count)}
                        {t('general.units.g')}
                    </span>
                    <span className="text-body-light font-size-extra-small">
                        {' '}
                        {t('diary.food.detail.protein').toUpperCase()}
                    </span>
                </p>
                <p
                    className="mb-0 mt-0 font-size-small text-right"
                    style={{ minWidth: 72 }}>
                    <span className="font-weight-semi-bold">
                        {getFormattedNumber(meal.carbs_count)}
                        {t('general.units.g')}
                    </span>
                    <span className="text-body-light font-size-extra-small">
                        {' '}
                        {t('diary.food.detail.carbs').toUpperCase()}
                    </span>
                </p>
            </div>
        </div>
    );
};

MealHeaderListItem.propTypes = {
    meal: PropTypes.object
};

export default MealHeaderListItem;
