import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import IconButton from '../../../../shared/buttons/IconButton';
import {
    isPositiveFeedback,
    isNegativeFeedback
} from '../../../../../../core/models/diary';

const getButtonClassName = feedback => {
    if (isPositiveFeedback(feedback)) {
        return 'btn-success';
    } else if (isNegativeFeedback(feedback)) {
        return 'btn-alert';
    } else {
        return '';
    }
};

const FeedbackView = ({ feedback, className, onEditClick }) => {
    return (
        <div
            className={`d-flex align-items-center ${className || ''}`}
            style={{ maxWidth: 400 }}>
            {feedback.note ? (
                <button
                    className={`mb-0 mt-0 btn-description text-right ${getButtonClassName(
                        feedback
                    )}`}
                    onClick={() => onEditClick(feedback)}>
                    {feedback.note}
                </button>
            ) : (
                <Fragment>
                    <IconButton
                        icon="feedback"
                        className="mr-2"
                        onClick={() => {
                            onEditClick({});
                        }}
                    />
                </Fragment>
            )}
        </div>
    );
};

FeedbackView.propTypes = {
    feedback: PropTypes.object.isRequired,
    onEditClick: PropTypes.func.isRequired
};

export default FeedbackView;
