import React from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import { ROUTE_LIBRARY, ROUTE_LIBRARY_DETAIL } from '../../platform/routing';

import LibraryDetailContainer from './detail/LibraryDetailContainer';
import LibraryOverviewContainer from './overview/LibraryOverviewContainer';

const LibraryScene = () => (
    <Switch>
        <Route
            exact
            path={ROUTE_LIBRARY}
            component={LibraryOverviewContainer}
        />

        <Route
            exact
            path={ROUTE_LIBRARY_DETAIL}
            component={LibraryDetailContainer}
        />

        <Redirect to={ROUTE_LIBRARY} />
    </Switch>
);

export default LibraryScene;
