import React from 'react';
import PropTypes from 'prop-types';
import Icons from '../../../assets/icons.svg';

const Icon = ({ name, className = '', style }) => {
    return (
        <svg style={style} className={`icon ${className}`}>
            <use xlinkHref={`${Icons}#${name}`} />
        </svg>
    );
};

Icon.propTypes = {
    name: PropTypes.string.isRequired
};

export default Icon;
