import {
    ROUTE_CLIENTS,
    ROUTE_DASHBOARD,
    ROUTE_DIGITAL_COACHING,
    ROUTE_LIBRARY,
    ROUTE_SETTINGS,
    ROUTE_SUBSCRIBERS
} from 'app/platform/routing';

import PropTypes from 'prop-types';
import React from 'react';
import SidebarItem from './SidebarItem';
import { t } from '@dive/localization-js';

const Sidebar = ({ isAdmin }) => {
    return (
        <nav className="sidebar">
            <ul>
                {!isAdmin ? (
                    <>
                        <SidebarItem
                            link={ROUTE_DASHBOARD}
                            icon="home"
                            label={t('dashboard.title')}
                        />

                        <SidebarItem
                            link={ROUTE_CLIENTS}
                            icon="clients"
                            label={t('clients')}
                        />

                        <SidebarItem
                            link={ROUTE_DIGITAL_COACHING}
                            icon="calendar"
                            label={t('digitalcoaching')}
                        />

                        <SidebarItem
                            link={ROUTE_LIBRARY}
                            icon="library"
                            label={t('library')}
                        />

                        <SidebarItem
                            link={ROUTE_SETTINGS}
                            icon="settings"
                            label={t('settings')}
                        />
                    </>
                ) : (
                    <SidebarItem
                        link={ROUTE_SUBSCRIBERS}
                        icon="user"
                        label={t('subscribers')}
                    />
                )}
            </ul>
        </nav>
    );
};

Sidebar.propTypes = {
    isAdmin: PropTypes.bool.isRequired
};

export default Sidebar;
