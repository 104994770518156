import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import MeasurementGraph from '../../evolution/graph/MeasurementGraph';
import MoreButton from '../../../../shared/buttons/MoreButton';
import { t } from '@dive/localization-js';
import { ROUTE_CLIENTS_DETAIL_TAB_EVOLUTION } from '../../../../../platform/routing';
import Card from '../../../../shared/card/Card';
import { getClientDetailGraphType } from '../../../../../../core/models/settings';
import { updateSetting } from '../../../../../../core/actions/settings';

const mapStateToProps = state => {
    return {
        type: getClientDetailGraphType(state.settings.data)
    };
};

const mapDispatchToProps = dispatch => {
    return {
        saveGraphType: type => {
            return dispatch(updateSetting('client_detail_graph_type', type));
        }
    };
};

class ClientMeasurementGraph extends Component {
    render() {
        return (
            <Card>
                <MeasurementGraph
                    client={this.props.client.id}
                    showLastValue={true}
                    type={this.props.type}
                />
                {this.props.client.weight && this.props.client.weight !== 0 ? (
                    <div className="d-flex mt-3 justify-content-between align-items-center">
                        <form className="form-filter w-auto">
                            <select
                                className="form-control w-auto"
                                value={this.props.type}
                                onChange={e =>
                                    this.props.saveGraphType(e.target.value)
                                }>
                                <option value={'weight'}>
                                    {t('graphs.title.weight')}
                                </option>
                                <option value={'mass'}>
                                    {t('graphs.title.mass')}
                                </option>
                                <option value={'contour'}>
                                    {t('graphs.title.contour')}
                                </option>
                            </select>
                        </form>
                        <MoreButton
                            label={t('clients.evolution.view')}
                            to={ROUTE_CLIENTS_DETAIL_TAB_EVOLUTION.replace(
                                ':id',
                                this.props.client.id
                            )}
                        />{' '}
                    </div>
                ) : null}
            </Card>
        );
    }
}

ClientMeasurementGraph.propTypes = {
    client: PropTypes.object
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(ClientMeasurementGraph);
