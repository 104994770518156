import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { t } from '@dive/localization-js';
import { request } from '@dive/react-redux-networking';

import { updateClientTreatment } from '../../../../../../platform/actions/treatment';
import DynamicFormDialog from '../../../../../shared/forms/DynamicFormDialog';
import { isVoid } from '@dive/utils-js';

class TreatmentForm extends Component {
    constructor(props) {
        super(props);

        const { fields, ...treatment } = props.treatment;
        treatment.use_simplified_diary = treatment.use_simplified_diary.toString();
        treatment.hide_fields = [];

        fields.forEach(field => {
            treatment[field.internal_name] = !field.is_hidden;

            if (field.is_hidden) {
                treatment.hide_fields.push(field.id);
            }
        });

        this.state = {
            isLoading: false,
            errors: {},
            treatment
        };
    }

    get fields() {
        const GENERAL_FIELDS = [
            {
                name: 'treatment_duration',
                label: t('treatment.plan.treatment_duration'),
                type: 'number',
                size: '6'
            },
            {
                name: 'is_intensive',
                label: t('treatment.plan.is_intensive'),
                type: 'checkbox',
                size: '6'
            },
            {
                name: 'consultation_frequency',
                label: t('treatment.plan.consultation_frequency'),
                type: 'text',
                size: '12'
            }
        ];

        const KCAL_FIELDS = [
            {
                name: 'kcal_total',
                label: t('treatment.plan.kcal_total'),
                type: 'number',
                saveInput: this.checkPercentages,
                size: '6',
                unit: t('general.units.kcal')
            },
            {
                name: 'kcal_breakfast',
                label: t('treatment.plan.kcal_breakfast'),
                size: '6',
                type: 'number',
                unit: t('general.units.kcal')
            },
            {
                name: 'kcal_lunch',
                label: t('treatment.plan.kcal_lunch'),
                size: '6',
                type: 'number',
                unit: t('general.units.kcal')
            },
            {
                name: 'kcal_dinner',
                label: t('treatment.plan.kcal_dinner'),
                size: '6',
                type: 'number',
                unit: t('general.units.kcal')
            },
            {
                name: 'kcal_snacks',
                label: t('treatment.plan.kcal_snacks'),
                size: '6',
                type: 'number',
                unit: t('general.units.kcal')
            }
        ];

        const NUTRIENTS_FIELDS = [
            {
                name: 'carbs',
                label: t('treatment.plan.carbs'),
                size: '6',
                saveInput: this.checkPercentages,
                type: 'number',
                unit: t('general.units.g')
            },
            {
                name: 'carbs_percentage',
                label: '  ',
                saveInput: this.checkPercentages,
                type: 'number',
                size: '6',
                unit: '%'
            },
            {
                name: 'protein',
                label: t('treatment.plan.protein'),
                size: '6',
                saveInput: this.checkPercentages,
                type: 'number',
                unit: t('general.units.g')
            },
            {
                name: 'protein_percentage',
                label: '  ',
                saveInput: this.checkPercentages,
                size: '6',
                type: 'number',
                unit: '%'
            },
            {
                name: 'fat',
                label: t('treatment.plan.fat'),
                size: '6',
                saveInput: this.checkPercentages,
                type: 'number',
                unit: t('general.units.g')
            },
            {
                name: 'fat_percentage',
                label: '  ',
                saveInput: this.checkPercentages,
                size: '6',
                type: 'number',
                unit: '%'
            }
        ];

        const MOVEMENT_FIELDS = [
            {
                name: 'movement_total',
                label: t('treatment.plan.movement_count'),
                type: 'number',
                size: '12'
            },
            {
                name: 'movement_type',
                label: t('treatment.plan.movement_type'),
                size: '12',
                type: 'text'
            },
            {
                name: 'movement_duration',
                label: t('treatment.plan.movement_duration'),
                size: '12',
                type: 'number'
            }
        ];

        const OTHER_FIELDS = [
            {
                name: 'note',
                label: t('treatment.plan.note'),
                size: '12',
                type: 'text'
            }
        ];

        const APP_CONFIG_FIELDS = [
            {
                title: t('treatment.plan.diary_default_configuration'),
                size: '12',
                name: 'group_diary_default',
                checkVisibility: this.isAppFieldVisible,
                fields: [
                    {
                        name: 'show_nutrient_values',
                        label: t('treatment.plan.show_nutrient_values'),
                        type: 'checkbox',
                        size: '12'
                    }
                ]
            },
            {
                title: t('treatment.plan.diary_simple_configuration'),
                size: '12',
                name: 'group_diary_simple',
                checkVisibility: this.isAppFieldVisible,
                fields: [
                    {
                        name: 'simple_question_sleep',
                        label: t('treatment.plan.simple_question_sleep'),
                        type: 'checkbox',
                        size: '6'
                    },
                    {
                        name: 'simple_question_stress',
                        label: t('treatment.plan.simple_question_stress'),
                        type: 'checkbox',
                        size: '6'
                    },
                    {
                        name: 'simple_question_alcohol',
                        label: t('treatment.plan.simple_question_alcohol'),
                        type: 'checkbox',
                        size: '6'
                    },
                    {
                        name: 'simple_question_coffee',
                        label: t('treatment.plan.simple_question_coffee'),
                        type: 'checkbox',
                        size: '6'
                    },
                    {
                        name: 'simple_question_water',
                        label: t('treatment.plan.simple_question_water'),
                        type: 'checkbox',
                        size: '6'
                    },
                    {
                        name: 'simple_question_bool_sugar',
                        label: t('treatment.plan.simple_question_bool_sugar'),
                        type: 'checkbox',
                        size: '6'
                    },
                    {
                        name: 'simple_question_bool_steps_5000',
                        label: t(
                            'treatment.plan.simple_question_bool_steps_5000'
                        ),
                        type: 'checkbox',
                        size: '6'
                    },
                    {
                        name: 'simple_question_bool_steps_10000',
                        label: t(
                            'treatment.plan.simple_question_bool_steps_10000'
                        ),
                        type: 'checkbox',
                        size: '6'
                    },
                    {
                        name: 'simple_question_bool_yoga',
                        label: t('treatment.plan.simple_question_bool_yoga'),
                        type: 'checkbox',
                        size: '6'
                    },
                    {
                        name: 'simple_question_bool_movement',
                        label: t(
                            'treatment.plan.simple_question_bool_movement'
                        ),
                        type: 'checkbox',
                        size: '6'
                    },
                    {
                        name: 'simple_question_bool_red_meat',
                        label: t(
                            'treatment.plan.simple_question_bool_red_meat'
                        ),
                        type: 'checkbox',
                        size: '6'
                    },
                    {
                        name: 'simple_question_quantity_vegetables',
                        label: t(
                            'treatment.plan.simple_question_quantity_vegetables'
                        ),
                        type: 'checkbox',
                        size: '6'
                    },
                    {
                        name: 'simple_question_quantity_bread',
                        label: t(
                            'treatment.plan.simple_question_quantity_bread'
                        ),
                        type: 'checkbox',
                        size: '6'
                    },
                    {
                        name: 'simple_question_quantity_potatoes',
                        label: t(
                            'treatment.plan.simple_question_quantity_potatoes'
                        ),
                        type: 'checkbox',
                        size: '6'
                    },
                    {
                        name: 'simple_question_bool_restaurant',
                        label: t(
                            'treatment.plan.simple_question_bool_restaurant'
                        ),
                        type: 'checkbox',
                        size: '6'
                    }
                ]
            }
        ];

        const { fields } = this.props.treatment;

        if (fields.length > 0) {
            const custom = {
                checkVisibility: this.isAppFieldVisible,
                title: t('treatment.plan.diary_custom_fields'),
                size: '12',
                name: 'group_diary_custom',
                fields: fields.map(field => ({
                    id: field.id,
                    name: field.internal_name,
                    label: field.name,
                    type: 'checkbox',
                    size: '6',
                    saveInput: this.checkCustomFields
                }))
            };

            APP_CONFIG_FIELDS.push(custom);
        }

        const APP_FIELDS = [
            {
                name: 'use_simplified_diary',
                label: t('treatment.plan.diary_type'),
                type: 'radiobutton',
                required: true,
                size: '12',
                options: [
                    {
                        value: 'false',
                        name: t('treatment.plan.diary_default')
                    },
                    {
                        value: 'true',
                        name: t('treatment.plan.diary_simple')
                    }
                ]
            },
            {
                fieldGroups: APP_CONFIG_FIELDS
            }
        ];

        return [
            {
                size: '12',
                fields: GENERAL_FIELDS
            },
            {
                title: t('treatment.plan.kcal'),
                size: '6',
                fields: KCAL_FIELDS
            },
            {
                title: t('treatment.plan.nutrients'),
                size: '6',
                fields: NUTRIENTS_FIELDS
            },
            {
                title: t('treatment.plan.movement'),
                size: '6',
                fields: MOVEMENT_FIELDS
            },
            {
                title: t('treatment.plan.app'),
                size: '6',
                fields: APP_FIELDS
            },
            {
                size: '6',
                fields: OTHER_FIELDS
            }
        ];
    }

    handleSubmit = model => {
        // we save true and false as a string because radio button
        model.use_simplified_diary = model.use_simplified_diary === 'true';

        this.props.treatment.fields.forEach(field => {
            delete model[field.internal_name];
        });

        return this.props.updateClientTreatment(this.props.client.id, model);
    };

    getRatio = name => {
        // this returns the gram/kcal ratio, this is hardcoded since its scientifically determined
        switch (name) {
            case 'carbs_percentage':
            case 'carbs':
                return 4;
            case 'protein_percentage':
            case 'protein':
                return 4;
            case 'fat_percentage':
            case 'fat':
                return 9;
            default:
                return 0;
        }
    };

    checkCustomFields = (field, model, value) => {
        field = this.props.treatment.fields.find(
            f => f.internal_name === field.name
        );

        if (value) {
            model.hide_fields = model.hide_fields.slice();
            model.hide_fields.splice(model.hide_fields.indexOf(field.id), 1);
        } else {
            model.hide_fields.push(field.id);
        }

        model[field.internal_name] = value;

        return model;
    };

    checkPercentages = (field, model, value) => {
        if (field.name === 'kcal_total' && value) {
            for (const type of ['carbs', 'protein', 'fat']) {
                model[type] =
                    Math.round(
                        ((value * (model[`${type}_percentage`] / 100)) /
                            this.getRatio(type)) *
                            100
                    ) / 100;
            }
        } else if (model.kcal_total) {
            if (field.name.indexOf('percentage') >= 0) {
                // get ratio, round to 2 decimals
                // e.g. from carbs_percentage -> carbs (g)
                model[field.name.replace('_percentage', '')] =
                    Math.round(
                        ((model.kcal_total * (value / 100)) /
                            this.getRatio(field.name)) *
                            100
                    ) / 100;
            } else {
                // get ratio, round to 2 decimals
                // e.g. from carbs (g) -> carbs_percentage
                model[`${field.name}_percentage`] =
                    Math.round(
                        ((value * this.getRatio(field.name)) /
                            model.kcal_total) *
                            100 *
                            100
                    ) / 100;
            }
        }

        // set value
        model[field.name] = value;

        return model;
    };

    isAppFieldVisible = (field, model) => {
        if (field.name === 'group_diary_default') {
            return (
                model.use_simplified_diary === 'false' ||
                model.use_simplified_diary === false
            );
        } else {
            return (
                model.use_simplified_diary === 'true' ||
                model.use_simplified_diary === true
            );
        }
    };

    handleValidate = (fields, model) => {
        const errors = {};

        if (
            model.kcal_total &&
            model.kcal_breakfast &&
            model.kcal_lunch &&
            model.kcal_dinner &&
            model.kcal_snacks &&
            parseInt(model.kcal_total, 10) !==
                parseInt(model.kcal_breakfast, 10) +
                    parseInt(model.kcal_lunch, 10) +
                    parseInt(model.kcal_dinner, 10) +
                    parseInt(model.kcal_snacks, 10)
        ) {
            errors.kcal_total = t('treatment.plan.form.errors.kcal');
        }

        if (
            model.carbs_percentage &&
            model.fat_percentage &&
            model.protein_percentage &&
            100 !==
                parseFloat(model.carbs_percentage) +
                    parseFloat(model.fat_percentage) +
                    parseFloat(model.protein_percentage)
        ) {
            errors.carbs_percentage = t('treatment.plan.form.errors.nutrients');
            errors.fat_percentage = t('treatment.plan.form.errors.nutrients');
            errors.protein_percentage = t(
                'treatment.plan.form.errors.nutrients'
            );
        }

        return errors;
    };

    render() {
        const { treatment } = this.state;
        const { onDismissClicked, onFinished } = this.props;

        if (isVoid(treatment)) {
            return null;
        }

        return (
            <DynamicFormDialog
                size="xxl"
                title={t('treatment.plan.edit')}
                model={treatment}
                submitLabel={t('general.form.update')}
                fieldGroups={this.fields}
                onDismissClicked={onDismissClicked}
                onFinished={onFinished}
                onValidate={this.handleValidate}
                onSaveForm={this.handleSubmit}
            />
        );
    }
}

TreatmentForm.propTypes = {
    treatment: PropTypes.object.isRequired,
    client: PropTypes.object.isRequired,
    onDismissClicked: PropTypes.func.isRequired,
    onFinished: PropTypes.func.isRequired
};

const mapDispatchToProps = dispatch => ({
    updateClientTreatment: (id, treatment) =>
        request(dispatch(updateClientTreatment(id, treatment)))
});

export default connect(
    undefined,
    mapDispatchToProps
)(TreatmentForm);
