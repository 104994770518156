import React from 'react';
import { FileType } from '../../../../core/models/library';
import Icon from '../../shared/icon/Icon';
import { isVoid } from '@dive/utils-js';

const FilePreview = ({ children, className, small, style }) => {
    if (children.type === FileType.IMAGE) {
        return (
            <img
                className={className}
                src={children.versions['thumb' + (small ? '-small' : '')]}
                style={{ objectFit: 'cover', ...style }}
                alt={children.name}
            />
        );
    }

    let name = null;

    switch (children.type) {
        case FileType.PDF:
            name = 'adobe';
            break;

        case FileType.DOCUMENT:
            name = 'file';
            break;

        case FileType.VIDEO:
            name = 'video';
            break;

        default:
            return null;
    }

    let classes = 'd-flex justify-content-center align-items-center';

    if (!isVoid(className)) {
        classes += ` ${className}`;
    }

    return (
        <div className={classes} style={style}>
            <Icon
                style={{ fill: '#94A29B', width: '50%', height: '75%' }}
                name={name}
            />
        </div>
    );
};

export default FilePreview;
