import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { t } from '@dive/localization-js';
import { request } from '@dive/react-redux-networking';

import Card from '../../shared/card/Card';
import SortableTable from '../../shared/table/SortableTable';
import Icon from '../../shared/icon/Icon';
import RoundButton from '../../shared/buttons/RoundButton';
import LoadingIcon from '../../shared/loading/LoadingIcon';
import DeleteAlertModal from '../../shared/modal/DeleteAlertModal';
import { deleteTag, fetchTags, getTags } from '../../../platform/actions/tags';
import TagForm from './form/TagForm';
import { isLoadingData } from '../../../../core/utils/data';
import ErrorView from '../../shared/view/ErrorView';
import EmptyView from '../../shared/view/EmptyView';

const mapStateToProps = state => {
    return {
        isLoading: isLoadingData(state.tags),
        error: state.tags.error,
        tags: state.tags.data
    };
};

const mapDispatchToProps = dispatch => {
    return {
        getTags: () => {
            return dispatch(getTags());
        },
        fetchTags: () => {
            return dispatch(fetchTags());
        },
        deleteTag: id => {
            return request(dispatch(deleteTag(id)));
        }
    };
};

class SettingsTagsTab extends Component {
    constructor(props) {
        super(props);
        this.state = {
            actions: {
                deleteTag: null,
                updateTag: null
            },
            errors: {}
        };
        this.columns = [
            {
                title: t('settings.tags.list.name'),
                key: 'name',
                sortable: false
            },
            {
                title: t('settings.tags.list.actions'),
                key: '',
                sortable: false
            }
        ];
    }

    componentDidMount() {
        this.props.getTags();
    }

    componentDidUpdate(prevProps) {
        this.props.getTags();
    }

    addTag = () => {
        this.editTag({});
    };

    deleteTag(tag) {
        // show or hide the delete modal
        this.setState({
            actions: { deleteTag: tag }
        });
    }

    editTag(tag) {
        this.setState({
            actions: { updateTag: tag }
        });
    }

    onFinishAction() {
        // remove dialog
        this.setState({ actions: {} });
        // refetch data
        this.props.fetchTags();
    }

    render() {
        return (
            <Fragment>
                {this.props.isLoading ? (
                    <LoadingIcon />
                ) : (
                    <Fragment>
                        <div className="d-flex align-items-center mb-3">
                            <RoundButton
                                icon="add"
                                className="btn-round ml-auto"
                                onClick={this.addTag}
                                label={t('settings.tags.add')}
                            />
                        </div>
                        <Card
                            padding={
                                !this.props.tags || this.props.tags.length === 0
                            }>
                            {this.props.tags && this.props.tags.length > 0 ? (
                                <SortableTable
                                    columns={this.columns}
                                    isLoading={this.props.isLoading}>
                                    {this.props.tags.map(tag => (
                                        <tr key={tag.id}>
                                            <td>
                                                <span className="font-weight-semi-bold">
                                                    {tag.name}
                                                </span>
                                            </td>
                                            <td>
                                                <div className="d-flex">
                                                    <button
                                                        className="btn btn-icon"
                                                        onClick={() =>
                                                            this.editTag(tag)
                                                        }>
                                                        <Icon name="edit" />
                                                    </button>
                                                    <button
                                                        className="btn btn-icon btn-alert ml-2"
                                                        onClick={() =>
                                                            this.deleteTag(tag)
                                                        }>
                                                        <Icon name="delete" />
                                                    </button>
                                                </div>
                                            </td>
                                        </tr>
                                    ))}
                                </SortableTable>
                            ) : this.props.error ? (
                                <ErrorView error={this.props.error} />
                            ) : (
                                <EmptyView
                                    label={t('settings.tags.list.empty')}
                                />
                            )}
                        </Card>
                    </Fragment>
                )}

                {this.state.actions.updateTag && (
                    <TagForm
                        tag={this.state.actions.updateTag}
                        onDismissClicked={() => this.editTag(null)}
                        onFinished={() => this.onFinishAction()}
                    />
                )}
                {this.state.actions.deleteTag && (
                    <DeleteAlertModal
                        title={t('settings.tags.delete.title')}
                        description={t('settings.tags.delete.description')}
                        onDelete={() =>
                            this.props.deleteTag(
                                this.state.actions.deleteTag.id
                            )
                        }
                        onDismissClicked={() => this.deleteTag(null)}
                        onFinished={() => this.onFinishAction()}
                    />
                )}
            </Fragment>
        );
    }
}

SettingsTagsTab.propTypes = {};

export default connect(mapStateToProps, mapDispatchToProps)(SettingsTagsTab);
