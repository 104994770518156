import { isVoid } from '@dive/utils-js';
import { t } from '@dive/localization-js';
import React, { createRef, Component } from 'react';

import DialogModal from '../../shared/modal/DialogModal';
import ErrorView from '../../shared/view/ErrorView';
import LoadingIcon from '../../shared/loading/LoadingIcon';
import IconButton from '../../shared/buttons/IconButton';
import { MAX_FILE_SIZE } from '../../../platform/constants';
import { connect } from 'react-redux';
import EmptyView from '../../shared/view/EmptyView';

const mapStateToProps = state => {
    return {
        tags: state.tags.data
    };
};

class LibraryUploadForm extends Component {
    static defaultProps = {
        maxFileSize: MAX_FILE_SIZE
    };

    constructor(props) {
        super(props);

        this.input = createRef();

        this.state = {
            errors: {},
            isLoading: false,
            tagIds: isVoid(this.props.entry.tags)
                ? []
                : this.props.entry.tags.map(tag => tag.id.toString())
        };
    }

    // ///////////////////////////////////////////////////////////////////////
    // LIFECYCLE METHODS
    // ///////////////////////////////////////////////////////////////////////

    componentDidMount() {
        // this.displayOpenFileDialog();
    }

    // ///////////////////////////////////////////////////////////////////////
    // EVENT HANDLERS
    // ///////////////////////////////////////////////////////////////////////

    handleFileRemove = () => {
        if (!isVoid(this.input.current)) {
            this.input.current.value = '';
        }

        this.setState({ selected: null, error: null });
    };

    handleFileSelect = e => {
        const { files } = e.currentTarget;

        if (isVoid(files) || isVoid(files[0])) {
            return;
        }

        if (files[0].size > this.props.maxFileSize) {
            window.alert(t('general.form.errors.max_size'));
            return;
        }

        this.setState({ selected: files[0] });
    };

    handleUpload = () => {
        const { selected } = this.state;
        const { entry, onCreate, onFinished, onUpdate } = this.props;

        if (isVoid(entry.id) && isVoid(selected)) return;

        const data = new FormData();
        for (var i in this.state.tagIds) {
            data.append('tag_ids[]', this.state.tagIds[i]);
        }
        if (!isVoid(selected)) {
            data.append('file', selected, selected.name);
        }

        this.setState({ isUploading: true, error: null });

        const action = isVoid(entry.id)
            ? onCreate(data)
            : onUpdate(entry.id, data);

        action.then(onFinished).catch(err => {
            this.setState({ error: err.getMessage(), isUploading: false });
        });
    };

    handleTagChange = (id, value) => {
        var tagIds = this.state.tagIds;
        var index = tagIds.indexOf(id);

        if (value && index < 0) {
            tagIds.push(id);
        } else if (!value && index !== -1) {
            tagIds.splice(index, 1);
        }

        this.setState({ tagIds: tagIds });
    };

    // ///////////////////////////////////////////////////////////////////////
    // OTHERS
    // ///////////////////////////////////////////////////////////////////////

    displayOpenFileDialog = () => {
        if (!isVoid(this.input.current)) {
            this.input.current.click();
        }
    };

    render() {
        const { error, isUploading, selected, tagIds } = this.state;
        const { entry, onDismissClicked } = this.props;

        return (
            <DialogModal
                title={t(
                    'library.form.' +
                        (isVoid(entry.id) ? 'add' : 'edit') +
                        '.title'
                )}
                onDismissClicked={onDismissClicked}>
                <ErrorView error={error} />

                <div className="upload-results">
                    {isUploading ? (
                        <LoadingIcon />
                    ) : selected ? (
                        <div className="d-flex align-items-center">
                            <p className="my-0">{selected.name}</p>

                            <IconButton
                                className="btn-alert"
                                onClick={this.handleFileRemove}
                                icon="delete"
                            />
                        </div>
                    ) : (
                        <>
                            <input
                                ref={this.input}
                                name="file"
                                onChange={this.handleFileSelect}
                                type="file"
                                id="file"
                                hidden
                            />

                            <button
                                onClick={this.displayOpenFileDialog}
                                className="btn btn-tertiary">
                                {t('general.form.upload')}
                            </button>
                        </>
                    )}
                </div>

                {!isUploading && (
                    <div className="form-group mt-3">
                        <label htmlFor="tag_ids">
                            {t('digitalcoaching.list.tags')}
                        </label>
                        <div className="input-group">
                            <div
                                className={`form-check-group ${
                                    error ? 'is-invalid' : ''
                                }`}>
                                {this.props.tags.length > 0 ? (
                                    this.props.tags.map(option => (
                                        <div
                                            className="form-check"
                                            key={`${option.id}`}>
                                            <label
                                                className="form-check-label"
                                                htmlFor={`tag_${option.id}`}>
                                                <input
                                                    className="form-check-input"
                                                    type="checkbox"
                                                    name="tags[]"
                                                    id={`tag_${option.id}`}
                                                    value={option.id}
                                                    checked={
                                                        tagIds.indexOf(
                                                            option.id.toString()
                                                        ) !== -1
                                                    }
                                                    onChange={e => {
                                                        this.handleTagChange(
                                                            e.target.value,
                                                            e.target.checked
                                                        );
                                                    }}
                                                />
                                                {option.name}
                                            </label>
                                        </div>
                                    ))
                                ) : (
                                    <EmptyView
                                        className="m-0"
                                        label={t(
                                            'general.forms.error.tags.empty'
                                        )}
                                    />
                                )}
                            </div>
                        </div>
                    </div>
                )}

                {!isUploading && (
                    <div className="d-flex justify-content-end">
                        <button
                            disabled={isVoid(entry.id) && isVoid(selected)}
                            onClick={this.handleUpload}
                            className="btn btn-submit btn-primary">
                            {t(
                                'general.' +
                                    (isVoid(entry.id) ? 'upload' : 'save')
                            )}
                        </button>
                    </div>
                )}
            </DialogModal>
        );
    }
}

export default connect(mapStateToProps)(LibraryUploadForm);
