import React from 'react';
import PropTypes from 'prop-types';
import Icon from 'app/components/shared/icon/Icon';

const RoundButton = ({ label, className, icon, onClick }) => {
    return (
        <button
            className={`btn btn-secondary btn-round ${className || ''}`}
            onClick={() => onClick()}>
            {icon && <Icon name={icon} />} {label}
        </button>
    );
};

RoundButton.propTypes = {
    label: PropTypes.string.isRequired,
    icon: PropTypes.string
};

export default RoundButton;
