import PropTypes from 'prop-types';
import React, { Fragment } from 'react';
import LoadingIcon from '../../../shared/loading/LoadingIcon';
import { t } from '@dive/localization-js';
import ErrorView from '../../../shared/view/ErrorView';
import connect from 'react-redux/es/connect/connect';
import { showMessage } from '../../../../platform/actions/messages';

const mapDispatchToProps = dispatch => {
    return {
        showMessage: message => {
            dispatch(showMessage(message));
        }
    };
};

class CouponButton extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            code: '',
            isLoading: false,
            errors: {}
        };
    }

    componentDidMount() {}

    onCodeChange = e => {
        this.setState({
            code: e.target.value
        });
    };

    onSubmit = () => {
        if (this.state.code && this.state.code.length > 0) {
            this.setState({
                isLoading: true,
                errors: {}
            });
            // this will be replaced in the future with a real coupon system
            setTimeout(() => {
                if (this.isValidCode(this.state.code)) {
                    // show message
                    this.props.showMessage(t('subscriptions.coupon.success'));
                    // set state and let parent know
                    this.setState(
                        {
                            isLoading: false
                        },
                        () => {
                            this.props.onCouponSelect(this.state.code);
                        }
                    );
                } else {
                    this.setState({
                        isLoading: false,
                        errors: {
                            coupon: t('subscriptions.coupon.errors.not_valid')
                        }
                    });
                }
            }, 400);
        }
    };

    isValidCode = code => {
        // if (code === 'FIRSTABO') {
        //     return true;
        // } else if (
        //     code === 'LAUNCH2018' &&
        //     moment().isBefore(moment('11-01-2018', 'MM-DD-YYYY'))
        // ) {
        //     return true;
        // } else if (
        //     code === 'KLANT2018' &&
        //     moment().isBetween(
        //         moment('10-06-2018', 'MM-DD-YYYY'),
        //         moment('10-13-2018', 'MM-DD-YYYY')
        //     )
        // ) {
        //     return true;
        // }
        return false;
    };

    render() {
        return (
            <Fragment>
                <ErrorView error={this.state.errors.coupon} />
                <div className="input-group mb-3">
                    <input
                        type="text"
                        className="form-control"
                        placeholder={t('subscriptions.coupon.code')}
                        id="coupon"
                        disabled={this.state.isLoading}
                        onChange={this.onCodeChange}
                        value={this.state.code}
                    />
                    <div className="input-group-append">
                        <button
                            className="btn btn-primary"
                            onClick={this.onSubmit}
                            type="button"
                            id="button-addon2">
                            {this.state.isLoading ? (
                                <LoadingIcon />
                            ) : (
                                t('subscriptions.coupon.submit')
                            )}
                        </button>
                    </div>
                </div>
            </Fragment>
        );
    }
}

CouponButton.propTypes = {
    coupon: PropTypes.object,
    onCouponSelect: PropTypes.func
};

export default connect(
    null,
    mapDispatchToProps
)(CouponButton);
