import { isVoid } from '@dive/utils-js';

export const selectPage = data => (isVoid(data) ? 1 : data.page);

export const selectPagination = data =>
    isVoid(data)
        ? null
        : {
              page: data.page,
              page_size: data.page_size,
              count: data.count
          };

export const selectSort = data => (isVoid(data) ? null : data.sort);
