import React from 'react';
import { connect } from 'react-redux';
import { t } from '@dive/localization-js';
import Card from '../../shared/card/Card';
import Logo from '../../shared/logo/Logo';
import AppStoreDownloadButton from '../../../assets/apple-app-store.png';

const mapStateToProps = state => {
    return {
        isLoading: state.user.isLoading
    };
};

class MobileAppContainer extends React.Component {
    render() {
        return (
            <div className="container">
                <Card>
                    <div className="mb-5">
                        <Logo />
                    </div>
                    <h1 className="d-block">{t('mobile_app.title')}</h1>
                    <p className="mt-3">{t('mobile_app.description')}</p>
                    <div className="download-links d-flex align-items-center">
                        <a
                            target="_blank"
                            rel="noopener noreferrer"
                            href="https://play.google.com/store/apps/details?id=com.myfoodcoachandme&pcampaignid=MKT-Other-global-all-co-prtnr-py-PartBadge-Mar2515-1">
                            <img
                                alt="Get it on Google Play"
                                src="https://play.google.com/intl/en_gb/badges/images/generic/en_badge_web_generic.png"
                            />
                        </a>
                        <a
                            target="_blank"
                            rel="noopener noreferrer"
                            href="https://itunes.apple.com/WebObjects/MZStore.woa/wa/viewSoftware?id=1417928165&mt=8">
                            <img
                                alt="Get it on the App Store"
                                src={AppStoreDownloadButton}
                            />
                        </a>
                    </div>
                </Card>
            </div>
        );
    }
}

export default connect(mapStateToProps)(MobileAppContainer);
