import { buildQuery, isEmptyText, isVoid } from '@dive/utils-js';

import { selectClientLibraryFilters } from '../model/client';
import { selectClientLibrary } from 'core/models/client';
import { selectPage, selectSort } from 'core/models/app';
import { shouldRefreshStateData } from 'core/utils/data';
import {
    LIBRARY_CACHE_INTERVAL,
    LibraryTypes,
    BASE_URL
} from 'core/actions/library';

export const fetchClientLibrary = (id, page = 1, sort) => (
    dispatch,
    getState
) => {
    const filters = selectClientLibraryFilters(getState());
    const qs = { page };

    if (!isVoid(sort)) {
        qs.sort = sort.column + ':' + sort.order;
    }

    if (!isEmptyText(filters.search)) {
        qs.search = filters.search;
    }

    if (!isVoid(filters.tag)) {
        qs.tag = filters.tag;
    }

    return dispatch({
        type: LibraryTypes.GET_CLIENT_LIBRARY,
        payload: {
            request: {
                url: '/clients/' + id + BASE_URL + buildQuery(qs),
                method: 'GET'
            }
        }
    });
};

export const getClientLibrary = id => (dispatch, getState) => {
    const library = selectClientLibrary(getState());

    if (shouldRefreshStateData(library, LIBRARY_CACHE_INTERVAL)) {
        return dispatch(
            fetchClientLibrary(
                id,
                selectPage(library.data),
                selectSort(library.data)
            )
        );
    }
};
