import { t } from '@dive/localization-js';
import { login } from '@dive/react-redux-networking';
import { parseQuery, isVoid } from '@dive/utils-js';
import React from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';

import LoadingIcon from 'app/components/shared/loading/LoadingIcon';
import SubmitButton from '../../shared/buttons/SubmitButton';
import Card from '../../shared/card/Card';
import {
    history,
    ROUTE_MOBILE_APP,
    ROUTE_REGISTER,
    ROUTE_PASSWORD_RESET
} from '../../../platform/routing';
import {
    isAdmin,
    isDietician,
    selectUserLoading,
    selectUser,
    selectUserPlan
} from '../../../../core/models/user';
import Logo from '../../shared/logo/Logo';
import ErrorView from '../../shared/view/ErrorView';
import { isValid } from '../../../platform/model/subscription';

class LoginContainer extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            isLoading: false,
            email: '',
            password: '',
            errors: {}
        };
    }

    handleInputChanged = ({ currentTarget }) => {
        this.setState({
            [currentTarget.name]: currentTarget.value,
            errors: {}
        });
    };

    handleRegister() {
        history.push(ROUTE_REGISTER);
    }

    handleSubmit = event => {
        event.preventDefault();

        if (!this.isValid()) {
            return;
        }

        // set loading
        this.setState({
            isLoading: true,
            errors: {}
        });

        const { location, login, subscribed } = this.props;
        const { email, password } = this.state;

        // submit form

        login(email, password)
            .then(response => {
                if (!isDietician(response) && !isAdmin(response)) {
                    history.push(ROUTE_MOBILE_APP);
                    return;
                }

                const { redirect_url: redirect } = parseQuery(location.search);

                if (subscribed && !isVoid(redirect)) {
                    history.push(redirect);
                }
            })
            .catch(err => {
                this.setState({
                    isLoading: false,
                    errors: {
                        general:
                            err.getStatus() === 401
                                ? t('login.login.form.errors.auth')
                                : err.getMessage()
                    }
                });
            });
    };

    isValid() {
        const { email, password } = this.state;
        const errors = {};

        if (email.length === 0) {
            errors.email = t('general.form.errors.required');
        }

        if (password.length === 0) {
            errors.password = t('general.form.errors.required');
        }

        if (Object.keys(errors).length > 0) {
            this.setState({ errors });

            return false;
        }

        return true;
    }

    render() {
        const { errors, email, isLoading } = this.state;
        const { isUserLoading } = this.props;

        return (
            <div className="container">
                <Card>
                    <Logo />

                    <h1>{t('login.login.title')}</h1>

                    <form
                        className="form"
                        onSubmit={this.handleSubmit}
                        autoComplete="false">
                        {errors.general && (
                            <ErrorView
                                className="d-block"
                                error={errors.general}
                            />
                        )}

                        <p>{t('login.login.description')}</p>

                        {isUserLoading || isLoading ? (
                            <LoadingIcon />
                        ) : (
                            <div>
                                <div className="form-group">
                                    <label htmlFor="email">
                                        {t('login.login.form.email.label')}{' '}
                                    </label>

                                    <input
                                        className={`form-control ${
                                            errors.email ? 'is-invalid' : ''
                                        }`}
                                        type="text"
                                        name="email"
                                        id="email"
                                        value={email}
                                        onChange={this.handleInputChanged}
                                        placeholder=""
                                    />

                                    {errors.email && (
                                        <div className="invalid-feedback">
                                            {errors.email}
                                        </div>
                                    )}
                                </div>

                                <div className="form-group">
                                    <label htmlFor="password">
                                        {t('login.login.form.password.label')}
                                    </label>

                                    <input
                                        className={`form-control ${
                                            errors.password ? 'is-invalid' : ''
                                        }`}
                                        type="password"
                                        name="password"
                                        id="password"
                                        onChange={this.handleInputChanged}
                                        placeholder=""
                                    />

                                    {errors.password && (
                                        <div className="invalid-feedback">
                                            {errors.password}
                                        </div>
                                    )}
                                </div>

                                <div className="d-flex flex-wrap justify-content-between">
                                    <SubmitButton
                                        classes="mt-1 mr-3"
                                        label={t('login.login.form.submit')}
                                        type="submit"
                                    />

                                    <Link
                                        className="btn-link btn-light mt-1"
                                        to={ROUTE_PASSWORD_RESET}>
                                        {t('reset.password.link')}
                                    </Link>
                                </div>
                            </div>
                        )}
                    </form>

                    <span className="divider mt-4 mb-4" />

                    <div>
                        <h1>{t('login.register.title')}</h1>

                        <p>{t('login.register.description')}</p>

                        <div className="btn-group">
                            <SubmitButton
                                onClick={this.handleRegister}
                                className="btn-secondary"
                                label={t('login.register.button')}
                                type="submit"
                            />
                        </div>
                    </div>
                </Card>
            </div>
        );
    }
}

const mapStateToProps = state => ({
    isUserLoading: selectUserLoading(state),
    subscribed: !!selectUser(state) && isValid(selectUserPlan(state))
});

const mapDispatchToProps = {
    login
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(LoginContainer);
