import { shouldRefreshStateData } from '../utils/data';

export const CoachingActions = {
    SET_FILTERS: '/coachings/SET_FILTERS',

    GET_COACHINGS: '/coachings/LOAD',
    GET_COACHINGS_SUCCESS: '/coachings/LOAD_SUCCESS',
    GET_COACHINGS_FAIL: '/coachings/LOAD_FAIL',

    CLEAR_COACHINGS: '/coachings/CLEAR',

    GET_CLIENT_COACHING: '/client/coachings/LOAD',
    GET_CLIENT_COACHING_SUCCESS: '/client/coachings/LOAD_SUCCESS',
    GET_CLIENT_COACHING_FAIL: '/client/coachings/LOAD_FAIL',
    SET_CLIENT_FILTERS: '/client/coachings/SET_FILTERS',

    CLEAR_CLIENT_COACHING: '/client/coachings/CLEAR',

    CREATE_CLIENT: '/coaching/CREATE',
    CREATE_CLIENT_SUCCESS: '/coaching/CREATE_SUCCESS',
    CREATE_CLIENT_FAIL: '/coaching/CREATE_FAIL',

    UPDATE_CLIENT: '/coaching/UPDATE',
    UPDATE_CLIENT_SUCCESS: '/coaching/UPDATE_SUCCESS',
    UPDATE_CLIENT_FAIL: '/coaching/UPDATE_FAIL',

    REMOVE_CLIENT: '/coaching/REMOVE',
    REMOVE_CLIENT_SUCCESS: '/coaching/REMOVE_SUCCESS',
    REMOVE_CLIENT_FAIL: '/coaching/REMOVE_FAIL'
};

const COACHINGS_CACHE_INTERVAL = 1000 * 60 * 5;

export const getCoachings = () => (dispatch, getState) => {
    if (shouldRefreshStateData(getState().coaching, COACHINGS_CACHE_INTERVAL)) {
        return dispatch(
            fetchCoachings(
                getState().coaching.data ? getState().coaching.data.page : 1
            )
        );
    }
};

export const setFilters = filters => ({
    type: CoachingActions.SET_FILTERS,
    filters
});

export const setClientFilters = filters => ({
    type: CoachingActions.SET_CLIENT_FILTERS,
    filters
});

export const fetchCoachings = (page = 1, combinePages = false) => (
    dispatch,
    getState
) => {
    const { filters } = getState().coaching;

    return dispatch({
        type: CoachingActions.GET_COACHINGS,
        payload: {
            request: {
                url: `/coaching?page=${page}
                    ${filters.category ? `&category=${filters.category}` : ''}
                    ${filters.tag ? `&tag=${filters.tag}` : ''}
                    ${
                        filters.startDate
                            ? `&start_date=${filters.startDate}`
                            : ''
                    }
                    ${filters.endDate ? `&end_date=${filters.endDate}` : ''}
                    `,
                method: 'GET',
                combinePages: combinePages
            }
        }
    });
};

export const getClientCoachings = (id, page = 1) => (
  dispatch,
  getState
) => {
    const { filters } = getState().clients.detail.coaching

    return dispatch({
        type: CoachingActions.GET_CLIENT_COACHING,
        payload: {
            request: {
                url: `/clients/${id}/coaching?page=${page}
                    ${filters.tag ? `&tag=${filters.tag}` : ''}`,
                method: 'GET'
            }
        }
    });
};

export const updateCoaching = consultation => {
    const data = new FormData();

    if (consultation.image_foodFile) {
        data.append('image_food', consultation.image_foodFile);
        delete consultation['image_food'];
    }
    if (consultation.image_movementFile) {
        data.append('image_movement', consultation.image_movementFile);
        delete consultation['image_movement'];
    }
    if (consultation.image_motivationFile) {
        data.append('image_motivation', consultation.image_motivationFile);
        delete consultation['image_motivation'];
    }

    data.append('data', JSON.stringify(consultation));

    return {
        type: consultation.id
            ? CoachingActions.UPDATE_CLIENT
            : CoachingActions.CREATE_CLIENT,
        payload: {
            request: {
                id: consultation.id,
                url: consultation.id
                    ? `/coaching/${consultation.id}`
                    : '/coaching',
                method: 'POST',
                data: data,
                headers: { 'Content-Type': 'multipart/form-data' }
            }
        }
    };
};

export const deleteCoaching = id => ({
    type: CoachingActions.REMOVE_CLIENT,
    payload: {
        request: {
            url: `/coaching/${id}`,
            method: 'DELETE',
            id
        }
    }
});

export const clearCoachings = () => ({
    type: CoachingActions.CLEAR_COACHINGS
});
