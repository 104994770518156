import React, { Component } from 'react';

import DynamicFormDialog from '../../../shared/forms/DynamicFormDialog';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { request } from '@dive/react-redux-networking';
import { t } from '@dive/localization-js';
import { updateCategory } from '../../../../platform/actions/categories';

const mapDispatchToProps = dispatch => {
    return {
        saveCategory: category => {
            return request(dispatch(updateCategory(category)));
        }
    };
};

class CategoryForm extends Component {
    constructor(props) {
        super(props);

        this.setFields();
    }

    setFields() {
        this.fields = [
            {
                name: 'name',
                label: t('settings.categories.list.name'),
                type: 'string',
                required: true,
                size: '12'
            }
        ];
    }

    render() {
        return this.props.category ? (
            <DynamicFormDialog
                title={t(
                    this.props.category.id
                        ? 'settings.categories.edit'
                        : 'settings.categories.add'
                )}
                model={this.props.category}
                onSaveForm={this.props.saveCategory}
                fields={this.fields}
                onDismissClicked={this.props.onDismissClicked}
                onFinished={this.props.onFinished}
            />
        ) : null;
    }
}

CategoryForm.propTypes = {
    category: PropTypes.object.isRequired,
    onDismissClicked: PropTypes.func.isRequired,
    onFinished: PropTypes.func.isRequired
};

export default connect(
    undefined,
    mapDispatchToProps
)(CategoryForm);
