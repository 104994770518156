import React, { PureComponent } from 'react';

import { CHART_COLORS } from '../../../../../platform/constants';
import { Doughnut } from 'react-chartjs-2';

const randHex = () => {
    let n = (Math.random() * 0xfffff * 1000000).toString(16);
    return '#' + n.slice(0, 6);
};

const setLegend = initialLegend => {
    return initialLegend.map((legendItem, index) => {
        return {
            id: `${legendItem}-${index}`,
            title: legendItem,
            color: CHART_COLORS[index]
        };
    });
};

const setColors = dataLength => {
    return CHART_COLORS.slice(0, dataLength);
};

const addExtraColors = dataLength => {
    const missingColorsLength = dataLength - CHART_COLORS.length;
    if (Math.sign(missingColorsLength) > 0) {
        for (let i = 0; i < missingColorsLength; i++) {
            CHART_COLORS.push(randHex());
        }
    }
};

const options = {
    cutoutPercentage: 90,
    hoverBorderWidth: 0,
    borderWidth: 0,
    legend: {
        display: false
    }
};

export default class PieGraph extends PureComponent {
    state = {
        legend: [],
        colorSet: !!this.props.data.datasets[0].backgroundColor
    };

    componentDidMount() {
        // If you have more items than there are constant colors, add extra colors.
        addExtraColors(this.props.data.labels.length);

        // After you've set extra colors transform the data and add default colors.
        this.setDefaultColors(this.props.colors, this.props.data);
    }

    setDefaultColors = (colors, data) => {
        if (!colors) {
            data.datasets[0].backgroundColor = setColors(data.labels.length);
        }
        // Colors are set and set the legend colors by transforming the data
        this.setState({
            legend: setLegend(this.props.data.labels),
            colorSet: true
        });
    };

    render() {
        const { legend, colorSet } = this.state;
        return (
            <div className={this.props.className && this.props.className}>
                {colorSet && (
                    <Doughnut data={this.props.data} options={options} />
                )}
                <ul className="d-flex flex-wrap justify-content-center mt-4">
                    {legend.length &&
                        legend.map(item => {
                            return (
                                <li
                                    key={item.id}
                                    className="d-flex justify-content-center align-items-center text-center mr-3 mt-2 font-size-extra-small text-body-light">
                                    <div
                                        className="mr-1 flex-shrink-0 rounded-circle"
                                        style={{
                                            width: '12px',
                                            height: '12px',
                                            backgroundColor: item.color
                                        }}
                                    />
                                    <span>{item.title}</span>
                                </li>
                            );
                        })}
                </ul>
            </div>
        );
    }
}
