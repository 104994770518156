import React, { Component } from 'react';
import {
    fetchDashboardCard,
    getDashboardCard
} from '../../../../platform/actions/dashboard';

import Card from '../../../shared/card/Card';
import ConditionalWrapper from '../../../shared/wrapper/ConditionalWrapper';
import DashboardCardContent from './DashboardCardContent';
import FilterDropdown from './FilterDropdown';
import LoadingIcon from 'app/components/shared/loading/LoadingIcon';
import { connect } from 'react-redux';
import { isVoid } from '@dive/utils-js';

const mapStateToProps = (state, props) => {
    const card = state.dashboard.list && state.dashboard.list[props.cardName];

    return {
        isLoading:
            isVoid(card) ||
            ((card.isLoading && isVoid(card.data)) ||
                (isVoid(card.data) && isVoid(card.error))),
        card: card,
        options: card && card.ranges,
        selectedOption: card && card.range
    };
};

const mapDispatchToProps = {
    getDashboardCard: cardName => getDashboardCard(cardName),
    fetchDashboardCard: (cardName, range) => fetchDashboardCard(cardName, range)
};

class DashboardCard extends Component {
    componentDidMount() {
        this.props.getDashboardCard(this.props.cardName);
    }

    onFilterChanged = e => {
        // By fetching the dashboard card you set the range in the global state.
        this.props.fetchDashboardCard(this.props.cardName, e.target.value);
        e.target.blur();
    };

    render() {
        return (
            <div className="col-md-6 col-lg-4 mb-4 d-flex flex-column">
                {this.props.isLoading ? (
                    <LoadingIcon />
                ) : (
                    <>
                        <h2 className="p-0">{this.props.card.title}</h2>
                        <ConditionalWrapper
                            condition={this.props.card.url}
                            wrapper={children => (
                                <a
                                    className="d-flex flex-grow-1"
                                    href={this.props.card.url}>
                                    {children}
                                </a>
                            )}>
                            <Card className="d-flex flex-grow-1">
                                <>
                                    <DashboardCardContent
                                        card={this.props.card}
                                    />
                                    {this.props.options && (
                                        <FilterDropdown
                                            filters={this.props.options}
                                            defaultSelected={
                                                this.props.selectedOption
                                            }
                                            onFilterChanged={
                                                this.onFilterChanged
                                            }
                                        />
                                    )}
                                </>
                            </Card>
                        </ConditionalWrapper>
                    </>
                )}
            </div>
        );
    }
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(DashboardCard);
