import { shouldRefreshStateData } from '../../../core/utils/data';

export const SubscribersActions = {
    GET_SUBSCRIBERS: '/subscribers/LOAD',
    GET_SUBSCRIBERS_SUCCESS: '/subscribers/LOAD_SUCCESS',
    GET_SUBSCRIBERS_FAIL: '/subscribers/LOAD_FAIL',

    CLEAR_SUBSCRIBERS: 'CLEAR'
};

const SUBSCRIBERS_CACHE_INTERVAL = 1000 * 60 * 5;

export const getSubscribers = () => (dispatch, getState) => {
    const { subscribers } = getState();

    if (shouldRefreshStateData(subscribers, SUBSCRIBERS_CACHE_INTERVAL)) {
        const { data } = subscribers;

        return dispatch(
            fetchSubscribers(
                data ? data.page : 1,
                data ? data.sort.column : undefined,
                data ? data.sort.order : undefined
            )
        );
    }
};

export const fetchSubscribers = (page = 1, sortColumn, order) => {
    const sortQuery = sortColumn ? `&sort=${sortColumn}:${order}` : '';

    return {
        type: SubscribersActions.GET_SUBSCRIBERS,
        payload: {
            request: {
                url: `/admin/users?page=${page}${sortQuery}`,
                method: 'GET'
            }
        }
    };
};
