import { isVoid } from '@dive/utils-js';
import React from 'react';

import { getFullName } from '../../../../core/models/client';

const Receiver = ({ children, className }) => {
    const isCategory = isVoid(children.first_name);

    const content = isCategory ? children.name : getFullName(children);

    let classes = 'badge badge-' + (isCategory ? 'light' : 'border');

    if (!isVoid(className)) {
        classes += ` ${className}`;
    }

    return <span className={classes}>{content}</span>;
};

export default Receiver;
