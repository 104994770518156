import React from 'react';
import PropTypes from 'prop-types';

const ButtonToggleGroup = ({ className, buttons, value, onChange }) => {
    return (
        <div
            className={`d-flex justify-content-center btn-group btn-group-toggle ${className ||
                ''}`}
            data-toggle="buttons">
            {buttons.map(button => (
                <label
                    key={button.value}
                    className={`btn btn-secondary ${
                        button.value === value ? 'active' : ''
                    }`}>
                    <input
                        type="radio"
                        name={button.value}
                        id={button.value}
                        value={button.value}
                        onChange={e => onChange(e.target.value)}
                        checked={button.value === value}
                        autoComplete={'false'}
                    />{' '}
                    {button.label}
                </label>
            ))}
        </div>
    );
};

ButtonToggleGroup.propTypes = {
    buttons: PropTypes.arrayOf(PropTypes.object).isRequired,
    value: PropTypes.string,
    onChange: PropTypes.func.isRequired
};

export default ButtonToggleGroup;
