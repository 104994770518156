import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Header from './Header';
import { isAdmin } from '../../../../core/models/user';
import { logout } from '@dive/react-redux-networking';

const mapStateToProps = state => {
    return {
        user: state.user.data
    };
};

const mapDispatchToProps = dispatch => {
    return {
        logout: () => {
            dispatch(logout());
        }
    };
};

class HeaderContainer extends React.Component {
    render() {
        const { user } = this.props;
        return (
            <Header
                name={user.name || ''}
                email={user.email || ''}
                showFull={!this.props.basicMode}
                showSearch={!isAdmin(user) && this.props.showSearch}
                onLogoutClicked={this.props.logout}
            />
        );
    }
}

Header.propTypes = {
    basicMode: PropTypes.bool
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(HeaderContainer);
