import React from 'react';
import PropTypes from 'prop-types';
import Modal from './Modal';
import Icon from '../icon/Icon';

const DialogModal = ({ title, onDismissClicked, children, size = 'lg' }) => {
    return (
        <Modal
            className="modal-scroll"
            onDismissClicked={() => onDismissClicked()}
            size={size}>
            {
                <div className="modal-header">
                    <h1 className="modal-title">{title}</h1>
                    <button
                        type="button"
                        className="close btn-icon"
                        data-dismiss="modal"
                        onClick={() => onDismissClicked()}>
                        <Icon name="close" />
                    </button>
                </div>
            }
            <div className="modal-body">{children}</div>
        </Modal>
    );
};

DialogModal.propTypes = {
    title: PropTypes.string.isRequired,
    onDismissClicked: PropTypes.func.isRequired,
    size: PropTypes.string
};

export default DialogModal;
